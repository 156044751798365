import EmergencyNapForm from "components/daily/EmergencyNapForm";
import { DailyServer, DailyServerEmergencyNap } from "models/DailyServer";
import { ChangeEvent, useEffect, useState } from "react";

export interface EmergencyNapFormScreenProps {
  name?: string;
  dayNumber: number;
  serverData: DailyServer;
  onSaveData: (data: DailyServer) => void;
  onRemoveEN: () => void;
}

const EmergencyNapFormScreen: React.FC<EmergencyNapFormScreenProps> = ({
  name,
  dayNumber,
  serverData,
  onSaveData,
  onRemoveEN,
}) => {
  const [sleep, setSleep] = useState<string | undefined>(undefined);
  const [slept, setSlept] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSleep(value);
  };

  const onSlept = (value: boolean) => {
    if (value) {
      setSlept(true);
      return;
    }
    onRemoveEN();
  };

  const onSave = () => {
    const serverEmergencyNapData: DailyServerEmergencyNap = {
      used: slept,
      time: sleep,
    };
    onSaveData({ ...serverData, emergencyNap: serverEmergencyNapData });
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (serverData && serverData.emergencyNap) {
      setSlept(serverData.emergencyNap.used || false);
      setSleep(serverData.emergencyNap.time);
      setIsEditing(false);
    } else {
      setSlept(false);
      setSleep(undefined);
      setIsEditing(true);
    }
  }, [serverData]);

  return (
    <EmergencyNapForm
      name={name}
      isEditing={isEditing}
      sleep={sleep}
      slept={slept}
      onSlept={onSlept}
      onTimeChange={onTimeChange}
      onSave={onSave}
      onEdit={onEdit}
    />
  );
};

export default EmergencyNapFormScreen;
