import NextLink from "components/utils/NextLink";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { scheduleStrings } from "data/platform/sleep-stealers/scheduleStrings";
import StealersRate from "../StealersRate";

export interface ScheduleDescriptionProps {
  name?: string;
  rate: number;
}

const ScheduleDescription: React.FC<ScheduleDescriptionProps> = ({
  name,
  rate,
}) => {
  return (
    <PlatformSection>
      <PlatformDivider />
      <StealersRate rate={rate} />
      <div className="flex flex-col space-y-4">
        {rate === 5 ? (
          <p className="text-justify">
            {scheduleStrings.completedRateDesc(name)}
          </p>
        ) : (
          <p className="text-justify">
            {scheduleStrings.lowRateDesc(rate, name)}
          </p>
        )}
        <p>{scheduleStrings.letsMove}</p>
        <NextLink to="/platform/sleep-stealers/night-feeding" />
      </div>
    </PlatformSection>
  );
};

export default ScheduleDescription;
