import { WhatsMissingItem } from "models/WMItem";
import { FaSquare, FaRegCheckSquare } from "react-icons/fa";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { platformStrings } from "data/platform/platformStrings";

export interface WMListItemProps extends WhatsMissingItem {
  isExapnded: boolean;
  isMoreExpand: boolean;
  toggleExpand: () => void;
  toggleMoreExpand: () => void;
  onCheck: () => void;
}

const WMListItem: React.FC<WMListItemProps> = ({
  title,
  description,
  isExapnded,
  isChecked,
  more,
  isMoreExpand,
  toggleMoreExpand,
  toggleExpand,
  onCheck,
}) => {
  const textColor = isChecked ? "text-jungle-400" : "text-jasmine";

  const Icon: React.FC = () =>
    isChecked ? (
      <FaRegCheckSquare className="cursor-pointer" />
    ) : (
      <FaSquare onClick={onCheck} className="cursor-pointer" />
    );

  return (
    <div className={`${textColor} flex flex-col`}>
      <div className="flex flex-row items-center border-t border-navy py-4 px-8">
        <div className="w-1/12">
          <Icon />
        </div>
        <div className="w-10/12">
          <p className="text-sm text-justify px-2">{title}</p>
        </div>
        <div className="1/12">
          {description && (
            <span onClick={toggleExpand} className="text-lg cursor-pointer">
              {isExapnded ? <BiChevronUpCircle /> : <BiChevronDownCircle />}
            </span>
          )}
        </div>
      </div>
      {isExapnded && (
        <div>
          <p className="text-xs leading-relaxed text-justify px-8 pb-4">
            {description}
          </p>
          {more && (
            <span
              onClick={toggleMoreExpand}
              className="block text-xs text-orange font-semibold cursor-pointer select-none px-12 pb-4"
            >
              {isMoreExpand ? platformStrings.hideMore : platformStrings.more}
            </span>
          )}
        </div>
      )}
      {isExapnded && isMoreExpand && (
        <div>
          <p className="text-xs leading-relaxed text-justify px-8 pb-4">
            {more}
          </p>
        </div>
      )}
    </div>
  );
};

export default WMListItem;
