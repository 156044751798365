import PlanIntro from "components/plan-intro";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { methodNameState } from "recoil/selectors/methodName";

const PlanIntroScreen: React.FC = () => {
  const { name } = useRecoilValue(babyState);
  const methodName = useRecoilValue(methodNameState);
  const [step, setStep] = useState<number>(0);

  const onNextStep = (currStep: number) => {
    setStep(currStep + 1);
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [step]);

  return (
    <PlanIntro
      name={name}
      method={methodName}
      step={step}
      onNextStep={onNextStep}
    />
  );
};

export default PlanIntroScreen;
