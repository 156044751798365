export const habitErrors = (name?: string) => ({
  beforeSleepActs1: {
    title: `از پستونک فقط حین به خواب رفتن استفاده کنین:`,
    description: `انجمن پزشکی کودکان آمریکا اعلام کرده که استفاده از پستونک هنگام خواب از بروز سندروم مرگ ناگهانی نوزاد جلوگیری میکنه. `,
    more: `وقتی ${name} رو میخوابونین، باید پستونک رو در دهانش نگه دارین تا به خواب فرو بره. با توجه به گفته انجمن پزشکی کودکان آمریکا، پس از به خواب رفتن و در طول شب و چرت روزانه نیازی به استفاده از پستونک نیست، پس نباید بیش از این از پستونک استفاده کنین. استفاده از پستونک بعد از به خواب رفتن، باعث ایجاد ارتباطی قوی بین خواب و پستونک میشه و تمام تلاش های ${name} برای آروم کردن خودش رو از بین میبره.`,
  },
  beforeSleepActs2: {
    title: `اجازه ندین ${name} در حال تغذیه با شیر مادر به خواب فرو بره`,
    description: `شیر خوردن بخش اصلی فعالیت های قبل از خوابه، اما مطمئن بشین ${name} به خواب فرو نره. این کار ممکنه تغییر بزرگی برای شما باشه! در ویدئوها به شما نشون میدیم که چطور ${name} رو آروم اما بیدار نگه دارین.`,
    more: `اگه ${name} هنگام تغذیه با شیر مادر به خواب بره، اگه در طول شب خوابش سبک بشه، میترسه! "وای! آخرین چیزی که یادمه اینه که مامان اینجا بود و تو بغلش بودم و شیر میخوردم. اما الان نه مامان اینجا نیست و نه دارم شیر میخورم. یادم نمیاد کی اومدم تو تخت. کمک!" بعد ${name} گریه میکنه و شما هم میرین سراغش تا همون وضعیتی رو ایجاد کنین که قبل از خواب تجربه کرده و بهش شیر میدین. ${name} میخوابه، اما این دور باطل کل شب تکرار میشه. راز قرار دادن ${name} در تخت خواب برای یه خواب راحت اینه که ${name} خودش به خواب فرو بره تا: ۱) در طول شب دلیلی برای بیدار شدن نداشته باشه، چون شرایط مثل قبله و ۲) حتی اگرم بیدار بشه، خودش دوباره بخوابه، چون از اول هم خودش خوابیده بود!`,
  },
  beforeSleepActs3: {
    title: `اجازه ندین ${name} در حال تغذیه با شیر خشک به خواب فرو بره`,
    description: `شیر خوردن بخش اصلی فعالیت های قبل از خوابه، اما مطمئن بشین ${name} به خواب فرو نره. این کار ممکنه تغییر بزرگی برای شما باشه! در ویدئوها به شما نشون میدیم که چطور ${name} رو آروم اما بیدار نگه دارین.`,
    more: `اگه ${name} هنگام تغذیه با شیر خشک به خواب بره، اگه در طول شب خوابش سبک بشه، میترسه! "وای! آخرین چیزی که یادمه اینه که مامان اینجا بود و تو بغلش بودم و شیر میخوردم. اما الان نه مامان اینجا نیست و نه دارم شیر میخورم. یادم نمیاد کی اومدم تو تخت. کمک!" بعد ${name} گریه میکنه و شما هم میرین سراغش تا همون وضعیتی رو ایجاد کنین که قبل از خواب تجربه کرده و بهش شیر بدید. ${name} میخوابه، اما این دور باطل کل شب تکرار میشه. راز قرار دادن ${name} در تخت خواب برای یه خواب راحت اینه که ${name} خودش به خواب فرو بره تا: ۱) در طول شب دلیلی برای بیدار شدن نداشته باشه، چون شرایط مثل قبله و ۲) حتی اگرم بیدار بشه، خودش دوباره بخوابه، چون از اول هم خودش خوابیده بود!`,
  },
  beforeSleepActs4: {
    title: `برای خوابوندن ${name}، تکونش ندهید و اونو تو بغلتون راه نبرید.`,
    description: `در طول دوره آموزش خواب، هدف اینه که به ${name} یاد بدین در تختخواب خودش به خواب بره.`,
    more: `انجمن پزشکی کودکان آمریکا پیشنهاد میکنه که نوزادها همیشه روی سطحی محکم و صاف به تنهایی بخوابن. از تکون دادن ${name} تنها به مدت کوتاه و برای آروم کردن اون استفاده کنید. سعی کنید اونو به این حرکات عادت ندید. اگه دیدید که ${name} هنگام تکون دادن چرت می‌زنه، سریعا اونو به رخت خواب ببرید تا به حالت طبیعی به خواب بره و سعی کنید این کار رو دیگه انجام ندید.`,
  },
  beforeSleepActs5: {
    title: `برای خوابوندن ${name}، از ننو، ماشین، کالسکه یا تاب استفاده نکنین`,
    description: `در طول دوره آموزش خواب، هدف ما اینه که به ${name} یاد بدین در تختخواب خودش بخوابه. به علاوه انجمن پزشکی کودکان آمریکا بیان میکنه که بچه ها هنگام خواب باید به پشت قرار داده بشن.`,
    more: `انجمن پزشکی کودکان آمریکا پیشنهاد میکنه که نوزادها همیشه روی سطحی محکم و صاف به تنهایی بخوابن. طبق توصیه انجمن پزشکی کودکان آمریکا، اگه ${name} در ننو، ماشین، کالسکه، یا تاب به خواب رفت، باید خیلی سریع اون رو روی سطحی صاف و محکم قرار بدین.`,
  },
  beforeSleepActs6: {
    title: `قبل خواب یا وقتی ${name} از خواب میپره، اون رو در آغوش نگیرین`,
    description: `در آغوش گرفتن ${name} وقت خواب، توانایی اون برای آروم کردن خودش رو کاهش میده. وقتی ${name} هنوز بیداره اون رو در تختخواب قرار بدین تا یاد بگیره خودش بخوابه. اگه معنی ۱ حالت کاملا بیدار و ۱۰ حالت کاملا خواب باشه، وقتی ${name} رو در تختخواب قرار میدین نباید سطح هوشیاری اون بیشتر از ۳ یا ۴ باشه. در ویدئوها به شما نشون میدیم تا چطور ${name} رو بیدار نگه دارین.`,
    more: `اگه ${name} در آغوش شما به خواب بره، اگه در طول شب خوابش سبک بشه، میترسه! "وای! آخرین چیزی که یادمه اینه که مامان و بابا اینجا بودن و من تو بغلشون بودم. اما الان نه خبری از مامان هست نه بابا و یادم نمیاد اومده باشم تو تختخواب. کمک!" بعد ${name} گریه میکنه و شما به سمتش میرین تا اون رو در وضعیتی قرار بدین که قبل خواب تجربه کرده و طبیعتا بغلش میکنین. ${name} دوباره میخوابه، اما این دور باطل کل شب تکرار میشه. راز خواب راحت شبانه، اینه که اجازه بدین ${name} خودش به خواب فرو بره تا:۱) از اونجایی که شرایط تغییری نکردن، دلیلی برای بیدار شد نداشته باشه و ۲) حتی اگر هم بیدار بشه، خودش دوباره بخوابه، چون این کار رو یاد گرفته!`,
  },
  beforeSleepActs7: {
    title: `هنگامی که ${name} شبا می‌خواد بخوابه، یا از خواب می‌پره و دوباره میخواد به خواب بره و یا هنگامی که در طول روز می‌خواد چرت بزنه، کنارش دراز نکشید.`,
    description: `خوابیدن شما در کنار اون مانع رشد تواناییش برای آروم کردن خودش میشه. به جای اینکه در کنارش بخوابید بهتره در حالی که خودتون بیدارید، اجازه بدید ${name} بخوابه تا خودش یاد بگیره که چطور باید به خواب فرو بره.`,
    more: `اگر برای خوابوندن ${name} خودتون هم کنارش دراز بکشید، ممکنه شما هم خوابتون ببره. در حالی که آکادمی پزشکی اطفال، خوابیدن روی تخت کودک رو به هیچ  عنوان توصیه نمی‌کنه چرا که این امر می‌تونه خطر خفگی در اثر خوابیدن والدین روی تخت کودکو افزایش بده. همچنین اگر ${name} کنار شما بخوابه، هنگامی که در طول شب به چرخه خواب سبک‌تر وارد بشه یا بیدار شه و شما رو کنار خودش نبینه، دچار ترس و وحشت می‌شه و با خودش میگه: "هی! من آخرین چیزی‌که یادم میاد اینه که مامان یا بابا اینجا کنار من خوابیده بودن. اما الان اونا اینجا نیستن، و اصلا یادم نمیاد که چه کسی منو روی تختم گذاشته. کمک!" بعد به گریه می‌افته و البته شما برای آروم کردنش فوراً میرید پیشش و تلاش می‌کنید که باز همون شرایط رو براش فراهم کنید تا به خواب بره. یعنی کنارش می‌خوابید تا دوباره اونو بخوابونید. و این دور باطل دوباره در طول شب تکرار می‌شه. راز خواب راحت شبانه، اینه که اجازه بدین ${name} خودش به خواب فرو بره تا: ۱) از اونجایی که شرایط تغییری نکردن، دلیلی برای بیدار شد نداشته باشه و ۲) حتی اگر هم بیدار بشه، خودش دوباره بخوابه، چون از اولشم خودش خوابیده بود!`,
  },
  fallingSleepDuration: {
    title: `وقتی تمامی موانع خواب ${name} رو برطرف کنید، سریع تر به خواب فرو میره:`,
    description: `حسابی تلاش میکنین تا ${name} بخوابه، اما این کار مدتی زمان میبره. این شرایط حتما برای شما و ${name} مشکله. وقتی ${name} یاد بگیره که خودش رو آروم کنه، میتونه در مدت 15 دقیقه یا کمتر به خواب فرو بره.`,
    more: ``,
  },
  awakeScale: {
    title: `قبل از اینکه ${name} کاملا به خواب بره، اون رو در تختخواب قرار بدین`,
    description: `میدونیم که شما وسوسه میشین تا ${name} حسابی خواب آلود بشه و بعد اون رو در تختخواب بذارین، اما این کار باعث میشه نتونه خودش رو آروم کنه و به خواب بره. اگه معنی ۱ حالت کاملا بیدار و ۱۰ حالت کاملا خواب باشه، وقتی ${name} رو در تختخواب قرار میدین نباید سطح هوشیاریش بیشتر از ۳ یا ۴ باشه. در ویدئوها بهتون نشون میدیم چطور در فعالیت های قبل از خواب ${name} رو بیدار نگه دارین`,
    more: ``,
  },
});
