import React from "react";
import Layout from "components/landing/Layout";
import Hero from "components/landing/Hero/Container";
import Introduction from "components/landing/Introduction/Container";
import Gallery from "components/landing/Gallery/Container";
import Features from "components/landing/Features/Container";
import Music from "components/landing/Music/Container";
import Why from "components/landing/Why/Container";
import Comments from "components/landing/Comments/Container";
import Facility from "components/landing/Facility/Container";

export interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <React.Fragment>
      <Layout lang="fa" dir="rtl">
        <Hero />
        <Introduction />
        <Gallery />
        <Features />
        <Music />
        <Why />
        <Comments />
        <Facility />
      </Layout>
    </React.Fragment>
  );
};

export default Home;
