import { dailyStrings } from "data/platform/daily/dailyStrings";

export interface LastDayEndProps {
  name?: string;
  onAddDay: () => void;
  onFinish: () => void;
}

const LastDayEnd: React.FC<LastDayEndProps> = ({
  name,
  onAddDay,
  onFinish,
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <p className="text-jungle-400 font-semibold text-2xl">
        {dailyStrings.lastDayTitle}
      </p>
      <p>{dailyStrings.lastDayDescription(name)}</p>
      <div className="flex flex-col space-y-6">
        <button
          onClick={onAddDay}
          className="py-2 border border-jasmine text-lg text-jasmine rounded-lg transition duration-200 hover:text-jungle-400 hover:border-jungle-400"
        >
          {dailyStrings.addDay}
        </button>
        <button
          onClick={onFinish}
          className="py-2 border border-jasmine text-lg text-jasmine rounded-lg transition duration-200 hover:text-jungle-400 hover:border-jungle-400"
        >
          {dailyStrings.endDreamlab}
        </button>
      </div>
    </div>
  );
};

export default LastDayEnd;
