import { toPersian } from "utils/raqam";

export const nightStrings = {
  nighttime: "شب",
  winddown: "زمان فعالیت های قبل از خواب",
  bedtime: "زمان خواب",
  bedtimeTooltip:
    "ساعت خواب شبانه همواره باید ثابت باشه، به این دلیل که چرخه خواب روزانه کودک شما منظم و قابل پیش بینی بشه. تنها استثنا در مورد این قاعده، اینه که اگر کودک در طول روز چرت های خیلی کوتاهی داشت، شب اونو کمی زودتر بخوابونید که در این صورت هم ساعت بیدار شدن صبحگاهی هیچ تغییری نمیکنه.",
  sleepLabel: (name?: string) =>
    `چه زمانی ${name} رو برای خواب شبانه در تخت گذاشتین؟`,
  durationLabel: (name?: string) => `چقدر طول کشید تا ${name} به خواب فرو بره؟`,
  feed: (time: number) => `شیردهی ${toPersian(time)}`,
  addNightWaking: "افزودن بیداری شبانه",
  nightWakingTitle: (index: number) => `بیداری شبانه ${toPersian(index)}`,
  nightWakingTimeLabel: (name?: string) => `${name} چه ساعتی از خواب بیدار شد؟`,
  nightWakingDurationLabel: (name?: string) =>
    `چه مدت طول کشید تا ${name} دوباره بخوابه؟`,
  wokeUpTime: "زمان بیداری",
  fallSleepDuration: "مدت دوباره به خواب رفتن",
};
