import { IFeedingItem } from "models/FeedingItem";
import { addTime, isAfterTime, isBeforeTime, subtractTime } from "./timeUtils";

const checkContinuePlanning = (lastNoshing: IFeedingItem[]) => {
  for (const noshing of lastNoshing) {
    if (noshing.duration !== "Unlimited" && Number(noshing.duration) > 0)
      return true;
  }
  return false;
};

export const weaningPlanGenerator = (
  age: number,
  weight: number,
  nightNoshingData: IFeedingItem[],
  milkType: string
) => {
  let decrease_step_th,
    decrease_step_h,
    decrease_step_l = 0;
  if (milkType !== "bottle") {
    decrease_step_th = 20; //minute
    decrease_step_h = 5; //minute
    decrease_step_l = 2; //minute
  } else {
    decrease_step_th = 300; //ml.
    decrease_step_h = 75; //ml.
    decrease_step_l = 30; //ml.
  }

  let plan_table: Array<IFeedingItem[]> = [];
  const age_th = 6;
  const weight_th = 7.25;

  if (nightNoshingData.length === 0) return plan_table;

  let firstNoshing: IFeedingItem[] = [];

  if (age >= age_th && weight > weight_th) {
    firstNoshing.push({
      time: "22:00",
      duration: nightNoshingData[0].duration,
    });
  } else {
    firstNoshing.push({ time: "22:00", duration: "Unlimited" });
  }

  for (let index = 1; index < nightNoshingData.length; index++) {
    const noshing_record = nightNoshingData[index];
    if (
      isAfterTime(
        firstNoshing[firstNoshing.length - 1].time,
        subtractTime(noshing_record.time, 120),
        true
      ) ||
      (isBeforeTime("08:00", subtractTime(noshing_record.time, 120), true) &&
        isAfterTime("18:00", firstNoshing[firstNoshing.length - 1].time, true))
    ) {
      firstNoshing.push({
        time: subtractTime(noshing_record.time, 60),
        duration: noshing_record.duration,
      });
    } else {
      firstNoshing.push({
        time: addTime(firstNoshing[firstNoshing.length - 1].time, 60),
        duration: noshing_record.duration,
      });
    }
  }

  plan_table.push(firstNoshing);

  while (checkContinuePlanning(plan_table[plan_table.length - 1])) {
    let noshingItem: IFeedingItem[] = [];

    for (const noshing of plan_table[plan_table.length - 1]) {
      if (noshing.duration === "Unlimited") {
        noshingItem.push(noshing);
      } else {
        if (Number(noshing.duration) > decrease_step_th) {
          noshingItem.push({
            time: noshing.time,
            duration: (Number(noshing.duration) - decrease_step_h).toString(),
          });
        } else {
          noshingItem.push({
            time: noshing.time,
            duration: Math.max(
              Number(noshing.duration) - decrease_step_l,
              0
            ).toString(),
          });
        }
      }
    }
    plan_table.push(noshingItem);
  }

  if (plan_table[plan_table.length - 1][0].duration === "Unlimited") {
    while (plan_table.length < 15) {
      plan_table.push([{ time: "22:00", duration: "Unlimited" }]);
    }
  }

  return plan_table;
};
