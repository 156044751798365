import PlatformContent from "components/layout/PlatfromContent";
import VideoPlayer from "components/utils/VideoPlayer";
import { faBrand, platformStrings } from "data/platform/platformStrings";
import { FaChevronLeft } from "react-icons/fa";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import NumBox from "../NumBox";

export interface MethodVideoProps {}

const MethodVideo: React.FC<MethodVideoProps> = () => {
  const { name } = useRecoilValue(babyState);
  const { code } = useParams<any>();

  return (
    <PlatformContent>
      <NavLink
        to="/platform/method-in-action"
        className="text-lg font-semibold text-left mb-6 block"
      >
        <span>{platformStrings.back}</span>
        <FaChevronLeft className="inline-block mr-2" />
      </NavLink>
      <VideoPlayer code={code} />
      <div className="flex flex-col space-y-12 mt-12 leading-relxed">
        <NumBox
          num={1}
          description="فعالیت های قبل از خواب رو شروع کنین"
          items={[
            `در تمام طول مدت فعالیت ها، ${name} رو بیدار نگه دارید.`,
            `این فعالیت ها رو در اتاقی انجام بدین که ${name} داخلش می خوابه.`,
            `هر شب، این فعالیت ها رو با ترتیب یکسان انجام بدین`,
            `وقتی ${name} رو توی تختخوابش گذاشتین نویز سفید رو پخش کنید`,
          ]}
        />
        <NumBox
          num={2}
          description={`${name} رو وقتی بیداره توی تختخوابش بذارین`}
          items={[
            `${name} باید خودش به خواب رفتن رو تمرین کنه`,
            `${name} به جای اینکه به شما وابسته باشه، می تونه خودش مهارتای آروم شدن برای خوابیدن رو یاد بگیره`,
            `${name} در صورتی که طی شب بیدار بشه، می تونه خودش خودشو دوباره بخوابونه`,
          ]}
        />
        <NumBox
          num={3}
          description={`ساعتی که ${name} رو در تختش گذاشتید رو توی ${faBrand} وارد کنید`}
        />
        {code === "1101" && (
          <NumBox
            num={4}
            description="بعد از ۵ دقیقه بهش سر بزنید(روش ملاقات)"
            items={[
              ` برید نزدیک تخت ${name}. اگر به اندازه ای بزرگ شده که میتونه توی تختش بایسته، کمی عقب تر بایستین`,
              `${name} باید بتونه شما رو ببینه و صداتونو بشنوه`,
              `با کلمات آرامش بخش، عاشقانه و مثبت با ${name} حرف بزنید`,
              `${name} رو لمس نکنید. لمس کردن ${name} ممکنه باعث بشه اون بیشتر گریه کنه`,
              `وقتی بهش سر میزنید بیشتر از ۳۰ ثانیه پیشش نمونید`,
              `اتاق رو ترک کنید`,
            ]}
          />
        )}
        {code === "1102" && (
          <NumBox
            num={4}
            description="بعد از ۵ دقیقه بهش سر بزنید(روش ماندن)"
            items={[
              `بعد از از اینکه ${name} رو توی تختش گذاشتید، خودتون روی زمین یا روی یه صندلی کنارش بشینید.`,
              `وقتی که زمان سر زدن برسه، ${name} باید شما رو ببینه و صداتونو بشنوه.`,
              `با کلمات آرامش بخش، عاشقانه و مثبت با ${name} حرف بزنید`,
              `${name} رو لمس نکنید. لمس کردن ${name} ممکنه باعث بشه اون بیشتر گریه کنه`,
              `وقتی بهش سر میزنید بیشتر از ۳۰ ثانیه پیشش نمونید`,
              `روی زمین یا روی یه صندلی کنارش بشینید.`,
            ]}
          />
        )}
        {code === "1103" && (
          <NumBox
            num={4}
            description="بعد از ۵ دقیقه بهش سر بزنید(روش لمسی)"
            items={[
              `بعد از از اینکه ${name} رو توی تختش گذاشتید، خودتون روی زمین یا روی یه صندلی کنارش بشینید.`,
              `اگه گریه ${name} کمه: همون طوری در حالت نشسته یا ایستاده برید نزدیک تا ${name} شما رو ببینه و صداتونو بشنوه (اگر به اندازه ای بزرگ شده که میتونه توی تختش بایسته، کمی عقب تر بایستین)`,
              `با کلمات آرامش بخش، عاشقانه و مثبت بدون اینکه ${name} رو لمس کنید باهاش حرف بزنید(حداکثر ۳۰ ثانیه)`,
              `اگر ${name} کمی بیشتر گریه کرد، برید پیشش و در حالی که از جملات آرامش بخش استفاده می‌کنید، اونو نوازش کنید و  به سکوت دعوتش کنید(حداکثر ۳۰ ثانیه).`,
              `اگر گریه‌ش شدت گرفت، اونو بلند کنید و با گفتن جملات آرام بخش آرومش کنید(حداکثر ۳۰ ثانیه).`,
              `بعد هم دوباره روی زمین یا صندلی کنارش بشینید.`,
            ]}
          />
        )}
        <NumBox
          num={5}
          description={`بعد از ده دقیقه و بعد از اون هر ۱۵ دقیقه یک بار به ${name} سر بزنید`}
          items={[
            `۱۵ دقیقه طولانی‌ترین فاصله بین سر زدن هاست`,
            `سر زدن هاتونو هر ۱۵ دقیقه یک بار ادامه بدید تا وقتی که ${name} کاملا به خواب بره`,
          ]}
        />
        <NumBox
          num={6}
          description={`زمان چرت های روزانه ${name}  رو در ${faBrand} وارد کنید`}
        />
        <NumBox
          description="پیشنهاد های مفید:"
          items={[
            "حواستون به ساعت باشه و خودتونو با برنامه هماهنگ کنید",
            `اگر ${name} بیش از حد خسته بشه، ممکنه به سختی به خواب بره، در طول زمان چرت ها مدام بیدار شه و یا حتی خیلی زود تر از زمان برنامه ریزی شده از چرت بیدار بشه.`,
          ]}
        />
      </div>
    </PlatformContent>
  );
};

export default MethodVideo;
