import React from "react";
import Header from "./Header";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { commentList } from "data/landing/comments";
import Item from "./Item";
import dotPNG from "images/dot-box.png";

SwiperCore.use([Pagination]);

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section id="comments" className="bg-gray-100 relative">
      <div className="container mx-auto py-12 px-6 lg:py-16" dir={dir}>
        <Header lang={lang} />

        <Swiper
          className="rounded-xl"
          pagination={{ clickable: true }}
          breakpoints={{
            "640": {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            "768": {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            "1024": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {commentList(lang).map((item, index) => (
            <SwiperSlide key={index}>
              <Item {...item} dir={dir} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <img src={dotPNG} alt="dot-box" className="absolute comment-dots" />
    </section>
  );
};

export default Container;
