import { useMutation } from "@apollo/client";
import BlueButton from "components/utils/BlueButton";
import { appStrings } from "data/landing/strings";
import { PURCHASE } from "graphql/queries/purchase";
import { Fragment, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { authState } from "recoil/atoms/auth";
import { getQueryStringValue } from "utils/queryString";
import PurchaseModal from "./PurchaseModal";

export interface PurchaseButtonProps {
  lang?: string;
  dir?: string;
}

const PurchaseButton: React.FC<PurchaseButtonProps> = ({
  lang = "fa",
  dir = "rtl",
}) => {
  const auth = useRecoilValue(authState);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [link, setLink] = useState(undefined);
  const [price, setPrice] = useState(undefined);

  const closeModal = () => setOpenModal(false);

  const openPurchaseModal = () => setOpenModal(true);

  const [purchase, { loading }] = useMutation(PURCHASE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const invoice = data.MARKET_purchase_dreamlab;
      const totalPrice = invoice.total;
      const serverLink =
        invoice.payments && invoice.payments.length > 0
          ? invoice.payments[0].url
          : "#";
      setPrice(totalPrice);
      setLink(serverLink);
      openPurchaseModal();
    },
  });

  const handlePurchase = useCallback(() => {
    if (auth) {
      purchase();
      return;
    }
    const url = `${window.location.href}?purchase=true`;
    window.location.assign(
      `${process.env.REACT_APP_ACCOUNT_URL}/login/?from=${encodeURIComponent(
        url
      )}`
    );
  }, [purchase, auth]);

  useEffect(() => {
    const isPurchase = getQueryStringValue("purchase") === "true";
    isPurchase && auth === true && handlePurchase();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <BlueButton onClick={handlePurchase} loading={loading}>
        {appStrings.purchase[lang]}
      </BlueButton>
      {openModal && price && link && (
        <PurchaseModal
          open={openModal}
          price={price || 0}
          link={link || ""}
          onClose={closeModal}
        />
      )}
    </Fragment>
  );
};

export default PurchaseButton;
