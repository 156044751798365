import SingleChoice from "components/form-elements/SingleChoice";
import SliderInput from "components/form-elements/SliderInput";
import NextLink from "components/utils/NextLink";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import VideoPlayer from "components/utils/VideoPlayer";
import { methodSelectionStrings } from "data/platform/methodSelectionStrings";
import { methodOptions } from "data/platform/methodsOptions";
import { platformStrings } from "data/platform/platformStrings";
import { ynOptions } from "data/platform/ynOptions";
import { MethodFormData } from "models/MethodFormData";
import { SliderValue } from "models/SliderValue";
import { Fragment } from "react";
import { toPersian } from "utils/raqam";

export interface MethodSelectionFormProps extends MethodFormData {
  formStep: number;
  name?: string;
  touchPercentage: number;
  visitPercentage: number;
  stayPercentage: number;
  formCompleted: boolean;
  loading: boolean;
  onSliderChange: (value: SliderValue, key: string) => void;
  onSingleChange: (value: string | boolean | number, key: string) => void;
  onNextFormStep: (currStep: number) => void;
  onFormCompleted: () => void;
}

const MethodSelectionForm: React.FC<MethodSelectionFormProps> = ({
  name,
  parentReducingInteraction,
  sitterReducingInteraction,
  leavingUpset,
  method,
  usedSleepPlan,
  formStep,
  helpScale,
  interactionLevel,
  touchPercentage,
  stayPercentage,
  visitPercentage,
  formCompleted,
  loading,
  onSliderChange,
  onFormCompleted,
  onNextFormStep,
  onSingleChange,
}) => {
  return (
    <div>
      <PlatformSection isPassed={formStep > 1}>
        <PlatformDivider />
        <SliderInput
          label={methodSelectionStrings.interactionLabel}
          hint={methodSelectionStrings.interactionHint(name)}
          minHint={methodSelectionStrings.interactionMin}
          maxHint={methodSelectionStrings.interactionMax}
          min={1}
          max={5}
          value={interactionLevel}
          onChange={(value) => onSliderChange(value, "interactionLevel")}
        />
        {formStep < 2 && <NextStep onNext={() => onNextFormStep(1)} />}
      </PlatformSection>
      {formStep > 1 && (
        <PlatformSection isPassed={formStep > 2}>
          <PlatformDivider />
          <SliderInput
            label={methodSelectionStrings.reducingParentInteractionLabel}
            hint={methodSelectionStrings.reducingParentInteractionHint(name)}
            minHint={methodSelectionStrings.reducingParentInteractionMin}
            maxHint={methodSelectionStrings.reducingParentInteractionMax}
            min={1}
            max={5}
            value={parentReducingInteraction}
            onChange={(value) =>
              onSliderChange(value, "parentReducingInteraction")
            }
          />
          {formStep < 3 && <NextStep onNext={() => onNextFormStep(2)} />}
        </PlatformSection>
      )}
      {formStep > 2 && (
        <PlatformSection isPassed={formStep > 3}>
          <PlatformDivider />
          <SliderInput
            label={methodSelectionStrings.reducingSitterInteractionLabel(name)}
            hint={methodSelectionStrings.reducingSitterInteractionHint(name)}
            minHint={methodSelectionStrings.reducingSitterInteractionMin}
            maxHint={methodSelectionStrings.reducingSitterInteractionMax}
            min={1}
            max={5}
            value={sitterReducingInteraction}
            onChange={(value) =>
              onSliderChange(value, "sitterReducingInteraction")
            }
          />
          {formStep < 4 && <NextStep onNext={() => onNextFormStep(3)} />}
        </PlatformSection>
      )}
      {formStep > 3 && (
        <PlatformSection isPassed={formStep > 4}>
          <PlatformDivider />
          <SliderInput
            label={methodSelectionStrings.needingHelpLabel(name)}
            minHint={methodSelectionStrings.needingHelpMin}
            maxHint={methodSelectionStrings.needingHelpMax}
            min={1}
            max={5}
            value={helpScale}
            onChange={(value) => onSliderChange(value, "helpScale")}
          />
          {formStep < 5 && <NextStep onNext={() => onNextFormStep(4)} />}
        </PlatformSection>
      )}
      {formStep > 4 && (
        <PlatformSection isPassed={usedSleepPlan !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={methodSelectionStrings.usedSleepHintsLabel}
            options={ynOptions}
            selected={usedSleepPlan}
            onSelect={(value) => onSingleChange(value, "usedSleepPlan")}
          />
        </PlatformSection>
      )}
      {formStep > 4 && usedSleepPlan && (
        <PlatformSection isPassed={leavingUpset !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={methodSelectionStrings.leavingUpsetLabel(name)}
            options={ynOptions}
            selected={leavingUpset}
            onSelect={(value) => onSingleChange(value, "leavingUpset")}
          />
        </PlatformSection>
      )}
      {((formStep > 4 && usedSleepPlan === false) ||
        (formStep > 4 && leavingUpset !== undefined)) && (
        <Fragment>
          <PlatformDivider />
          <div className="flex flex-col space-y-6 text-justify">
            <p>{methodSelectionStrings.suggestionTitle}</p>
            <div>
              <p>
                {toPersian(visitPercentage)}% {methodSelectionStrings.with}{" "}
                {platformStrings.visitMethod}
              </p>
              <p>
                {toPersian(stayPercentage)}% {methodSelectionStrings.with}{" "}
                {platformStrings.stayMethod}
              </p>
              <p>
                {toPersian(touchPercentage)}% {methodSelectionStrings.with}{" "}
                {platformStrings.touchMethod}
              </p>
              <p>{methodSelectionStrings.suggestionEnd}</p>
            </div>
            <p>{methodSelectionStrings.suggestionDescription(name)}</p>
            <div className="flex flex-col space-y-8 font-semibold">
              <p>{platformStrings.visitMethod}</p>
              <VideoPlayer code="5" />
              <p>{platformStrings.stayMethod}</p>
              <VideoPlayer code="6" />
              <p>{platformStrings.touchMethod}</p>
              <VideoPlayer code="7" />
            </div>
          </div>
          <SingleChoice
            label={methodSelectionStrings.methodLabel}
            options={methodOptions}
            selected={method}
            onSelect={(value) => onSingleChange(value, "method")}
          />
          <NextStep loading={loading} onNext={onFormCompleted} />
        </Fragment>
      )}
      {formCompleted && (
        <PlatformSection>
          <PlatformDivider />
          <p>{methodSelectionStrings.done}</p>
          <NextLink to="/platform/plan-intro" />
        </PlatformSection>
      )}
    </div>
  );
};

export default MethodSelectionForm;
