import DailyList from "components/daily/list";
import { platformStrings } from "data/platform/platformStrings";
import { IMenu } from "models/Menu";
import { useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";

const DailyListScreen: React.FC = () => {
  const app = useRecoilValue(appState);

  let links: IMenu[] = [];

  if (app.dayCount && app.dayCount > 0) {
    for (let index = 0; index < app.dayCount; index++) {
      const item: IMenu = {
        title: platformStrings.day(index + 1),
        link: `/platform/daily/${index + 1}`,
      };
      links.push(item);
    }
  }

  return <DailyList items={links} />;
};

export default DailyListScreen;
