import SelectBaby from "components/select-baby";
import { IBaby } from "models/Baby";
import { useState } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { babyState } from "recoil/atoms/baby";

const SelectBabyScreen: React.FC = () => {
  const setBaby = useSetRecoilState(babyState);
  const [selected, setSelected] = useState<IBaby | undefined>(undefined);
  const { push } = useHistory();

  const onSelect = (baby: IBaby) => {
    selected?.id === baby.id ? setSelected(undefined) : setSelected(baby);
  };

  const onNext = () => {
    if (selected) {
      setBaby(selected);
    }
    push("/platform/baby-info");
  };

  return <SelectBaby selected={selected} onNext={onNext} onSelect={onSelect} />;
};

export default SelectBabyScreen;
