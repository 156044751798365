import MultipleChoice from "components/form-elements/MultipleChoice";
import SingleChoice from "components/form-elements/SingleChoice";
import SliderInput from "components/form-elements/SliderInput";
import LockedAlert from "components/utils/LockedAlert";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import {
  cribToysOptions,
  roomOptions,
  roomTempOptions,
  sleepClothOptions,
} from "data/platform/sleep-stealers/envOptions";
import { envStrings } from "data/platform/sleep-stealers/envStrings";
import { ynOptions } from "data/platform/ynOptions";
import { SliderValue } from "models/SliderValue";
import { SleepStealerEnvData } from "models/SPE";
import { Fragment } from "react";

export interface EnvFormProps extends SleepStealerEnvData {
  name?: string;
  multipleDone: boolean;
  sliderDone: boolean;
  formCompleted: boolean;
  loading: boolean;
  locked: boolean;
  onSingleChange: (value: number | string | boolean, key: string) => void;
  onMultipleChange: (value: number | boolean | string) => void;
  onSliderChange: (value: SliderValue) => void;
  onMultipleAnswered: () => void;
  onSliderAnswered: () => void;
  onFormCompleted: () => void;
}

const EnvForm: React.FC<EnvFormProps> = ({
  name,
  cribToys,
  multipleDone,
  whiteNoise,
  sliderDone,
  daylight,
  room,
  roomTemp,
  sameRoomSiblings,
  sleepBag,
  sleepCloth,
  loading,
  locked,
  onMultipleAnswered,
  onMultipleChange,
  onSingleChange,
  onSliderAnswered,
  onSliderChange,
  onFormCompleted,
}) => {
  return (
    <div>
      {locked && <LockedAlert />}
      <SingleChoice
        label={envStrings.roomLabel(name)}
        options={roomOptions}
        selected={room}
        onSelect={(value) => onSingleChange(value, "room")}
      />
      {room !== undefined && (
        <PlatformSection isPassed={sameRoomSiblings !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={envStrings.sameRoomWithSiblingsLabel(name)}
            options={ynOptions}
            selected={sameRoomSiblings}
            onSelect={(value) => onSingleChange(value, "sameRoomSiblings")}
          />
        </PlatformSection>
      )}
      {sameRoomSiblings !== undefined && (
        <PlatformSection isPassed={sleepBag !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={envStrings.sleepBagLabel(name)}
            options={ynOptions}
            selected={sleepBag}
            onSelect={(value) => onSingleChange(value, "sleepBag")}
          />
        </PlatformSection>
      )}
      {sleepBag !== undefined && (
        <Fragment>
          <PlatformDivider lowMargin />
          <MultipleChoice
            label={envStrings.cribToysLabel(name)}
            hint={envStrings.cribToysHint}
            options={cribToysOptions}
            selected={cribToys}
            onSelect={onMultipleChange}
          />
          {!multipleDone && <NextStep onNext={onMultipleAnswered} />}
        </Fragment>
      )}
      {multipleDone && (
        <PlatformSection isPassed={whiteNoise !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={envStrings.whiteNoiseLabel(name)}
            options={ynOptions}
            selected={whiteNoise}
            onSelect={(value) => onSingleChange(value, "whiteNoise")}
          />
        </PlatformSection>
      )}
      {whiteNoise !== undefined && (
        <PlatformSection isPassed={sliderDone}>
          <PlatformDivider />
          <SliderInput
            label={envStrings.daylightLabel(name)}
            hint={envStrings.daylightHint}
            min={1}
            max={10}
            value={daylight}
            onChange={onSliderChange}
          />
          {!sliderDone && <NextStep onNext={onSliderAnswered} />}
        </PlatformSection>
      )}
      {sliderDone && (
        <PlatformSection isPassed={sleepCloth !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={envStrings.sleepSuitLabel(name)}
            options={sleepClothOptions}
            selected={sleepCloth}
            onSelect={(value) => onSingleChange(value, "sleepCloth")}
          />
        </PlatformSection>
      )}
      {sleepCloth !== undefined && (
        <PlatformSection>
          <PlatformDivider />
          <SingleChoice
            label={envStrings.roomTempLabel(name)}
            options={roomTempOptions}
            selected={roomTemp}
            onSelect={(value) => onSingleChange(value, "roomTemp")}
          />
          {!locked && <NextStep loading={loading} onNext={onFormCompleted} />}
        </PlatformSection>
      )}
    </div>
  );
};

export default EnvForm;
