export interface MethodItemProps {
  icon: string;
  title: string;
}

const MethodItem: React.FC<MethodItemProps> = ({ icon, title }) => {
  return (
    <div>
      <img src={icon} alt={title} className="w-24 mx-auto mb-4" />
      <p className="text-center">{title}</p>
    </div>
  );
};

export default MethodItem;
