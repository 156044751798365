import React from "react";
import { facilityList } from "data/landing/facilities";
import Item from "./Item";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section
      className={`container mx-auto py-12 px-4 ${
        dir === "rtl" ? "lg:pr-24" : "lg:pl-24"
      }`}
      dir={dir}
    >
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:gap-12">
        {facilityList(lang).map((item, index) => (
          <Item key={index} {...item} dir={dir} />
        ))}
      </div>
    </section>
  );
};

export default Container;
