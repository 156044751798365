import { appStrings } from "./strings";
import featSupport from "images/features/feat-support.png";
import featExpert from "images/features/feat-expert.png";
import featVideo from "images/features/feat-video.png";
import featPersonalize from "images/features/feat-personalize.png";

export interface IFeatureItem {
  title: string;
  icon: string;
  description: string;
}

export const featuresList = (lang: string): IFeatureItem[] => [
  {
    title: appStrings.featureContact.title[lang],
    description: appStrings.featureContact.description[lang],
    icon: featSupport,
  },
  {
    title: appStrings.featureExpert.title[lang],
    description: appStrings.featureExpert.description[lang],
    icon: featExpert,
  },
  {
    title: appStrings.featureTutorial.title[lang],
    description: appStrings.featureTutorial.description[lang],
    icon: featVideo,
  },
  {
    title: appStrings.featurePersonalize.title[lang],
    description: appStrings.featurePersonalize.description[lang],
    icon: featPersonalize,
  },
];
