import { dailyStrings } from "data/platform/daily/dailyStrings";
import ContentWrapper from "../EmergencyNapDescriptions/ContentWrapper";
import AlertModal, { AlertModalProps } from "./AlertModal";

export interface WokeLateProps extends AlertModalProps {
  name?: string;
}

const WokeLate: React.FC<WokeLateProps> = (props) => {
  const { name } = props;
  return (
    <AlertModal {...props}>
      <ContentWrapper
        title={dailyStrings.wokeUpLateTitle(name)}
        description={dailyStrings.wokeUpLate(name)}
      >
        <p>{dailyStrings.wokeUpLateNote(name)}</p>
      </ContentWrapper>
    </AlertModal>
  );
};

export default WokeLate;
