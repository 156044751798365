import { IOption } from "models/Option";
import { envStrings } from "./envStrings";

export const roomOptions: IOption[] = [
  {
    label: envStrings.sameRoom,
    value: true,
  },
  {
    label: envStrings.seperateRoom,
    value: false,
  },
];

export const cribToysOptions: IOption[] = [
  {
    label: envStrings.cloth,
    value: 1,
  },
  {
    label: envStrings.stuffedToy,
    value: 2,
  },
  {
    label: envStrings.blanket,
    value: 3,
  },
  {
    label: envStrings.wedge,
    value: 4,
  },
  {
    label: envStrings.bumper,
    value: 5,
  },
  {
    label: envStrings.pillow,
    value: 6,
  },
  {
    label: envStrings.toys,
    value: 7,
  },
  {
    label: envStrings.nothingInCrib,
    value: 8,
  },
];

export const sleepClothOptions: IOption[] = [
  {
    label: envStrings.swaddleArmsLegs,
    value: 1,
  },
  {
    label: envStrings.swaddle,
    value: 2,
  },
  {
    label: envStrings.sleepSack,
    value: 4,
  },
  {
    label: envStrings.pjs,
    value: 6,
  },
  {
    label: envStrings.pjsFeet,
    value: 7,
  },
];

export const roomTempOptions: IOption[] = [
  {
    label: envStrings.low,
    value: 1,
  },
  {
    label: envStrings.medium,
    value: 2,
  },
  {
    label: envStrings.high,
    value: 3,
  },
];
