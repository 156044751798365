import PlatformContent from "components/layout/PlatfromContent";
import { platformStrings } from "data/platform/platformStrings";
import { ticketStrings } from "data/platform/ticketStrings";
import { Ticket } from "models/Ticket";
import { NavLink } from "react-router-dom";
import TicketItemScreen from "screens/tickets/Item";

export interface TicketsProps {
  tickets: Ticket[];
  showLoadMore: boolean;
  loadMore: () => void;
}

const Tickets: React.FC<TicketsProps> = ({
  tickets,
  showLoadMore,
  loadMore,
}) => {
  if (tickets.length === 0) {
    return (
      <PlatformContent>
        <p className="text-jungle-400 font-semibold text-2xl">
          {ticketStrings.ticketList}
        </p>
        <p className="my-8">{ticketStrings.noTicket}</p>
        <p className="text-center mt-16">
          <NavLink
            to="/platform/ask-question"
            className="py-2 px-4 border-2 border-jungle-400 rounded-lg font-semibold text-jungle-400"
          >
            {ticketStrings.askQuestion}
          </NavLink>
        </p>
      </PlatformContent>
    );
  }

  return (
    <PlatformContent>
      <p className="text-jungle-400 font-semibold text-2xl">
        {ticketStrings.ticketList}
      </p>
      <div className="mt-10 flex flex-col space-y-8">
        {tickets.map((ticket, index) => (
          <TicketItemScreen ticket={ticket} key={index} />
        ))}
      </div>
      {showLoadMore && (
        <div className="h-1 w-full mt-12 bg-jasmine bg-opacity-30 flex items-center justify-center cursor-pointer select-none">
          <span onClick={loadMore} className="bg-navy px-3">
            {platformStrings.loadMore}
          </span>
        </div>
      )}
    </PlatformContent>
  );
};

export default Tickets;
