import { useQuery } from "@apollo/client";
import GoalSchedule from "components/sleep-plan/goal-schedule";
import PageLoader from "components/utils/PageLoader";
import { GET } from "graphql/queries/store";
import { IFeedingItem } from "models/FeedingItem";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { bedtimeState } from "recoil/atoms/bedtime";
import { winddownState } from "recoil/atoms/winddown";
import { morningBoxDataGenerator } from "utils/morningScheduleGenerator";
import { weaningPlanGenerator } from "utils/nightFeedingScheduleGenerator";
import { parseStoreData, valueGetter } from "utils/storeParser";
import { minuteDiffer, subtractTime } from "utils/timeUtils";

const GoalScheduleScreen: React.FC = () => {
  const { name, age, id } = useRecoilValue(babyState);
  const [bedtime, setBedtime] = useRecoilState(bedtimeState);
  const [winddwon, setWinddown] = useRecoilState(winddownState);
  const [feedingCount, setFeedingCount] = useState<number>(0);
  const [feedingMethod, setFeedingMethod] = useState<string>("mother");
  const [weight, setWeight] = useState<number>(0);
  const [noshingInputs, setNoshingInputs] = useState<IFeedingItem[]>([]);
  const [scheduleAge, setScheduleAge] = useState<number | undefined>(undefined);

  const ffNoshingInput: IFeedingItem[] = [
    { time: "22:00", duration: "25" },
    { time: "00:00", duration: "15" },
    { time: "01:30", duration: "30" },
    { time: "03:00", duration: "15" },
    { time: "04:30", duration: "10" },
  ];

  const keys: string[] = [
    "bedtime",
    "winddown",
    "108",
    "603",
    "weight",
    "604",
    "605",
    "606",
    "607",
    "608",
    "609",
    "scheduleAge",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const bedtimeState = valueGetter(serverResult, "bedtime") as string;
      const serverScheduleAge = valueGetter(serverResult, "scheduleAge")
        ? Number(valueGetter(serverResult, "scheduleAge"))
        : age;
      const winddownState = valueGetter(serverResult, "winddown")
        ? Number(valueGetter(serverResult, "winddown"))
        : 60;
      const feedingCountState = valueGetter(serverResult, "603")
        ? Number(valueGetter(serverResult, "603"))
        : 0;
      const feedingMethodState = valueGetter(serverResult, "108") as string;
      const noshing1: IFeedingItem = {
        time: valueGetter(serverResult, "604") as string,
        duration: valueGetter(serverResult, "605") as string,
      };
      const noshing2: IFeedingItem = {
        time: valueGetter(serverResult, "606") as string,
        duration: valueGetter(serverResult, "607") as string,
      };
      const noshing3: IFeedingItem = {
        time: valueGetter(serverResult, "608") as string,
        duration: valueGetter(serverResult, "609") as string,
      };
      let noshingInputState: IFeedingItem[] = [];
      if (feedingCountState === 3) {
        noshingInputState = [noshing1, noshing2, noshing3];
      }
      if (feedingCountState === 2) {
        noshingInputState = [noshing1, noshing2];
      }
      if (feedingCountState === 1) {
        noshingInputState = [noshing1];
      }
      const weightObject = JSON.parse(
        valueGetter(serverResult, "weight") as string
      );
      const weightState =
        Number(weightObject.weightKilo) +
        Number(weightObject.weightGeram) / 1000;
      setScheduleAge(serverScheduleAge);
      setBedtime(bedtimeState || "19:00");
      setWinddown(winddownState);
      setFeedingCount(feedingCountState);
      setFeedingMethod(feedingMethodState);
      setWeight(weightState);
      setNoshingInputs(noshingInputState);
    },
  });

  const startWinddownTime = subtractTime(bedtime, winddwon);

  let weaningData: IFeedingItem[][] = [];

  if (feedingCount > 3) {
    weaningData = weaningPlanGenerator(
      age || 0,
      weight,
      ffNoshingInput,
      feedingMethod
    );
  } else {
    weaningData = weaningPlanGenerator(
      age || 0,
      weight,
      noshingInputs,
      feedingMethod
    );
  }

  const morningSchedule = morningBoxDataGenerator(
    scheduleAge || 0,
    minuteDiffer("19:00", bedtime),
    name
  );

  const handleScheduleAge = () => {
    setScheduleAge(age);
  };

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <GoalSchedule
        name={name}
        isBoth={feedingMethod === "both"}
        nightBoxProps={{
          bedtime: bedtime,
          windDownTime: startWinddownTime,
          isFF: feedingCount > 3,
          showOneFeeding:
            feedingCount > 0 &&
            (weight < 7.25 || (age !== undefined && age < 6)),
          feed1: weaningData.length > 0 ? weaningData[0][0]?.time : undefined,
          feed2: weaningData.length > 0 ? weaningData[0][1]?.time : undefined,
          feed3: weaningData.length > 0 ? weaningData[0][2]?.time : undefined,
        }}
        morningBoxData={morningSchedule}
        weaningSchedule={weaningData}
        age={age || 0}
        scheduleAge={scheduleAge || 0}
        isFF={feedingCount > 3}
        handleScheduleAge={handleScheduleAge}
      />
    </PageLoader>
  );
};

export default GoalScheduleScreen;
