import { useMutation, useQuery } from "@apollo/client";
import PageLoader from "components/utils/PageLoader";
import WhenToBegin from "components/when-to-begin";
import { wtbErrors } from "data/platform/errors/wtbErros";
import { GET, STORE } from "graphql/queries/store";
import { IServerData } from "models/ServerData";
import { WhenToBeginData } from "models/WTB";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { boolToJson } from "utils/booleanParser";
import { parseStoreData, valueGetter } from "utils/storeParser";

const WhenToBeginScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [wtbData, setWtbData] = useState<WhenToBeginData>({});
  const [resultSeen, setResultSeen] = useState<boolean>(false);
  const [openItemsFlag, setOpenItemsFlag] = useState<boolean>(false);
  const [formCompleted, setformCompleted] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);
  const [items, setItems] = useState<string[]>([]);
  const [app, setApp] = useRecoilState(appState);
  const { push } = useHistory();

  const handleWtbData = (value: string | boolean | number, key: string) => {
    if (locked) return;
    setWtbData({ ...wtbData, [key]: value });
  };

  const openItems = () => {
    setOpenItemsFlag(true);
  };

  const closeItems = () => {
    setOpenItemsFlag(false);
  };

  const handleResultSeen = () => {
    setResultSeen(true);
  };

  const generateErrors = () => {
    const errors = wtbErrors(name);
    let userErrors = [];
    wtbData.tooth && userErrors.push(errors.tooth);
    wtbData.act !== "none" && userErrors.push(errors.act);
    wtbData.sitterJob && userErrors.push(errors.sitterJob);
    wtbData.familyTrip && userErrors.push(errors.familyTrip);
    wtbData.illness && userErrors.push(errors.illness);
    wtbData.reflax && userErrors.push(errors.reflax);
    wtbData.medical && userErrors.push(errors.medical);
    wtbData.breastWorry && userErrors.push(errors.breastWorry);
    wtbData.hardSituation && userErrors.push(errors.hardSituation);
    const finalItems = userErrors.filter(
      (error, index, self) => index === self.findIndex((t) => t === error)
    );
    setItems(finalItems);
    return finalItems.length > 0;
  };

  const generatServerData = (isFailed: boolean) => {
    return [
      {
        key: "101",
        value: boolToJson(wtbData.tooth),
      },
      {
        key: "102",
        value: wtbData.act,
      },
      {
        key: "103",
        value: boolToJson(wtbData.sitterJob),
      },
      {
        key: "104",
        value: boolToJson(wtbData.familyTrip),
      },
      {
        key: "105",
        value: boolToJson(wtbData.illness),
      },
      {
        key: "106",
        value: boolToJson(wtbData.reflax),
      },
      {
        key: "107",
        value: boolToJson(wtbData.medical),
      },
      {
        key: "108",
        value: wtbData.feeding,
      },
      {
        key: "109",
        value: boolToJson(wtbData.breastWorry),
      },
      {
        key: "110",
        value: boolToJson(wtbData.hardSituation),
      },
      {
        key: "wtbLocked",
        value: boolToJson(true),
      },
      {
        key: "appState",
        value: JSON.stringify({
          ...app,
          state: !isFailed
            ? "/platform/sleep-stealers/env"
            : "/platform/when-to-begin",
        }),
      },
    ];
  };

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setformCompleted(true);
      setLocked(true);
      setApp((app) => ({
        ...app,
        state:
          items.length === 0
            ? "/platform/sleep-stealers/env"
            : "/platform/when-to-begin",
      }));
    },
  });

  const completeForm = () => {
    if (locked) return;
    const isFailed = generateErrors();
    const serverData: IServerData[] = generatServerData(isFailed);
    storeData({
      variables: {
        infant_id: id,
        data: serverData,
      },
    });
  };

  const finishPage = () => {
    if (app.state === "/platform/when-to-begin") {
      storeData({
        variables: {
          infant_id: id,
          data: [
            {
              key: "appState",
              value: JSON.stringify({
                ...app,
                state: "/platform/sleep-stealers/env",
              }),
            },
          ],
        },
      });
    }
    push("/platform/sleep-stealers/env");
  };

  const wtbKeys: string[] = [
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "wtbLocked",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: wtbKeys,
    },
    onCompleted: (data) => {
      const wtbServerData = parseStoreData(data);
      const newData = {
        tooth: valueGetter(wtbServerData, "101", true) as boolean,
        act: valueGetter(wtbServerData, "102") as string,
        sitterJob: valueGetter(wtbServerData, "103", true) as boolean,
        familyTrip: valueGetter(wtbServerData, "104", true) as boolean,
        illness: valueGetter(wtbServerData, "105", true) as boolean,
        reflax: valueGetter(wtbServerData, "106", true) as boolean,
        medical: valueGetter(wtbServerData, "107", true) as boolean,
        feeding: valueGetter(wtbServerData, "108") as string,
        breastWorry: valueGetter(wtbServerData, "109", true) as boolean,
        hardSituation: valueGetter(wtbServerData, "110", true) as boolean,
      };
      setWtbData(newData);
      const locked = valueGetter(wtbServerData, "wtbLocked", true) as boolean;
      setLocked(locked);
      if (locked) {
        setformCompleted(true);
      }
    },
  });

  useEffect(() => {
    generateErrors();
    window.scrollTo(0, document.body.scrollHeight);
    //eslint-disable-next-line
  }, [wtbData, resultSeen, formCompleted]);

  const rawItems = Object.values(wtbErrors(name)).map((item) => item);
  const allItems = rawItems.filter(
    (error, index, self) => index === self.findIndex((t) => t === error)
  );

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <WhenToBegin
        formProps={{
          ...wtbData,
          name,
          locked,
          loading: storeLoading,
          onChange: handleWtbData,
          completeForm,
        }}
        descProps={{
          name,
          allItems,
          userItems: items,
          isOK: items.length === 0,
          resultSeen,
          openItemsFlag,
          loading: storeLoading,
          onFinish: finishPage,
          handleResultSeen,
          openItems,
          closeItems,
        }}
        formCompleted={formCompleted}
      />
    </PageLoader>
  );
};

export default WhenToBeginScreen;
