import { IOption } from "models/Option";
import { habitStrings } from "./habitStrings";

export const fallingSleepMethodsOptions = (name?: string): IOption[] => [
  {
    label: habitStrings.pacifier,
    value: 1,
  },
  {
    label: habitStrings.breastfeed,
    value: 2,
  },
  {
    label: habitStrings.bottlefeed,
    value: 3,
  },
  {
    label: habitStrings.rock,
    value: 4,
  },
  {
    label: habitStrings.motion,
    value: 5,
  },
  {
    label: habitStrings.hold,
    value: 6,
  },
  {
    label: habitStrings.lie,
    value: 7,
  },
  {
    label: habitStrings.noAssistant(name),
    value: 8,
  },
];

export const fallingSleepDurationOptions: IOption[] = [
  {
    label: habitStrings.veryLow,
    value: 1,
  },
  {
    label: habitStrings.low,
    value: 2,
  },
  {
    label: habitStrings.medium,
    value: 3,
  },
  {
    label: habitStrings.high,
    value: 4,
  },
  {
    label: habitStrings.veryHigh,
    value: 5,
  },
];
