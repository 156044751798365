import { toPersian } from "utils/raqam";

export const morningString = {
  morning: "صبح",
  wakeTime: "ساعت بیداری",
  wakeTimeTooltip: (name?: string) =>
    `هر ساعتی هم که ${name} به خواب بره، زمان بیداری روزانه تغییری نمیکنه. علت این کار اینه که چرخه خواب فرزند شما تنظیم و قابل پیشبینی بشه.`,
  wakeBy: (time: string, name?: string) =>
    `${name} رو ساعت ${toPersian(time)} بیدار کنید`,
  userTimeLabel: (name?: string) =>
    ` امروز صبح ${name} چه ساعتی از خواب بیدار شد؟`,
};
