import PlatformContent from "components/layout/PlatfromContent";
import { activityStrings } from "data/platform/sleep-stealers/activityStrings";
import StealerContainer from "../Container";
import ActivityDescription, { ActivityDescriptionProps } from "./Description";
import ActivityForm, { ActivityFormProps } from "./Form";

export interface SleepStealersActivityProps {
  formProps: ActivityFormProps;
  descProps: ActivityDescriptionProps;
  formCompleted: boolean;
  rate?: number;
}

const SleepStealersActivity: React.FC<SleepStealersActivityProps> = ({
  formProps,
  formCompleted,
  descProps,
  rate,
}) => {
  return (
    <PlatformContent>
      <StealerContainer
        title={activityStrings.title}
        duration={activityStrings.duration}
      >
        <ActivityForm {...formProps} />
        {formCompleted && rate !== undefined && (
          <ActivityDescription {...descProps} />
        )}
      </StealerContainer>
    </PlatformContent>
  );
};

export default SleepStealersActivity;
