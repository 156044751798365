import React from "react";
import { appStrings } from "data/landing/strings";

export interface HeaderProps {
  lang?: string;
}

const Header: React.FC<HeaderProps> = ({ lang = "fa" }) => {
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-col space-y-6 text-navy text-center lg:text-right">
        <p className="text-2xl font-bold lg:text-4xl">
          {appStrings.commentsTitle[lang]}
        </p>
        <p className="text-sm lg:text-base">
          {appStrings.commentsSubtitle[lang]}
        </p>
      </div>
    </div>
  );
};

export default Header;
