import { atom } from "recoil";

export const licenseState = atom<boolean>({
  key: "license",
  default: false,
});

export const licenseCodeState = atom<string | undefined>({
  key: "licenseCode",
  default: undefined,
});
