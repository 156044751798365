import {
  dailyStrings,
  secondDayStrings,
} from "data/platform/daily/dailyStrings";
import { NavLink } from "react-router-dom";
import StartWrapper from "./StartWrapper";

export interface SecondDayStartProps {}

const SecondDayStart: React.FC<SecondDayStartProps> = () => {
  return (
    <StartWrapper
      title={dailyStrings.goodMorning}
      description={secondDayStrings.description}
      video="18"
    >
      <p>{secondDayStrings.questionDescription}</p>
      <NavLink
        to="/platform/ask-question"
        className="font-semibold text-jungle underline"
      >
        {dailyStrings.askUs}
      </NavLink>
    </StartWrapper>
  );
};

export default SecondDayStart;
