import PlatformContent from "components/layout/PlatfromContent";
import { scheduleStrings } from "data/platform/sleep-stealers/scheduleStrings";
import StealerContainer from "../Container";
import ScheduleDescription, { ScheduleDescriptionProps } from "./Description";
import SceduleForm, { ShceduleFormProps } from "./Form";

export interface SleepStealersScheduleProps {
  formProps: ShceduleFormProps;
  descProps: ScheduleDescriptionProps;
  formCompleted: boolean;
  rate?: number;
}

const SleepStealersSchedule: React.FC<SleepStealersScheduleProps> = ({
  formCompleted,
  formProps,
  descProps,
  rate,
}) => {
  return (
    <PlatformContent>
      <StealerContainer
        title={scheduleStrings.title}
        duration={scheduleStrings.duration}
      >
        <SceduleForm {...formProps} />
        {formCompleted && rate !== undefined && (
          <ScheduleDescription {...descProps} />
        )}
      </StealerContainer>
    </PlatformContent>
  );
};

export default SleepStealersSchedule;
