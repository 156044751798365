import MultipleChoice from "components/form-elements/MultipleChoice";
import SingleChoice from "components/form-elements/SingleChoice";
import SliderInput from "components/form-elements/SliderInput";
import LockedAlert from "components/utils/LockedAlert";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import {
  fallingSleepDurationOptions,
  fallingSleepMethodsOptions,
} from "data/platform/sleep-stealers/habitOptions";
import { habitStrings } from "data/platform/sleep-stealers/habitStrings";
import { SliderValue } from "models/SliderValue";
import { SleepStealersHabitData } from "models/SPH";
import { Fragment } from "react";

export interface HabitFormProps extends SleepStealersHabitData {
  name?: string;
  doneFallingSleep: boolean;
  doneAgainSleep: boolean;
  doneFallingNap: boolean;
  loading: boolean;
  locked: boolean;
  onDoneFallingSleep: () => void;
  onDoneAgainSleep: () => void;
  onDoneFallingNap: () => void;
  onFormCompleted: () => void;
  onSingleChange: (value: string | number | boolean, key: string) => void;
  onMultipleChange: (
    value: string | number | boolean,
    key: keyof SleepStealersHabitData
  ) => void;
  onSlideChange: (value: SliderValue) => void;
}

const HabitForm: React.FC<HabitFormProps> = ({
  fallingNapDuration,
  fallingSleepDuration,
  name,
  againSleepActs,
  doneFallingSleep,
  doneFallingNap,
  doneAgainSleep,
  beforeSleepActs,
  awakeScale,
  beforeNapActs,
  loading,
  locked,
  onFormCompleted,
  onDoneAgainSleep,
  onDoneFallingNap,
  onDoneFallingSleep,
  onSingleChange,
  onMultipleChange,
  onSlideChange,
}) => {
  return (
    <div>
      {locked && <LockedAlert />}
      <MultipleChoice
        label={habitStrings.beforeFallingActsLabel(name)}
        options={fallingSleepMethodsOptions(name)}
        selected={beforeSleepActs}
        onSelect={(value) => onMultipleChange(value, "beforeSleepActs")}
      />
      {!doneFallingSleep && <NextStep onNext={onDoneFallingSleep} />}
      {doneFallingSleep && (
        <PlatformSection isPassed={!!fallingSleepDuration}>
          <PlatformDivider />
          <SingleChoice
            label={habitStrings.fallingDurationLabel(name)}
            hint={habitStrings.fallingDurationHint}
            options={fallingSleepDurationOptions}
            selected={fallingSleepDuration}
            onSelect={(value) => onSingleChange(value, "fallingSleepDuration")}
          />
        </PlatformSection>
      )}
      {fallingSleepDuration && (
        <Fragment>
          <PlatformDivider lowMargin />
          <MultipleChoice
            label={habitStrings.againSleepingLabel(name)}
            hint={habitStrings.againSleepingHint}
            options={fallingSleepMethodsOptions(name)}
            selected={againSleepActs}
            onSelect={(value) => onMultipleChange(value, "againSleepActs")}
          />
          {!doneAgainSleep && <NextStep onNext={onDoneAgainSleep} />}
        </Fragment>
      )}
      {doneAgainSleep && (
        <Fragment>
          <PlatformDivider lowMargin />
          <MultipleChoice
            label={habitStrings.beforeNapActsLabel(name)}
            options={fallingSleepMethodsOptions(name)}
            selected={beforeNapActs}
            onSelect={(value) => onMultipleChange(value, "beforeNapActs")}
          />
          {!doneFallingNap && <NextStep onNext={onDoneFallingNap} />}
        </Fragment>
      )}
      {doneFallingNap && (
        <PlatformSection isPassed={!!fallingNapDuration}>
          <PlatformDivider />
          <SingleChoice
            label={habitStrings.fallingNapDurationLabel(name)}
            options={fallingSleepDurationOptions}
            selected={fallingNapDuration}
            onSelect={(value) => onSingleChange(value, "fallingNapDuration")}
          />
        </PlatformSection>
      )}
      {fallingNapDuration && (
        <PlatformSection>
          <PlatformDivider />
          <SliderInput
            label={habitStrings.wakeScaleLabel(name)}
            min={1}
            max={10}
            value={awakeScale}
            onChange={onSlideChange}
          />
          {!locked && <NextStep loading={loading} onNext={onFormCompleted} />}
        </PlatformSection>
      )}
    </div>
  );
};

export default HabitForm;
