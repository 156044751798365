import DailyTimeInput from "components/form-elements/DailyTimeInput";
import { morningString } from "data/platform/daily/morningStrings";
import { ChangeEvent } from "react";
import { AiOutlineQuestionCircle, AiFillCheckSquare } from "react-icons/ai";
import { FiSun } from "react-icons/fi";
import { toPersian } from "utils/raqam";
import GlassBox from "./GlassBox";

export interface DailyMorningProps {
  name?: string;
  startTime: string;
  wakeTime: string;
}

export interface MorningFormProps extends DailyMorningProps {
  userTime?: string;
  isEditing: boolean;
  onTimeChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onSave: () => void;
  onEdit: () => void;
}

const MorningForm: React.FC<MorningFormProps> = ({
  name,
  startTime,
  wakeTime,
  userTime,
  isEditing,
  onTimeChange,
  onSave,
  onEdit,
}) => {
  return (
    <GlassBox
      isEditing={isEditing}
      title={
        <p className="text-lg font-semibold">
          <FiSun className="inline-block ml-2" />
          {morningString.morning}
        </p>
      }
      onSave={onSave}
      onEdit={onEdit}
    >
      <div className="flex justify-between">
        <span>
          {!isEditing && <AiFillCheckSquare className="inline-block ml-2" />}
          {morningString.wakeTime}
          <span data-tip={morningString.wakeTimeTooltip(name)}>
            <AiOutlineQuestionCircle
              className="inline-block mr-2 cursor-pointer"
              transform="scale(-1,1)"
            />
          </span>
        </span>
        <div className="flex flex-col text-left">
          <span>
            {toPersian(!isEditing && userTime ? userTime : startTime)}
          </span>
          {isEditing && (
            <span className="text-xs">
              {morningString.wakeBy(wakeTime, name)}
            </span>
          )}
        </div>
      </div>
      {isEditing && (
        <div>
          <DailyTimeInput
            label={morningString.userTimeLabel(name)}
            value={userTime}
            onChange={onTimeChange}
          />
        </div>
      )}
    </GlassBox>
  );
};

export default MorningForm;
