import { IOption } from "models/Option";
import { platformStrings } from "./platformStrings";

export const ynOptions: IOption[] = [
  {
    label: platformStrings.yes,
    value: true,
  },
  {
    label: platformStrings.no,
    value: false,
  },
];
