export const planIntroStrings = {
  title: "برنامه خواب شما آماده است",
  introDescription: (name?: string) =>
    `ما مشتاقیم تا به شما آموزش بدیم چطوری موهبت خواب آروم رو به ${name} کوچولو هدیه بدین! بیاید با هم آموزش هایی که می خوایم تو برنامه خواب شما اونا رو پوشش بدیم مرور کنیم`,
  whatsMissing: "بخش ۱: چه چیزی کمه",
  whatsMissingDescription:
    'اول از همه تغییراتی که لازمه برای فراهم کردن شرایط آموزش خواب در محیط خونه اعمال کنید رو با هم مرور می کنیم. اینجا به شما نشون میدیم که چطور در تمام بخش های "خواب دزد ها" هر ۵ ستاره رو دریافت کنید.',
  methodInAction: (method?: string) => `بخش ۲: یادگیری عملی ${method}`,
  methodInActionDescription: (method?: string) =>
    `در این بخش به شما نشون میدیم که چطور از ${method} استفاده کنین. میتونین این آموزش رو در ویدئوهای آموزشی ۲۰ دقیقه ای قدم به قدم تماشا کنین.`,
  goalSchedule: "بخش ۳: برنامه هدف",
  goalScheduleDescription: (name?: string) =>
    `بعد از اون به شما برنامه خواب ایده آل ${name} رو نشون میدیم که بر اساس اطلاعاتی که در بخش ارزیابی وارد کردین تنظیم شده.`,
  dailyGuide: "بخش ۴: راهنمای روزانه",
  dailyGuideDescription:
    "در نهایت، با کمک ویدئوهای کوتاه و برنامه خوابی که به صورت هوشمند شما رو راهنمایی میکنه، در روند آموزش خواب در کنار شما قدم برمیداریم.",
  timeTogetPlan: "حالا وقت دریافت برنامه خواب شخصی سازی شده است!",
  drumroll: "بر طبل شادانه بکوب!",
};
