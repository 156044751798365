import React from "react";
import { IFacility } from "data/landing/facilities";

export interface ItemProps extends IFacility {
  dir?: string;
}

const Item: React.FC<ItemProps> = ({ title, icon, subtitle, dir = "rtl" }) => {
  return (
    <div
      className={`text-navy flex flex-row mb-4 ${
        dir === "rtl" ? "lg:border-l-2" : "lg:border-r-2"
      } lg:border-gray-200 last:border-0`}
    >
      <div className="self-center">
        <img src={icon} alt={title} className="w-10 mx-auto" />
      </div>
      <div className={`self-center ${dir === "rtl" ? "pr-4" : "pl-4"}`}>
        <p className="text-sm font-bold lg:text-lg">{title}</p>
        <p className="text-xs mt-1 lg:text-base">{subtitle}</p>
      </div>
    </div>
  );
};

export default Item;
