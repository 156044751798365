import { IBaby } from "models/Baby";

export const babyMockData: IBaby[] = [
  {
    id: 1,
    name: "خشایار",
    age: 5,
    gender: "boy",
  },
  {
    id: 2,
    name: "سمیه",
    age: 8,
    gender: "girl",
  },
];
