import { useMutation } from "@apollo/client";
import EnterLicense from "components/enter-license";
import { SUBMIT_LICENSE } from "graphql/queries/license";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { appState } from "recoil/atoms/appState";
import { userState } from "recoil/atoms/auth";
import { licenseCodeState } from "recoil/atoms/license";
import { getQueryStringValue } from "utils/queryString";

const EnterLicenseScreen: React.FC = () => {
  const user = useRecoilValue(userState);
  const app = useRecoilValue(appState);
  const setLicenseCode = useSetRecoilState(licenseCodeState);
  const [license, setLicense] = useState<string | undefined>(undefined);
  const { push, listen } = useHistory();

  const licenseCode = getQueryStringValue("code");

  useEffect(() => {
    if (licenseCode) {
      setLicense(licenseCode);
    }
  }, [licenseCode]);

  const handleLicenseChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLicense(event.currentTarget.value);
  };

  const [submitLicense, { loading }] = useMutation(SUBMIT_LICENSE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setLicenseCode(license);
      push("/platform");
    },
  });

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    submitLicense({
      variables: {
        code: license,
      },
    });
  };

  useEffect(() => {
    return listen((location, action) => {
      if (action === "POP") {
        push("/");
      }
    });
    //eslint-disable-next-line
  }, []);

  if (user?.licenses && user.licenses.length > 0) {
    let url = app.state || "/platform";
    // if (user.licenses[0].infant_id !== undefined) {
    //   url = app.state || "/platform";
    // }
    return <Redirect to={url} />;
  }

  return (
    <EnterLicense
      license={license}
      handleLicenseChange={handleLicenseChange}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};

export default EnterLicenseScreen;
