import React from "react";
import introCirclePNG from "images/intro/intro-circle.png";
import introMobilePNG from "images/intro/intro-mobile.png";

const Demo: React.FC = () => {
  return (
    <div className="relative lg:w-1/2">
      <img
        src={introCirclePNG}
        alt="intro-circle"
        className="w-10/12 mx-auto"
      />
      <img
        src={introMobilePNG}
        alt="intro-mobile"
        className="absolute intro-mobile"
      />
    </div>
  );
};

export default Demo;
