import React from "react";
import { appStrings } from "data/landing/strings";
import TitleChip from "components/utils/TitleChip";
import PurchaseButton from "../PurchaseButton";

export interface ContentProps {
  lang?: string;
  dir?: string;
}

const Content: React.FC<ContentProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <div
      className={`text-center mt-2 lg:w-2/6 ${
        dir === "rtl" ? "lg:text-right" : "lg:text-left"
      } lg:self-center lg:mx-8`}
    >
      <TitleChip title={appStrings.heroSubtitle[lang]} color="green" />
      <p className="font-bold text-4xl text-navy my-6 w-3/4 mx-auto leading-10 lg:text-5xl lg:leading-normal lg:mx-0 lg:w-full">
        {appStrings.heroTitle[lang]}
      </p>
      <p className="text-sm w-11/12 mx-auto my-6 text-navy lg:w-full">
        {appStrings.heroDesc[lang]}
      </p>
      <PurchaseButton lang={lang} dir={dir} />
    </div>
  );
};

export default Content;
