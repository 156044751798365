import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import { FaMoon } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { toPersian } from "utils/raqam";
import { FaChevronLeft } from "react-icons/fa";

export interface NightTimeBoxProps {
  name?: string;
  windDownTime: string;
  bedtime: string;
  isFF: boolean;
  showOneFeeding: boolean;
  feed1?: string;
  feed2?: string;
  feed3?: string;
}

const NightTimeBox: React.FC<NightTimeBoxProps> = ({
  name,
  windDownTime,
  bedtime,
  feed1,
  feed2,
  feed3,
  isFF,
  showOneFeeding,
}) => {
  const rowRender = (label: string, time: string) => (
    <div className="flex justify-between text-jungle-400">
      <span>{label}</span>
      <span dir="ltr">{toPersian(time)}</span>
    </div>
  );

  return (
    <div className="flex flex-col space-y-6">
      <p className="text-xl text-jungle-400 font-semibold">
        <FaMoon className="inline-block ml-2" />
        {goalScheduleStrings.nightTime}
      </p>
      <div className="bg-white bg-opacity-10 py-4 px-3 rounded-xl">
        <div className="flex flex-col space-y-2">
          {rowRender(goalScheduleStrings.windDownTimeLabel, windDownTime)}
          {rowRender(goalScheduleStrings.bedtime, bedtime)}
        </div>
        {!isFF && (
          <div className="flex flex-col space-y-2 mt-3 pt-3 border-t border-navy">
            {feed1 && rowRender(goalScheduleStrings.feed1Label, feed1)}
            {feed2 && rowRender(goalScheduleStrings.feed2Label, feed2)}
            {feed3 && rowRender(goalScheduleStrings.feed3Label, feed3)}
          </div>
        )}
      </div>
      {!isFF && showOneFeeding && (
        <p>{goalScheduleStrings.nightTimeDescription(name)}</p>
      )}
      <div className="flex flex-col space-y-4 border-t border-b border-gray-700 py-6">
        <NavLink to="/platform/bedtime" className="text-white">
          {goalScheduleStrings.changeBedtimeLabel}
          <FaChevronLeft className="inline-block mr-2" />
        </NavLink>
        <NavLink to="/platform/winddown" className="text-white">
          {goalScheduleStrings.changeWindDownLabel}
          <FaChevronLeft className="inline-block mr-2" />
        </NavLink>
      </div>
    </div>
  );
};

export default NightTimeBox;
