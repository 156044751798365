import { faBrand } from "./platformStrings";

export const introStrings = {
  title: faBrand,
  takeAssessment: "انجام ارزیابی",
  howItWorks: `تو ${faBrand} چه کارایی انجام میدیم؟`,
  firstStep: "گام اول",
  taDesc:
    "ارزیابی جامعی از خواب انجام بدین و بعد یکی از روشهای یادگیری خواب رو انتخاب کنین.",
  secondStep: "گام دوم",
  learnPlan: "یادگیری روش",
  lpDesc:
    "روش انتخابی خودتون رو یاد بگیرین و موانع خواب کودکتون رو بر طرف کنین.",
  thirdStep: "گام سوم",
  followPlan: "عمل به برنامه",
  fpDesc: "برنامه خواب روزانه شخصی سازی شده برای خودتون رو دنبال کنین تا کودکتون خوابیدن رو یاد بگیره",
  msterSleepPlan: "برنامه جامع خواب",
};
