import { gql } from "@apollo/client";

export const PURCHASE = gql`
  mutation MARKET_purchase_dreamlab {
    MARKET_purchase_dreamlab {
      payments {
        url
      }
      total
    }
  }
`;
