import { enStep4Strings } from "data/platform/daily/enStrings";
import { platformStrings } from "data/platform/platformStrings";
import Modal from "react-modal";
import EmergencyNapContent from "./Content";

export interface EmergencyNapModalProps {
  open: boolean;
  name?: string;
  step: number;
  onNextStep: () => void;
  onAddNap: () => void;
  onClose: () => void;
}

const EmergencyNapModal: React.FC<EmergencyNapModalProps> = ({
  name,
  step,
  open,
  onAddNap,
  onClose,
  onNextStep,
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="w-full h-screen overflow-y-auto mx-auto bg-navy text-jasmine outline-none"
      overlayClassName="bg-transparent fixed inset-0 z-50"
    >
      <div className="w-full lg:w-1/3 px-10 lg:px-0 mx-auto py-12">
        <EmergencyNapContent name={name} step={step} />
        <div className="flex flex-col space-y-6 mt-12">
          <button
            onClick={step === 4 ? onAddNap : onNextStep}
            className="bg-jungle text-navy py-3 px-4 rounded shadow-md font-semibold"
          >
            {step === 4 ? enStep4Strings.addNap : platformStrings.nextStep}
          </button>
          <button
            onClick={onClose}
            className="bg-gray-700 text-white py-1 px-4 rounded shadow-md"
          >
            {platformStrings.close}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EmergencyNapModal;
