import NextStep from "components/utils/NextStep";

export interface PartItemProps {
  title: string;
  description: string;
  icon: string;
  isPassed: boolean;
  onNext: () => void;
}

const PartItem: React.FC<PartItemProps> = ({
  title,
  description,
  icon,
  isPassed,
  onNext,
}) => {
  return (
    <div className="flex flex-col space-y-8 text-justify">
      <div>
        <img src={icon} alt={title} className="mx-auto w-32" />
      </div>
      <p className="text-lg font-semibold">{title}</p>
      <p>{description}</p>
      {isPassed && <NextStep onNext={onNext} />}
    </div>
  );
};

export default PartItem;
