import { IMenu } from "models/Menu";
import { faBrand } from "./platform/platformStrings";

export const assessmentMenu: IMenu[] = [
  {
    title: "کی شروع کنیم؟",
    link: "/platform/when-to-begin",
  },
  {
    title: "خواب دزدها",
    link: "/platform/sleep-stealers",
  },
  {
    title: "انتخاب روش",
    link: "/platform/method-selection",
  },
];

export const planMenu = (isFinished: boolean): IMenu[] => [
  {
    title: "چه چیزی کمه؟",
    link: "/platform/whats-missing",
  },
  {
    title: "یادگیری عملی روش",
    link: "/platform/method-in-action",
  },
  {
    title: "برنامه هدف",
    link: "/platform/goal-schedule",
  },
  {
    title: "راهنمای روزانه",
    link: "/platform/daily",
  },
  ...(isFinished
    ? [
        {
          title: `بعد از ${faBrand}`,
          link: "/platform/after-dreamlab",
        },
      ]
    : []),
];

export const continueMenu = (url?: string): IMenu[] => [
  {
    title: "ادامه فرآیند ارزیابی",
    link: url || "/platform",
  },
];

export const supportMenu: IMenu[] = [
  {
    title: "پرسش از ما",
    link: "/platform/ask-question",
  },
  {
    title: "لیست پرسش ها",
    link: "/platform/tickets",
  },
];

export const spMenu: IMenu[] = [
  {
    title: "محیط خواب",
    link: "/platform/sleep-stealers/env",
  },
  {
    title: "فعالیت های قبل از خواب",
    link: "/platform/sleep-stealers/activities",
  },
  {
    title: "عادت های قبل از خواب",
    link: "/platform/sleep-stealers/habits",
  },
  {
    title: "برنامه خواب",
    link: "/platform/sleep-stealers/schedule",
  },
  {
    title: "تغذیه شبانه",
    link: "/platform/sleep-stealers/night-feeding",
  },
];
