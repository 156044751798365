import moment from "moment-jalaali";

export const isBeforeTime = (limit: string, time?: string, same?: boolean) => {
  if (same) {
    const result = moment(time, "HH:mm").isSameOrBefore(moment(limit, "HH:mm"));
    return result;
  }
  const result = moment(time, "HH:mm").isBefore(moment(limit, "HH:mm"));
  return result;
};

export const isAfterTime = (limit: string, time?: string, same?: boolean) => {
  if (same) {
    const result = moment(time, "HH:mm").isSameOrAfter(moment(limit, "HH:mm"));
    return result;
  }
  const result = moment(time, "HH:mm").isAfter(moment(limit, "HH:mm"));
  return result;
};

export const isSameTIme = (limit: string, time?: string) => {
  const result = moment(time, "HH:mm").isSame(moment(limit, "HH:mm"));
  return result;
};

export const timeDiffer = (start: string, end: string) => {
  const differ = moment(end, "HH:mm").diff(moment(start, "HH:mm"), "hours");
  return Number(24 - differ);
};

export const minuteDiffer = (start: string, end: string) => {
  const differ = moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes");
  return Number(differ);
};

export const getHour = (time?: string) => {
  const timeHour = moment(time, "HH:mm").hour();
  return timeHour;
};

export const isBeforeHour = (limit: string, time?: string, same?: boolean) => {
  const limitHour = moment(limit, "HH:mm").hour();
  const timeHour = moment(time, "HH:mm").hour();
  if (same) {
    return limitHour >= timeHour;
  }
  return limitHour > timeHour;
};

export const isAfterHour = (limit: string, time?: string, same?: boolean) => {
  const limitHour = moment(limit, "HH:mm").hour();
  const timeHour = moment(time, "HH:mm").hour();
  if (same) {
    return limitHour <= timeHour;
  }
  return limitHour < timeHour;
};

export const isBetweenTime = (start: string, end: string, time: string) => {
  const result = moment(time, "HH:mm").isBetween(
    moment(start, "HH:mm"),
    moment(end, "HH:mm"),
    undefined,
    "[]"
  );
  return result;
};

export const subtractTime = (from: string, duration: number) => {
  const result = moment(from, "HH:mm")
    .subtract(duration, "minutes")
    .format("HH:mm");
  return result;
};

export const addTime = (to: string, duration: number) => {
  const result = moment(to, "HH:mm")
    .subtract(duration * -1, "minutes")
    .format("HH:mm");
  return result;
};
export const napTimeCalculator = (
  wakeTime: string,
  min: string,
  diff: number
) => {
  const napTime = moment(wakeTime, "HH:mm").add(diff, "hours").format("HH:mm");
  const isValid = moment(napTime, "HH:mm").isSameOrAfter(moment(min, "HH:mm"));
  return isValid ? napTime : min;
};

export const timeValidation = (time: string) => {
  const result = moment(time, "HH:mm", true).isValid();
  return result;
};

export const jalaliDateValidation = (date: string) => {
  const result =
    moment(date, "jYYYY-jM-jD", true).isValid() ||
    moment(date, "jYYYY-jMM-jDD", true).isValid() ||
    moment(date, "jYYYY-jM-jDD", true).isValid() ||
    moment(date, "jYYYY-jMM-jD", true).isValid();
  return result;
};

export const parseDateToJalaliArray = (date: string) => {
  const day = moment(date, "YYYY-MM-DD").format("jD");
  const month = moment(date, "YYYY-MM-DD").format("jM");
  const year = moment(date, "YYYY-MM-DD").format("jYYYY");
  return [year, month, day];
};

export const validMomentObjectBuilder = (date: string) => {
  if (moment(date, "jYYYY-jM-jD", true).isValid()) {
    return moment(date, "jYYYY-jM-jD", true);
  }
  if (moment(date, "jYYYY-jMM-jDD", true).isValid()) {
    return moment(date, "jYYYY-jMM-jDD", true);
  }
  if (moment(date, "jYYYY-jMM-jD", true).isValid()) {
    return moment(date, "jYYYY-jMM-jD", true);
  }
  if (moment(date, "jYYYY-jM-jDD", true).isValid()) {
    return moment(date, "jYYYY-jM-jDD", true);
  }
  return moment();
};

export const parseJalaliObjectToStringDate = (date: string) => {
  const momentObj = validMomentObjectBuilder(date);
  return momentObj.format("YYYY-MM-DD");
};

export const isFuture = (date: string) => {
  const momentObj = validMomentObjectBuilder(date);
  return momentObj.isAfter(moment());
};

export const monthDiff = (date: string) => {
  const momentObj = validMomentObjectBuilder(date);
  return moment().diff(momentObj, "months");
};
