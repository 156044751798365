import FormItem from "components/form-elements/FormItem";
import ShortTextInput from "components/form-elements/ShortTextInput";
import SingleChoice from "components/form-elements/SingleChoice";
import SliderInput from "components/form-elements/SliderInput";
import TimeInput from "components/form-elements/TimeInput";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { platformStrings } from "data/platform/platformStrings";
import { nightFeedingStrings } from "data/platform/sleep-stealers/nightFeedingStrings";
import { ynOptions } from "data/platform/ynOptions";
import { SliderValue } from "models/SliderValue";
import { SleepStealersNightFeedingData } from "models/SPN";
import { ChangeEvent, Fragment } from "react";

export interface NightFeedingFormProps extends SleepStealersNightFeedingData {
  name?: string;
  step: number;
  weightKilo?: string;
  weightGeram?: string;
  loading: boolean;
  feedingMethod?: string;
  onNextStep: (currStep: number) => void;
  onFormCompleted: () => void;
  onTimeChange: (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    key: string
  ) => void;
  onSingleChoiceChange: (value: boolean | number | string) => void;
  onSliderChange: (value: SliderValue, key: string) => void;
  onWeightKiloChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onWeightGeramChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const NightFeedingForm: React.FC<NightFeedingFormProps> = ({
  name,
  step,
  secFeedingDuration,
  thirdFeedingDuration,
  firstFeedingTime,
  hasFeeding,
  secFeedingTime,
  thirdFeedingTime,
  weightGeram,
  weightKilo,
  feedingCount,
  firstFeedingDuration,
  loading,
  feedingMethod,
  onTimeChange,
  onSliderChange,
  onNextStep,
  onFormCompleted,
  onSingleChoiceChange,
  onWeightGeramChange,
  onWeightKiloChange,
}) => {
  const sliderStep = feedingMethod === "bottle" ? 15 : 1;
  const sliderMax = feedingMethod === "bottle" ? 675 : 45;

  return (
    <div>
      <SingleChoice
        label={nightFeedingStrings.hasFeedingLabel(name)}
        options={ynOptions}
        selected={hasFeeding}
        onSelect={(value) => {
          onSingleChoiceChange(value);
          !value ? onFormCompleted() : onNextStep(1);
        }}
      />
      {step > 1 && hasFeeding && (
        <PlatformSection isPassed={step > 2}>
          <PlatformDivider />
          <FormItem
            label={nightFeedingStrings.weightLabel(name)}
            hint={nightFeedingStrings.weightHint(name)}
          >
            <div className="grid grid-cols-2 gap-4">
              <ShortTextInput
                name="geram"
                type="tel"
                placeholder={platformStrings.geram}
                value={weightGeram}
                className="text-center"
                onChange={onWeightGeramChange}
              />
              <ShortTextInput
                name="kilo"
                type="tel"
                placeholder={platformStrings.kilo}
                value={weightKilo}
                className="text-center"
                onChange={onWeightKiloChange}
              />
            </div>
          </FormItem>
          {step < 3 && <NextStep onNext={() => onNextStep(2)} />}
        </PlatformSection>
      )}
      {step > 2 && hasFeeding && (
        <PlatformSection isPassed={step > 3}>
          <PlatformDivider />
          <SliderInput
            label={nightFeedingStrings.feedingCountLabel(name)}
            hint={nightFeedingStrings.feedingCountHint(name)}
            min={1}
            max={4}
            value={feedingCount}
            onChange={(value) => onSliderChange(value, "feedingCount")}
          />
          {feedingCount > 3 && (
            <Fragment>
              <p className="text-justify text-orange font-semibold text-lg mt-6">
                {nightFeedingStrings.frequentFeederTitle}
              </p>
              <p className="text-justify text-orange font-semibold text-sm mt-6">
                {nightFeedingStrings.frequentFeeder}
              </p>
            </Fragment>
          )}
          {step < 4 && <NextStep onNext={() => onNextStep(3)} />}
        </PlatformSection>
      )}
      {step > 3 && feedingCount > 0 && (
        <PlatformSection isPassed={step > 4}>
          <PlatformDivider />
          <TimeInput
            label={nightFeedingStrings.firstFeedingLabel(name)}
            hint={nightFeedingStrings.feedingTimeHint}
            value={firstFeedingTime}
            min="18:00"
            nightFeedingMin
            onChange={(event, value) =>
              onTimeChange(event, value, "firstFeedingTime")
            }
          />
          {step < 5 && <NextStep onNext={() => onNextStep(4)} />}
        </PlatformSection>
      )}
      {step > 4 && feedingCount > 0 && (
        <PlatformSection isPassed={step > 5}>
          <PlatformDivider />
          <SliderInput
            label={nightFeedingStrings.firstFeedingDurationLabel(name)}
            hint={nightFeedingStrings.FeedingDurationHint(name)}
            value={firstFeedingDuration}
            min={0}
            max={sliderMax}
            step={sliderStep}
            postText={
              feedingMethod === "bottle"
                ? platformStrings.ml
                : platformStrings.minute
            }
            onChange={(value) => onSliderChange(value, "firstFeedingDuration")}
          />
          {step < 6 && (
            <NextStep loading={loading} onNext={() => onNextStep(5)} />
          )}
        </PlatformSection>
      )}
      {step > 5 && feedingCount > 1 && (
        <PlatformSection isPassed={step > 6}>
          <PlatformDivider />
          <TimeInput
            label={nightFeedingStrings.secFeedingLabel(name)}
            hint={nightFeedingStrings.feedingTimeHint}
            value={secFeedingTime}
            min={firstFeedingTime}
            nightFeedingMin
            onChange={(event, value) =>
              onTimeChange(event, value, "secFeedingTime")
            }
          />
          {step < 7 && <NextStep onNext={() => onNextStep(6)} />}
        </PlatformSection>
      )}
      {step > 6 && feedingCount > 1 && (
        <PlatformSection isPassed={step > 7}>
          <PlatformDivider />
          <SliderInput
            label={nightFeedingStrings.secFeedingDurationLabel(name)}
            hint={nightFeedingStrings.FeedingDurationHint(name)}
            value={secFeedingDuration}
            min={0}
            max={sliderMax}
            step={sliderStep}
            postText={
              feedingMethod === "bottle"
                ? platformStrings.ml
                : platformStrings.minute
            }
            onChange={(value) => onSliderChange(value, "secFeedingDuration")}
          />
          {step < 8 && (
            <NextStep loading={loading} onNext={() => onNextStep(7)} />
          )}
        </PlatformSection>
      )}
      {step > 7 && feedingCount > 2 && (
        <PlatformSection isPassed={step > 8}>
          <PlatformDivider />
          <TimeInput
            label={nightFeedingStrings.thirdFeedingLabel(name)}
            hint={nightFeedingStrings.feedingTimeHint}
            value={thirdFeedingTime}
            min={secFeedingTime}
            nightFeedingMin
            onChange={(event, value) =>
              onTimeChange(event, value, "thirdFeedingTime")
            }
          />
          {step < 9 && <NextStep onNext={() => onNextStep(8)} />}
        </PlatformSection>
      )}
      {step > 8 && feedingCount > 2 && (
        <PlatformSection>
          <PlatformDivider />
          <SliderInput
            label={nightFeedingStrings.thirdFeedingDurationLabel(name)}
            hint={nightFeedingStrings.FeedingDurationHint(name)}
            value={thirdFeedingDuration}
            min={0}
            max={sliderMax}
            step={sliderStep}
            postText={
              feedingMethod === "bottle"
                ? platformStrings.ml
                : platformStrings.minute
            }
            onChange={(value) => onSliderChange(value, "thirdFeedingDuration")}
          />
          <NextStep loading={loading} onNext={onFormCompleted} />
        </PlatformSection>
      )}
    </div>
  );
};

export default NightFeedingForm;
