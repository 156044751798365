const napOrderString = (order?: number) => {
  const o = order !== undefined ? order : 0;
  switch (o) {
    case 1:
      return "اول";
    case 2:
      return "دوم";
    case 3:
      return "سوم";
    default:
      return "";
  }
};

export const napStrings = {
  sleepLabel: (name?: string, order?: number) =>
    `چه زمانی ${name} رو برای چرت ${napOrderString(order)} در تخت گذاشتین؟`,
  durationLabel: (name?: string) => `چقدر طول کشید تا ${name} به خواب فرو بره؟`,
  wakeLabel: (name?: string) => `${name} چه زمانی از خواب بیدار شد؟`,
  putdown: "زمان خواباندن",
  fallSleepTime: "زمان طول کشیده تا خوابیدن",
  wakeTime: "ساعت بیداری",
  didntSleep: (name?: string) => `${name} نخوابید`,
  emergencyNap: "چرت اضطراری",
  usedNap: (name?: string) => `آیا ${name} از چرت اضطراری استفاده کرد؟`,
  enLabel: (name?: string) => `چه ساعتی ${name} چرت اضطراری رو شروع کرد؟`,
  enDuration: "مدت",
};
