import PlatformContent from "components/layout/PlatfromContent";
import VideoPlayer from "components/utils/VideoPlayer";
import { platformStrings } from "data/platform/platformStrings";
import { Fragment } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import NumBox from "../NumBox";

export interface FeedingVideoProps {
  age: number;
  name?: string;
  weight: number;
  feedingCount: number;
}

const FeedingVideo: React.FC<FeedingVideoProps> = ({
  age,
  name,
  weight,
  feedingCount,
}) => {
  return (
    <PlatformContent>
      <NavLink
        to="/platform/method-in-action"
        className="text-lg font-semibold text-left mb-6 block"
      >
        <span>{platformStrings.back}</span>
        <FaChevronLeft className="inline-block mr-2" />
      </NavLink>
      <VideoPlayer code="13" />
      <div className="flex flex-col space-y-12 mt-12 leading-relxed">
        {feedingCount > 3 && (
          <Fragment>
            <p className="text-orange font-semibold text-2xl">
              هشدار تغذیه زیاد
            </p>
            <p>
              {name} عاشق شیر خوردن شبانه است! از اونجایی که {name} در طول شب ۴
              بار یا بیشتر شیر میخوره، باید قبل از شروع روند آموزش خواب و عمل به
              دستورات توقف تغذیه شبانه، تغذیه رو به یکبار در شب کاهش بدین. در
              این قسمت میتونین گام های کاهش تغذیه رو مشاهده کنین:
            </p>
            <NumBox
              num={1}
              description={`اگه تا بحال این کار رو نکردین، در سه شب متوالی، حداکثر میزان شیر ${name} در یک وعده و طول مدت تقریبی تغذیه(یا مقدار اون در صورت تغذیه با شیر خشک) رو اندازه بگیرین و یادداشت کنین. `}
            />
            <NumBox
              num={2}
              description={`اولین شبی که تغذیه شبانه رو طبق برنامه شروع میکنین، هر بار که ${name} از خواب بیدار میشه و شیر میخواد بهش شیر بدین اما:`}
              items={[
                "فقط برای اولین بار هر قدر که خواست بهش شیر بدین.",
                "برای دفعات بعد، میزان شیر خوردن رو مادامی که بیش از ۲۰ دقیقه یا ۳۰۰ میلی لیتره، هر بار ۵ دقیقه (در صورت مصرف شیر مادر) یا ۷۵ میلی لیتر (در صورت استفاده از شیر خشک) کاهش بدین و هر وقت به زیر ۲۰ دقیقه یا ۳۰۰ میلی لیتر رسید، میزان شیر خوردن رو هر بار ۲ دقیقه (در صورت مصرف شیر مادر) یا ۳۰ میلی لیتر (در صورت استفاده از شیر خشک) کاهش بدین",
              ]}
            />
            <NumBox
              num={3}
              description={`هر شب میزان شیر مصرفی ${name} رو در سامانه یادداشت و دقیقه و میلی لیتر رو کم کنین تا حسابش از دستتون در نره.`}
            />
            <NumBox
              num={4}
              description={` اگه بعد از تغذیه ${name} بدخلقی میکنه یا در طول شب از خواب بیدار میشه، هر طور که مایلین آرومش کنین، اما دیگه بهش شیر ندین!`}
            />
            <NumBox
              num={5}
              description={` از اونجایی که میزان تغذیه شبانه رو به کاهشه، صبح ها شیر بیشتری به ${name} بدین. `}
            />
            <NumBox
              num={6}
              description={`وقتی ${name} به یک بار تغذیه در شب رسید، برید سراغ ارزیابی تغذیه شبانه(خواب دزد پنجم). باید تعداد دفعات تغذیه رو به ۱ بار تغییر بدین و میزان و زمان دقیق تغذیه رو وارد کنین. حالا میتونین برین سراغ دستورالعمل کاهش تغذیه شبانه و روند آموزش خواب رو هم شروع کنین.`}
            />
            <p>
              ***یادتون نره تا وقتی که {name} به مرحله یکبار تغذیه در شب نرسیده،
              آموزش خواب رو شروع نکنین.
            </p>
          </Fragment>
        )}
        <p className="text-jungle-400 font-semibold text-2xl pt-16">
          کاهش تغذیه شبانه
        </p>
        <NumBox
          num={1}
          description="قبل از کاهش تغذیه:"
          items={["مطمئن بشین که شیر کافی دارین. "]}
        />
        {(age < 6 || weight < 7.25) && (
          <div>
            <li className="list-disc list-inside">
              از اونجایی که {name} همزمان به سن ۶ ماهگی و وزن ۷.۲۵ کیلوگرم
              نرسیده، هنوز برای توقف کامل تغذیه شبانه آماده نیست و باید تغذیه
              اول رو ادامه بدین. در این حالت میتونین تمام تغذیه های شبانه به جز
              یکی رو حذف کنین تا به وزن و سن مورد نظر برسه. برای اون یدونه
              تغذیه، بین ساعت ۱۰ تا ۱۱ شب، {name} رو خیلی آروم بیدار کنین و هر
              قدر که میخواد بهش شیر بدین. مراحل به شرح زیر هستن:
            </li>
            <p>
              گام اول: موقع تغذیه، {name} رو خیلی آروم بیدار کنین (نیازی نیست
              کاملا بیدار باشه) و هر قدر که میخواد بهش شیر بدین.
            </p>
            <p>گام دوم: {name} رو دوباره در تختخواب قرار بدین. </p>
            <p>
              گام سوم: اگه {name} بعد از شیر خوردن بدخلقی کرد، راس ۵، ۱۰ و هر ۱۵
              دقیقه یکبار بهش سر بزنین تا دوباره به خواب بره.
            </p>
            <p>
              گام چهارم: اگه {name} موقع تغذیه بیدار بود، اونقدر بهش سر بزنین تا
              به خواب فرو بره. بعدش ۵ دقیقه صبر کنین و بعد شیر دادن رو آغاز
              کنین.
            </p>
            <p>
              وقتی {name} همزمان به سن ۶ ماهگی و وزن ۷.۲۵ کیلوگرم رسید، میتونین
              طبق دستورالعمل، تغذیه شبانه رو متوقف کنید.
            </p>
          </div>
        )}
        <ul className="list-inside list-disc">
          <li>مطمئن بشین که {name} به درستی داره وزن گیری می کنه</li>
          <li>
            برنامه توقف شیردهی شبانه رو از همون شبی شروع کنید که یادگیری خواب رو
            شروع می کنید.
          </li>
        </ul>
        <NumBox
          num={2}
          description="اطلاعات در مورد برنامه کاهش تغذیه: "
          items={[
            `ما بر اساس الگوی فعلی تغذیه ${name}، برنامه کاهش تغذیه شبانه رو تنظیم کردیم. برنامه کاهش تغذیه در برنامه هدف و راهنمای روزانه موجوده. ما زمان هر تغذیه رو یک ساعت زودتر از ساعت بیداری ${name} برای شیر خوردن تنظیم کردیم تا زمان گرسنگیش رو پیش بینی کنیم و هر دفعه قبل اینکه به خاطر گرسنگی از خواب بیدار شه بهش شیر بدین. در طول کاهش تغذیه، هر شب دو دقیقه زودتر(یا ۳۰ میلی لیتر کمتر، در صورت تغذیه با شیر خشک) شیردهی رو متوقف کنید تا هر دفعه شیر کمتری مصرف کنه و در عوض در طول روز بهش شیر بیشتری بدین. وقتی زمان هر کدوم از دفعات شیردهی به دو دقیقه(یا ۳۰ میلی لیتر) برسه، اون وعده برای شب بعد  از برنامه حذف میشه.`,
            `اگه شیر زیادی ندارین یا ${name} زیاد شیر نمیخوره، می تونید هر شب زمان شیردهی رو یک دقیقه(یا ۱۵ میلی لیتر) کاهش بدین.`,
            `اگر در وعده ای بیشتر از ۲۰ دقیقه یا ۳۰۰ میلی لیتر به ${name} شیر میدین، ابتدا هر شب زمان شیردهی رو پنج دقیقه(۷۵ میلی لیتر) کاهش بدین تا به ۲۰ دقیقه(۳۰۰ میلی لیتر) برسین. وقتی به زمان ۲۰ دقیقه(۳۰۰ میلی لیتر) رسیدین، هر شب ۲ دقیقه(۳۰ میلی لیتر) زمان شیردهی رو کاهش بدین.`,
          ]}
        />
        <NumBox
          description="نکته مهم!"
          items={[
            `در طول دوره کاهش تغذیه، هرگز شب ها هر وقت ${name} خواست بهش شیر ندین. `,
            `مطمئن بشین که صبح ها شیر بیشتری به ${name} میدین. `,
            `اگه با این حال نگرانین، برنامه کاهش تغذیه رو به پزشک تون نشون بدین. `,
          ]}
        />
        <p>روند کاهش تغذیه به صورت گام به گام:</p>
        <NumBox
          num={1}
          description={`قبل از خواب، کل برنامه خواب رو مرور کنین.`}
        />
        <NumBox
          num={2}
          description={`زنگ هشدار تغذیه رو با صدای بلند تنظیم کنین (اگه اتاق با کودک تون مشترکه، گوشی رو روی حالت لرزش بذارین)! اگه بیشتر از یکبار به ${name} شیر میدین، از قبل همه هشدارها رو تنظیم کنین.`}
        />
        <NumBox
          num={3}
          description={`در هر نوبت از شیردهی ها؛ ${name} رو به آرومی بیدار کنین تا بهش شیر بدین (نیازی نیست کاملا بیدار باشه). `}
        />
        <NumBox num={4} description={`${name} رو به تختش بر گردونید. `} />
        <NumBox
          num={5}
          description={`اگه ${name} بعد از شیر خوردن بدخلقی میکنه، بعد از ۵ دقیقه، ۱۰ دقیقه و هر ۱۵ دقیقه یکبار بهش سر بزنین تا دوباره به خواب فرو بره. `}
        />
        <NumBox
          num={6}
          description={`اگه موقع شیر دادن ${name} بیداره، اونقدر بهش سر بزنین تا به خواب فرو بره. بعد از اون ۵ دقیقه منتظر بمونین و بعد شیر دادن رو شروع کنین.`}
        />
      </div>
    </PlatformContent>
  );
};

export default FeedingVideo;
