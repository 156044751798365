import { SliderValue } from "models/SliderValue";
import Slider from "react-input-slider";
import { toPersian } from "utils/raqam";
import FormItem from "./FormItem";

export interface SliderInputProps {
  label: string;
  hint?: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  minHint?: string;
  maxHint?: string;
  postText?: string;
  onChange: (value: SliderValue) => void;
}

const SliderInput: React.FC<SliderInputProps> = ({
  label,
  hint,
  value,
  min,
  max,
  step = 1,
  minHint,
  maxHint,
  postText,
  onChange,
}) => {
  return (
    <FormItem label={label} hint={hint}>
      <div dir="ltr">
        <div className="flex justify-between my-4 text-lg select-none">
          <span className="pt-6">{toPersian(min)}</span>
          <span className="font-semibold text-3xl mb-2" dir="rtl">
            {toPersian(value)}
            <span className="text-sm mr-2">{postText}</span>
          </span>
          <span className="pt-6">{toPersian(max)}</span>
        </div>
        <Slider
          axis="x"
          xmin={min}
          xmax={max}
          xstep={step}
          x={value}
          onChange={onChange}
          styles={{
            track: {
              width: "100%",
              background:
                "linear-gradient(90deg, rgba(87,87,132,1) 0%, rgba(255,255,255,1) 100%)",
              height: "30px",
              borderRadius: "15px",
            },
            active: {
              background: "transparent",
              height: "30px",
              borderRadius: "15px",
            },
            thumb: {
              width: "50px",
              height: "50px",
              background: "#d4d4f9",
              borderColor: "#122d41",
            },
          }}
        />
        {(minHint || maxHint) && (
          <div className="flex justify-between my-4 text-sm font-thin">
            <span>{minHint}</span>
            <span>{maxHint}</span>
          </div>
        )}
      </div>
    </FormItem>
  );
};

export default SliderInput;
