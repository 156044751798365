import PlatformContent from "components/layout/PlatfromContent";
import VideoPlayer from "components/utils/VideoPlayer";
import { faBrand, platformStrings } from "data/platform/platformStrings";
import { FaChevronLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";

export interface MorningVideoProps {}

const MorningVideo: React.FC<MorningVideoProps> = () => {
  const { name } = useRecoilValue(babyState);

  return (
    <PlatformContent>
      <NavLink
        to="/platform/method-in-action"
        className="text-lg font-semibold text-left mb-6 block"
      >
        <span>{platformStrings.back}</span>
        <FaChevronLeft className="inline-block mr-2" />
      </NavLink>
      <VideoPlayer code="14" />
      <div className="flex flex-col space-y-12 mt-12 leading-relxed">
        <p className="text-jungle-400 font-semibold text-2xl">صبح بخیر</p>
        <p>شروع روز چطور روی خواب تاثیر میذاره.</p>
        <ul className="list-inside list-disc flex flex-col space-y-2">
          <li>
            اگه {name} در ساعت مشخص شده برای بلند شدن بیداره، اول چراغ ها رو
            روشن کنین و بعد {name} رو از توی تخت بردارین.
          </li>
          <li>
            اگه {name} در ساعت تعیین شده خوابه، اجازه بدین بخوابه (تا ۱۲ ساعت
            بعد از زمانی که خوابش برده، اجازه داره بخوابه).
          </li>
          <li>
            قربون صدقه ش برید، نوازشش کنین و در آغوش بگیریدش. اگه آماده بود،
            میتونین بهش شیر بدین.
          </li>
          <li>
            به هیچ وجه قبل از زمان اولین چرت، اجازه ندین {name} به خواب بره.
          </li>
          <li>
            زمان بیدار شدن {name} رو در {faBrand} وارد کنین.
          </li>
        </ul>
      </div>
    </PlatformContent>
  );
};

export default MorningVideo;
