export const methodSelectionStrings = {
  title: "انتخاب روش",
  assesmentCompleted:
    "ارزیابی شما تقریبا کامل شده! آخرین گام قبل از دریافت برنامه خواب مختص شما، انتخاب روش آموزشه",
  pageDescription: (name?: string) =>
    `روش آموزش خواب به شما کمک میکنه تا ${name} رو در طول شب و چرت های روزانه حمایت کنین تا یاد بگیره بهتر بخوابه. هر روش مزایا و معایب خاص خودش رو داره که این موارد شامل طول مدت یادگیری و مقدار گریه های ${name} در پروسه آموزش میشه.`,
  nextPartDescription:
    "بخش بعدی، مروری کامل بر تمام روش ها و تفاوت های اونا ارائه میده. بعد از اون شما یه ارزیابی انجام میدین تا ببینین با کدوم روش سازگاری بهتری دارین و با توجه به مواردی که یاد گرفتین روش مناسب رو انتخاب میکنین. بعدش هم میریم سراغ برنامه خواب مخصوص شما!",
  methodsDescription:
    "روش های آموزش خواب زیادی وجود دارن که تعداد اونا از شمارش خارجه! اما حقیقت اینه که تمام اون روش ها رو میشه در سه دسته خلاصه کرد: روش ملاقات، روش ماندن و روش لمسی. توضیحات زیرتفاوت های این سه روش رو به طور خلاصه بیان میکنه.",
  visitDescription: "سر زدن همراه با صحبت کردن و بعد ترک اتاق",
  visitDuration: "زمان آموزش: ۴ الی ۵",
  stayDescription: (name?: string) => `حضور در اتاق تا زمانی که ${name} بخوابه`,
  stayDuration: "زمان آموزش: ۷ الی ۹ روز",
  touchDescription: (name?: string) =>
    `حضور در اتاق تا زمانی که ${name} بخوابه و لمس کردنش در صورت گریه کردن`,
  touchDuration: "زمان آموزش: ۱۴ روز",
  methodsDescription2: (name?: string) =>
    `ممکنه فکر کنین غیر طبیعیه که تماس بیشتر با کودک از طریق بغل کردن، لمس کردن و صحبت کردن، باعث طولانی تر شدن روند آموزش و گریه بیشتر کودک میشه. به هر حال در طی ۲۰ سالی که خانواده ها از این روش ها استفاده کردن، همیشه این مورد مشاهده شده. علت اینه که این کار روند آموزش خواب رو کند میکنه و این برای ${name} خسته کننده است که شما همون کارایی که قبلا برای خوابوندنش انجام میدادین رو دوباره تکرار می کنید.`,
  knowingDifferences:
    "آشنایی با تفاوت روش ها لازمه، اما مهمتر از اون پیدا کردن روشیه که با شما و کودکتون سازگار باشه. پرسش های بعد به شما کمک میکنه تا درک بهتری از شرایط خودتون پیدا کنین و متوجه بشین که کدوم روش برای شما مناسبه.",
  interactionLabel:
    "این سه روش از نظر طول پروسه آموزش و میزان تعامل شما با کودکتون متفاوتن.",
  interactionHint: (name?: string) =>
    `(مشخص کنین که موقع گریه ${name} با چه میزان تعامل -که معناش حضور شما در اتاق و بودن پیش کودکه- و زمان برای یادگیری راحت هستین)`,
  interactionMin: "زمان طولانی تر و تعامل بیشتر",
  interactionMax: "زمان کمتر و تعامل کمتر(در نتیجه گریه کمتر)",
  reducingParentInteractionLabel:
    "برای کاهش گریه کردن کوچولوتون در روند آموزش خواب، تا چه اندازه مایلین که تعامل تون رو باهاش کاهش بدین؟",
  reducingParentInteractionHint: (name?: string) =>
    `(تعامل شامل لمس کردن و حضور در اتاق ${name} میشه.)`,
  reducingParentInteractionMin: "تمایل ندارم",
  reducingParentInteractionMax: "تمایل دارم",
  reducingSitterInteractionLabel: (name?: string) =>
    `برای کاهش گریه کردن کوچولوتون در روند آموزش خواب، همسر شما یا پرستار کودکتون تا چه اندازه مایله که تعاملش رو با ${name} کاهش بده؟`,
  reducingSitterInteractionHint: (name?: string) =>
    `(تعامل شامل لمس کردن و حضور در اتاق ${name} میشه.)`,
  reducingSitterInteractionMin: "تمایل ندارم",
  reducingSitterInteractionMax: "تمایل دارم",
  needingHelpLabel: (name?: string) =>
    `در طول روز که ${name} گریه میکنه یا بدخلقی میکنه، برای آروم شدن چقدر به کمک نیاز داره؟`,
  needingHelpMin: "بسیار کم و به راحتی آروم میشه",
  needingHelpMax: "بسیار زیاد و به سختی آروم میشه",
  usedSleepHintsLabel: "قبلا از آموزش خواب استفاده کردین؟ ",
  leavingUpsetLabel: (name?: string) =>
    `وقتی سعی داشتید به ${name} کمک کنید تا خوابیدن رو یاد بگیره ، آیا در صورت آروم کردنش، سر زدن بهش یا موندن پیشش نسبت به وقتی که اتاق رو ترک میکردید ${name} بیشتر ناراحت می شد؟`,
  suggestionTitle:
    "با توجه به نکاتی که در مورد خانواده و کودک شما فهمیدیم، شما",
  suggestionEnd: "سازگاری دارین",
  suggestionDescription: (name?: string) =>
    `الان وقت انتخاب روش آموزش مناسب  برای ${name} هست. روش انتخابی، اساس خوابیدن ${name} در طول شبه، پس مهمه روشی رو انتخاب کنین که هم برای شما و هم برای ${name} مناسب باشه و بتونید بهش پایبند باشید.`,
  methodLabel: "کدوم روش رو برای آموزش خواب به کوچولوتون انتخاب میکنین؟",
  done: "تبریک میگیم! شما مرحله ارزیابی رو تموم کردین!",
  with: "با",
};
