import JungleButton from "components/form-elements/JungleButton";
import ShortTextInput from "components/form-elements/ShortTextInput";
import PurchaseButton from "components/landing/PurchaseButton";
import PlatformContent from "components/layout/PlatfromContent";
import { enterLicenseStrings } from "data/platform/enterLicenseString";
import { ChangeEvent, FormEvent } from "react";

export interface EnterLicenseProps {
  license?: string;
  loading: boolean;
  handleLicenseChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent) => void;
}

const EnterLicense: React.FC<EnterLicenseProps> = ({
  license,
  loading,
  handleLicenseChange,
  onSubmit,
}) => {
  return (
    <PlatformContent>
      <div className="flex flex-col space-y-12">
        <p className="text-jungle-400 font-semibold text-2xl">
          {enterLicenseStrings.title}
        </p>
        <ShortTextInput
          name="license"
          label={enterLicenseStrings.description}
          placeholder={enterLicenseStrings.placeholder}
          value={license}
          autoFocus
          className="text-center"
          onChange={handleLicenseChange}
        />
        <JungleButton loading={loading} onClick={onSubmit}>
          {enterLicenseStrings.validateLicense}
        </JungleButton>
        <PurchaseButton />
      </div>
    </PlatformContent>
  );
};

export default EnterLicense;
