import { toPersian } from "utils/raqam";

const ffAlert = (name?: string) =>
  `از اونجایی که ${name} به تغذیه زیاد در شب عادت داره، اول تغذیه شبانه رو به یک بار کاهش میدیم و بعد میریم سراغ آموزش خواب و همزمان آخرین تغذیه شبانه رو هم قطع میکنیم. راهنمایی های مرتبط رو میتونین در ویدئوی "شیر دادن در شب" تماشا کنین و در بخش "برنامه هدف" و "راهنمای روزانه" میتونین به برنامه نمونه قطع تغذیه شبانه دسترسی پیدا کنین.`;

export const feedingErrors = (
  isFF: boolean,
  name?: string,
  age?: number,
  weight?: number
) => ({
  stopFeeding: {
    title: `وقت متوقف کردن تمام تغذیه های شبانه است`,
    description: `بعد از ${toPersian(age || 0)} ماهگی و وزن ${toPersian(
      weight || 0
    )} کیلوگرم، ${name} دیگه به تغذیه شبانه نیازی نداره. بله، درسته! میتونیم به آرومی تغذیه شبانه ${name} رو قطع کنیم و به تغذیه روزانه ش اضافه کنیم. ما بهتون نشون میدیم که چطور آروم و آهسته، تغذیه شبانه ${name} رو قطع کنین. ${
      isFF ? ffAlert(name) : ""
    }`,
  },
  reduceFeeding: {
    title: `وقت رسوندن تعداد تغذیه های شبانه به یک باره`,
    description: `بعد از ${toPersian(age || 0)} ماهگی و وزن ${toPersian(
      weight || 0
    )} کیلوگرم، ${name} میتونه تمام تغذیه های شبانه به جز یکی رو متوقف بکنه! وقتی که اون آماده است تمام تغذیه رو به روز انتقال بده، تنها یک تغذیه رو در طول شب و ساعت ۱۰ نگه میداریم تا صبح راحت تر از خواب بیدار بشه. این کار به حفظ شیر شما هم کمک میکنه.وقتی ${name} حداقل ۶ ماهه باشه و حداقل ۷.۲۵۰ کیلوگرم وزن داشته باشه، میتونین تغذیه شبانه رو کاملا متوقف کنین.
    ${isFF ? ffAlert(name) : ""}`,
  },
  keepFeeding: {
    title: `یک بار تغذیه شبانه رو تا وقتی که ${name} حداقل به سن ۶ ماهگی و وزن ۷.۲۵۰ کیلوگرم برسه ادامه بدید`,
    description: `راهنمایی های مرتبط با شیردهی شبانه رو میتونین در ویدئوی "شیر دادن در شب" تماشا کنین و در بخش "برنامه هدف" و "راهنمای روزانه" میتونین به برنامه شخصی سازی شده قطع تغذیه شبانه دسترسی پیدا کنین.`,
  },
});
