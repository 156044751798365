import { ChangeEvent } from "react";
import FormItem from "./FormItem";

export interface ShortTextInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  value?: string;
  className?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ShortTextInput: React.FC<ShortTextInputProps> = ({
  name,
  label,
  placeholder,
  value,
  hint,
  className = "",
  autoFocus = false,
  tabIndex,
  type = "text",
  onChange,
}) => {
  return (
    <FormItem label={label} hint={hint}>
      <input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value || ""}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        onChange={onChange}
        autoComplete="off"
        className={`bg-navy border-b-2 border-gray-600 py-3 text-lg font-semibold placeholder-gray-700 transition duration-200 outline-none focus:border-jasmine ${className}`}
      />
    </FormItem>
  );
};

export default ShortTextInput;
