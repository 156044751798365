import { toPersian } from "utils/raqam";
import { faBrand } from "../platformStrings";

export const dailyStrings = {
  day: (day: number) => `روز ${toPersian(day)}`,
  goodMorning: "صبح بخیر!",
  description: (day: number) =>
    `شما پیشرفت خیلی خوبی داشتید! امیدواریم روز ${toPersian(
      day
    )} ام هم موفق باشید.`,
  lastDayTitle: "میخواین یه روز دیگه اضافه کنین؟",
  lastDayDescription: (name?: string) =>
    `اگه ${name} کل شب رو نمیخوابه یا کاملا با برنامه همراه نشده، پیشنهاد میکنیم یک یا دو روز دیگه به برنامه اضافه کنین. در صورتی که ${faBrand} رو تکمیل کنین، همچنان به همه محتواهای سامانه دسترسی دارید`,
  addDay: "یک روز اضافه کنین",
  endDreamlab: `${faBrand} رو تکمیل کنین`,
  wokeUpEarlyTitle: (name?: string) => `${name} زود از خواب بیدار شده`,
  wokeUpEarly: (name?: string) =>
    ` اگه ${name} زودتر از مان تعیین شده از خواب بیدار شد، همون رفتاری رو داشته باشین که با شب بیداری دارین. ۵ ۱۰، و ۱۵ دقیقه یکبار به او سر بزنین تا ${name} به خواب بره یا به زمان تعیین شده برسین. اگه ${name} به خواب بره، میتونه ۱۲ ساعت از وقتی که دیشب به تحتخواب گذاشته شد بخوابه. اگه به خواب نره، در ساعت تعیین شده وارد اتاق بشین، چراغ ها رو روشن کنین، با شادی با ${name} صحبت کنین و او رو از تختخواب بیرون بیارین.`,
  wokeUpEarlyNote: (name?: string) =>
    `نکته: بعضی بچه ها با سر زدن در طول روز بیشتر تحریک میشن، چرا که بیشتر خواب شبانه رو پشت سر گذاشتن و میتونن حسابی با خواب مبارزه کنن! اگه سر زدن شما، ${name} رو ناراحت میکنه، بهتره در این ساعات به او سر نزنین. زود بیدار شدن در صبح، یکی از پیچیده ترین بخش های آموزش خوابه. صبور باشین، ممکنه یک هفته طول بکشه تا ${name} به این اوضاع عادت کنه!`,
  wokeUpLateTitle: (name?: string) => `${name} زیادی خوابیده`,
  wokeUpLate: (name?: string) =>
    `اگه اجازه بدیم ${name} زیادی بخوابه، دیگه فرصت کافی برای تمام چرت ها پیدا نمیکنه. وقتی ${name} زیادی خوابید، بهتره زمان یکی از چرت ها رو کاهش بدین تا شب بیخواب نشه.`,
  wokeUpLateNote: (name?: string) =>
    `ما همیشه سعی میکنیم تا زمان خواب، زمان چرت، و زمان بیداری رو ثابت نگه داریم تا چرخه خواب ${name} منظم و قابل پیشبینی بشه.`,
  askUs: "پرسش از ما",
};

export const firstDayStrings = {
  title: (name?: string) => `امشب اولین شب آموش خواب ${name} هست!`,
  description: (name?: string) =>
    `طبیعیه که نگران باشین. اجرای دقیق این برنامه به خواب شما و ${name} کمک میکنه . هنوز هم سوالاتی تو ذهنتون دارین؟ کارشناسان ما در بخش پرسش از ما در اسرع وقت و با دقت به تک تک پرسشهای شما پاسخ میدن.`,
  cryTitle: (name?: string) =>
    `وقتی ${name} شروع به گریه کرد، این ویدئو رو تماشا کنین...`,
  readyTitle: "آمادگی های لازم برای امشب و فردا صبح",
  readyDescription:
    "قبل از اینکه بخوابین، نکات زیر در مورد بیداری شبانه، تغذیه شبانه، و شروع روزانه رو مطالعه کنین تا برای فردا آماده باشین.",
  nightAwakingSummary: "مرور  بیداری شبانه",
  nightFeedingSummary: "مرور تغذیه شبانه",
  startDaySummary: "مرور شروع روزانه",
};

export const secondDayStrings = {
  description:
    "تبریک! شما شب اول رو پشت سر گذاشتین! این ویدئو رو ببینین تا انرژی بگیرین و ادامه بدین.",
  questionDescription:
    "سوالی دارین؟ کارشناسان ما به دقت به پرسش های شما پاسخ میدن. سوال خودتون رو در بخش پرسش از ما مطرح کنید.",
};

export const thirdDayStrings = {
  description:
    "روز سوم کمی متفاوته. با توضیحات زیر میتونین روزتون رو شروع کنین:",
};

export const fourthDayString = {
  description: "در ویدئوی زیر توضیحاتی برای شروع درست امروز داده شده",
};
