import PlatformContent from "components/layout/PlatfromContent";
import { babyInfoStrings } from "data/platform/babyInfoStrings";
import BabyInformationDescription, {
  BabyInformationDescriptionProps,
} from "./Descriptions";
import BabyInformationForm, { BabyInformationFormProps } from "./Form";

export interface BabyInfoProps {
  formProps: BabyInformationFormProps;
  descripitonProps: BabyInformationDescriptionProps;
}

const BabyInfo: React.FC<BabyInfoProps> = ({ formProps, descripitonProps }) => {
  return (
    <PlatformContent>
      <p className="text-jungle font-semibold text-2xl">
        {babyInfoStrings.title}
      </p>
      <BabyInformationForm {...formProps} />
      <BabyInformationDescription {...descripitonProps} />
    </PlatformContent>
  );
};

export default BabyInfo;
