import { useQuery } from "@apollo/client";
import { VIDEO } from "graphql/queries/video";
import mockVideo from "images/mock-video.jpg";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import PageLoader from "./PageLoader";
import Player from "./Player";

export interface VideoPlayerProps {
  code?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ code }) => {
  const { id } = useRecoilValue(babyState);
  const [videoLink, setVideoLink] = useState<string | undefined>(undefined);

  const { loading, error, refetch } = useQuery(VIDEO, {
    variables: {
      infant_id: id,
      key: code || "",
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const link = data.dreamlab_video.hls_playlist;
      setVideoLink(link);
    },
  });

  return (
    <PageLoader
      loading={loading}
      error={error?.message}
      onRefetch={() => refetch()}
      isVideoLoader
    >
      <div>
        {videoLink === undefined && (
          <img src={mockVideo} alt="mock-video" className="w-full" />
        )}
        {videoLink && code && <Player link={videoLink} code={code} />}
      </div>
    </PageLoader>
  );
};

export default VideoPlayer;
