import NextStep from "components/utils/NextStep";
import { methodSelectionStrings } from "data/platform/methodSelectionStrings";
import { Fragment } from "react";
import stayIcon from "images/hero/hero-stay.png";
import visitIcon from "images/hero/hero-visit.png";
import touchIcon from "images/hero/hero-touch.png";
import MethodItem from "./MethodItem";
import { platformStrings } from "data/platform/platformStrings";
import VideoPlayer from "components/utils/VideoPlayer";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";

export interface MethodSelectionDescriptionProps {
  name?: string;
  descStep: number;
  onNextDescStep: (currStep: number) => void;
  onIntroCompleted: () => void;
}

const MethodSelectionDescription: React.FC<MethodSelectionDescriptionProps> = ({
  name,
  descStep,
  onNextDescStep,
  onIntroCompleted,
}) => {
  return (
    <div className="text-justify leading-relaxed flex flex-col space-y-10">
      <p>{methodSelectionStrings.assesmentCompleted}</p>
      <p>{methodSelectionStrings.pageDescription(name)}</p>
      {descStep < 2 && <NextStep onNext={() => onNextDescStep(1)} />}
      {descStep > 1 && (
        <PlatformSection isPassed={descStep > 2}>
          <PlatformDivider />
          <p>{methodSelectionStrings.nextPartDescription}</p>
          {descStep < 3 && <NextStep onNext={() => onNextDescStep(2)} />}
        </PlatformSection>
      )}
      {descStep > 2 && (
        <Fragment>
          <PlatformDivider />
          <p>{methodSelectionStrings.methodsDescription}</p>
          <div className="flex flex-col space-y-12">
            <MethodItem
              title={platformStrings.visitMethod}
              description={methodSelectionStrings.visitDescription}
              duration={methodSelectionStrings.visitDuration}
              icon={visitIcon}
            />
            <MethodItem
              title={platformStrings.stayMethod}
              description={methodSelectionStrings.stayDescription(name)}
              duration={methodSelectionStrings.stayDuration}
              icon={stayIcon}
            />
            <MethodItem
              title={platformStrings.touchMethod}
              description={methodSelectionStrings.touchDescription(name)}
              duration={methodSelectionStrings.touchDuration}
              icon={touchIcon}
            />
          </div>
          <p>{methodSelectionStrings.methodsDescription2(name)}</p>
          {descStep < 4 && <NextStep onNext={() => onNextDescStep(3)} />}
        </Fragment>
      )}
      {descStep > 3 && (
        <PlatformSection>
          <PlatformDivider />
          <VideoPlayer code="4" />
          <p className="mt-4">{methodSelectionStrings.knowingDifferences}</p>
          {descStep < 5 && <NextStep onNext={onIntroCompleted} />}
        </PlatformSection>
      )}
    </div>
  );
};

export default MethodSelectionDescription;
