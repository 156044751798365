import { useMutation, useQuery } from "@apollo/client";
import WhatsMissing from "components/sleep-plan/whats-missing";
import PageLoader from "components/utils/PageLoader";
import { GET, STORE } from "graphql/queries/store";
import { IWhatsMissing, WhatsMissingItem } from "models/WMItem";
import { useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { methodNameState } from "recoil/selectors/methodName";
import { parseStoreData, valueGetter } from "utils/storeParser";

const WhatsMissingScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [data, setData] = useState<Record<string, IWhatsMissing>>({});
  const [loadingKey, setLoadingKey] = useState<string | undefined>(undefined);
  const methodName = useRecoilValue(methodNameState);

  const whatsMissingKeys: string[] = [
    "speResult",
    "spnResult",
    "spsResult",
    "sphResult",
    "spaResult",
  ];

  const { error, loading, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: whatsMissingKeys,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const env = valueGetter(serverResult, "speResult")
        ? JSON.parse(valueGetter(serverResult, "speResult") as string)
        : {};
      const habit = valueGetter(serverResult, "sphResult")
        ? JSON.parse(valueGetter(serverResult, "sphResult") as string)
        : {};
      const schedule = valueGetter(serverResult, "spsResult")
        ? JSON.parse(valueGetter(serverResult, "spsResult") as string)
        : {};
      const routine = valueGetter(serverResult, "spaResult")
        ? JSON.parse(valueGetter(serverResult, "spaResult") as string)
        : {};
      const noshing = valueGetter(serverResult, "spnResult")
        ? JSON.parse(valueGetter(serverResult, "spnResult") as string)
        : {};
      setData({
        speResult: env,
        sphResult: habit,
        spaResult: routine,
        spsResult: schedule,
        spnResult: noshing,
      });
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => toast.error(error.message),
  });

  const onCheck = (item: WhatsMissingItem, key: string) => {
    setLoadingKey(key);
    const items = data[key]?.items || [];
    const itemObject = data[key];
    const cleanItems = items.filter((i) => i.title !== item.title);
    const newItems: WhatsMissingItem[] = [
      ...cleanItems,
      { ...item, isChecked: true },
    ];
    const isCompleted =
      newItems.filter((i) => i.isChecked === true).length === newItems.length;
    const newRate = isCompleted ? 5 : itemObject.rate;
    storeData({
      variables: {
        infant_id: id,
        data: [
          { key, value: JSON.stringify({ rate: newRate, items: newItems }) },
        ],
      },
    }).then((res) => {
      setData({ ...data, [key]: { rate: newRate, items: newItems } });
    });
  };

  return (
    <PageLoader error={error} loading={loading} onRefetch={refetch}>
      <WhatsMissing
        name={name}
        envItems={data.speResult}
        habitItems={data.sphResult}
        scheduleItems={data.spsResult}
        routineItems={data.spaResult}
        noshingItems={data.spnResult}
        loading={storeLoading}
        method={methodName}
        loadingKey={loadingKey}
        onCheck={onCheck}
      />
    </PageLoader>
  );
};

export default WhatsMissingScreen;
