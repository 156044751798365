import { selector } from "recoil";
import { methodState } from "recoil/atoms/method";
import { getMethodName } from "utils/getMethodName";

export const methodNameState = selector({
  key: "getMethodName",
  get: ({ get }) => {
    const methodName = get(methodState);
    return getMethodName(methodName || "");
  },
});
