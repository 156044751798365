import { appStrings } from "./strings";
import comment1PNG from "images/comments/comment1.png";
import comment2PNG from "images/comments/comment2.png";
import comment3PNG from "images/comments/comment3.png";

export interface IComment {
  name: string;
  duration: string;
  text: string;
  image: string;
}

export const commentList = (lang: string): IComment[] => {
  switch (lang) {
    case "fa":
      return persianCommentList;
    case "en":
      return englishCommentList;
    default:
      return persianCommentList;
  }
};

const persianCommentList: IComment[] = [
  {
    name: "آرتا کوچولو",
    duration: "استفاده از روش بازدید",
    text: `ما یک زوج شاغل هستیم که بعد از بچه دار شدن یکی از دغدغه های اصلیمون خواب نوزادمون بود. مسئله خواب آرتا کوچولوی ما باعث شده بود شبا درست نتونیم بخوابیم و صبح سر کار کاملا بی انرژی و خسته باشیم. خیلی خوشحالیم که ${appStrings.brand.fa} این مشکل رو برامون حل کرد`,
    image: comment1PNG,
  },
  {
    name: "حلما کوچولو",
    duration: "استفاده از روش ماندن",
    text: `من در برخی سایت ها راجع به تاثیر مستقیم خواب نوزاد بر رشدش مقالاتی خونده بودم. این شد که به دنبال روشی برای بهبود خواب نوزاد گشتم و به توصیه یکی از دوستان با ${appStrings.brand.fa} آشنا شدم که انصافا هم خیلی کاربردی بود و نتیجه داد`,
    image: comment2PNG,
  },
  {
    name: "پریسا کوچولو",
    duration: "استفاده از روش لمس کردن",
    text: `می تونم این رو بگم که به تیم خوب ${appStrings.brand.fa} اعتماد کنید. من چون خودم استفاده کردم و راضی بودم به هر کس که تایم های خواب نوزادش واقعا داره اذیتش میکنه ${appStrings.brand.fa} رو توصیه می کنم`,
    image: comment3PNG,
  },
];

const englishCommentList: IComment[] = [
  {
    name: "Mohammad Abhari",
    duration: "More than 3 months usage",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis. Duis vel mi a risus viverra dignissim in a urna. Maecenas mollis turpis id magna tristique imperdiet. Vestibulum varius leo ut molestie ultrices. Vivamus vulputate blandit metus, sit amet ",
    image: "/images/comments/comment-khosh.png",
  },
  {
    name: "Somayeh Khani",
    duration: "More than 3 months usage",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis. Duis vel mi a risus viverra dignissim in a urna. Maecenas mollis turpis id magna tristique imperdiet. Vestibulum varius leo ut molestie ultrices. Vivamus vulputate blandit metus, sit amet ",
    image: "/images/comments/comment-khosh.png",
  },
  {
    name: "Niloofar Khosh",
    duration: "More than 3 months usage",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis. Duis vel mi a risus viverra dignissim in a urna. Maecenas mollis turpis id magna tristique imperdiet. Vestibulum varius leo ut molestie ultrices. Vivamus vulputate blandit metus, sit amet ",
    image: "/images/comments/comment-khosh.png",
  },
];
