import TicketItem from "components/tickets/Item";
import { Ticket } from "models/Ticket";
import { useState } from "react";

export interface TicketItemScreenProps {
  ticket: Ticket;
}

const TicketItemScreen: React.FC<TicketItemScreenProps> = ({ ticket }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return <TicketItem open={open} toggleOpen={toggleOpen} {...ticket} />;
};

export default TicketItemScreen;
