import { gql } from "@apollo/client";

export const STORE = gql`
  mutation dreamlab_store_infant_data(
    $infant_id: Int!
    $data: [DREAMLAB_QuestionInput]!
  ) {
    dreamlab_store_infant_data(infant_id: $infant_id, data: $data) {
      key
      value
    }
  }
`;

export const GET = gql`
  query DREAMLAB_get_infant_data($keys: [String]!, $infant_id: Int!) {
    DREAMLAB_get_infant_data(keys: $keys, infant_id: $infant_id) {
      key
      value
    }
  }
`;
