import WokeEarly from "components/daily/AlertModals/WokeEarlyModal";
import WokeLate from "components/daily/AlertModals/WokeLate";
import MorningForm, { DailyMorningProps } from "components/daily/MorningForm";
import { DailyServer } from "models/DailyServer";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { isAfterTime, isBeforeTime } from "utils/timeUtils";

export interface MorningFormScreenProps extends DailyMorningProps {
  dayNumber: number;
  serverData: DailyServer;
  onSaveData: (data: DailyServer) => void;
}

const MorningFormScreen: React.FC<MorningFormScreenProps> = (props) => {
  const { serverData, onSaveData } = props;
  const [userTime, setUserTime] = useState<string | undefined>(props.startTime);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [wokeEarlyModalOpen, setWokeEarlyModalOpen] = useState(false);
  const [wokeLateModalOpen, setWokeLateModalOpen] = useState(false);

  const onCloseModals = () => {
    setWokeEarlyModalOpen(false);
    setWokeLateModalOpen(false);
  };

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setUserTime(value);
  };

  const onSave = () => {
    if (isBeforeTime(props.startTime, userTime)) {
      setWokeEarlyModalOpen(true);
    }
    if (isAfterTime(props.wakeTime, userTime)) {
      setWokeLateModalOpen(true);
    }
    const serverDataMorning = userTime;
    const newServerData: DailyServer = {
      ...serverData,
      morningWaketime: serverDataMorning,
    };
    onSaveData(newServerData);
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (serverData && serverData.morningWaketime) {
      setUserTime(serverData.morningWaketime);
      setIsEditing(false);
    } else {
      setUserTime(undefined);
      setIsEditing(true);
    }
  }, [serverData]);

  return (
    <Fragment>
      <MorningForm
        {...props}
        isEditing={isEditing}
        userTime={userTime}
        onTimeChange={onTimeChange}
        onSave={onSave}
        onEdit={onEdit}
      />
      {wokeEarlyModalOpen && (
        <WokeEarly
          open={wokeEarlyModalOpen}
          name={props.name}
          onClose={onCloseModals}
        />
      )}
      {wokeLateModalOpen && (
        <WokeLate
          open={wokeLateModalOpen}
          name={props.name}
          onClose={onCloseModals}
        />
      )}
    </Fragment>
  );
};

export default MorningFormScreen;
