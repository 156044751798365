import { toPersian } from "utils/raqam";
import { faBrand } from "./platformStrings";

export const babyInfoStrings = {
  title: "آشنایی با کودک شما",
  genderLabel: "فرزند شما دختره یا پسر؟",
  nameLabel: "اسمش چیه؟",
  birthdayLabel: "تاریخ تولد کودکتون رو وارد کنید:",
  birthdayHint: "(مثلا ۱۳۹۹/۲/۱۱)",
  yearPlaceholder: "سال",
  monthPlaceholder: "ماه",
  dayPlaceholder: "روز",
  namePlaceholder: "اسم کودک",
  preMatureLabel: (name?: string) => `${name} چند ماه زودتر به دنیا اومده؟`,
  preMatureHint: "(اگه به موقع به دنیا اومده بذارید صفر بمونه)",
  weightLabel: (name?: string) => `الان وزن ${name} چقدره؟`,
  weightHint: (name?: string) =>
    `(اگه در ماه اخیر ${name} رو وزن نکردین، به مطب پزشک کودکان مراجعه و وزن دقیق رو وارد کنین)`,
  desc1: "باورتون نمیشه که میخوایم بهتون چی بگیم...",
  desc2: "شاید بهتر باشه قبلش با آرامش یه جا بشینین...",
  desc3: (age: number, a: string, b: string, name?: string) =>
    `در ${toPersian(
      age
    )} ماهگی، ${name} کوچولو میتونه ۱۱ تا ۱۲ ساعت در شب و ${toPersian(
      a
    )} تا ${toPersian(b)} ساعت در طول روز بخوابه!`,
  desc4: (name?: string) =>
    `میدونیم که دارین به چی فکر میکنین: "الهام و محیاسادات، شما ${name} کوچولوی من رو نمیشناسین. امکان نداره اون اینقدر بخوابه!"`,
  desc5: (name?: string) =>
    `در طول ۲۰ سال گذشته، با روش هایی که در ${faBrand} ارائه میشه بیش از ۵۰۰۰۰۰ کودک یاد گرفتن که خودشونو بخوابونن. اگه همه اون کوچولوها یاد گرفتن که خوب بخوابن، پس ${name} هم میتونه!`,
  desc6: (name?: string) =>
    `ما به کمک آموزش های ${faBrand} به ${name} کوچولو کمک می کنیم که با کمترین میزان بدخلقی و گریه، هم در طول شب به مقدار کافی بخوابه و هم روز ها چرت بزنه. محکم بشینین که زندگیتون داره عوض میشه!`,
};
