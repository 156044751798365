import { useMutation, useQuery } from "@apollo/client";
import SleepStealerEnv from "components/sleep-stealers/env";
import PageLoader from "components/utils/PageLoader";
import { envErrors } from "data/platform/errors/envErrors";
import { GET, STORE } from "graphql/queries/store";
import { IServerData } from "models/ServerData";
import { SliderValue } from "models/SliderValue";
import { SleepStealerEnvData } from "models/SPE";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { boolToJson } from "utils/booleanParser";
import { parseSentData, parseStoreData, valueGetter } from "utils/storeParser";

const SleepStealersEnvScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [app, setApp] = useRecoilState(appState);
  const [speData, setSpeData] = useState<SleepStealerEnvData>({
    daylight: 1,
    cribToys: [],
  });
  const [multipleDone, setMultipleDone] = useState<boolean>(false);
  const [sliderDone, setSliderDone] = useState<boolean>(false);
  const [formCompleted, setFormCompleted] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);
  const [rate, setRate] = useState<number | undefined>(undefined);
  const [feeding, setFeeding] = useState<string | undefined>(undefined);

  const generateResult = () => {
    const errors = envErrors(name);
    let missingItems = [];
    if (speData.room) {
      feeding === "bottle"
        ? missingItems.push(errors.roomBottle)
        : missingItems.push(errors.room);
    }
    speData.sameRoomSiblings && missingItems.push(errors.sameRoomSiblings);
    !speData.sleepBag && missingItems.push(errors.sleepBag);
    speData.cribToys.length !== 1 &&
      speData.cribToys[0] !== 8 &&
      missingItems.push(errors.cribToys);
    !speData.whiteNoise && missingItems.push(errors.whiteNoise);
    speData.daylight !== 10 && missingItems.push(errors.daylight);
    speData.sleepCloth !== 4 && missingItems.push(errors.sleepCloth);
    speData.roomTemp !== 2 && missingItems.push(errors.roomTemp);
    const finalRate = Number((((8 - missingItems.length) / 8) * 5).toFixed(0));
    return {
      items: missingItems,
      rate: finalRate,
    };
  };

  const onMultipleAnswered = () => {
    setMultipleDone(true);
  };

  const onSliderAnswered = () => {
    setSliderDone(true);
  };

  const speKeys: string[] = [
    "108",
    "201",
    "202",
    "203",
    "204",
    "205",
    "206",
    "207",
    "208",
    "speLocked",
    "speResult",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: Number(id),
      keys: speKeys,
    },
    onCompleted: (data) => {
      const speServerResult = parseStoreData(data);
      const speState: SleepStealerEnvData = {
        room: valueGetter(speServerResult, "201", true) as boolean,
        sameRoomSiblings: valueGetter(speServerResult, "202", true) as boolean,
        sleepBag: valueGetter(speServerResult, "203", true) as boolean,
        cribToys: valueGetter(speServerResult, "204")
          ? JSON.parse(valueGetter(speServerResult, "204") as string)
          : [],
        whiteNoise: valueGetter(speServerResult, "205", true) as boolean,
        daylight: valueGetter(speServerResult, "206")
          ? Number(valueGetter(speServerResult, "206"))
          : 1,
        sleepCloth: valueGetter(speServerResult, "207")
          ? Number(valueGetter(speServerResult, "207"))
          : undefined,
        roomTemp: valueGetter(speServerResult, "208")
          ? Number(valueGetter(speServerResult, "208"))
          : undefined,
      };
      const feedState = valueGetter(speServerResult, "108") as string;
      const lockedState = valueGetter(speServerResult, "speLocked") as boolean;
      const rateState = valueGetter(speServerResult, "speResult")
        ? JSON.parse(valueGetter(speServerResult, "speResult") as string).rate
        : undefined;
      rateState && setRate(Number(rateState));
      lockedState && setFormCompleted(true);
      setLocked(lockedState);
      setFeeding(feedState);
      setSpeData(speState);
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const rateState = JSON.parse(
        valueGetter(parseSentData(data), "speResult") as string
      ).rate;
      rateState !== undefined && setRate(rateState);
      setFormCompleted(true);
      setLocked(true);
      setApp({
        ...app,
        state: "/platform/sleep-stealers/activities",
      });
    },
  });

  const onFormCompleted = () => {
    const result = generateResult();
    const speServerData: IServerData[] = [
      {
        key: "201",
        value: boolToJson(speData.room),
      },
      {
        key: "202",
        value: boolToJson(speData.sameRoomSiblings),
      },
      {
        key: "203",
        value: boolToJson(speData.sleepBag),
      },
      {
        key: "204",
        value: JSON.stringify(speData.cribToys),
      },
      {
        key: "205",
        value: boolToJson(speData.whiteNoise),
      },
      {
        key: "206",
        value: speData.daylight.toString() || "",
      },
      {
        key: "207",
        value: speData.sleepCloth?.toString() || "",
      },
      {
        key: "208",
        value: speData.roomTemp?.toString() || "",
      },
      {
        key: "speLocked",
        value: boolToJson(true),
      },
      {
        key: "appState",
        value: JSON.stringify({
          ...app,
          state: "/platform/sleep-stealers/activities",
        }),
      },
      {
        key: "speResult",
        value: JSON.stringify(result),
      },
    ];
    if (rate === undefined) {
      storeData({
        variables: {
          infant_id: id,
          data: speServerData,
        },
      });
    }
  };

  const onSingleChange = (value: string | boolean | number, key: string) => {
    if (locked) return;
    setSpeData({ ...speData, [key]: value });
  };

  const onMultipleChange = (value: string | boolean | number) => {
    if (locked) return;
    const currentCribs = speData.cribToys;
    const isExist = currentCribs.findIndex((item) => item === value);
    if (isExist > -1) {
      const newCribs = currentCribs.filter((item) => item !== value);
      setSpeData({ ...speData, cribToys: newCribs });
      return;
    }
    const selectedNone = currentCribs.filter((item) => item === 8).length > 0;
    if (selectedNone && value !== 8) return;
    if (!selectedNone && currentCribs.length > 0 && value === 8) return;
    const newCribs = [...currentCribs, Number(value)];
    setSpeData({ ...speData, cribToys: newCribs });
    return;
  };

  const onSliderChange = (value: SliderValue) => {
    if (locked) return;
    setSpeData({ ...speData, daylight: value.x });
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [speData, sliderDone, multipleDone, formCompleted]);

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <SleepStealerEnv
        formCompleted={formCompleted}
        formProps={{
          ...speData,
          name,
          loading: storeLoading,
          sliderDone,
          multipleDone,
          formCompleted,
          locked,
          onMultipleAnswered,
          onFormCompleted,
          onMultipleChange,
          onSingleChange,
          onSliderAnswered,
          onSliderChange,
        }}
        descProps={{
          rate: rate || 0,
          name,
        }}
        rate={rate}
      />
    </PageLoader>
  );
};

export default SleepStealersEnvScreen;
