import { useLazyQuery } from "@apollo/client";
import PageLoader from "components/utils/PageLoader";
import { ME } from "graphql/queries/auth";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState, tokenState, userState } from "recoil/atoms/auth";
import { babySavedState, babyState } from "recoil/atoms/baby";
import { babyListState } from "recoil/atoms/babyList";
import { impersonatingState } from "recoil/atoms/impersonating";
import { licenseCodeState, licenseState } from "recoil/atoms/license";
import { babyArrayParser } from "utils/babyParser";
import StateIdentifier from "./StateIdentifier";

export interface UserIdentifierProps {
  children: ReactNode;
}

const UserIdentifier: React.FC<UserIdentifierProps> = ({ children }) => {
  const setUser = useSetRecoilState(userState);
  const setBabyList = useSetRecoilState(babyListState);
  const setBaby = useSetRecoilState(babyState);
  const setAuth = useSetRecoilState(authState);
  const setBabySaved = useSetRecoilState(babySavedState);
  const setLicense = useSetRecoilState(licenseState);
  const setLicenseCode = useSetRecoilState(licenseCodeState);
  const setImpersonating = useSetRecoilState(impersonatingState);
  const token = useRecoilValue(tokenState);
  const [id, setId] = useState<number | undefined>(undefined);
  const [myLoading, setMyLoading] = useState(true);
  const { push } = useHistory();

  const [getMe, { loading, error }] = useLazyQuery(ME, {
    onError: () => {
      setAuth(false);
      setMyLoading(false);
    },
    onCompleted: (data) => {
      if (data.me) {
        setUser({
          firstName: data.me.first_name,
          lastName: data.me.last_name,
          email: data.me.email,
          licenses: data.me.dreamlab_licenses,
        });
        if (data.me.is_impersonating) {
          setImpersonating(true);
        }
        if (
          !data.me.dreamlab_licenses ||
          data.me.dreamlab_licenses.length === 0
        ) {
          const path = window.location.pathname;
          if (path.includes("platform") && !path.includes("enter-license"))
            push("/platform/enter-license");
        } else {
          setLicenseCode(data.me.dreamlab_licenses[0].code);
        }
        const babyList = babyArrayParser(data.me.infants);
        const filteredBabyList = babyList.filter((baby) => {
          if (baby.age) {
            return baby.age > 3 && baby.age < 19;
          }
          return false;
        });
        setBabyList(filteredBabyList);
        if (
          data.me.dreamlab_licenses &&
          data.me.dreamlab_licenses.length > 0 &&
          data.me.dreamlab_licenses[0]?.infant?.id
        ) {
          const baby = babyList.find(
            (item) =>
              item.id === Number(data.me.dreamlab_licenses[0]?.infant?.id)
          );
          if (baby) {
            setBaby(baby);
            setBabySaved(true);
            setId(baby.id);
            setLicense(true);
          }
        }
        setAuth(true);
      } else {
        setAuth(false);
      }
      setMyLoading(false);
    },
  });
  useEffect(() => {
    if (token) {
      getMe();
    }
  }, [getMe, token]);

  return (
    <PageLoader loading={loading} error={error} showError={false}>
      {!myLoading && <StateIdentifier id={id || 0}>{children}</StateIdentifier>}
    </PageLoader>
  );
};

export default UserIdentifier;
