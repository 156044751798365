import { platformStrings } from "data/platform/platformStrings";
import { AiFillLock } from "react-icons/ai";
import { RiExternalLinkFill } from "react-icons/ri";

const AccessDenied: React.FC = () => {
  const url = window.location.pathname;

  return (
    <div className="flex flex-col space-y-8 text-center">
      <p>
        <AiFillLock className="text-9xl inline-block text-jungle-400" />
      </p>
      <div>
        <p className="text-2xl font-semibold mb-3 text-jungle-400">
          {platformStrings.accessDenied}
        </p>
        <p>{platformStrings.accessDeniedHint}</p>
      </div>
      <div>
        <a
          href={`${process.env.REACT_APP_ACCOUNT_URL}?from=${url}&app=sleepschool`}
          className="bg-primary-600 text-navy py-2 px-3 rounded font-semibold bg-orange"
        >
          <RiExternalLinkFill className="inline-block ml-2" />
          {platformStrings.login}
        </a>
      </div>
    </div>
  );
};

export default AccessDenied;
