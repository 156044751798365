import PlatformContent from "components/layout/PlatfromContent";
import { methodSelectionStrings } from "data/platform/methodSelectionStrings";
import MethodSelectionDescription, {
  MethodSelectionDescriptionProps,
} from "./Description";
import MethodSelectionForm, { MethodSelectionFormProps } from "./Form";

export interface MethodSelectionProps {
  formProps: MethodSelectionFormProps;
  descProps: MethodSelectionDescriptionProps;
  introCompleted: boolean;
}

const MethodSelection: React.FC<MethodSelectionProps> = ({
  formProps,
  descProps,
  introCompleted,
}) => {
  return (
    <PlatformContent>
      <p className="text-jungle font-semibold text-2xl mb-8">
        {methodSelectionStrings.title}
      </p>
      <MethodSelectionDescription {...descProps} />
      {introCompleted && <MethodSelectionForm {...formProps} />}
    </PlatformContent>
  );
};

export default MethodSelection;
