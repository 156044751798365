import React from "react";
import { reasonsList } from "data/landing/reasons";
import Header from "./Header";
import Item from "./Item";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section id="why" className="why-bg bg-cover bg-no-repeat" dir={dir}>
      <div className="container mx-auto py-8 lg:py-14 lg:px-8">
        <Header lang={lang} />
        <div className="my-7 flex flex-col space-y-5 px-4 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-5">
          {reasonsList(lang).map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Container;
