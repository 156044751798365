import {
  dailyStrings,
  thirdDayStrings,
} from "data/platform/daily/dailyStrings";
import StartWrapper from "./StartWrapper";

export interface ThirdDayStartProps {}

const ThirdDayStart: React.FC<ThirdDayStartProps> = () => {
  return (
    <StartWrapper
      title={dailyStrings.goodMorning}
      description={thirdDayStrings.description}
      video="19"
    />
  );
};

export default ThirdDayStart;
