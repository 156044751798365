import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";

export interface DescStepProps {
  text: string;
  id: string;
  isPassed?: boolean;
  onNext: () => void;
}

const DescStep: React.FC<DescStepProps> = ({
  text,
  id,
  isPassed = false,
  onNext,
}) => {
  return (
    <PlatformSection isPassed={isPassed}>
      <PlatformDivider />
      <p id={id}>{text}</p>
      {!isPassed && <NextStep onNext={onNext} />}
    </PlatformSection>
  );
};

export default DescStep;
