import { ReactNode } from "react";

export interface ContentWrapperProps {
  title: string;
  description: string;
  children?: ReactNode;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="flex flex-col space-y-6 text-justify">
      <p className="text-2xl text-jungle-400 font-semibold">{title}</p>
      <p>{description}</p>
      {children}
    </div>
  );
};

export default ContentWrapper;
