import NapForm, { NapProps } from "components/daily/NapForm";
import { DailyServer, DailyServerNap } from "models/DailyServer";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import WokeEarly from "components/daily/AlertModals/WokeEarlyModal";
import WokeLate from "components/daily/AlertModals/WokeLate";
import { addTime, isBeforeTime, minuteDiffer } from "utils/timeUtils";
import { toast } from "react-toastify";
import { platformStrings } from "data/platform/platformStrings";

export interface NapFormScreenProps extends NapProps {
  index: number;
  dayNumber: number;
  hasEmergency: boolean;
  serverData: DailyServer;
  onSaveData: (data: DailyServer) => void;
  onOpenEnModal: () => void;
}

const NapFormScreen: React.FC<NapFormScreenProps> = (props) => {
  const { serverData, index, onSaveData } = props;
  const [sleep, setSleep] = useState<string | undefined>(props.startTime);
  const [duration, setDuration] = useState<number>(5);
  const [wake, setWake] = useState<string | undefined>(props.endTime);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [hasSlept, setHasSlept] = useState<boolean | undefined>(undefined);
  const [wokeEarlyModalOpen, setWokeEarlyModalOpen] = useState(false);
  const [wokeLateModalOpen, setWokeLateModalOpen] = useState(false);

  const minWake = sleep ? addTime(sleep, duration) : undefined;

  const onCloseModals = () => {
    setWokeEarlyModalOpen(false);
    setWokeLateModalOpen(false);
  };

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    key: string
  ) => {
    key === "sleep" ? setSleep(value) : setWake(value);
  };

  const onSliderChange = (value: SliderValue) => {
    setDuration(value.x);
  };

  const onSave = () => {
    if (props.endTime && sleep && wake) {
      if (minWake && isBeforeTime(minWake, wake)) {
        toast.error(platformStrings.timeError);
        return;
      }
      const max = minuteDiffer(props.startTime, props.wakeTime);
      const min = minuteDiffer(props.startTime, props.endTime);
      const userInput = minuteDiffer(sleep, wake);
      if (userInput > max) {
        setWokeLateModalOpen(true);
      }
      if (userInput < min) {
        setWokeEarlyModalOpen(true);
      }
    }
    setHasSlept(true);
    const key = `nap${index + 1}`;
    const serverDataNap: DailyServerNap = {
      hasSlept: true,
      putdown: sleep,
      duration: duration.toString(),
      waketime: wake,
    };
    onSaveData({ ...serverData, [key]: serverDataNap });
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const openModal = () => {
    setHasSlept(false);
    const key = `nap${index + 1}`;
    const serverDataNap: DailyServerNap = {
      hasSlept: false,
    };
    onSaveData({ ...serverData, [key]: serverDataNap });
    props.onOpenEnModal();
  };

  useEffect(() => {
    const key: keyof DailyServer = `nap${index + 1}` as keyof DailyServer;
    const napObject: DailyServerNap | undefined = serverData
      ? (serverData[key] as DailyServerNap)
      : undefined;
    if (serverData && napObject) {
      setSleep(napObject.putdown);
      setWake(napObject.waketime);
      setDuration(Number(napObject.duration));
      setHasSlept(napObject.hasSlept);
      setIsEditing(false);
    } else {
      setSleep(undefined);
      setWake(undefined);
      setDuration(5);
      setHasSlept(undefined);
      setIsEditing(true);
    }
    //eslint-disable-next-line
  }, [serverData]);

  return (
    <Fragment>
      <NapForm
        {...props}
        isEditing={isEditing}
        sleep={sleep}
        duration={duration}
        wake={wake}
        hasEmergencyNap={props.hasEmergency}
        hasSlept={hasSlept}
        minWake={minWake}
        onOpenENModal={openModal}
        onSliderChange={onSliderChange}
        onTimeChange={onTimeChange}
        onSave={onSave}
        onEdit={onEdit}
      />
      {wokeEarlyModalOpen && (
        <WokeEarly
          open={wokeEarlyModalOpen}
          name={props.name}
          onClose={onCloseModals}
        />
      )}
      {wokeLateModalOpen && (
        <WokeLate
          open={wokeLateModalOpen}
          name={props.name}
          onClose={onCloseModals}
        />
      )}
    </Fragment>
  );
};

export default NapFormScreen;
