import { useMutation, useQuery } from "@apollo/client";
import DailyForm from "components/daily";
import PageLoader from "components/utils/PageLoader";
import { GET, STORE } from "graphql/queries/store";
import { IAppState } from "models/AppState";
import { DailyServer } from "models/DailyServer";
import { IFeedingItem } from "models/FeedingItem";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { bedtimeState } from "recoil/atoms/bedtime";
import { winddownState } from "recoil/atoms/winddown";
import { morningBoxDataGenerator } from "utils/morningScheduleGenerator";
import { weaningPlanGenerator } from "utils/nightFeedingScheduleGenerator";
import { parseStoreData, valueGetter } from "utils/storeParser";
import { minuteDiffer, subtractTime } from "utils/timeUtils";

const DailyScreen: React.FC = () => {
  const { name, age, id } = useRecoilValue(babyState);
  const { day } = useParams<any>();
  const [bedtime, setBedtime] = useRecoilState(bedtimeState);
  const [app, setApp] = useRecoilState(appState);
  const [winddwonDuration, setWinddownDuration] = useRecoilState(winddownState);
  const [feedingCount, setFeedingCount] = useState<number>(0);
  const [feedingMethod, setFeedingMethod] = useState<string>("mother");
  const [weight, setWeight] = useState<number>(0);
  const [noshingInputs, setNoshingInputs] = useState<IFeedingItem[]>([]);
  const [scheduleAge, setScheduleAge] = useState<number | undefined>(undefined);
  const [ageSpecified, setAgeSpecified] = useState<boolean>(false);

  const [modalStep, setModalStep] = useState<number>(1);
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [hasemergencyNap, setHasemergencyNap] = useState<boolean>(false);
  const [serverData, setServerData] = useState<DailyServer>({});
  const [allLoaded, setAllLoaded] = useState<boolean>(false);

  const { push } = useHistory();

  const dayNumber = Number(day);

  const ffNoshingInput: IFeedingItem[] = [
    { time: "22:00", duration: "25" },
    { time: "00:00", duration: "15" },
    { time: "01:30", duration: "30" },
    { time: "03:00", duration: "15" },
    { time: "04:30", duration: "10" },
  ];

  const keys: string[] = [
    "bedtime",
    "winddown",
    "108",
    "603",
    "weight",
    "604",
    "605",
    "606",
    "607",
    "608",
    "609",
    `day${dayNumber}`,
    "appState",
    "scheduleAge",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys,
    },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const bedtimeState = valueGetter(serverResult, "bedtime") as string;
      const winddownState = valueGetter(serverResult, "winddown")
        ? Number(valueGetter(serverResult, "winddown"))
        : 60;
      const serverScheduleAge = valueGetter(serverResult, "scheduleAge")
        ? Number(valueGetter(serverResult, "scheduleAge"))
        : age;
      const feedingCountState = valueGetter(serverResult, "603")
        ? Number(valueGetter(serverResult, "603"))
        : 0;
      const feedingMethodState = valueGetter(serverResult, "108") as string;
      const noshing1: IFeedingItem = {
        time: valueGetter(serverResult, "604") as string,
        duration: valueGetter(serverResult, "605") as string,
      };
      const noshing2: IFeedingItem = {
        time: valueGetter(serverResult, "606") as string,
        duration: valueGetter(serverResult, "607") as string,
      };
      const noshing3: IFeedingItem = {
        time: valueGetter(serverResult, "608") as string,
        duration: valueGetter(serverResult, "609") as string,
      };
      let noshingInputState: IFeedingItem[] = [];
      if (feedingCountState === 3) {
        noshingInputState = [noshing1, noshing2, noshing3];
      }
      if (feedingCountState === 2) {
        noshingInputState = [noshing1, noshing2];
      }
      if (feedingCountState === 1) {
        noshingInputState = [noshing1];
      }
      const weightObject = JSON.parse(
        valueGetter(serverResult, "weight") as string
      );
      const weightState =
        Number(weightObject.weightKilo) +
        Number(weightObject.weightGeram) / 1000;
      const serverDataObject = valueGetter(serverResult, `day${dayNumber}`);
      setBedtime(bedtimeState || "19:00");
      setWinddownDuration(winddownState);
      setFeedingCount(feedingCountState);
      setFeedingMethod(feedingMethodState);
      setWeight(weightState);
      setNoshingInputs(noshingInputState);
      if (serverDataObject !== undefined) {
        const newServerData = JSON.parse(serverDataObject as string);
        setServerData(newServerData);
        if (newServerData.hasEmergencyNap) {
          setHasemergencyNap(true);
        } else {
          setHasemergencyNap(false);
        }
        if (newServerData.age) {
          setScheduleAge(newServerData.age);
          setAgeSpecified(true);
        } else {
          setScheduleAge(serverScheduleAge);
          setAgeSpecified(false);
        }
      } else {
        setServerData({});
        setHasemergencyNap(false);
        setScheduleAge(serverScheduleAge);
        setAgeSpecified(false);
      }
      const appStateObject: IAppState = valueGetter(serverResult, "appState")
        ? JSON.parse(valueGetter(serverResult, "appState") as string)
        : {};
      setApp({ ...app, ...appStateObject });
      setAllLoaded(true);
    },
  });

  let weaningData: IFeedingItem[][] = [];

  if (feedingCount > 3) {
    weaningData = weaningPlanGenerator(
      age || 0,
      weight,
      ffNoshingInput,
      feedingMethod
    );
  } else {
    weaningData = weaningPlanGenerator(
      age || 0,
      weight,
      noshingInputs,
      feedingMethod
    );
  }

  const winddown = subtractTime(bedtime, winddwonDuration);

  const napList =
    morningBoxDataGenerator(
      scheduleAge || 0,
      minuteDiffer("19:00", bedtime),
      name
    ).length > 1
      ? morningBoxDataGenerator(
          scheduleAge || 0,
          minuteDiffer("19:00", bedtime),
          name
        ).slice(1)
      : [];

  const morningData =
    morningBoxDataGenerator(
      scheduleAge || 0,
      minuteDiffer("19:00", bedtime),
      name
    ).length > 0
      ? morningBoxDataGenerator(
          scheduleAge || 0,
          minuteDiffer("19:00", bedtime),
          name
        )[0]
      : {
          startTime: "06:00",
          wakeTime: "07:00",
        };

  const feedings: IFeedingItem[] = weaningData[dayNumber - 1] || [];

  const onOpenENModal = () => setmodalOpen(true);

  const onCloseEnModal = () => setmodalOpen(false);

  const onNextStep = () => setModalStep(modalStep + 1);

  const onRemoveEN = () => {
    storeData({
      variables: {
        infant_id: id,
        data: [
          {
            key: `day${dayNumber}`,
            value: JSON.stringify({ ...serverData, hasEmergencyNap: false }),
          },
        ],
      },
    }).then((res) => {
      setServerData({ ...serverData, hasEmergencyNap: false });
      setHasemergencyNap(false);
    });
  };

  const onAddEN = () => {
    setModalStep(1);
    setmodalOpen(false);
    storeData({
      variables: {
        infant_id: id,
        data: [
          {
            key: `day${dayNumber}`,
            value: JSON.stringify({ ...serverData, hasEmergencyNap: true }),
          },
        ],
      },
    }).then((res) => {
      setServerData({ ...serverData, hasEmergencyNap: true });
      setHasemergencyNap(true);
    });
  };

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
  });

  const onSaveData = (data: DailyServer) => {
    storeData({
      variables: {
        infant_id: id,
        data: [
          {
            key: `day${dayNumber}`,
            value: JSON.stringify({ ...data, age: scheduleAge }),
          },
        ],
      },
    }).then((res) => {
      setServerData(data);
      setAgeSpecified(true);
    });
  };

  const [addDay, { loading: dayLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setApp({ ...app, dayCount: app.dayCount + 1 });
      push(`/platform/daily/${dayNumber + 1}`);
    },
  });

  const onAddDay = () => {
    addDay({
      variables: {
        infant_id: id,
        data: [
          {
            key: "appState",
            value: JSON.stringify({ ...app, dayCount: app.dayCount + 1 }),
          },
        ],
      },
    });
  };

  const [finish, { loading: finishLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setApp({ ...app, isFinished: true });
      push("/platform/after-dreamlab");
    },
  });

  const onFinish = () => {
    finish({
      variables: {
        infant_id: id,
        data: [
          {
            key: "appState",
            value: JSON.stringify({ ...app, isFinished: true }),
          },
        ],
      },
    });
  };

  const handleScheduleAge = () => {
    setScheduleAge(age);
  };

  return (
    <PageLoader
      loading={loading || !allLoaded}
      error={error}
      onRefetch={refetch}
    >
      {allLoaded && (
        <DailyForm
          name={name}
          dayNumber={dayNumber}
          morningProps={{
            name,
            startTime: morningData.startTime,
            wakeTime: morningData.wakeTime,
          }}
          napList={napList}
          hasEmergencyNap={hasemergencyNap}
          nightProps={{ bedtime, winddown, name, feedings }}
          modalOpen={modalOpen}
          modalStep={modalStep}
          serverData={serverData}
          loading={storeLoading || dayLoading || finishLoading}
          maxDays={app.dayCount}
          age={age || 0}
          scheduleAge={scheduleAge || 0}
          ageSpecified={ageSpecified}
          handleScheduleAge={handleScheduleAge}
          onFinish={onFinish}
          onAddDay={onAddDay}
          onSaveData={onSaveData}
          onAddEN={onAddEN}
          onCloseEnModal={onCloseEnModal}
          onNextStep={onNextStep}
          onOpenEnModal={onOpenENModal}
          onRemoveEN={onRemoveEN}
        />
      )}
    </PageLoader>
  );
};

export default DailyScreen;
