import ListInput from "components/form-elements/ListInput";
import SingleChoice from "components/form-elements/SingleChoice";
import LockedAlert from "components/utils/LockedAlert";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import {
  activitiesOptions,
  activityDurationOptions,
  activityRoomOptions,
} from "data/platform/sleep-stealers/activityOptions";
import { activityStrings } from "data/platform/sleep-stealers/activityStrings";
import { ynOptions } from "data/platform/ynOptions";
import { IOption } from "models/Option";
import { SleepStealersActivityData } from "models/SPA";
import { Fragment } from "react";

export interface ActivityFormProps extends SleepStealersActivityData {
  name?: string;
  doneList: boolean;
  loading: boolean;
  locked: boolean;
  onSingleChange: (value: number | boolean | string, key: string) => void;
  onListAdd: (value: IOption) => void;
  onListRemove: (value: IOption) => void;
  onListDone: () => void;
  onFormCompleted: () => void;
}

const ActivityForm: React.FC<ActivityFormProps> = ({
  name,
  doneList,
  beforeNapActivity,
  duration,
  hasActivity,
  inRoom,
  activityList,
  loading,
  locked,
  onFormCompleted,
  onSingleChange,
  onListAdd,
  onListDone,
  onListRemove,
}) => {
  return (
    <div>
      {locked && <LockedAlert />}
      <SingleChoice
        label={activityStrings.usingActicityLabel}
        options={ynOptions}
        selected={hasActivity}
        onSelect={(value) => onSingleChange(value, "hasActivity")}
      />
      {hasActivity && (
        <PlatformSection isPassed={inRoom !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={activityStrings.inRoomLabel(name)}
            options={activityRoomOptions(name)}
            selected={inRoom}
            onSelect={(value) => onSingleChange(value, "inRoom")}
          />
        </PlatformSection>
      )}
      {inRoom !== undefined && (
        <Fragment>
          <PlatformDivider />
          <ListInput
            label={activityStrings.activitiesLabel(name)}
            hint={activityStrings.activitiesHint}
            options={activitiesOptions}
            selected={activityList}
            selectedLabel={activityStrings.routine}
            onSelect={onListAdd}
            onRemove={onListRemove}
          />
          {!doneList && <NextStep onNext={onListDone} />}
        </Fragment>
      )}
      {doneList && (
        <PlatformSection isPassed={duration !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={activityStrings.actDuaration(name)}
            options={activityDurationOptions}
            selected={duration}
            onSelect={(value) => onSingleChange(value, "duration")}
          />
        </PlatformSection>
      )}
      {(hasActivity === false || duration !== undefined) && (
        <PlatformSection>
          <PlatformDivider />
          <SingleChoice
            label={activityStrings.beforeNapLabel}
            options={ynOptions}
            selected={beforeNapActivity}
            onSelect={(value) => onSingleChange(value, "beforeNapActivity")}
          />
          {!locked && <NextStep loading={loading} onNext={onFormCompleted} />}
        </PlatformSection>
      )}
    </div>
  );
};

export default ActivityForm;
