import { faBrand, platformStrings } from "data/platform/platformStrings";
import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import Menu from "./Menu";
import avatarPNG from "images/platform/avatar.png";
import { authState } from "recoil/atoms/auth";
import { licenseCodeState } from "recoil/atoms/license";

export interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  const app = useRecoilValue(appState);
  const hasLicense = useRecoilValue(licenseCodeState);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const isAuth = useRecoilValue(authState);

  const toggleMenu = () => setOpenMenu(!openMenu);

  const closeMenu = () => {
    setOpenMenu(false);
  };

  return (
    <div className="py-8 text-white flex flex-row items-center">
      <div className="w-1/3">
        {hasLicense && (
          <AiOutlineMenu
            onClick={toggleMenu}
            className="cursor-pointer mx-auto text-3xl"
          />
        )}
      </div>
      <div className="w-1/3">
        <p className="font-semibold text-xl text-center">{faBrand}</p>
      </div>
      <div className="w-1/3">
        {isAuth ? (
          <a href={process.env.REACT_APP_ACCOUNT_URL}>
            <img
              src={avatarPNG}
              className="w-10 h-10 rounded-full mx-auto"
              alt="user-avatar"
            />
          </a>
        ) : (
          <a href={process.env.REACT_APP_ACCOUNT_URL}>
            {platformStrings.login}
          </a>
        )}
      </div>
      {openMenu && (
        <Menu
          open={openMenu}
          isFinished={app.isFinished}
          isEvaluated={app.isEvaluated}
          state={app.state}
          onClose={closeMenu}
        />
      )}
    </div>
  );
};

export default Navbar;
