import { useMutation, useQuery } from "@apollo/client";
import MethodSelection from "components/method-selection";
import PageLoader from "components/utils/PageLoader";
import { platformStrings } from "data/platform/platformStrings";
import { GET, STORE } from "graphql/queries/store";
import { MethodFormData } from "models/MethodFormData";
import { IServerData } from "models/ServerData";
import { SliderValue } from "models/SliderValue";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { methodState } from "recoil/atoms/method";
import { boolToJson } from "utils/booleanParser";
import { parseStoreData, valueGetter } from "utils/storeParser";

const MethodSelectionScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [app, setApp] = useRecoilState(appState);
  const [data, setData] = useState<MethodFormData>({
    helpScale: 1,
    interactionLevel: 1,
    parentReducingInteraction: 1,
    sitterReducingInteraction: 1,
  });
  const [introCompleted, setIntroCompleted] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [descStep, setDescStep] = useState(0);
  const [formStep, setFormStep] = useState(0);
  const [touchEstimation, setTouchEstimation] = useState<number>(0);
  const [visitEstimation, setVisitEstimation] = useState<number>(0);
  const [stayEstimation, setStayEstimation] = useState<number>(0);
  const setMethod = useSetRecoilState(methodState);

  const onNextDescStep = (currStep: number) => {
    setDescStep(currStep + 1);
  };

  const onNextFormStep = (currStep: number) => {
    setFormStep(currStep + 1);
  };

  const onIntroCompleted = () => {
    setIntroCompleted(true);
  };

  const onSingleChange = (value: string | boolean | number, key: string) => {
    setData({ ...data, [key]: value });
  };

  const onSliderChange = (value: SliderValue, key: string) => {
    setData({ ...data, [key]: value.x });
  };

  const heaviside = (a: number) => (a > 0 ? 1 : 0);

  const score = (answers: number[], targets: number[], cutOff: number) => {
    let score = 0;
    for (let i = 0; i < 4; i++) {
      score =
        score +
        heaviside(5 - Math.abs(answers[i] - targets[i]) - cutOff) *
          (5 - Math.abs(answers[i] - targets[i]));
    }
    return score;
  };

  const boolToNumber = (flag: boolean | undefined) => (flag ? 1 : 0);

  const recommend = (answers: number[]) => {
    const cut_off = 3;
    let visitScore =
      score(answers, [5, 5, 5, 1], cut_off) +
      answers[4] * (10 * answers[5]) +
      4;
    visitScore = visitScore * 1.5;

    let stayScore =
      score(answers, [3, 3, 3, 3], cut_off) +
      answers[4] * (10 * (1 - answers[5])) +
      1;
    stayScore = stayScore * 1.25;

    let touchScore =
      score(answers, [1, 1, 1, 5], cut_off) +
      answers[4] * (5 * (1 - answers[5]));

    let sum = visitScore + stayScore + touchScore;
    visitScore = 5 * Math.round((100 * visitScore) / sum / 5);
    stayScore = 5 * Math.round((100 * stayScore) / sum / 5);
    touchScore = 100 - visitScore - stayScore;
    setVisitEstimation(visitScore);
    setStayEstimation(stayScore);
    setTouchEstimation(touchScore);
  };

  const msKeys: string[] = [
    "701",
    "702",
    "703",
    "704",
    "705",
    "706",
    "appState",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: msKeys,
    },
    onCompleted: (data) => {
      const msServerResult = parseStoreData(data);
      const serverMethod = valueGetter(msServerResult, "appState")
        ? JSON.parse(valueGetter(msServerResult, "appState") as string).method
        : undefined;
      const msData: MethodFormData = {
        interactionLevel: valueGetter(msServerResult, "701")
          ? Number(valueGetter(msServerResult, "701"))
          : 1,
        parentReducingInteraction: valueGetter(msServerResult, "702")
          ? Number(valueGetter(msServerResult, "702"))
          : 1,
        sitterReducingInteraction: valueGetter(msServerResult, "703")
          ? Number(valueGetter(msServerResult, "703"))
          : 1,
        helpScale: valueGetter(msServerResult, "704")
          ? Number(valueGetter(msServerResult, "704"))
          : 1,
        usedSleepPlan: valueGetter(msServerResult, "705", true) as boolean,
        leavingUpset: valueGetter(msServerResult, "706", true) as boolean,
        method: serverMethod,
      };

      setData(msData);
      setMethod(serverMethod);
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (res) => {
      setMethod(data.method);
      setFormCompleted(true);
      setApp({
        ...app,
        state: "/platform/master-sleep-plan",
        isEvaluated: true,
        method: data.method,
      });
    },
  });

  const onFormCompleted = () => {
    if (data.method) {
      const msServerData: IServerData[] = [
        {
          key: "701",
          value: data.interactionLevel.toString(),
        },
        {
          key: "702",
          value: data.parentReducingInteraction.toString(),
        },
        {
          key: "703",
          value: data.sitterReducingInteraction.toString(),
        },
        {
          key: "704",
          value: data.helpScale.toString(),
        },
        {
          key: "705",
          value: boolToJson(data.usedSleepPlan),
        },
        {
          key: "706",
          value: boolToJson(data.leavingUpset),
        },
        {
          key: "appState",
          value: JSON.stringify({
            ...app,
            state: "/platform/master-sleep-plan",
            isEvaluated: true,
            method: data.method,
          }),
        },
      ];
      storeData({
        variables: {
          infant_id: id,
          data: msServerData,
        },
      });
      return;
    }
    toast.error(platformStrings.methodSelectionError);
  };

  useEffect(() => {
    if (descStep === 3) {
      window.scrollBy(0, 300);
      return;
    }
    window.scrollTo(0, document.body.scrollHeight);
  }, [formCompleted, introCompleted, formStep, descStep]);

  useEffect(() => {
    if (data.usedSleepPlan) {
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
    window.scrollBy(0, 500);
  }, [data.usedSleepPlan]);

  useEffect(() => {
    window.scrollBy(0, 500);
  }, [data.leavingUpset]);

  useEffect(() => {
    const answers = [
      data.interactionLevel,
      data.parentReducingInteraction,
      data.sitterReducingInteraction,
      data.helpScale,
      boolToNumber(data.usedSleepPlan),
      boolToNumber(data.leavingUpset),
    ];
    recommend(answers);
    //eslint-disable-next-line
  }, [data]);

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <MethodSelection
        introCompleted={introCompleted}
        formProps={{
          ...data,
          name,
          formStep,
          formCompleted,
          touchPercentage: touchEstimation,
          visitPercentage: visitEstimation,
          stayPercentage: stayEstimation,
          loading: storeLoading,
          onFormCompleted,
          onNextFormStep,
          onSingleChange,
          onSliderChange,
        }}
        descProps={{ name, descStep, onIntroCompleted, onNextDescStep }}
      />
    </PageLoader>
  );
};

export default MethodSelectionScreen;
