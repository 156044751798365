import React from "react";
import { IReason } from "data/landing/reasons";

export interface ItemProps extends IReason {}

const Item: React.FC<ItemProps> = ({ title, description }) => {
  return (
    <div className="rounded-xl bg-white text-navy p-6 select-none group transition duration-300  hover:shadow-2xl">
      <p className="text-xl font-semibold transition duration-300 group-hover:text-sky-500">
        {title}
      </p>
      <p className="text-sm mt-4">{description}</p>
    </div>
  );
};

export default Item;
