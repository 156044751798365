import { introStrings } from "data/platform/introStrings";
import { NavLink } from "react-router-dom";

export interface MasterSleepPlanProps {}

const MasterSleepPlan: React.FC<MasterSleepPlanProps> = () => {
  return (
    <div className="bg-gradient-to-bl from-gray-800 via-gray-600 to-orange pt-24 text-center min-h-screen flex flex-col justify-evenly">
      <div>
        <p className="text-jungle-400 text-5xl font-semibold mb-10">
          {introStrings.title}
        </p>
        <p>
          <NavLink
            to="/platform/method-in-action"
            className="text-lg text-navy bg-jungle-400 py-3 px-4 rounded shadow-lg"
          >
            {introStrings.msterSleepPlan}
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default MasterSleepPlan;
