import { IUser } from "models/User";
import { atom } from "recoil";

export const tokenState = atom<string | undefined>({
  key: "token",
  default: undefined,
});

export const authState = atom<boolean | undefined>({
  key: "auth",
  default: false,
});

export const userState = atom<IUser | undefined>({
  key: "user",
  default: undefined,
});
