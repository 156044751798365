import { toPersian } from "utils/raqam";

export const habitStrings = {
  title: "خواب دزد ۳: عادتهای قبل از خواب",
  duration: "(زمان تقریبی جواب دادن: ۵ تا ۱۰ دقیقه)",
  beforeFallingActsLabel: (name?: string) =>
    `شب ها برای اینکه ${name} کاملا به خواب فرو بره، کدوم یکی از کارهای زیر رو انجام میدین؟`,
  beforeFallingActsHint: (name?: string) =>
    ` (اگه ${name} از وقتی بیداره شروع به شیر خوردن میکنه تا وقتی که کاملا به خواب بره، تمام گزینه های لازم رو انتخاب کنین.)`,
  pacifier: "استفاده از پستونک",
  breastfeed: "تغذیه با شیر مادر",
  bottlefeed: "تغذیه با شیر خشک",
  rock: "تکون دادن یا راه بردن نوزاد",
  motion: "استفاده از گهواره، ننو، ماشین، کالسکه یا تاب",
  hold: "بغل کردن",
  lie: "کنار نوزاد خوابیدن",
  noAssistant: (name?: string) => `${name} بدون هیچ کاری میخوابه`,
  fallingDurationLabel: (name?: string) =>
    `به طور میانگین، شبا چقدر طول میکشه تا ${name} کاملا به خواب فرو بره؟ `,
  fallingDurationHint: "(بعد از اینکه اون رو تو تختخواب گذاشتین.)",
  veryLow: "کمتر از ۱۵ دقیقه",
  low: "۱۵ تا ۳۰ دقیقه",
  medium: "۳۰ تا ۴۵ دقیقه",
  high: "۴۵ تا ۶۰ دقیقه",
  veryHigh: "بیشتر از ۶۰ دقیقه",
  againSleepingLabel: (name?: string) =>
    `اگه ${name} در طول شب از خواب بیدار بشه، به چه روشی اون رو دوباره میخوابونین؟`,
  againSleepingHint: "(بعد از اینکه اون رو تو تختخواب قرار دادین.)",
  fallingNapDurationLabel: (name?: string) =>
    `به طور میانگین، وقت چرت زدن چقدر زمان میبره تا ${name} به خواب فرو بره؟`,
  beforeNapActsLabel: (name?: string) =>
    `کدومیک از کارهای زیر رو انجام میدین تا ${name} وقت چرت زدن کاملا به خواب فرو بره؟`,
  beforeNapActsHint: (name?: string) =>
    `(اگه ${name} از وقتی بیداره شروع به شیر خوردن میکنه تا وقتی به خواب بره، تمام گزینه های لازم رو انتخاب کنین.)`,
  wakeScaleLabel: (name?: string) =>
    ` از مقیاس ۱ تا ۱۰ (۱ کاملا بیدار و ۱۰ کاملا خواب) وقتی ${name} رو تو تختخواب میذارین، چقدر هوشیاره؟`,
  completedRateDesc: (name?: string) =>
    `همه چی مرتبه!  شما همه ۵ ستاره مربوط به عادتهای قبل از خواب رو دریافت کردین!`,
  lowRateDesc: (rate: number, name?: string) =>
    `شما ${toPersian(
      rate
    )} ستاره از ۵ ستاره عادتهای قبل از خواب رو دریافت کردین. تنظیم عادتهای قبل از خواب، واقعا میتونه به خواب بهتر ${name} کمک کنه! در بخش "چه چیزی کمه" بهتون به طور دقیق نشون میدیم که چه مواردی رو اصلاح کنید و چطور این کارو انجام بدین.`,
  letsMove: 'بریم سراغ خواب دزد بعدی: "برنامه خواب"',
};
