import { toPersian } from "utils/raqam";

export const envStrings = {
  title: "خواب دزد شماره ۱: محیط خواب",
  duration: "(زمان تقریبی جواب دادن: ۳ تا ۸ دقیقه)",
  roomLabel: (name?: string) => `${name} تو اتاق شما میخوابه یا اتاقی دیگه؟`,
  sameRoom: "اتاق ما",
  seperateRoom: "اتاقی دیگه",
  sameRoomWithSiblingsLabel: (name?: string) =>
    `آیا ${name} با خواهر یا برادرش در اتاقی مشترک میخوابه؟`,
  sleepBagLabel: (name?: string) => `آیا ${name} از قنداق استفاده میکنه؟`,
  cribToysLabel: (name?: string) =>
    `کدوم یکی از وسایل زیر تو گهواره یا تخت ${name} قرار داره؟`,
  cribToysHint: "(میتونین چند گزینه رو انتخاب کنین)",
  cloth: "پیشبند یا دستمال",
  stuffedToy: "عروسک پولیشی",
  blanket: "پتو",
  wedge: "محافظ کودک",
  bumper: "گارد تخت",
  pillow: "بالش",
  toys: "اسباب بازی",
  nothingInCrib: "هیچ چیزی در گهواره نیست",
  whiteNoiseLabel: (name?: string) =>
    `وقتی ${name} خوابه، از نویز سفید استفاده میکنین؟`,
  daylightLabel: (name?: string) =>
    `از مقیاس ۱ تا ۱۰، چقدر نور طبیعی وارد اتاقی میشه که ${name} روزها در اون چرت میزنه؟ `,
  daylightHint: "(۱ بسیار روشن و ۱۰ کاملا تاریک)",
  sleepSuitLabel: (name?: string) => `${name} وقت خواب چی میپوشه؟`,
  swaddleArmsLegs: "قنداق با دست و پای داخل",
  swaddle: "قنداق با دست و پای آزاد",
  sleepSack: "قنداق با دستای آزاد",
  pjs: "لباس سر همی",
  pjsFeet: "سایر",
  roomTempLabel: (name?: string) => `دمای اتاقی که ${name} داخلش میخوابه چنده؟`,
  low: "زیر ۲۰ درجه",
  medium: "بین ۲۰ تا ۲۲.۵ درجه",
  high: "بیش از ۲۲.۵ درجه",
  completeStarDesc: (name?: string) =>
    `همه چی مرتبه!  شما همه ۵ ستاره مربوط به محیط خواب مناسب رو دریافت کردین!`,
  lowStar: (rate: number, name?: string) =>
    `شما ${toPersian(rate)} ستاره از ۵ ستاره محیط خواب مناسب رو دریافت کردین.`,
  lowStarDesc: (name?: string) =>
    `تنظیم محیط خواب، واقعا میتونه به خواب بهتر ${name} کمک کنه! در بخش "چه چیزی کمه" بهتون به طور دقیق نشون میدیم که چه مواردی رو اصلاح کنید و چطور این کارو انجام بدین.`,
  letsMove: 'بریم سراغ خواب دزد بعدی: "فعالیت های قبل از خواب"',
};
