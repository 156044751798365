import PlatformContent from "components/layout/PlatfromContent";
import { habitStrings } from "data/platform/sleep-stealers/habitStrings";
import StealerContainer from "../Container";
import HabitDescription, { HabitDescriptionProps } from "./Description";
import HabitForm, { HabitFormProps } from "./Form";

export interface SleepStealersHabitProps {
  formProps: HabitFormProps;
  descProps: HabitDescriptionProps;
  formCompleted: boolean;
  rate?: number;
}

const SleepStealersHabit: React.SFC<SleepStealersHabitProps> = ({
  formCompleted,
  formProps,
  descProps,
  rate,
}) => {
  return (
    <PlatformContent>
      <StealerContainer
        title={habitStrings.title}
        duration={habitStrings.duration}
      >
        <HabitForm {...formProps} />
        {formCompleted && rate !== undefined && (
          <HabitDescription {...descProps} />
        )}
      </StealerContainer>
    </PlatformContent>
  );
};

export default SleepStealersHabit;
