import {
  dailyStrings,
  firstDayStrings,
} from "data/platform/daily/dailyStrings";
import { NavLink } from "react-router-dom";
import StartWrapper from "./StartWrapper";

export interface FirstDayStartProps {
  name?: string;
}

const FirstDayStart: React.FC<FirstDayStartProps> = ({ name }) => {
  return (
    <StartWrapper
      title={firstDayStrings.title(name)}
      description={firstDayStrings.description(name)}
    >
      <NavLink
        to="/platform/ask-question"
        className="font-semibold text-jungle underline"
      >
        {dailyStrings.askUs}
      </NavLink>
    </StartWrapper>
  );
};

export default FirstDayStart;
