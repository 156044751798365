import { useMutation, useQuery } from "@apollo/client";
import SleepStealersSchedule from "components/sleep-stealers/schedule";
import { scheduleErrors } from "data/platform/errors/scheduleErrors";
import { GET, STORE } from "graphql/queries/store";
import { IServerData } from "models/ServerData";
import { SliderValue } from "models/SliderValue";
import { SleepStealersScheduleData } from "models/SPS";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { boolToJson } from "utils/booleanParser";
import { parseSentData, parseStoreData, valueGetter } from "utils/storeParser";
import {
  isAfterTime,
  isBeforeTime,
  napTimeCalculator,
  timeDiffer,
} from "utils/timeUtils";

const SleepStealersScheduleScreen: React.FC = () => {
  const { name, age, id } = useRecoilValue(babyState);
  const [app, setApp] = useRecoilState(appState);
  const [spsData, setSpsData] = useState<SleepStealersScheduleData>({
    napCount: 0,
  });
  const [formCompleted, setFormCompleted] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [rate, setRate] = useState<number | undefined>(undefined);
  const [locked, setLocked] = useState<boolean>(false);

  const onNextStep = (currStep: number) => {
    const napCount = spsData.napCount;
    if (napCount === 0 && step === 4) {
      setStep(11);
      return;
    }
    if (napCount === 1 && step === 6) {
      setStep(11);
      return;
    }
    if (napCount === 2 && step === 8) {
      setStep(11);
      return;
    }
    setStep(currStep + 1);
  };

  const getN = (kidAge: number) => {
    if (kidAge < 7) return 3;
    if (kidAge < 12) return 2;
    if (kidAge < 19) return 1;
    return 0;
  };

  const getAB = (kidAge: number) => {
    if (kidAge < 5) return [3, 4];
    if (kidAge < 7) return [3, 3.5];
    if (kidAge < 12) return [2, 3];
    if (kidAge < 19) return [1, 2];
    return [0, 0];
  };

  const getNapDiff = (kidAge: number) => {
    if (kidAge < 8) return 2;
    if (kidAge < 10) return 2.5;
    if (kidAge < 12) return 3;
    if (kidAge < 19) return 5;
    return 0;
  };

  const getNapMin = (kidAge: number) => {
    if (kidAge < 8) return "08:00";
    if (kidAge < 10) return "08:30";
    if (kidAge < 12) return "09:00";
    if (kidAge < 19) return "11:00";
    return "00:00";
  };

  const generateResult = () => {
    if (age !== undefined) {
      const n = getN(age);
      const [a, b] = getAB(age);
      const napDiff = getNapDiff(age);
      const napMin = getNapMin(age);
      const napTime = napTimeCalculator(
        spsData.awakeTime || "00:00",
        napMin,
        napDiff
      );
      const sleepDuration = timeDiffer(
        spsData.awakeTime || "00:00",
        spsData.sleepTime || "00:00"
      );
      const errors = scheduleErrors(n, age, a, b, napDiff, name);
      let missingItems = [];
      isBeforeTime("19:00", spsData.sleepTime || "00:00") &&
        missingItems.push(errors.sleepTimeLess);
      isAfterTime("20:00", spsData.sleepTime || "00:00") &&
        missingItems.push(errors.sleepTimeMore);
      sleepDuration > 12 && missingItems.push(errors.awakeTimeMore);
      sleepDuration < 11 && missingItems.push(errors.awakeTimeLess);
      spsData.napCount > n && missingItems.push(errors.napCountMore);
      spsData.napCount < n && missingItems.push(errors.napCountLess);
      isBeforeTime(napTime, spsData.firstNap) &&
        missingItems.push(errors.firstNapLess);
      isAfterTime(napTime, spsData.firstNap) &&
        missingItems.push(errors.firstNapMore);
      spsData.betweenNaps && missingItems.push(errors.betweenNaps);
      const finalRate = Number(
        (((5 - missingItems.length) / 5) * 5).toFixed(0)
      );
      return {
        items: missingItems,
        rate: finalRate,
      };
    }
  };

  const spsKeys: string[] = [
    "501",
    "502",
    "503",
    "504",
    "505",
    "506",
    "507",
    "508",
    "509",
    "510",
    "511",
    "spsLocked",
    "spsResult",
  ];

  //eslint-disable-next-line
  const {} = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: spsKeys,
    },
    onCompleted: (data) => {
      const spsServerResult = parseStoreData(data);
      const spsState: SleepStealersScheduleData = {
        sleepTime: valueGetter(spsServerResult, "501") as string,
        awakeTime: valueGetter(spsServerResult, "502") as string,
        napCount: valueGetter(spsServerResult, "503")
          ? Number(valueGetter(spsServerResult, "503"))
          : 0,
        hasSitter: valueGetter(spsServerResult, "504", true) as boolean,
        firstNap: valueGetter(spsServerResult, "505") as string,
        firstNapAwake: valueGetter(spsServerResult, "506") as string,
        secNap: valueGetter(spsServerResult, "507") as string,
        secNapAwake: valueGetter(spsServerResult, "508") as string,
        thirdNap: valueGetter(spsServerResult, "509") as string,
        thirdNapAwake: valueGetter(spsServerResult, "510") as string,
        betweenNaps: valueGetter(spsServerResult, "511", true) as boolean,
      };
      const lockedState = valueGetter(spsServerResult, "spsLocked") as boolean;
      const rateState = valueGetter(spsServerResult, "spsResult")
        ? JSON.parse(valueGetter(spsServerResult, "spsResult") as string).rate
        : undefined;
      rateState && setRate(Number(rateState));
      lockedState && setStep(11);
      lockedState && setFormCompleted(true);
      setLocked(lockedState);
      setSpsData(spsState);
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const rateState = JSON.parse(
        valueGetter(parseSentData(data), "spsResult") as string
      ).rate;
      rateState !== undefined && setRate(rateState);
      setFormCompleted(true);
      setLocked(true);
      setApp({
        ...app,
        state: "/platform/sleep-stealers/night-feeding",
      });
    },
  });

  const onFormCompleted = () => {
    const result = generateResult();
    const spsServerData: IServerData[] = [
      {
        key: "501",
        value: spsData.sleepTime || "00:00",
      },
      {
        key: "502",
        value: spsData.awakeTime || "00:00",
      },
      {
        key: "503",
        value: spsData.napCount.toString(),
      },
      {
        key: "504",
        value: boolToJson(spsData.hasSitter),
      },
      {
        key: "505",
        value: spsData.firstNap || "",
      },
      {
        key: "506",
        value: spsData.firstNapAwake || "",
      },
      {
        key: "507",
        value: spsData.secNap || "",
      },
      {
        key: "508",
        value: spsData.secNapAwake || "",
      },
      {
        key: "509",
        value: spsData.thirdNap || "",
      },
      {
        key: "510",
        value: spsData.thirdNapAwake || "",
      },
      {
        key: "511",
        value: boolToJson(spsData.betweenNaps),
      },
      {
        key: "spsLocked",
        value: boolToJson(true),
      },
      {
        key: "spsResult",
        value: JSON.stringify(result),
      },
      {
        key: "scheduleAge",
        value: age?.toString(),
      },
      {
        key: "appState",
        value: JSON.stringify({
          ...app,
          state: "/platform/sleep-stealers/night-feeding",
        }),
      },
    ];
    if (rate === undefined) {
      storeData({
        variables: {
          infant_id: id,
          data: spsServerData,
        },
      });
    }
  };

  const onSliderChange = (value: SliderValue) => {
    if (locked) return;
    setSpsData({ ...spsData, napCount: value.x });
  };

  const onSingleChange = (value: string | number | boolean, key: string) => {
    if (locked) return;
    setSpsData({ ...spsData, [key]: value });
  };

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    key: string
  ) => {
    if (locked) return;
    setSpsData({ ...spsData, [key]: value });
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [spsData.hasSitter, spsData.betweenNaps, step, formCompleted]);

  return (
    <SleepStealersSchedule
      formCompleted={formCompleted}
      formProps={{
        ...spsData,
        name,
        step,
        locked,
        loading: storeLoading,
        onFormCompleted,
        onNextStep,
        onSingleChange,
        onSliderChange,
        onTimeChange,
      }}
      descProps={{ name, rate: rate || 0 }}
      rate={rate}
    />
  );
};

export default SleepStealersScheduleScreen;
