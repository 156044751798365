export interface MethodItemProps {
  icon: string;
  title: string;
  description: string;
  duration: string;
}

const MethodItem: React.FC<MethodItemProps> = ({
  icon,
  title,
  description,
  duration,
}) => {
  return (
    <div className="flex flex-col space-y-4 text-center">
      <img src={icon} alt={title} className="w-24 mx-auto" />
      <p className="font-semibold text-xl">{title}</p>
      <p className="font-thin">{description}</p>
      <p className="text-sm text-orange" >{duration}</p>
    </div>
  );
};

export default MethodItem;
