import Wrapper from "components/layout/Wrapper";
import GoalScheduleGuide from "components/sleep-plan/goal-schedule/Guide";
import SleepStealers from "components/sleep-stealers";
import MethodVideo from "components/video-overview/method";
import MorningVideo from "components/video-overview/morning";
import NapsVideo from "components/video-overview/naps";
import NightWakes from "components/video-overview/night-awakes";
import { Route, Switch } from "react-router-dom";
import AfterDreamLabScreen from "screens/after-dreamlab";
import AskQuestionScreen from "screens/ask-question";
import BabyInformationScreen from "screens/baby-info";
import BedtimeScreen from "screens/bedtime";
import DailyScreen from "screens/daily";
import DailyListScreen from "screens/daily/list";
import EnterLicenseScreen from "screens/enter-license";
import IntroScreen from "screens/intro";
import LandingScreen from "screens/landing";
import MasterSleepPlanScreen from "screens/master-sleep-plan";
import MeetInstructorScreen from "screens/meet-instructors";
import MethodSelectionScreen from "screens/method-selection";
import PlanIntroScreen from "screens/plan-intro";
import SelectBabyScreen from "screens/select-baby";
import GoalScheduleScreen from "screens/sleep-plan/goal-schedule";
import MethodInActionScreen from "screens/sleep-plan/method-in-action";
import WhatsMissingScreen from "screens/sleep-plan/whats-missing";
import SleepStealersActivityScreen from "screens/sleep-stealers/activities";
import SleepStealersEnvScreen from "screens/sleep-stealers/env";
import SleepStealersHabitScreen from "screens/sleep-stealers/habit";
import SleepStealersNightFeedingScreen from "screens/sleep-stealers/night-feeding";
import SleepStealersScheduleScreen from "screens/sleep-stealers/schedule";
import TicketListScreen from "screens/tickets";
import FeedingsVideoScreen from "screens/video-overview/feedings";
import WhenToBeginScreen from "screens/when-to-begin";
import WhereToBeginScreen from "screens/where-to-begin";
import WinddownScreen from "screens/winddown";
import ProtectedRoute from "./ProtectedRoute";

const Navigator: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={LandingScreen} />
      <Wrapper>
        <Switch>
          <ProtectedRoute
            path="/platform/enter-license/:licenseCode"
            component={EnterLicenseScreen}
          />
          <ProtectedRoute
            path="/platform/enter-license"
            component={EnterLicenseScreen}
          />
          <ProtectedRoute path="/platform" exact component={IntroScreen} />
          <ProtectedRoute
            path="/platform/meet-instructors"
            component={MeetInstructorScreen}
          />
          <ProtectedRoute
            path="/platform/select-baby"
            component={SelectBabyScreen}
          />
          <ProtectedRoute
            path="/platform/baby-info"
            component={BabyInformationScreen}
          />
          <ProtectedRoute
            path="/platform/ready-to-begin"
            component={WhereToBeginScreen}
          />
          <ProtectedRoute
            path="/platform/when-to-begin"
            component={WhenToBeginScreen}
          />
          <ProtectedRoute
            path="/platform/sleep-stealers"
            component={SleepStealers}
            exact
          />
          <ProtectedRoute
            path="/platform/sleep-stealers/env"
            component={SleepStealersEnvScreen}
          />
          <ProtectedRoute
            path="/platform/sleep-stealers/activities"
            component={SleepStealersActivityScreen}
          />
          <ProtectedRoute
            path="/platform/sleep-stealers/habits"
            component={SleepStealersHabitScreen}
          />
          <ProtectedRoute
            path="/platform/sleep-stealers/schedule"
            component={SleepStealersScheduleScreen}
          />
          <ProtectedRoute
            path="/platform/sleep-stealers/night-feeding"
            component={SleepStealersNightFeedingScreen}
          />
          <ProtectedRoute
            path="/platform/method-selection"
            component={MethodSelectionScreen}
          />
          <ProtectedRoute
            path="/platform/plan-intro"
            component={PlanIntroScreen}
          />
          <ProtectedRoute
            path="/platform/whats-missing"
            component={WhatsMissingScreen}
          />
          <ProtectedRoute
            path="/platform/method-in-action"
            component={MethodInActionScreen}
          />
          <ProtectedRoute
            path="/platform/goal-schedule"
            component={GoalScheduleScreen}
            exact
          />
          <ProtectedRoute
            path="/platform/goal-schedule/guide"
            component={GoalScheduleGuide}
          />
          <ProtectedRoute path="/platform/bedtime" component={BedtimeScreen} />
          <ProtectedRoute
            path="/platform/winddown"
            component={WinddownScreen}
          />
          <ProtectedRoute
            path="/platform/daily"
            component={DailyListScreen}
            exact
          />
          <ProtectedRoute path="/platform/daily/:day" component={DailyScreen} />
          <ProtectedRoute
            path="/platform/master-sleep-plan"
            component={MasterSleepPlanScreen}
          />
          <ProtectedRoute
            path="/platform/after-dreamlab"
            component={AfterDreamLabScreen}
          />
          <ProtectedRoute
            path="/platform/tickets"
            component={TicketListScreen}
          />
          <ProtectedRoute
            path="/platform/ask-question"
            component={AskQuestionScreen}
          />
          <ProtectedRoute
            path="/platform/video-overview/method/:code"
            component={MethodVideo}
          />
          <ProtectedRoute
            path="/platform/video-overview/night-awakes"
            component={NightWakes}
          />
          <ProtectedRoute
            path="/platform/video-overview/morning"
            component={MorningVideo}
          />
          <ProtectedRoute
            path="/platform/video-overview/naps/:method"
            component={NapsVideo}
          />
          <ProtectedRoute
            path="/platform/video-overview/feedings"
            component={FeedingsVideoScreen}
          />
        </Switch>
      </Wrapper>
    </Switch>
  );
};

export default Navigator;
