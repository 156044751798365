import { useQuery } from "@apollo/client";
import PageLoader from "components/utils/PageLoader";
import FeedingVideo from "components/video-overview/feeding";
import { GET } from "graphql/queries/store";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { parseStoreData, valueGetter } from "utils/storeParser";

const FeedingsVideoScreen: React.FC = () => {
  const { name, age, id } = useRecoilValue(babyState);
  const [feedingCount, setFeedingCount] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);

  const keys: string[] = ["603", "weight"];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const feedingCountState = valueGetter(serverResult, "603")
        ? Number(valueGetter(serverResult, "603"))
        : 0;
      const weightObject = JSON.parse(
        valueGetter(serverResult, "weight") as string
      );
      const weightState =
        Number(weightObject.weightKilo) +
        Number(weightObject.weightGeram) / 1000;
      setFeedingCount(feedingCountState);
      setWeight(weightState);
    },
  });

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <FeedingVideo
        name={name}
        age={age || 0}
        weight={weight}
        feedingCount={feedingCount}
      />
    </PageLoader>
  );
};

export default FeedingsVideoScreen;
