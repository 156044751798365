import DailyTimeInput from "components/form-elements/DailyTimeInput";
import { napStrings } from "data/platform/daily/napStrings";
import { platformStrings } from "data/platform/platformStrings";
import { ChangeEvent } from "react";
import { CgDanger } from "react-icons/cg";
import { toPersian } from "utils/raqam";
import GlassBox from "./GlassBox";

export interface EmergencyNapFormProps {
  name?: string;
  isEditing: boolean;
  slept: boolean;
  sleep?: string;
  onSlept: (value: boolean) => void;
  onTimeChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onEdit: () => void;
  onSave: () => void;
}

const EmergencyNapForm: React.FC<EmergencyNapFormProps> = ({
  name,
  isEditing,
  slept,
  sleep,
  onEdit,
  onSave,
  onSlept,
  onTimeChange,
}) => {
  return (
    <GlassBox
      title={
        <p className="text-lg font-semibold">
          <CgDanger className="inline-block ml-2" />
          {napStrings.emergencyNap}
        </p>
      }
      isEditing={isEditing}
      onSave={onSave}
      onEdit={onEdit}
    >
      <div className="flex justify-between">
        <span>{napStrings.enDuration}</span>
        <span>
          {toPersian(30)} {platformStrings.minutes}
        </span>
      </div>
      {!slept && (
        <div className="mt-6 pt-6 border-t border-navy text-jasmine flex flex-col space-y-6">
          <p>{napStrings.usedNap(name)}</p>
          <div className="grid grid-cols-2 gap-6">
            <button
              className="py-2 px-3 border border-jasmine rounded-lg"
              onClick={() => onSlept(true)}
            >
              {platformStrings.yes}
            </button>
            <button
              className="py-2 px-3 border border-jasmine rounded-lg"
              onClick={() => onSlept(false)}
            >
              {platformStrings.no}
            </button>
          </div>
        </div>
      )}
      {slept && isEditing && (
        <DailyTimeInput
          label={napStrings.enLabel(name)}
          value={sleep}
          onChange={onTimeChange}
        />
      )}
    </GlassBox>
  );
};

export default EmergencyNapForm;
