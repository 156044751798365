import { ReactNode, useEffect, useState } from "react";
import { FaStar, FaRegStar } from "react-icons/fa";

export interface StarRateProps {
  rate: number;
  max?: number;
  className?: string;
}

const StarRate: React.FC<StarRateProps> = ({
  rate,
  max = 5,
  className = "",
}) => {
  const [starsArr, setStarsArr] = useState<ReactNode[]>([]);

  useEffect(() => {
    let arr: ReactNode[] = [];
    for (let index = 0; index < max; index++) {
      const element = index < rate ? <FaStar /> : <FaRegStar />;
      arr.push(element);
    }
    setStarsArr(arr);
  }, [rate, max]);

  return (
    <div
      className={`flex flex-row justify-center text-xl ${className}`}
      dir="ltr"
    >
      {starsArr.map((item, index) => (
        <span key={index} className="mx-1">
          {item}
        </span>
      ))}
    </div>
  );
};

export default StarRate;
