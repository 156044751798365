import React from "react";
import { IComment } from "data/landing/comments";
import QouteIcon from "components/utils/QouteIcon";

export interface ItemProps extends IComment {
  dir?: string;
}

const Item: React.FC<ItemProps> = ({
  name,
  duration,
  image,
  text,
  dir = "rtl",
}) => {
  return (
    <div className="bg-white text-navy p-6 h-3/4 rounded-xl lg:p-10 group select-none transition duration-300 hover:shadow-xl my-12">
      <div className="flex flex-row">
        <div className="w-1/5">
          <img src={image} alt={name} className="w-16 mx-auto" />
        </div>
        <div className={`w-3/5 ${dir === "rtl" ? "pr-3" : "pl-3"}`}>
          <p className="text-base font-bold">{name}</p>
          <p className="text-sm mt-1">{duration}</p>
        </div>
        <div className="w-1/5">
          <QouteIcon />
        </div>
      </div>
      <div className="w-full mx-auto h-1 bg-gray-100 rounded my-6"></div>{" "}
      {/* Divider */}
      <div>
        <p className="text-xs leading-relaxed">{text}</p>
      </div>
    </div>
  );
};

export default Item;
