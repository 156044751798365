import JungleButton from "components/form-elements/JungleButton";
import { platformStrings } from "data/platform/platformStrings";
import { ReactNode } from "react";
import Modal from "react-modal";

export interface AlertModalProps {
  open: boolean;
  children?: ReactNode;
  onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({ open, children, onClose }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="w-full h-screen overflow-y-auto mx-auto bg-navy text-jasmine outline-none"
      overlayClassName="bg-transparent fixed inset-0 z-50"
    >
      <div className="w-full lg:w-1/3 mx-auto py-12 px-10 lg:px-0 flex flex-col space-y-6">
        {children}
        <JungleButton onClick={onClose}>
          {platformStrings.understood}
        </JungleButton>
      </div>
    </Modal>
  );
};

export default AlertModal;
