import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

export interface LayoutProps {
  children: React.ReactElement[];
  dir?: string;
  lang?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  dir = "rtl",
  lang = "fa",
}) => {
  const childrenWithProps = children.map((child, index) =>
    React.cloneElement(child, { lang, dir, key: index })
  );

  return (
    <React.Fragment>
      <header dir={dir}>
        <Navbar lang={lang} dir={dir} />
      </header>
      {childrenWithProps}
      <Footer lang={lang} dir={dir} />
    </React.Fragment>
  );
};

export default Layout;
