import { IOption } from "models/Option";
import { activityStrings } from "./activityStrings";

export const activityRoomOptions = (name?: string): IOption[] => [
  {
    label: activityStrings.inRoom(name),
    value: true,
  },
  {
    label: activityStrings.otherRooms,
    value: false,
  },
];

export const activitiesOptions: IOption[] = [
  { label: activityStrings.bath, value: 1 },
  { label: activityStrings.massage, value: 2 },
  { label: activityStrings.diaper, value: 3 },
  { label: activityStrings.dimmingLights, value: 4 },
  { label: activityStrings.drawingBlinkds, value: 5 },
  { label: activityStrings.whiteNoise, value: 6 },
  { label: activityStrings.playingMusic, value: 7 },
  { label: activityStrings.feeding, value: 8 },
  { label: activityStrings.singings, value: 9 },
  { label: activityStrings.bouncing, value: 10 },
  { label: activityStrings.reading, value: 11 },
  { label: activityStrings.goodnight, value: 12 },
  { label: activityStrings.lyingDown, value: 13 },
];

export const activityDurationOptions: IOption[] = [
  {
    label: activityStrings.veryLow,
    value: 1,
  },
  {
    label: activityStrings.low,
    value: 2,
  },
  {
    label: activityStrings.medium,
    value: 3,
  },
  {
    label: activityStrings.high,
    value: 4,
  },
  {
    label: activityStrings.veryHigh,
    value: 5,
  },
];
