import JungleButton from "components/form-elements/JungleButton";
import PlatformContent from "components/layout/PlatfromContent";
import SleepPlanHeader from "components/sleep-plan/SleepPlanHeader";
import { changeStrings } from "data/platform/changeStrings";
import { platformStrings } from "data/platform/platformStrings";
import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import { Fragment } from "react";
import goalScheduleIcon from "images/platform/goal-schedule.png";
import { SliderValue } from "models/SliderValue";
import SliderInput from "components/form-elements/SliderInput";

export interface WinddownFormProps {
  name?: string;
  duration: number;
  loading: boolean;
  onDurationChange: (value: SliderValue) => void;
  onSave: () => void;
}

const WinddownForm: React.FC<WinddownFormProps> = ({
  name,
  duration,
  loading,
  onDurationChange,
  onSave,
}) => {
  return (
    <Fragment>
      <SleepPlanHeader
        name={name}
        title={goalScheduleStrings.title}
        icon={goalScheduleIcon}
      />
      <PlatformContent>
        <div className="flex flex-col space-y-8">
          <p className="text-jungle-400 font-semibold text-2xl">
            {changeStrings.changeWinddownTitle}
          </p>
          <p className="text-justify">
            {changeStrings.changeWinddownDescription}
          </p>
          <SliderInput
            label={""}
            value={duration}
            onChange={onDurationChange}
            min={30}
            max={60}
          />
          <JungleButton loading={loading} onClick={onSave}>
            {platformStrings.save}
          </JungleButton>
        </div>
      </PlatformContent>
    </Fragment>
  );
};

export default WinddownForm;
