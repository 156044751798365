import Box from "components/after-dreamlab/Box";
import PlatformContent from "components/layout/PlatfromContent";
import { finishStrings } from "data/platform/finishStrings";
import { platformStrings } from "data/platform/platformStrings";
import { FaChevronLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";

export interface GoalScheduleGuideProps {}

const GoalScheduleGuide: React.FC<GoalScheduleGuideProps> = () => {
  const { name, age } = useRecoilValue(babyState);

  return (
    <PlatformContent>
      <NavLink
        to="/platform/goal-schedule"
        className="text-lg font-semibold text-left mb-6 block"
      >
        <span>{platformStrings.back}</span>
        <FaChevronLeft className="inline-block mr-2" />
      </NavLink>
      <p className="mb-4 text-2xl font-semibold text-jungle-400">
        توضیحات بیشتر درباره برنامه
      </p>
      <p className="font-semibold mb-2"> نکات: </p>
      <p>
        -پیروی از برنامه به طور دقیق بسیار حائز اهمیته تا {name} نه بیش از
        اندازه خسته بشه و نه کلا خوابش نبره.
      </p>
      <p>
        -اگه {name} بیش از اندازه خسته بشه (شب یا هنگام چرت دیرتر از موعد
        بخوابه):
      </p>
      <ul className="mt-2 list-disc list-inside">
        <li>مغزش هورمون استرس کورتیزول رو ترشح میکنه</li>
        <li>ممکنه به سختی به خواب فرو بره </li>
        <li>ممکنه موقع خواب شبانه یا روزانه بیشتر از خواب بپره</li>
        <li>ممکنه صبح یا بعد چرت ها زودتر از خواب بیدار بشه</li>
      </ul>
      <p className="mb-1 mt-2">
        -از راهنمای "زمان بیداری" برای هر چرت استفاده کنین تا برنامه خواب {name}{" "}
        به هم نریزه.
      </p>
      <p className="mb-1">
        -اگه {name} تمایل به خوابیدن در کالسکه داره، او رو در حالی که صاف دراز
        کشیده بخوابونین.{" "}
      </p>
      {age && age < 8 && (
        <p className="mb-1">
          -اگه {name} برای چرت سوم به خواب فرو نمیره، زمان خواب رو موقتا ۳۰
          دقیقه به تعویق بندازین تا زمانی که درست خوابیدن رو یاد بگیره
        </p>
      )}
      <p className="font-semibold mt-4">-زمان خواب بر اساس سن:</p>
      <ul className="list-inside list-disc mt-2">
        <li>نوزادان ۴-۱۲ ماهه به ۱۱ تا ۱۲ ساعت خواب شبانه نیاز دارن</li>
        <li>
          نوزادان ۴ تا ۵ ماهه به ۳ تا ۴ ساعت خواب روزانه که در ۳ چرت اتفاق بیفته
          نیاز دارن
        </li>
        <li>
          نوزادان ۶-۷ ماهه به ۳ تا ۳.۵ ساعت خواب روزانه که در ۳ چرت اتفاق بیافته
          نیاز دارن
        </li>
        <li>
          نوزادان ۸-۱۲ ماهه به ۲ تا ۳ ساعت خواب روزانه که در ۲ چرت اتفاق بیفته
          نیاز دارن
        </li>
        <li>
          نوزادان ۱۳ تا ۱۸ ماهه به ۱:۳۰ تا ۲:۳۰ ساعت خواب روزانه که در ۱ چرت
          اتفاق بیفته نیاز دارن
        </li>
      </ul>
      <p className="mt-4 mb-1 font-semibold">برنامه خواب بر اساس سن:</p>
      <p>-نوزادان بهتره بین ساعت ۷-۸ شب به خواب برن.</p>
      <p className="mt-4 font-semibold">-نوزادان ۴-۷ ماهه:</p>
      <ul className="mt-2 list-disc list-inside">
        <li>چرت اول ۲ ساعت بعد از زمان بیداری و بعد از ساعت ۸ صبح باشه.</li>
        <li>چرت دوم ۲:۳۰ بعد از چرت اول باشه. </li>
        <li>
          چرت سوم ، ۲:۳۰ بعد از چرت دوم باشه (بین چرت سوم و زمان خواب، حداقل
          ۲:۳۰ تا ۳ ساعت زمان باشه).
        </li>
        <li>چرت اول و دوم باید حداقل ۱ ساعت طول بکشن</li>
        <li>
          چرت سوم میتونه کوتاه تر باشه و برای یک نوزاد هشت ماهه ممکنه حتی ۱۰ تا
          ۱۵ دقیقه طول بکشه.
        </li>
      </ul>
      <p className="mt-4 mb-1 font-semibold">نوزادان ۸-۹ ماهه:</p>
      <ul className="list-disc list-inside mt-2">
        <li>چرت اول ۲:۳۰ بعد از زمان بیداری و بعد از ساعت ۸:۳۰ صبح باشه.</li>
        <li>چرت دوم، ۳ ساعت بعد از چرت اول باشه.</li>
      </ul>
      <p className="mt-4 mb-1 font-semibold">نوزادان ۱۰-۱۲ ماهه:</p>
      <ul className="list-disc list-inside mt-2">
        <li>چرت اول ۳ ساعت بعد از بیداری و بعد از ساعت ۹ صبح باشه.</li>
        <li>
          چرت دوم ۳:۳۰ بعد از چرت اول باشه (بعد از چرت دوم، حداقل ۲:۳۰ تا ۳ ساعت
          تا زمان خواب فاصله باشه.)
        </li>
        <li>هر چرت حداقل باید ۱ ساعت طول بکشه. </li>
      </ul>
      <p className="mt-4 mb-1 font-semibold">نوزادان ۱۳ تا ۱۸ ماهه:</p>
      <ul className="list-disc list-inside mt-2 mb-8">
        <li>
          چرت اول ۵ ساعت بعد از بیداری و بعد از ساعت ۱۱ صبح باشه و حداقل ۱.۵
          ساعت طول بکشه
        </li>
      </ul>
      <Box title={finishStrings.reduceNaps}>
        <p>{finishStrings.reduceNapsDesc1(name)}</p>
        <ul className="list-inside list-disc">
          {finishStrings.reduceNapsitems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>{finishStrings.reduceNapsDesc2(name)}</p>
      </Box>
    </PlatformContent>
  );
};

export default GoalScheduleGuide;
