import React from "react";

export interface RedButtonProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
}

const RedButton: React.FC<RedButtonProps> = ({
  title,
  children,
  onClick = () => {},
}) => {
  return (
    <button
      onClick={onClick}
      className="bg-gradient-to-l from-strawberry-600 to-strawberry-200 text-white py-3 px-8 text-xs font-medium rounded-xl shadow-redXl"
    >
      {children || title}
    </button>
  );
};

export default RedButton;
