import { IOption } from "models/Option";
import { platformStrings } from "./platformStrings";

export const methodOptions: IOption[] = [
  {
    label: platformStrings.visitMethod,
    value: "visit",
  },
  {
    label: platformStrings.stayMethod,
    value: "stay",
  },
  {
    label: platformStrings.touchMethod,
    value: "touch",
  },
];
