import JungleButton from "components/form-elements/JungleButton";
import TimeInput from "components/form-elements/TimeInput";
import PlatformContent from "components/layout/PlatfromContent";
import SleepPlanHeader from "components/sleep-plan/SleepPlanHeader";
import { changeStrings } from "data/platform/changeStrings";
import { platformStrings } from "data/platform/platformStrings";
import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import { ChangeEvent, Fragment } from "react";
import goalScheduleIcon from "images/platform/goal-schedule.png";

export interface BedtimeFormProps {
  name?: string;
  time: string;
  error?: string;
  loading: boolean;
  onTimeChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onSave: () => void;
}

const BedtimeForm: React.FC<BedtimeFormProps> = ({
  name,
  time,
  error,
  loading,
  onTimeChange,
  onSave,
}) => {
  return (
    <Fragment>
      <SleepPlanHeader
        name={name}
        title={goalScheduleStrings.title}
        icon={goalScheduleIcon}
      />
      <PlatformContent>
        <div className="flex flex-col space-y-8">
          <p className="text-jungle-400 font-semibold text-2xl">
            {changeStrings.changeBedtimTitle}
          </p>
          <p className="text-justify">
            {changeStrings.changeBedtimDescription}
          </p>
          {error && (
            <p className="text-sm text-orange font-semibold">{error}</p>
          )}
          <TimeInput label={""} value={time} onChange={onTimeChange} />
          <JungleButton loading={loading} disabled={!!error} onClick={onSave}>
            {platformStrings.save}
          </JungleButton>
        </div>
      </PlatformContent>
    </Fragment>
  );
};

export default BedtimeForm;
