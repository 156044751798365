import { babyInfoStrings } from "data/platform/babyInfoStrings";
import DescStep from "./DescStep";

export interface BabyInformationDescriptionProps {
  step: number;
  age?: number;
  minSleep?: string;
  maxSleep?: string;
  name?: string;
  onNext: (currStep: number) => void;
}

const BabyInformationDescription: React.FC<BabyInformationDescriptionProps> = ({
  step,
  age = 0,
  minSleep = "",
  maxSleep = "",
  name = "",
  onNext,
}) => {
  const steps: Record<number, string> = {
    1: babyInfoStrings.desc1,
    2: babyInfoStrings.desc2,
    3: babyInfoStrings.desc3(age, minSleep, maxSleep, name),
    4: babyInfoStrings.desc4(name),
    5: babyInfoStrings.desc5(name),
    6: babyInfoStrings.desc6(name),
  };

  return (
    <div>
      {step > 0 && (
        <DescStep
          id="desc-1"
          text={steps[1]}
          onNext={() => onNext(1)}
          isPassed={step > 1}
        />
      )}
      {step > 1 && (
        <DescStep
          id="desc-2"
          text={steps[2]}
          onNext={() => onNext(2)}
          isPassed={step > 2}
        />
      )}
      {step > 2 && (
        <DescStep
          id="desc-3"
          text={steps[3]}
          onNext={() => onNext(3)}
          isPassed={step > 3}
        />
      )}
      {step > 3 && (
        <DescStep
          id="desc-4"
          text={steps[4]}
          onNext={() => onNext(4)}
          isPassed={step > 4}
        />
      )}
      {step > 4 && (
        <DescStep
          id="desc-5"
          text={steps[5]}
          onNext={() => onNext(5)}
          isPassed={step > 5}
        />
      )}
      {step > 5 && (
        <DescStep
          id="desc-6"
          text={steps[6]}
          onNext={() => onNext(6)}
          isPassed={step > 6}
        />
      )}
    </div>
  );
};

export default BabyInformationDescription;
