import { IOption } from "models/Option";
import { askQuestionStrings } from "./askQuestionStrings";

export const categoryOptions: IOption[] = [
  {
    value: "sleep",
    label: askQuestionStrings.typeSleep,
  },
  {
    value: "dreamlab",
    label: askQuestionStrings.typeDremlab,
  },
];
