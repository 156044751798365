import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import { IFeedingItem } from "models/FeedingItem";
import { toPersian } from "utils/raqam";

export interface FeedingItemProps {
  index: number;
  items: IFeedingItem[];
}

const FeedingItem: React.FC<FeedingItemProps> = ({ index, items }) => {
  return (
    <div className="flex flex-col space-y-3 text-jungle-400 border-b border-navy pb-4">
      <p className="font-semibold">{goalScheduleStrings.night(index)}</p>
      {items.map((item, index) => (
        <div key={index} className="flex justify-between">
          <span>{toPersian(item.time)}</span>
          <span>
            {toPersian(
              item.duration === "Unlimited"
                ? goalScheduleStrings.unlimited
                : item.duration
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default FeedingItem;
