import React from "react";
import { appStrings } from "data/landing/strings";
import TitleChip from "components/utils/TitleChip";

export interface ContentProps {
  lang?: string;
  dir?: string;
}

const Content: React.FC<ContentProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <div
      className={`text-center lg:w-1/2 ${
        dir === "rtl" ? "lg:text-right" : "lg:text-left"
      } lg:self-center lg:pl-64`}
    >
      <TitleChip title={appStrings.musicSubtitle[lang]} color="blue" />
      <p className="font-bold text-2xl text-navy my-6 w-5/6 mx-auto leading-10 lg:text-3xl lg:leading-normal lg:mx-0 lg:w-full">
        {appStrings.musicTitle[lang]}
      </p>
      <p className="text-sm w-11/12 mx-auto my-6 text-navy lg:w-full">
        {appStrings.musicDesc[lang]}
      </p>
    </div>
  );
};

export default Content;
