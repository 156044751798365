import { useMutation } from "@apollo/client";
import BlueButton from "components/utils/BlueButton";
import { platformStrings } from "data/platform/platformStrings";
import { STOP_IMPERSONATING } from "graphql/queries/auth";
import { ReactNode } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { impersonatingState } from "recoil/atoms/impersonating";
import { redirectState } from "recoil/atoms/redirect";
import Navbar from "./Navbar";

export interface WrapperProps {
  children: ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const from = useRecoilValue(redirectState);
  const isImpersonating = useRecoilValue(impersonatingState);

  const [stopImpersinating, { loading }] = useMutation(STOP_IMPERSONATING, {
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      window.location.replace(from || `${process.env.REACT_APP_SUPPORT_URL}`);
    },
  });

  return (
    <div className="min-h-screen bg-navy text-jasmine flex flex-col relative">
      <Navbar />
      {children}
      {isImpersonating && (
        <div className="fixed bottom-10 left-10">
          <BlueButton loading={loading} onClick={stopImpersinating}>
            {platformStrings.stopImpersonating}
          </BlueButton>
        </div>
      )}
    </div>
  );
};

export default Wrapper;
