import PlatformContent from "components/layout/PlatfromContent";
import { whatsMissingStrings } from "data/platform/sleep-plan/whatsMissingStrings";
import { IWhatsMissing, WhatsMissingItem } from "models/WMItem";
import { Fragment } from "react";
import {
  FaMoon,
  FaBed,
  FaBookOpen,
  FaRegClock,
  FaChevronRight,
} from "react-icons/fa";
import { GiBabyBottle } from "react-icons/gi";
import SleepPlanHeader from "../SleepPlanHeader";
import WMList from "./List";
import whatsMissingIcon from "images/platform/whats-missing.png";
import { NavLink } from "react-router-dom";
import { methodInActionStrings } from "data/platform/sleep-plan/methodinActionStrings";

export interface WhatsMissingProps {
  name?: string;
  envItems: IWhatsMissing;
  routineItems: IWhatsMissing;
  habitItems: IWhatsMissing;
  scheduleItems: IWhatsMissing;
  noshingItems: IWhatsMissing;
  loading: boolean;
  loadingKey?: string;
  method?: string;
  onCheck: (item: WhatsMissingItem, key: string) => void;
}

const WhatsMissing: React.FC<WhatsMissingProps> = ({
  name,
  envItems,
  routineItems,
  habitItems,
  scheduleItems,
  noshingItems,
  loading,
  method,
  loadingKey,
  onCheck,
}) => {
  return (
    <Fragment>
      <SleepPlanHeader
        name={name}
        icon={whatsMissingIcon}
        title={whatsMissingStrings.title}
        nextLink={"/platform/method-in-action"}
      />
      <PlatformContent isLarge>
        <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-16">
          <div className="flex flex-col space-y-16">
            <p className="text-jungle-400 font-semibold text-2xl">
              {whatsMissingStrings.needToChange}
            </p>
            <p className="text-justify">
              {whatsMissingStrings.description(name)}
            </p>
            <WMList
              title={whatsMissingStrings.sleepEnv}
              icon={<FaBed />}
              rate={envItems?.rate || 0}
              items={envItems?.items || []}
              video="8"
              onCheck={(item) => onCheck(item, "speResult")}
              loading={loading && loadingKey === "speResult"}
            />
            <WMList
              title={whatsMissingStrings.routines}
              icon={<FaBookOpen />}
              rate={routineItems?.rate || 0}
              items={routineItems?.items || []}
              video="9"
              onCheck={(item) => onCheck(item, "spaResult")}
              loading={loading && loadingKey === "spaResult"}
            />
          </div>
          <div className="flex flex-col space-y-16">
            <WMList
              title={whatsMissingStrings.sleepHabits}
              icon={<FaMoon />}
              rate={habitItems?.rate || 0}
              items={habitItems?.items || []}
              video="10"
              onCheck={(item) => onCheck(item, "sphResult")}
              loading={loading && loadingKey === "sphResult"}
            />
            <WMList
              title={whatsMissingStrings.sleepSchedule}
              icon={<FaRegClock />}
              rate={scheduleItems?.rate || 0}
              items={scheduleItems?.items || []}
              onCheck={(item) => onCheck(item, "spsResult")}
              loading={loading && loadingKey === "spsResult"}
            />
            <WMList
              title={whatsMissingStrings.nightNoshing}
              icon={<GiBabyBottle />}
              rate={noshingItems?.rate || 0}
              items={noshingItems?.items || []}
              onCheck={(item) => onCheck(item, "spnResult")}
              loading={loading && loadingKey === "spnResult"}
            />
          </div>
        </div>
        <p className="text-right mt-6">
          <NavLink
            to={`/platform/method-in-action`}
            className="text-xl text-orange font-semibold"
          >
            <FaChevronRight className="inline-block ml-2" />
            {methodInActionStrings.title(method)}
          </NavLink>
        </p>
      </PlatformContent>
    </Fragment>
  );
};

export default WhatsMissing;
