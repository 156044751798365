import React from "react";

export interface DividerProps {
  color: string;
}

const Divider: React.FC<DividerProps> = ({ color }) => {
  return <div className={`w-10 h-1 bg-${color} mx-auto rounded`}></div>;
};

export default Divider;
