import { ticketStrings } from "data/platform/ticketStrings";
import { Ticket } from "models/Ticket";
import { toPersian } from "utils/raqam";
import TicketBadge from "./Badge";
import ReactHtmlParser from "react-html-parser";

export interface TicketItemProps extends Ticket {
  open: boolean;
  toggleOpen: () => void;
}

const TicketItem: React.FC<TicketItemProps> = ({
  title,
  description,
  isAnswered,
  answers,
  open,
  toggleOpen,
}) => {
  return (
    <div
      className="flex flex-col space-y-4 border border-jasmine rounded-lg py-6 px-2 select-none cursor-pointer"
      onClick={toggleOpen}
    >
      <div className="flex flex-row">
        <div className="w-8/12 md:w-9/12">
          <p className="font-semibold text-justify text-sm md:text-base">
            {toPersian(title)}
          </p>
        </div>
        <div className="w-4/12 pr-2 md:w-3/12 md:pr-2">
          <TicketBadge open={open} isAnswered={isAnswered} />
        </div>
      </div>
      {open && (
        <div>
          <div className="w-8/12 h-1 mx-auto my-4 bg-jasmine rounded-lg"></div>
          <p className="text-justify text-sm">{toPersian(description)}</p>
          {answers.length > 0 && (
            <p className="font-semibold text-jungle-400 my-2">
              {ticketStrings.answers}:
            </p>
          )}
          <div className="flex flex-col space-y-2 text-sm">
            {answers.length > 0 &&
              answers.map((item, index) => (
                <div key={index}>{ReactHtmlParser(item)}</div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketItem;
