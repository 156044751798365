import PlatformContent from "components/layout/PlatfromContent";
import { whenToBeginStrings } from "data/platform/whenToBeginStrings";
import WhenToBeginDescription, {
  WhenToBeginDescriptionProps,
} from "./Description";
import WhenToBeginForm, { WhenToBeginFormProps } from "./Form";

export interface WhenToBeginProps {
  formProps: WhenToBeginFormProps;
  descProps: WhenToBeginDescriptionProps;
  formCompleted: boolean;
}

const WhenToBegin: React.FC<WhenToBeginProps> = ({
  formProps,
  descProps,
  formCompleted,
}) => {
  return (
    <PlatformContent>
      <p className="text-jungle font-semibold text-2xl mb-16">
        {whenToBeginStrings.title}
      </p>
      <WhenToBeginForm {...formProps} />
      {formCompleted && <WhenToBeginDescription {...descProps} />}
    </PlatformContent>
  );
};

export default WhenToBegin;
