import AfterDreamLab from "components/after-dreamlab";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";

const AfterDreamLabScreen: React.FC = () => {
  const { name, age } = useRecoilValue(babyState);

  return <AfterDreamLab name={name} age={age || 0} />;
};

export default AfterDreamLabScreen;
