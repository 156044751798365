import PlatformContent from "components/layout/PlatfromContent";
import { meetInstructorStrings } from "data/platform/meetInstructors";
import { NavLink } from "react-router-dom";
import NextLink from "components/utils/NextLink";
import { useRecoilValue } from "recoil";
import { babyListState } from "recoil/atoms/babyList";
import Player from "components/utils/Player";

export interface MeetInstructorsProps {}

const videoLink =
  "https://ghondagh.arvanvod.com/q1nReGk6XA/5GVlpRqvPW/h_,144_200,240_400,360_800,480_1500,720_2500,1080_4500,k.mp4.list/master.m3u8";

const MeetInstructors: React.FC<MeetInstructorsProps> = () => {
  const babyLength = useRecoilValue(babyListState).length;
  return (
    <PlatformContent isLarge={true}>
      <div className="text-center flex flex-col space-y-4">
        <p className="text-2xl text-jungle font-semibold">
          {meetInstructorStrings.title}
        </p>
        <p>{meetInstructorStrings.description}</p>
        <Player link={videoLink} code="1" />
        <div className="py-8">
          <NavLink
            to={
              babyLength > 0 ? "/platform/select-baby" : "/platform/baby-info"
            }
            className="text-jungle text-xl font-semibold"
          >
            {meetInstructorStrings.letStart}
          </NavLink>
          <NextLink
            to={
              babyLength > 0 ? "/platform/select-baby" : "/platform/baby-info"
            }
          />
        </div>
      </div>
    </PlatformContent>
  );
};

export default MeetInstructors;
