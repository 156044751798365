import { IOption } from "models/Option";
import FormItem from "./FormItem";

export interface SingleChoiceProps {
  label: string;
  hint?: string;
  tooltip?: string;
  options: IOption[];
  selected?: string | number | boolean;
  onSelect: (value: string | number | boolean) => void;
}

const SingleChoice: React.FC<SingleChoiceProps> = ({
  label,
  options,
  hint,
  tooltip,
  selected,
  onSelect,
}) => {
  return (
    <FormItem label={label} hint={hint} tooltip={tooltip}>
      <div className="flex flex-col space-y-3">
        {options.map((option, index) => (
          <p
            key={index}
            onClick={() => onSelect(option.value)}
            className={`border-2 border-jasmine rounded-lg py-3 text-center select-none cursor-pointer font-semibold transition duration-200 ${
              selected === option.value
                ? "bg-jasmine text-navy hover:text-navy hover:border-jasmine"
                : "hover:border-jungle-400 hover:text-jungle"
            }`}
          >
            {option.label}
          </p>
        ))}
      </div>
    </FormItem>
  );
};

export default SingleChoice;
