import { ReactNode } from "react";

export interface StealerContainerProps {
  title: string;
  duration: string;
  children: ReactNode;
}

const StealerContainer: React.FC<StealerContainerProps> = ({
  title,
  duration,
  children,
}) => {
  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-col space-y-4">
        <p className="text-jungle font-semibold text-2xl">{title}</p>
        <p className="text-sm text-gray-400">{duration}</p>
      </div>
      {children}
    </div>
  );
};

export default StealerContainer;
