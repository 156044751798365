export const scheduleNeedsUpdate = (age: number, scheduleAge: number) => {
  const scheduleTimetable = [
    [0, 5],
    [6, 7],
    [8, 12],
    [13, 1000],
  ];
  for (let index = 0; index < scheduleTimetable.length; index++) {
    const isAgeIndex =
      age <= scheduleTimetable[index][1] && age >= scheduleTimetable[index][0];
    const isScheduleAgeIndex =
      scheduleAge <= scheduleTimetable[index][1] &&
      scheduleAge >= scheduleTimetable[index][0];
    if (isAgeIndex !== isScheduleAgeIndex) return true;
  }
  return false;
};
