import { useLazyQuery } from "@apollo/client";
import PageLoader from "components/utils/PageLoader";
import { GET } from "graphql/queries/store";
import { ReactNode, useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babySavedState } from "recoil/atoms/baby";
import { methodState } from "recoil/atoms/method";
import { parseStoreData, valueGetter } from "utils/storeParser";

export interface StateIdentifierProps {
  id: number;
  children?: ReactNode;
}

const StateIdentifier: React.FC<StateIdentifierProps> = ({ children, id }) => {
  const setApp = useSetRecoilState(appState);
  const setMethod = useSetRecoilState(methodState);
  const babySaved = useRecoilValue(babySavedState);
  const { push } = useHistory();

  const [refetch, { loading, error }] = useLazyQuery(GET, {
    variables: {
      infant_id: id,
      keys: ["appState"],
    },
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const appStateJson = valueGetter(serverResult, "appState");
      if (appStateJson) {
        const serverAppState = JSON.parse(appStateJson as string);
        setApp(serverAppState);
        setMethod(serverAppState.method);
        if (
          serverAppState.state &&
          serverAppState.state !== "/platform/master-sleep-plan"
        ) {
          const path = window.location.pathname;
          if (path.includes("platform")) push(serverAppState.state);
        }
      }
    },
  });

  useEffect(() => {
    if (id && babySaved) {
      refetch();
    }
    //eslint-disable-next-line
  }, [babySaved]);

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      {children}
    </PageLoader>
  );
};

export default StateIdentifier;
