import { appStrings } from "data/landing/strings";
import Modal from "react-modal";
import { toPersian } from "utils/raqam";

export interface PurchaseModalProps {
  lang?: string;
  open: boolean;
  price: number;
  link: string;
  onClose: () => void;
}

const PurchaseModal: React.FC<PurchaseModalProps> = ({
  lang = "fa",
  open,
  price,
  link,
  onClose,
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="w-11/12 md:w-1/3 mx-auto bg-gray-100 text-navy mt-24 outline-none rounded-lg py-12 px-12"
      overlayClassName="bg-gray-300 bg-opacity-80 fixed inset-0 z-50"
    >
      <p className="font-semibold text-lg">{appStrings.surePurchase[lang]}</p>
      <p className="my-6 py-2 px-4 border-r-4 border-strawberry bg-strawberry-200 text-sm">
        {appStrings.purchaseNote1[lang]}
      </p>
      <p className="mb-6"> {appStrings.purchaseNote2[lang]}</p>
      <p className="w-full text-center py-3 text-xl text-green-600 border-2 border-green-600  rounded-lg">
        {appStrings.price[lang]}:{" "}
        <span className="font-semibold">
          {toPersian(price / 10, { comma: true })}
        </span>{" "}
        {appStrings.toman[lang]}
      </p>
      <div className="flex justify-end mt-6">
        <button
          onClick={onClose}
          className="bg-gray-200 text-gray-700 py-1 px-4 ml-4 rounded-lg text-lg"
        >
          {appStrings.close[lang]}
        </button>
        <a
          href={link}
          target="_self"
          className="bg-green-700 text-white font-semibold py-1 px-6 rounded-lg text-lg"
        >
          {appStrings.pay[lang]}
        </a>
      </div>
    </Modal>
  );
};

export default PurchaseModal;
