import PlatformContent from "components/layout/PlatfromContent";
import { nightFeedingStrings } from "data/platform/sleep-stealers/nightFeedingStrings";
import StealerContainer from "../Container";
import NightFeedingDescription, {
  NightFeedingDescriptionProps,
} from "./Description";
import NightFeedingForm, { NightFeedingFormProps } from "./Form";

export interface SleepStealersNightFeedingProps {
  formProps: NightFeedingFormProps;
  descProps: NightFeedingDescriptionProps;
  formCompleted: boolean;
  rate?: number;
}

const SleepStealersNightFeeding: React.FC<SleepStealersNightFeedingProps> = ({
  formCompleted,
  formProps,
  descProps,
  rate,
}) => {
  return (
    <PlatformContent>
      <StealerContainer
        title={nightFeedingStrings.title}
        duration={nightFeedingStrings.duration}
      >
        <NightFeedingForm {...formProps} />
        {formCompleted && rate !== undefined && (
          <NightFeedingDescription {...descProps} />
        )}
      </StealerContainer>
    </PlatformContent>
  );
};

export default SleepStealersNightFeeding;
