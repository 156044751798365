import { napStrings } from "data/platform/daily/napStrings";
import { platformStrings } from "data/platform/platformStrings";
import { toPersian } from "utils/raqam";

export interface NapSavedInfoProps {
  sleep?: string;
  duration: number;
  wake?: string;
}

const NapSavedInfo: React.FC<NapSavedInfoProps> = ({
  sleep,
  duration,
  wake,
}) => {
  return (
    <div className="flex flex-col space-y-2 text-sm pt-6 mt-6 border-t border-navy">
      {sleep && (
        <div className="flex justify-between">
          <span>{napStrings.putdown}</span>
          <span>{toPersian(sleep)}</span>
        </div>
      )}
      {sleep && (
        <div className="flex justify-between">
          <span>{napStrings.fallSleepTime}</span>
          <span>
            {toPersian(duration)} {platformStrings.minutes}
          </span>
        </div>
      )}
      {wake && (
        <div className="flex justify-between">
          <span>{napStrings.wakeTime}</span>
          <span>{toPersian(wake)}</span>
        </div>
      )}
    </div>
  );
};

export default NapSavedInfo;
