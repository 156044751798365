import { introStrings } from "data/platform/introStrings";
import stayIcon from "images/hero/hero-stay.png";
import visitIcon from "images/hero/hero-visit.png";
import touchIcon from "images/hero/hero-touch.png";
import MethodItem from "./MethodItem";
import { platformStrings } from "data/platform/platformStrings";
import { NavLink } from "react-router-dom";
import PlatformContent from "components/layout/PlatfromContent";

export interface ContentProps {}

const Content: React.FC<ContentProps> = () => {
  return (
    <PlatformContent>
      <div id="steps" className="flex flex-col space-y-10">
        {/* FirstStep */}
        <div>
          <p className="text-xl font-semibold text-jungle mb-2">
            {introStrings.firstStep}: {introStrings.takeAssessment}
          </p>
          <p>{introStrings.taDesc}</p>
          <div className="grid grid-cols-3 gap-6 py-8">
            <MethodItem icon={visitIcon} title={platformStrings.visitMethod} />
            <MethodItem icon={stayIcon} title={platformStrings.stayMethod} />
            <MethodItem icon={touchIcon} title={platformStrings.touchMethod} />
          </div>
        </div>
        {/* SecondStep */}
        <div>
          <p className="text-xl font-semibold text-jungle mb-2">
            {introStrings.secondStep}: {introStrings.learnPlan}
          </p>
          <p>{introStrings.lpDesc}</p>
        </div>
        {/* ThirdStep */}
        <div>
          <p className="text-xl font-semibold text-jungle mb-2">
            {introStrings.thirdStep}: {introStrings.followPlan}
          </p>
          <p>{introStrings.fpDesc}</p>
        </div>
        <div className="text-center py-8">
          <NavLink
            to="/platform/meet-instructors"
            className="text-navy bg-jungle-400 py-3 px-4 rounded text-xl"
          >
            {introStrings.takeAssessment}
          </NavLink>
        </div>
      </div>
    </PlatformContent>
  );
};

export default Content;
