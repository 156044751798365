import VideoPlayer from "components/utils/VideoPlayer";
import { platformStrings } from "data/platform/platformStrings";
import { ReactNode } from "react";
import { FaGripLines } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export interface MIAItemProps {
  title?: string;
  description?: string;
  video?: string;
  summaryLink?: string;
  children?: ReactNode;
}

const MIAItem: React.FC<MIAItemProps> = ({
  title,
  description,
  video,
  summaryLink,
  children,
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <p className="font-semibold">{title}</p>
      <p className="font-thin">{description}</p>
      <VideoPlayer code={video} />
      {children}
      {summaryLink && (
        <NavLink to={summaryLink} className="text-lg italic">
          <FaGripLines className="inline-block ml-2" />{" "}
          {platformStrings.videosSummary}
        </NavLink>
      )}
    </div>
  );
};

export default MIAItem;
