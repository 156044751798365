import { CgArrowDownO } from "react-icons/cg";
import { NavLink } from "react-router-dom";

export interface NextLinkProps {
  to: string;
}

const NextLink: React.FC<NextLinkProps> = ({ to }) => {
  return (
    <NavLink to={to} className="text-4xl">
      <CgArrowDownO className="mx-auto mt-4" />
    </NavLink>
  );
};

export default NextLink;
