import { methodInActionStrings } from "data/platform/sleep-plan/methodinActionStrings";
import { Fragment } from "react";
import SleepPlanHeader from "../SleepPlanHeader";
import methodIcon from "images/platform/method.png";
import PlatformContent from "components/layout/PlatfromContent";
import MIAItem from "./Item";
import NextLink from "components/utils/NextLink";
import { methodVideoCode } from "utils/methodVideoCode";

export interface MethodInActionProps {
  name?: string;
  method?: string;
  methodCode?: string;
  showFeeding: boolean;
  isFF?: boolean;
}

const MethodInAction: React.FC<MethodInActionProps> = ({
  method,
  methodCode,
  name,
  showFeeding,
  isFF,
}) => {
  return (
    <Fragment>
      <SleepPlanHeader
        name={name}
        title={methodInActionStrings.title(method)}
        nextLink={"/platform/goal-schedule"}
        backLink={"/platform/whats-missing"}
        icon={methodIcon}
      />
      <PlatformContent>
        <div className="flex flex-col space-y-12">
          <div className="flex flex-col space-y-8">
            <p className="text-2xl font-semibold text-jungle-400">
              {methodInActionStrings.videoStepByStep}
            </p>
            <p className="font-thin">
              {methodInActionStrings.videoDescription}
            </p>
          </div>
          <MIAItem
            title={methodInActionStrings.method(method)}
            description={methodInActionStrings.methodDescription}
            video={methodVideoCode(methodCode || "")}
            summaryLink={`/platform/video-overview/method/${methodVideoCode(
              methodCode || ""
            )}`}
          />
          <MIAItem
            title={methodInActionStrings.nightAwaking}
            description={methodInActionStrings.nightAwakingDescription}
            video="12"
            summaryLink="/platform/video-overview/night-awakes"
          />
          {showFeeding && (
            <MIAItem
              title={methodInActionStrings.nightFeeding}
              description={methodInActionStrings.nightFeedingDescription(name)}
              video="13"
              summaryLink="/platform/video-overview/feedings"
            >
              {isFF && (
                <div>
                  <p className="font-semibold text-orange">
                    {methodInActionStrings.ffAlertTitle}
                  </p>
                  <p className="font-semibold text-orange">
                    {methodInActionStrings.ffAlertDescription}
                  </p>
                </div>
              )}
            </MIAItem>
          )}
          <MIAItem
            title={methodInActionStrings.goodMorning}
            description={methodInActionStrings.goodMorningDescription}
            video="14"
            summaryLink="/platform/video-overview/morning"
          />
          <MIAItem
            title={methodInActionStrings.naps}
            description={methodInActionStrings.napsDescription}
            video="15"
            summaryLink={`/platform/video-overview/naps/${methodCode}`}
          />
          <MIAItem
            description={methodInActionStrings.bonusDescription}
            video="16"
          />
          <p>{methodInActionStrings.finalDescription}</p>
          <NextLink to="/platform/goal-schedule" />
        </div>
      </PlatformContent>
    </Fragment>
  );
};

export default MethodInAction;
