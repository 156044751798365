import { useMutation, useQuery } from "@apollo/client";
import BedtimeForm from "components/bedtime";
import PageLoader from "components/utils/PageLoader";
import { changeStrings } from "data/platform/changeStrings";
import { GET, STORE } from "graphql/queries/store";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { bedtimeState } from "recoil/atoms/bedtime";
import { parseStoreData, valueGetter } from "utils/storeParser";
import { isBetweenTime } from "utils/timeUtils";

const BedtimeScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [bedtime, setBedtime] = useRecoilState(bedtimeState);
  const [time, setTime] = useState(bedtime);
  const [error, setError] = useState<string | undefined>(undefined);
  const { push } = useHistory();

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setTime(value);
  };

  const { error: serverError, loading, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: ["bedtime"],
    },
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const bedtimeState = valueGetter(serverResult, "bedtime") as string;
      setBedtime(bedtimeState || "19:00");
    },
    fetchPolicy: "network-only",
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setBedtime(time);
      push("/platform/goal-schedule");
    },
  });

  const onSave = () => {
    storeData({
      variables: {
        infant_id: id,
        data: [{ key: "bedtime", value: time }],
      },
    });
  };

  useEffect(() => {
    const isValid = isBetweenTime("19:00", "20:00", time);
    isValid ? setError(undefined) : setError(changeStrings.bedtimeError);
  }, [time]);

  return (
    <PageLoader loading={loading} error={serverError} onRefetch={refetch}>
      <BedtimeForm
        name={name}
        time={bedtime}
        onTimeChange={onTimeChange}
        error={error}
        loading={storeLoading}
        onSave={onSave}
      />
    </PageLoader>
  );
};

export default BedtimeScreen;
