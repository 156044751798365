import { faBrand } from "./platformStrings";

export const whereBeginStrings = {
  title: "کی یادگیری رو شروع کنیم؟",
  description1: (name?: string) =>
    `آمادگی کافی برای شروع یادگیری در موفقیت ${name} خیلی موثره. اینجا نکاتی که قبل از شروع فرایند یادگیری لازم دارین رو بهتون توضیح میدیم.`,
  description2: (name?: string) =>
    `زمان بندی برای موفقیت سریع و بدون بدخلقی و گریه ${name} خیلی مهمه.`,
  description3: `با جواب دادن به سوال های زیر مطمئن بشین که خانواده شما آمادگی شروع روند آموزش خواب رو داره. اگه در شرایطی قرار دارین که آموزش خواب رو با مشکل مواجه می کنه، میتونین فعلا صبر کنین و بعدا به ${faBrand} مراجعه کنین. ما منتظرتون میمونیم!`,
};
