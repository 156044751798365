import SingleChoice from "components/form-elements/SingleChoice";
import SliderInput from "components/form-elements/SliderInput";
import TimeInput from "components/form-elements/TimeInput";
import LockedAlert from "components/utils/LockedAlert";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { scheduleStrings } from "data/platform/sleep-stealers/scheduleStrings";
import { ynOptions } from "data/platform/ynOptions";
import { SliderValue } from "models/SliderValue";
import { SleepStealersScheduleData } from "models/SPS";
import { ChangeEvent } from "react";

export interface ShceduleFormProps extends SleepStealersScheduleData {
  name?: string;
  step: number;
  loading: boolean;
  locked: boolean;
  onSingleChange: (value: string | boolean | number, key: string) => void;
  onSliderChange: (value: SliderValue) => void;
  onTimeChange: (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    key: string
  ) => void;
  onFormCompleted: () => void;
  onNextStep: (currStep: number) => void;
}

const SceduleForm: React.FC<ShceduleFormProps> = ({
  name,
  napCount,
  step,
  awakeTime,
  betweenNaps,
  firstNap,
  firstNapAwake,
  hasSitter,
  secNap,
  secNapAwake,
  sleepTime,
  thirdNap,
  thirdNapAwake,
  loading,
  locked,
  onFormCompleted,
  onSingleChange,
  onSliderChange,
  onNextStep,
  onTimeChange,
}) => {
  return (
    <div>
      {locked && <LockedAlert />}
      <TimeInput
        label={scheduleStrings.sleepTimeLabel(name)}
        hint={scheduleStrings.sleepTimeHint}
        value={sleepTime}
        onChange={(event, value) => onTimeChange(event, value, "sleepTime")}
      />
      {step < 2 && <NextStep onNext={() => onNextStep(1)} />}
      {step > 1 && (
        <PlatformSection isPassed={step > 2}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.awakeTimeLabel(name)}
            hint={scheduleStrings.awakeTimeHint}
            value={awakeTime}
            onChange={(event, value) => onTimeChange(event, value, "awakeTime")}
          />
          {step < 3 && <NextStep onNext={() => onNextStep(2)} />}
        </PlatformSection>
      )}
      {step > 2 && (
        <PlatformSection isPassed={step > 3}>
          <PlatformDivider />
          <SliderInput
            label={scheduleStrings.napCountLabel(name)}
            hint={scheduleStrings.napCountHint}
            min={0}
            max={4}
            value={napCount}
            onChange={onSliderChange}
          />
          {step < 4 && <NextStep onNext={() => onNextStep(3)} />}
        </PlatformSection>
      )}
      {step > 3 && (
        <PlatformSection isPassed={step > 4}>
          <PlatformDivider />
          <SingleChoice
            label={scheduleStrings.hasSitterLabel(name)}
            options={ynOptions}
            selected={hasSitter}
            onSelect={(value) => {
              onSingleChange(value, "hasSitter");
              onNextStep(4);
            }}
          />
        </PlatformSection>
      )}
      {step > 4 && napCount > 0 && (
        <PlatformSection isPassed={step > 5}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.firstNapLabel(name)}
            hint={scheduleStrings.firstNapHint}
            min={awakeTime}
            value={firstNap}
            onChange={(event, value) => onTimeChange(event, value, "firstNap")}
          />
          {step < 6 && <NextStep onNext={() => onNextStep(5)} />}
        </PlatformSection>
      )}
      {step > 5 && napCount > 0 && (
        <PlatformSection isPassed={step > 6}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.firstNapAwakeLabel(name)}
            min={firstNap}
            value={firstNapAwake}
            onChange={(event, value) =>
              onTimeChange(event, value, "firstNapAwake")
            }
          />
          {step < 7 && <NextStep onNext={() => onNextStep(6)} />}
        </PlatformSection>
      )}
      {step > 6 && napCount > 1 && (
        <PlatformSection isPassed={step > 7}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.secNapLabel(name)}
            min={firstNapAwake}
            value={secNap}
            onChange={(event, value) => onTimeChange(event, value, "secNap")}
          />
          {step < 8 && <NextStep onNext={() => onNextStep(7)} />}
        </PlatformSection>
      )}
      {step > 7 && napCount > 1 && (
        <PlatformSection isPassed={step > 8}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.secNapAwakeLabel(name)}
            min={secNap}
            value={secNapAwake}
            onChange={(event, value) =>
              onTimeChange(event, value, "secNapAwake")
            }
          />
          {step < 9 && <NextStep onNext={() => onNextStep(8)} />}
        </PlatformSection>
      )}
      {step > 8 && napCount > 2 && (
        <PlatformSection isPassed={step > 9}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.thirdNapLabel(name)}
            min={secNapAwake}
            value={thirdNap}
            onChange={(event, value) => onTimeChange(event, value, "thirdNap")}
          />
          {step < 10 && <NextStep onNext={() => onNextStep(9)} />}
        </PlatformSection>
      )}
      {step > 9 && napCount > 2 && (
        <PlatformSection isPassed={step > 10}>
          <PlatformDivider />
          <TimeInput
            label={scheduleStrings.thirdNapAwakeLabel(name)}
            min={thirdNap}
            value={thirdNapAwake}
            onChange={(event, value) =>
              onTimeChange(event, value, "thirdNapAwake")
            }
          />
          {step < 11 && <NextStep onNext={() => onNextStep(10)} />}
        </PlatformSection>
      )}
      {step > 10 && (
        <PlatformSection>
          <PlatformDivider />
          <SingleChoice
            label={scheduleStrings.betweenNapLabel(name)}
            options={ynOptions}
            selected={betweenNaps}
            onSelect={(value) => onSingleChange(value, "betweenNaps")}
          />
          {!locked && <NextStep loading={loading} onNext={onFormCompleted} />}
        </PlatformSection>
      )}
    </div>
  );
};

export default SceduleForm;
