import VideoPlayer from "components/utils/VideoPlayer";
import { ReactNode } from "react";

export interface StartWrapperProps {
  title: string;
  description: string;
  video?: string;
  children?: ReactNode;
}

const StartWrapper: React.FC<StartWrapperProps> = ({
  title,
  description,
  video,
  children,
}) => {
  return (
    <div className="flex flex-col space-y-6 mb-16">
      <p className="text-jungle-400 font-semibold text-2xl">{title}</p>
      <p className="text-justify">{description}</p>
      {video && <VideoPlayer code={video} />}
      {children}
    </div>
  );
};

export default StartWrapper;
