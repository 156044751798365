import { toPersian } from "utils/raqam";

export const activityStrings = {
  title: "خواب دزد شماره ۲: فعالیت های قبل از خواب",
  duration: "(زمان تقریبی جواب دادن: ۳ تا ۸ دقیقه)",
  usingActicityLabel:
    "آیا همواره قبل از خواب شبانه، با انجام یک سری فعالیت روتین مثل لالایی، شیر دادن، عوض کردن پوشک و...  آماده خواب میشین؟",
  inRoomLabel: (name?: string) =>
    `آیا کارهای آمادگی قبل از خواب رو در اتاق خواب ${name} انجام میدین یا در اتاقی دیگه؟`,
  inRoom: (name?: string) => `در اتاق خواب ${name}`,
  otherRooms: "در سایر اتاق ها",
  activitiesLabel: (name?: string) =>
    `کدوم یکی از فعالیت های زیر در برنامه خواب شبانه ${name} قرار داره؟`,
  activitiesHint:
    "(روی مواردی که انجام میدین کلیک کنید تا به برنامه روتین اضافه بشه)",
  bath: "حموم کردن",
  massage: "ماساژ دادن",
  diaper: "تعویض پوشک و پوشیدن لباس خواب",
  dimmingLights: "کم کردن نور اتاق",
  drawingBlinkds: "کشیدن پرده ها",
  whiteNoise: "پخش نویز سفید",
  playingMusic: "پخش موسیقی",
  feeding: "شیر خوردن",
  singings: "آواز خوندن",
  bouncing: "تکون دادن و راه بردن کودک تا به خواب بره",
  reading: "کتاب خوندن",
  goodnight: "شب بخیر گفتن به وسایل اتاق",
  lyingDown: "دراز کشیدن کنار کودک",
  actDuaration: (name?: string) =>
    `فعالیت های قبل از خواب ${name} چقدر طول میکشه؟`,
  veryLow: "کمتر از ۱۵ دقیقه",
  low: "۱۵ تا ۳۰ دقیقه",
  medium: "۳۰ تا ۴۵ دقیقه",
  high: "۴۵ تا ۶۰ دقیقه",
  veryHigh: "بیشتر از ۶۰ دقیقه",
  beforeNapLabel: "آیا قبل از چرت زدن ها هم کار خاصی انجام میدین؟",
  completeRateDesc: (name?: string) =>
    `همه چی مرتبه!  شما همه ۵ ستاره مربوط به فعالیت های قبل از خواب رو دریافت کردین!`,
  lowRateDesc: (rate: number, name?: string) =>
    `شما ${toPersian(
      rate
    )} ستاره از ۵ ستاره فعالیت های قبل از خواب رو دریافت کردین. تنظیم فعالیت های قبل از خواب، واقعا میتونه به خواب بهتر ${name} کمک کنه! در بخش "چه چیزی کمه" بهتون به طور دقیق نشون میدیم که چه مواردی رو اصلاح کنید و چطور این کارو انجام بدین.`,
  letsMove: 'بریم سراغ خواب دزد بعدی: "عادتهای قبل از خواب"',
  routine: "روتین",
};
