import { gql } from "@apollo/client";

export const ASK_QUESTION = gql`
  mutation SUPPORT_create_ticket($input: SUPPORT_ticketInput) {
    SUPPORT_create_ticket(input: $input) {
      title
    }
  }
`;

export const GET_TICKETS = gql`
  query SUPPORT_my_tickets($page: Int!) {
    SUPPORT_my_tickets(page: $page) {
      data {
        title
        body
        responses {
          body
        }
      }
      paginator_meta {
        current_page
        last_page
      }
    }
  }
`;
