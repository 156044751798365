import VideoPlayer from "components/utils/VideoPlayer";
import { firstDayStrings } from "data/platform/daily/dailyStrings";
import { FaGripLines } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export interface FirstDayEndProps {
  name?: string;
}

const FirstDayEnd: React.FC<FirstDayEndProps> = ({ name }) => {
  return (
    <div className="flex flex-col space-y-6">
      <p className="text-xl text-jungle-400 font-semibold">
        {firstDayStrings.cryTitle(name)}
      </p>
      <VideoPlayer code="17" />
      <p className="text-xl text-jungle-400 font-semibold">
        {firstDayStrings.readyTitle}
      </p>
      <p className="text-justify">{firstDayStrings.readyDescription}</p>
      <NavLink
        to={"/platform/video-overview/night-awakes"}
        className="text-lg italic"
      >
        <FaGripLines className="inline-block ml-2" />{" "}
        {firstDayStrings.nightAwakingSummary}
      </NavLink>
      <NavLink
        to={"/platform/video-overview/feedings"}
        className="text-lg italic"
      >
        <FaGripLines className="inline-block ml-2" />{" "}
        {firstDayStrings.nightFeedingSummary}
      </NavLink>
      <NavLink
        to={"/platform/video-overview/morning"}
        className="text-lg italic"
      >
        <FaGripLines className="inline-block ml-2" />{" "}
        {firstDayStrings.startDaySummary}
      </NavLink>
    </div>
  );
};

export default FirstDayEnd;
