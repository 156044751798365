import StarRate from "components/utils/StarRate";
import VideoPlayer from "components/utils/VideoPlayer";
import { WhatsMissingItem } from "models/WMItem";
import { ReactNode } from "react";
import WMListItemScreen from "screens/sleep-plan/whats-missing/ListItemScreen";
import loadingSVG from "images/platform/loading.svg";

export interface WMListProps {
  icon: ReactNode;
  title: string;
  rate: number;
  video?: string;
  items: WhatsMissingItem[];
  loading: boolean;
  onCheck: (item: WhatsMissingItem) => void;
}

const WMList: React.FC<WMListProps> = ({
  icon,
  title,
  rate,
  video,
  items,
  loading,
  onCheck,
}) => {
  const isCompleted = items.filter((item) => !item.isChecked).length === 0;
  const textColor = isCompleted ? "text-jungle-400" : "text-orange";
  const bgColor = isCompleted ? "bg-jungle-400" : "bg-orange";

  return (
    <div className="flex flex-col space-y-4 bg-white bg-opacity-10 py-8 rounded-xl relative">
      {loading && (
        <div className="absolute inset-0 bg-navy bg-opacity-80 flex items-center justify-center">
          <img src={loadingSVG} alt="..." />
        </div>
      )}
      <span
        className={`${bgColor} text-navy rounded-full p-4 text-5xl wm-list-icon`}
      >
        {icon}
      </span>
      <div
        className={`${textColor} text-center pt-3 pb-8 px-8 flex flex-col space-y-4`}
      >
        <div>
          <p className="text-2xl font-semibold">{title}</p>
        </div>
        <div className="pb-4">
          <StarRate rate={rate} />
        </div>
        {video && (
          <div>
            <VideoPlayer code={video} />
          </div>
        )}
      </div>
      <div>
        {items
          .sort((a, b) => a.title.length - b.title.length)
          .map((item, index) => (
            <WMListItemScreen
              key={index}
              {...item}
              onCheck={() => onCheck(item)}
            />
          ))}
      </div>
    </div>
  );
};

export default WMList;
