import { IBaby } from "models/Baby";
import { atom } from "recoil";

export const babyState = atom<IBaby>({
  key: "baby",
  default: {},
});

export const babySavedState = atom<boolean>({
  key: "babySaved",
  default: false,
});
