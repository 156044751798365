import PlatformContent from "components/layout/PlatfromContent";
import SleepPlanHeader from "components/sleep-plan/SleepPlanHeader";
import { dailyStrings } from "data/platform/daily/dailyStrings";
import { Fragment } from "react";
import MorningFormScreen from "screens/daily/MorningFormScreen";
import NapFormScreen from "screens/daily/NapFormScreen";
import NightFormScreen from "screens/daily/NightScreen";
import { DailyMorningProps } from "./MorningForm";
import { NapProps } from "./NapForm";
import { NightProps } from "./NightForm";
import dailyGuideIcon from "images/platform/daily-logo.png";
import Start from "./Start";
import FirstDayEnd from "./Descriptions/FirstDayEnd";
import LastDayEnd from "./Descriptions/LastDayEnd";
import EmergencyNapModal from "./EmergencyNapDescriptions/EmergencyNapModal";
import EmergencyNapFormScreen from "screens/daily/EmergencyNapFormScreen";
import { DailyServer } from "models/DailyServer";
import loadingSVG from "images/platform/loading.svg";
import { NavLink } from "react-router-dom";
import { platformStrings } from "data/platform/platformStrings";
import { FaChevronRight } from "react-icons/fa";
import UpdateScheduleScreen from "screens/sleep-plan/goal-schedule/updateSchedule";
import { scheduleNeedsUpdate } from "utils/checkScheduleUpdate";

export interface DailyFormProps {
  name?: string;
  age: number;
  scheduleAge: number;
  dayNumber: number;
  ageSpecified: boolean;
  morningProps: DailyMorningProps;
  napList: NapProps[];
  hasEmergencyNap: boolean;
  nightProps: NightProps;
  modalStep: number;
  modalOpen: boolean;
  serverData: DailyServer;
  loading: boolean;
  maxDays: number;
  onOpenEnModal: () => void;
  onCloseEnModal: () => void;
  onAddEN: () => void;
  onNextStep: () => void;
  onRemoveEN: () => void;
  onSaveData: (data: DailyServer) => void;
  onAddDay: () => void;
  onFinish: () => void;
  handleScheduleAge: () => void;
}

const DailyForm: React.FC<DailyFormProps> = ({
  name,
  dayNumber,
  morningProps,
  napList,
  hasEmergencyNap,
  nightProps,
  modalStep,
  modalOpen,
  serverData,
  loading,
  maxDays,
  age,
  scheduleAge,
  ageSpecified,
  handleScheduleAge,
  onAddDay,
  onSaveData,
  onOpenEnModal,
  onCloseEnModal,
  onAddEN,
  onNextStep,
  onRemoveEN,
  onFinish,
}) => {
  return (
    <Fragment>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-navy bg-opacity-60 z-50">
          <img src={loadingSVG} alt="..." />
        </div>
      )}
      <SleepPlanHeader
        title={dailyStrings.day(dayNumber)}
        icon={dailyGuideIcon}
        name={name}
        day={dayNumber}
        nextLink={
          dayNumber !== maxDays ? `/platform/daily/${dayNumber + 1}` : ""
        }
        backLink={
          dayNumber !== 1
            ? `/platform/daily/${dayNumber - 1}`
            : "/platform/goal-schedule"
        }
      />
      <PlatformContent>
        <Start dayNumber={dayNumber} name={name} />
        <div className="flex flex-col space-y-12">
          {scheduleNeedsUpdate(age, scheduleAge) && !ageSpecified && (
            <UpdateScheduleScreen handleScheduleAge={handleScheduleAge} />
          )}
          {dayNumber !== 1 && (
            <Fragment>
              <MorningFormScreen
                dayNumber={dayNumber}
                serverData={serverData}
                onSaveData={onSaveData}
                {...morningProps}
              />
              {napList.map((item, index) => (
                <NapFormScreen
                  key={index}
                  index={index}
                  {...item}
                  serverData={serverData}
                  hasEmergency={hasEmergencyNap}
                  dayNumber={dayNumber}
                  onSaveData={onSaveData}
                  onOpenEnModal={onOpenEnModal}
                />
              ))}
            </Fragment>
          )}
          {hasEmergencyNap && (
            <EmergencyNapFormScreen
              dayNumber={dayNumber}
              name={name}
              serverData={serverData}
              onSaveData={onSaveData}
              onRemoveEN={onRemoveEN}
            />
          )}
          <NightFormScreen
            dayNumber={dayNumber}
            {...nightProps}
            serverData={serverData}
            onSaveData={onSaveData}
          />
          {dayNumber === 1 && <FirstDayEnd name={name} />}
          {dayNumber === maxDays && (
            <LastDayEnd onAddDay={onAddDay} onFinish={onFinish} name={name} />
          )}
          {dayNumber !== maxDays && (
            <p className="text-right">
              <NavLink
                to={`/platform/daily/${dayNumber + 1}`}
                className="text-xl text-orange font-semibold"
              >
                <FaChevronRight className="inline-block ml-2" />
                {platformStrings.day(dayNumber + 1)}
              </NavLink>
            </p>
          )}
        </div>
        {modalOpen && (
          <EmergencyNapModal
            open={modalOpen}
            name={name}
            step={modalStep}
            onAddNap={onAddEN}
            onClose={onCloseEnModal}
            onNextStep={onNextStep}
          />
        )}
      </PlatformContent>
    </Fragment>
  );
};

export default DailyForm;
