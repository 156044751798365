import { platformStrings } from "data/platform/platformStrings";
import { ReactNode } from "react";

export interface GlassBoxProps {
  isEditing: boolean;
  title: ReactNode;
  children: ReactNode;
  onEdit: () => void;
  onSave: () => void;
}

const GlassBox: React.FC<GlassBoxProps> = ({
  isEditing,
  title,
  children,
  onSave,
  onEdit,
}) => {
  const textColors = isEditing ? "text-orange" : "text-jungle-400";
  const onClick = isEditing ? onSave : onEdit;
  return (
    <div className={`${textColors} flex flex-col space-y-4`}>
      <div className="flex justify-between font-semibold">
        {title}
        <span onClick={onClick} className="text-jasmine cursor-pointer">
          {isEditing ? platformStrings.save : platformStrings.edit}
        </span>
      </div>
      <div className="bg-white bg-opacity-10 py-4 px-3 rounded-lg">
        {children}
      </div>
    </div>
  );
};

export default GlassBox;
