import { dailyStrings } from "data/platform/daily/dailyStrings";
import StartWrapper from "./StartWrapper";

export interface NthDayStartProps {
  dayNumber: number;
}

const NthDayStart: React.FC<NthDayStartProps> = ({ dayNumber }) => {
  return (
    <StartWrapper
      title={dailyStrings.goodMorning}
      description={dailyStrings.description(dayNumber)}
    />
  );
};

export default NthDayStart;
