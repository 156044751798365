import { goalScheduleStrings } from "./goalScheduleStrings";
import loadingSVG from "images/platform/loading.svg";

export interface UpdateScheduleProps {
  name?: string;
  age: number;
  loading: boolean;
  handleScheduleAge: () => void;
}

const UpdateSchedule: React.FC<UpdateScheduleProps> = ({
  name,
  age,
  loading,
  handleScheduleAge,
}) => {
  return (
    <div className="relative bg-orange text-navy rounded-lg py-4 px-2 flex flex-col space-y-6">
      {loading && (
        <div className="absolute inset-0 bg-navy bg-opacity-80 flex items-center justify-center">
          <img src={loadingSVG} alt="..." />
        </div>
      )}
      <p>{goalScheduleStrings.changeScheduleDescription(age, name)}</p>
      <button
        onClick={handleScheduleAge}
        className="py-2 px-3 rounded-lg bg-navy text-jasmine"
      >
        {goalScheduleStrings.updateScheedule}
      </button>
    </div>
  );
};

export default UpdateSchedule;
