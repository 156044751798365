import { gql } from "@apollo/client";

export const ASSIGN_LICENSE = gql`
  mutation MARKET_set_dreamlab_infant($code: String!, $infant_id: Int!) {
    MARKET_set_dreamlab_infant(code: $code, infant_id: $infant_id) {
      infant {
        id
      }
    }
  }
`;

export const SUBMIT_LICENSE = gql`
  mutation MARKET_set_dreamlab_owner($code: String!) {
    MARKET_set_dreamlab_owner(code: $code) {
      code
    }
  }
`;
