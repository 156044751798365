import React from "react";
import nervMomPNG from "images/gallery/nerv-mom.png";
import relaxMomPNG from "images/gallery/relax-mom.png";
import asleepBabyPNG from "images/gallery/asleep-baby.png";
import motherDreamlabPNG from "images/gallery/mother-dreamlab.png";
import { appStrings } from "data/landing/strings";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section id="gallery" className="w-full py-24 bg-gray-50" dir={dir}>
      <div className="container mx-auto px-8 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <img src={relaxMomPNG} alt="relax-mom" />
          <img src={asleepBabyPNG} alt="asleep-baby" />
          <img src={motherDreamlabPNG} alt="mother-dreamlab" />
          <img src={nervMomPNG} alt="nervous-mom" />
        </div>
        <div className="flex justify-between mt-6 text-lg font-semibold text-white px-4 py-2 bg-gradient-to-r from-sky-200 via-white to-sky-200 rounded-xl">
          <span>{appStrings.after[lang]}</span>
          <span>{appStrings.before[lang]}</span>
        </div>
      </div>
    </section>
  );
};

export default Container;
