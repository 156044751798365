import SingleChoice from "components/form-elements/SingleChoice";
import LockedAlert from "components/utils/LockedAlert";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { acts } from "data/platform/acts";
import { feedingMethods } from "data/platform/feedingMethods";
import { whenToBeginStrings } from "data/platform/whenToBeginStrings";
import { ynOptions } from "data/platform/ynOptions";
import { WhenToBeginData } from "models/WTB";

export interface WhenToBeginFormProps extends WhenToBeginData {
  name?: string;
  locked: boolean;
  onChange: (value: string | boolean | number, key: string) => void;
  completeForm: () => void;
  loading: boolean;
}

const WhenToBeginForm: React.FC<WhenToBeginFormProps> = ({
  name,
  tooth,
  act,
  familyTrip,
  sitterJob,
  reflax,
  feeding,
  medical,
  illness,
  hardSituation,
  breastWorry,
  loading,
  locked,
  onChange,
  completeForm,
}) => {
  return (
    <div>
      {locked && <LockedAlert />}
      <SingleChoice
        label={whenToBeginStrings.toothLabel(name)}
        tooltip={whenToBeginStrings.toothTooltip}
        selected={tooth}
        options={ynOptions}
        onSelect={(value) => onChange(value, "tooth")}
      />
      {tooth !== undefined && (
        <PlatformSection isPassed={!!act}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.actsLabel(name)}
            selected={act}
            options={acts}
            onSelect={(value) => onChange(value, "act")}
          />
        </PlatformSection>
      )}
      {act && (
        <PlatformSection isPassed={sitterJob !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.sitterJobLabel(name)}
            hint={whenToBeginStrings.sitteJobHint}
            selected={sitterJob}
            options={ynOptions}
            onSelect={(value) => onChange(value, "sitterJob")}
          />
        </PlatformSection>
      )}
      {sitterJob !== undefined && (
        <PlatformSection isPassed={familyTrip !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.familyTripLabel}
            selected={familyTrip}
            options={ynOptions}
            onSelect={(value) => onChange(value, "familyTrip")}
          />
        </PlatformSection>
      )}
      {familyTrip !== undefined && (
        <PlatformSection isPassed={illness !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.illnessLabel(name)}
            selected={illness}
            options={ynOptions}
            onSelect={(value) => onChange(value, "illness")}
          />
        </PlatformSection>
      )}
      {illness !== undefined && (
        <PlatformSection isPassed={reflax !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.reflaxLabel(name)}
            selected={reflax}
            options={ynOptions}
            onSelect={(value) => onChange(value, "reflax")}
          />
        </PlatformSection>
      )}
      {reflax !== undefined && (
        <PlatformSection isPassed={medical !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.medicalLabel(name)}
            tooltip={whenToBeginStrings.medicalTooltip}
            selected={medical}
            options={ynOptions}
            onSelect={(value) => onChange(value, "medical")}
          />
        </PlatformSection>
      )}
      {medical !== undefined && (
        <PlatformSection isPassed={feeding !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.feedingLabel(name)}
            selected={feeding}
            options={feedingMethods}
            onSelect={(value) => onChange(value, "feeding")}
          />
        </PlatformSection>
      )}
      {feeding && feeding !== "bottle" && (
        <PlatformSection isPassed={breastWorry !== undefined}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.breastFeedWorryLabel}
            tooltip={whenToBeginStrings.breastFeedWorryTooltip}
            selected={breastWorry}
            options={ynOptions}
            onSelect={(value) => onChange(value, "breastWorry")}
          />
        </PlatformSection>
      )}
      {(breastWorry !== undefined || feeding === "bottle") && (
        <PlatformSection isPassed={locked}>
          <PlatformDivider />
          <SingleChoice
            label={whenToBeginStrings.hardSituationLabel}
            tooltip={whenToBeginStrings.hardSituationTooltip}
            selected={hardSituation}
            options={ynOptions}
            onSelect={(value) => onChange(value, "hardSituation")}
          />
          {!locked && <NextStep loading={loading} onNext={completeForm} />}
        </PlatformSection>
      )}
    </div>
  );
};

export default WhenToBeginForm;
