export const methodVideoCode = (method: string) => {
  switch (method) {
    case "visit":
      return "1101";
    case "stay":
      return "1102";
    case "touch":
      return "1103";
    default:
      return "none";
  }
};
