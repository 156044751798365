import React from "react";
import Content from "./Content";
import Demo from "./Demo";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section id="music" className="music-bg bg-cover bg-no-repeat" dir={dir}>
      <div className="container mx-auto py-16 flex flex-col space-y-4 lg:flex-row-reverse">
        <Content lang={lang} dir={dir} />
        <Demo />
      </div>
    </section>
  );
};

export default Container;
