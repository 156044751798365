import PlatformContent from "components/layout/PlatfromContent";
import { platformStrings } from "data/platform/platformStrings";
import { IMenu } from "models/Menu";
import { NavLink } from "react-router-dom";

export interface DailyListProps {
  items: IMenu[];
}

const DailyList: React.FC<DailyListProps> = ({ items }) => {
  return (
    <PlatformContent isLarge>
      <div className="flex flex-col space-y-8">
        <p className="text-jungle-400 font-semibold text-2xl">
          {platformStrings.dailyGuide}
        </p>
        <div className="grid grid-cols-4 gap-4">
          {items.map((item, index) => (
            <NavLink
              key={index}
              to={item.link}
              className="text-center py-3 border-2 border-jasmine rounded-lg"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </PlatformContent>
  );
};

export default DailyList;
