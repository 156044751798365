import { toPersian } from "utils/raqam";

export const goalScheduleStrings = {
  title: "برنامه هدف",
  goalSchedule: (name?: string) => `برنامه هدف ${name}`,
  description: (name?: string) =>
    `پیشنهاد میکنیم ساعت خواب ${name}، ۷ شب باشه و باقی برنامه بر اساس همین زمان تنظیم شده. اگه مایلین ساعت خواب یا طول مدت فعالیت های قبل از خواب رو تنظیم کنین، میتونین از لینک های زیر استفاده کنید.`,
  nightTime: "خواب شب",
  windDownTimeLabel: "زمان شروع فعالیت‌های قبل از خواب",
  bedtime: "زمان خوابیدن",
  feed1Label: "شیردهی اول",
  feed2Label: "شیردهی دوم",
  feed3Label: "شیردهی سوم",
  nightTimeDescription: (name?: string) =>
    `تا وقتی که ${name} حداقل همزمان به سن ۶ ماه و وزن ۷.۲۵۰ کیلوگرم نرسیده، باید یک وعده شیر دهی شبانه رو ادامه بدین`,
  changeBedtimeLabel: "تغییر ساعت خواب",
  changeWindDownLabel: "تغییر طول مدت فعالیت های قبل از خواب",
  morningTitle: "صبح",
  wakeTimeLabel: "ساعت بیداری",
  wakeTimeHint: (name?: string) =>
    `هر ساعتی هم که ${name} به خواب بره، زمان بیداری روزانه تغییری نمیکنه. علت این کار اینه که چرخه خواب فرزند شما تنظیم و قابل پیشبینی بشه.`,
  napLabel: (time: number) => `چرت ${toPersian(time)}`,
  minCribTime: "حداقل زمان حضور در گهواره",
  minCribHint: (name?: string) =>
    `زمانیکه هر روز ${name} باید در تختخواب بمونه و مهارتهای جدید خواب رو تمرین کنه.`,
  wakeBy: (time: string, name?: string) =>
    `${name} را ساعت ${toPersian(time)} بیدار کنید`,
  flexNap: "چرت متغیر",
  flexNapHint: (name?: string) =>
    `سومین چرت میتونه سخت باشه. پس اگه برای خوابوندن ${name} نیاز به کالسکه یا بغل کردن دارین، یا اگه ${name} کلا نخوابید هم مشکلی نیست. چرت سوم باعث کاهش خواب آلودگی ${name} میشه و در این حالت موقع خواب شبانه بیش از حد خسته نخواهد بود.`,
  morningDescription1: (name?: string) =>
    `لطفا توجه داشته باشین که وقتی ${name} درست چرت زدن رو یاد میگیره، ممکنه زمانبندی کمی تغییر کنه. به این علت که در ابتدای برنامه بچه ها کمتر میخوابن. اما وقتی ${name} حرفه ای بشه، طول خوابش بیشتر میشه و بنابراین ممکنه زمان خواب و بیداری کمی تغییر کنه.`,
  morningDescription2: (name?: string) =>
    `نگران نباشین، ما پرسشهای شما رو بی پاسخ نمیذاریم. ما هر روز شما رو رو در مورد زمان خواب و بیداری ${name} راهنمایی میکنیم.`,
  morningDescription3: (name?: string) =>
    `برنامه توقف تغذیه ${name} با استفاده از اطلاعاتی که در ارزیابی ها ارائه دادین تنظیم شده. اگه بیش از ۳ روز از انجام ارزیابی ها گذشته و برنامه تغذیه ${name} تغییر کرده، لطفا روی لینک زیر کلیک کنین تا اطلاعات به روز رسانی بشن.`,
  changeFeedingTImes: "تغییر برنامه تغذیه",
  morningDescription4: (name?: string) =>
    `هرچی ${name} بزرگتر بشه، برنامه هدف و راهنمای روزانه هم به روز رسانی میشن.`,
  weaningSchedule: (name?: string) => `برنامه از شیر گرفتن شبانه ${name}`,
  night: (time: number) => `شب ${toPersian(time)}`,
  unlimited: "نامحدود",
  weaningScheduleDescription1: (name?: string, isBoth?: boolean) =>
    `برنامه توقف تغذیه ${name} با استفاده از اطلاعاتی که در ارزیابی ها ارائه دادین تنظیم شده. اگه بیش از ۳ روز از انجام ارزیابی ها گذشته و برنامه تغذیه ${name} تغییر کرده، لطفا روی لینک زیر کلیک کنین تا اطلاعات به روز رسانی بشن. ${
      isBoth
        ? `اگر خواستید به ${name} شیر خشک بدید، تو برنامه بالا هر دقیقه رو معادل ۱۵ میلی لیتر در نظر بگیرید.`
        : ""
    }`,
  weaningScheduleDescription2: (name?: string, isBoth?: boolean) =>
    `از اونجایی که ${name} شب ها بیشتر از ۳ بار شیر میخوره، مطابق برنامه نمونه ای بالا لازمه تعداد دفعات شیر خوردنش رو کاهش بدید. برنامه بالا برای کاهش شیردهی شبانه در حالتیه که نوزاد ۵ بار در شب شیر میخوره و شما هم باید قبل از شروع فرایند آموزش خواب، با در نظر گرفتن تعداد دفعات و ساعت های شیرخوردن نوزاد خودتون، مشابه برنامه بالا رو پیاده کنید. ${
      isBoth
        ? `اگر خواستید به ${name} شیر خشک بدید، تو برنامه بالا هر دقیقه رو معادل ۱۵ میلی لیتر در نظر بگیرید.`
        : ""
    }`,
  frequentFeederNotice: (name?: string) =>
    `${name} قبل از اینکه بخواد فرآیند یادگیری خواب رو شروع کنه، باید تغذیه شبانه ش رو از شبی چند بار به شبی یک بار برسونه.`,
  unlimitedFeed: (name?: string) => `${name} الان فقط یک بار در شب شیر میخوره`,
  daily: "راهنمای روزانه",
  changeScheduleDescription: (age: number, name?: string) =>
    `${name} وارد ${toPersian(
      age
    )} ماهگی شده و برنامه روزانش باید تغییر کنه. هر وقت خواستین می تونید برنامه رو به روز کنید.`,
  updateScheedule: "به روزرسانی برنامه",
};
