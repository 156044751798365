import React from "react";
import Content from "./Content";
import Demo from "./Demo";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section
      id="solution"
      className="relative intro-bg mt-10 bg-cover bg-no-repeat pb-32"
      dir={dir}
    >
      <div className="container mx-auto py-16 flex flex-col space-y-4 lg:flex-row">
        <Content lang={lang} dir={dir} />
        <Demo />
      </div>
    </section>
  );
};

export default Container;
