export const askQuestionStrings = {
  title: "کمک لازم دارید؟",
  description:
    "اینجا میتونید هر سوال و مشکلی که در روند یادگیری خواب یا استفاده از سامانه براتون پیش اومده رو بپرسید؛ کارشناسان ما طی دو روز کاری سوالات شما رو جواب میدن.",
  forumHint:
    "سوالاتی که پدر مادرها قبلا از ما پرسیدن توی دانشامه خواب به صورت کامل اضافه شدن. لطفا اول سوالتون رو توی دانشنامه جستجو کنید و در صورتی که جوابشو پیدا نکردید، همین جا سوالتونو از ما بپرسید.",
  forumLink: "ورود به دانشنامه مدرسه خواب",
  typeLabel: "نوع سوال شما*",
  typeDremlab: "در مورد سامانه",
  typeSleep: "در مورد خواب",
  questionLabel: "عنوان سوال*",
  descriptionLabel: "شرح سوال*",
  emailLabel: "آدرس ایمیل*",
  askQuestion: "ثبت سوال",
  success:
    "سوال شما ثبت شد. کارشناسای ما به زودی پاسختون رو خواهند داد که می تونید از طریق صفحه لیست پرسش ها اون رو ببینید.",
};
