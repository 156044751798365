export const envErrors = (name?: string) => ({
  room: {
    title: `اتاق رو به فضایی آروم تبدیل کنین و توانایی ${name} در بوییدن شیر مادر رو کاهش بدین`,
    description: `اتاق مشترک مشکلی نداره!  اما این نکته  رو بدونین که از اونجایی که مادری شیرده هستین، توانایی ${name} برای بوییدن شیر شما باعث میشه به خواب سبکتری فرو بره. پس بهتره ژاکت ضخیمتری بپوشین تا احتمالا بوییدن شیر شما توسط ${name} کمتر بشه. اگه از اتاق مشترک استفاده میکنین، باید وقتی ${name} خوابه حسابی آروم باشین، صحبت نکنین و تلویزیون نبینین!  اگه احساس میکنین در طول روند آموزش خواب، نزدیک بودن به ${name} مشکله، بهتره به یه اتاق دیگه برین. وقتی ${name} خوابیدن رو یاد گرفت، میتونین دوباره به اتاق برگردین و از صدای سفید استفاده کنین. پیشنهاد میکنیم درصورت امکان گهواره ${name} رو از تختخواب خودتون دور کنین تا مزاحم خواب هم نشین.`,
  },
  roomBottle: {
    title: `اتاق رو به فضایی آروم تبدیل کنین`,
    description: `وقتی ${name} خوابه باید داخل اتاق حسابی آروم باشین، صحبت نکنین و تلویزیون نبینین! پیشنهاد میکنیم درصورت امکان گهواره ${name} رو از تختخواب خودتون دور کنین تا مزاحم خواب همدیگه نشین.`,
  },
  sameRoomSiblings: {
    title: "اتاق مشترک با برادر یا خواهر مشکلات زیادی داره",
    description: `زمان آموزش برنامه خواب باید برادر یا خواهر ${name} رو به یک اتاق دیگه ببرید. وقتی ${name} خوابیدن رو یاد گرفت دوباره می تونید خواهر یا برادرش رو به اتاق برگردونین و از نویز سفید استفاده کنید.`,
  },
  sleepBag: {
    title: `استفاده از قنداق برای ایجاد حس امنیت در ${name} به شدت توصیه میشه`,
    description: "",
  },
  cribToys: {
    title: "وسایل غیرضروری رو بر اساس سن کودک از گهواره بردارین",
    description:
      "به توصیه انجمن پزشکی کودکان آمریکا بهتره چیزی به جز ملافه ای مناسب داخل تختخواب کودک نباشه. وجود هر وسیله دیگه ای ممکنه باعث خفگی بشه.",
  },
  whiteNoise: {
    title: "از نویز سفید استفاده کنین",
    description: `استفاده از  نویز سفید کمک میکنه تا ${name} در معرض سایر صداهای محیط (چه در داخل و جه در خارج منزل) نباشه و از خواب نپره.`,
  },
  daylight: {
    title: `اتاق ${name} رو تا ۱۰ درجه تاریک کنین`,
    description: `در طول روند آموزش خواب، اتاق رو کاملا تاریک نگه دارین تا نور طبیعی به داخل نفوذ نکنه. این یکی از مهم ترین تغییرات محیط خواب ${name} هست. مغز بچه ها با نور ورودی به اتاق هماهنگ میشه و خوابیدن رو براشون مشکل میکنه. فعلا میتونین با کیسه زباله سیاه یا چسب رنگی شیشه ها رو بپوشونین، تا بعدا از پرده یا کرکره استفاده کنین. استفاده از نور ملایم مثل چراغ خواب در اتاق مشکلی نداره.`,
  },
  sleepCloth: {
    title: `بهترین لباس خواب قنداقیه  که دستان کودک در اون آزاد باشه و پاهاش در لباس محصور باشه`,
    description: `استفاده از قنداق کمک میکنه که ${name} راحت تر بخوابه ، فقط حواستون باشه که دست های ${name} کاملا آزاد باشه که موقع غلت خوردن بتونه دوباره خودشو برگردونه.`,
  },
  roomTemp: {
    title: `دمای اتاق ${name} باید بین ۲۰ تا ۲۲.۵ درجه باشه`,
    description:
      "دمای اتاق در فصل سرما باید نزدیک ۲۰ و در فصل گرما باید نزدیک ۲۲.۵ درجه باشه",
  },
});
