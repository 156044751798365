import { useMutation, useQuery } from "@apollo/client";
import SleepStealersNightFeeding from "components/sleep-stealers/night-feeding";
import PageLoader from "components/utils/PageLoader";
import { feedingErrors } from "data/platform/errors/feedingErrors";
import { GET, STORE } from "graphql/queries/store";
import { useMask } from "hooks/useMask";
import { IServerData } from "models/ServerData";
import { SliderValue } from "models/SliderValue";
import { SleepStealersNightFeedingData } from "models/SPN";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { boolToJson } from "utils/booleanParser";
import raqam from "utils/raqam";
import { parseSentData, parseStoreData, valueGetter } from "utils/storeParser";

const SleepStealersNightFeedingScreen: React.FC = () => {
  const [baby, setBaby] = useRecoilState(babyState);
  const { name, weightKilo, weightGeram, age, id } = baby;
  const [app, setApp] = useRecoilState(appState);
  const [spnData, setSpnData] = useState<SleepStealersNightFeedingData>({
    feedingCount: 1,
    firstFeedingDuration: 0,
    secFeedingDuration: 0,
    thirdFeedingDuration: 0,
  });
  const [step, setStep] = useState<number>(0);
  const [formCompleted, setFormCompleted] = useState<boolean>(false);
  const [rate, setRate] = useState<number | undefined>(undefined);
  const [feeding, setFeeding] = useState<string | undefined>(undefined);
  const [serverRate, setServerRate] = useState<number | undefined>(undefined);

  const onNextStep = (currStep: number) => {
    const { feedingCount } = spnData;
    if (step === 3 && feedingCount === 0) {
      onFormCompleted();
      return;
    }
    if (step === 5 && feedingCount === 1) {
      onFormCompleted();
      return;
    }
    if (step === 7 && feedingCount === 2) {
      onFormCompleted();
      return;
    }
    setStep(currStep + 1);
  };

  const onSliderChange = (value: SliderValue, key: string) => {
    setSpnData({ ...spnData, [key]: value.x });
  };

  const onSingleChange = (value: string | number | boolean) => {
    setSpnData({ ...spnData, hasFeeding: value as boolean });
  };

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    key: string
  ) => {
    setSpnData({ ...spnData, [key]: value });
  };

  const [newWeightKilo, maskedWeightKilo, setWeightKilo] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: weightKilo?.toString(),
  });

  const [newWeightGeram, maskedWeightGeram, setWeightGeram] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: weightGeram?.toString(),
  });

  const onWeightKiloChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWeightKilo(event.currentTarget.value);
  };

  const onWeightGeramChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWeightGeram(event.currentTarget.value);
  };

  const generateResult = () => {
    if (!spnData.hasFeeding) {
      return {
        rate: 5,
        items: [],
      };
    }
    const isFF = spnData.feedingCount > 3;
    const weight = Number(newWeightKilo) + Number(newWeightGeram) / 1000;
    const errors = feedingErrors(isFF, name, age, weight);
    let missingItems = [];

    if (age && age >= 6 && weight > 7.25) {
      missingItems.push(errors.stopFeeding);
    } else {
      spnData.feedingCount > 1 && missingItems.push(errors.reduceFeeding);
      spnData.feedingCount === 1 && missingItems.push(errors.keepFeeding);
    }
    let finalRate = 0;
    if (spnData.feedingCount === 1) finalRate = 3;
    if (spnData.feedingCount === 2) finalRate = 2;
    if (spnData.feedingCount === 3) finalRate = 1;
    return {
      items: missingItems,
      rate: finalRate,
    };
  };

  const spnKeys: string[] = [
    "108",
    "601",
    "603",
    "604",
    "605",
    "606",
    "607",
    "608",
    "609",
    "weight",
    "spnResult",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      infant_id: id,
      keys: spnKeys,
    },
    onCompleted: (data) => {
      const spnServerResult = parseStoreData(data);
      const weight = valueGetter(spnServerResult, "weight")
        ? JSON.parse(valueGetter(spnServerResult, "weight") as string)
        : { weightKilo: "", weightGeram: "" };
      setWeightKilo(weight.weightKilo);
      setWeightGeram(weight.weightGeram);
      // const spnState: SleepStealersNightFeedingData = {
      //   hasFeeding: valueGetter(spnServerResult, "601", true) as boolean,
      //   feedingCount: valueGetter(spnServerResult, "603")
      //     ? Number(valueGetter(spnServerResult, "603"))
      //     : 0,
      //   firstFeedingTime: valueGetter(spnServerResult, "604") as string,
      //   firstFeedingDuration: valueGetter(spnServerResult, "605")
      //     ? Number(valueGetter(spnServerResult, "605"))
      //     : 0,
      //   secFeedingTime: valueGetter(spnServerResult, "606") as string,
      //   secFeedingDuration: valueGetter(spnServerResult, "607")
      //     ? Number(valueGetter(spnServerResult, "607"))
      //     : 0,
      //   thirdFeedingTime: valueGetter(spnServerResult, "608") as string,
      //   thirdFeedingDuration: valueGetter(spnServerResult, "609")
      //     ? Number(valueGetter(spnServerResult, "609"))
      //     : 0,
      // };
      const feedState = valueGetter(spnServerResult, "108") as string;
      const lastRate = valueGetter(spnServerResult, "spnResult")
        ? JSON.parse(valueGetter(spnServerResult, "spnResult") as string).rate
        : undefined;
      setServerRate(lastRate);
      setFeeding(feedState);
      // setSpnData(spnState);
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const rateState = JSON.parse(
        valueGetter(parseSentData(data), "spnResult") as string
      ).rate;
      setBaby((currBaby) => ({
        ...currBaby,
        weightKilo: Number(newWeightKilo),
        weightGeram: Number(newWeightGeram),
      }));
      rateState !== undefined && setRate(rateState);
      setFormCompleted(true);
      setApp({
        ...app,
        state:
          serverRate !== undefined
            ? "/platform/master-sleep-plan"
            : "/platform/method-selection",
      });
    },
  });

  const onFormCompleted = () => {
    const result = generateResult();
    const spnServerData: IServerData[] = [
      {
        key: "601",
        value: boolToJson(spnData.hasFeeding),
      },
      {
        key: "weight",
        value: JSON.stringify({
          weightKilo: newWeightKilo,
          weightGeram: newWeightGeram,
        }),
      },
      {
        key: "603",
        value: spnData.hasFeeding ? spnData.feedingCount.toString() : "0",
      },
      {
        key: "604",
        value: spnData.firstFeedingTime || "",
      },
      {
        key: "605",
        value: spnData.firstFeedingDuration.toString() || "",
      },
      {
        key: "606",
        value: spnData.secFeedingTime || "",
      },
      {
        key: "607",
        value: spnData.secFeedingDuration.toString() || "",
      },
      {
        key: "608",
        value: spnData.thirdFeedingTime || "",
      },
      {
        key: "609",
        value: spnData.thirdFeedingDuration.toString() || "",
      },
      {
        key: "spnResult",
        value: JSON.stringify(result),
      },
      {
        key: "appState",
        value: JSON.stringify({
          ...app,
          state:
            serverRate !== undefined
              ? "/platform/master-sleep-plan"
              : "/platform/method-selection",
        }),
      },
    ];
    storeData({
      variables: {
        infant_id: id,
        data: spnServerData,
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [spnData.hasFeeding, step, formCompleted]);

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <SleepStealersNightFeeding
        formCompleted={formCompleted}
        formProps={{
          ...spnData,
          name,
          step,
          weightKilo: maskedWeightKilo,
          weightGeram: maskedWeightGeram,
          loading: storeLoading,
          feedingMethod: feeding,
          onWeightGeramChange,
          onWeightKiloChange,
          onFormCompleted,
          onNextStep,
          onSingleChoiceChange: onSingleChange,
          onSliderChange,
          onTimeChange,
        }}
        descProps={{ rate: rate || 0, name, lastRate: serverRate }}
        rate={rate}
      />
    </PageLoader>
  );
};

export default SleepStealersNightFeedingScreen;
