import { platformStrings } from "data/platform/platformStrings";
import { whenToBeginStrings } from "data/platform/whenToBeginStrings";
import Modal from "react-modal";

export interface ItemsModalProps {
  open: boolean;
  items: string[];
  onClose: () => void;
}

const ItemsModal: React.FC<ItemsModalProps> = ({ open, items, onClose }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="w-full lg:w-1/2 mx-auto bg-gray-100 text-navy mt-24 outline-none rounded-lg py-4"
      overlayClassName="bg-transparent fixed inset-0 z-50"
    >
      <p className="pr-4 text-xl font-semibold">
        {whenToBeginStrings.allFactors}
      </p>
      <div className="w-full h-1 bg-navy my-4"></div>
      <ul className="list-inside list-disc pr-4">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <div className="w-full h-1 bg-navy my-4"></div>
      <div className="flex justify-end pl-4">
        <button
          onClick={onClose}
          className="bg-gray-700 text-white py-1 px-4 rounded shadow-md"
        >
          {platformStrings.close}
        </button>
      </div>
    </Modal>
  );
};

export default ItemsModal;
