import { useMutation } from "@apollo/client";
import AskQuestion from "components/ask-question";
import { askQuestionStrings } from "data/platform/askQuestionStrings";
import { platformStrings } from "data/platform/platformStrings";
import { ASK_QUESTION } from "graphql/queries/question";
import { QuestionData } from "models/Question";
import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";

const AskQuestionScreen: React.FC = () => {
  const [data, setData] = useState<QuestionData>({});

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setData({ ...data, [key]: event.currentTarget.value });
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setData({ ...data, description: event.currentTarget.value });
  };

  const handleCategoryChange = (value: string | number | boolean) => {
    if (data.category === value) {
      setData({ ...data, category: undefined });
      return;
    }
    setData({ ...data, category: value.toString() });
  };

  const [ask, { loading }] = useMutation(ASK_QUESTION, {
    onError: (error) => toast.error(error.message),
    onCompleted: (res) => {
      toast.success(askQuestionStrings.success);
    },
  });

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (data.title && data.description && data.category) {
      ask({
        variables: {
          input: {
            category: data.category,
            body: data.description,
            title: data.title,
          },
        },
      });
      return;
    }
    toast.error(platformStrings.requiredError);
  };

  return (
    <AskQuestion
      {...data}
      loading={loading}
      handleCategoryChange={handleCategoryChange}
      handleDescriptionChange={handleDescriptionChange}
      handleInputChange={handleInputChange}
      onSubmit={onSubmit}
    />
  );
};

export default AskQuestionScreen;
