import { useQuery } from "@apollo/client";
import MethodInAction from "components/sleep-plan/method-in-action";
import PageLoader from "components/utils/PageLoader";
import { GET } from "graphql/queries/store";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { methodState } from "recoil/atoms/method";
import { methodNameState } from "recoil/selectors/methodName";
import { parseStoreData, valueGetter } from "utils/storeParser";

const MethodInActionScreen: React.FC = () => {
  const methodName = useRecoilValue(methodNameState);
  const methodCode = useRecoilValue(methodState);
  const { name, id } = useRecoilValue(babyState);
  const [showFeeding, setShowFeeding] = useState<boolean>(false);
  const [feedingCount, setFeedingCount] = useState<number | undefined>(
    undefined
  );

  const { loading, error, refetch } = useQuery(GET, {
    fetchPolicy: "network-only",
    variables: {
      infant_id: id,
      keys: ["601", "603"],
    },
    onCompleted: (data) => {
      const serverData = parseStoreData(data);
      const feedingState = valueGetter(serverData, "601", true) as boolean;
      const serverFeedingCount = valueGetter(serverData, "603");
      feedingState && setShowFeeding(feedingState);
      setFeedingCount(Number(serverFeedingCount));
    },
  });

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <MethodInAction
        method={methodName}
        methodCode={methodCode}
        name={name}
        showFeeding={showFeeding}
        isFF={feedingCount !== undefined ? feedingCount > 3 : false}
      />
    </PageLoader>
  );
};

export default MethodInActionScreen;
