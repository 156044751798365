import { useMutation, useQuery } from "@apollo/client";
import PageLoader from "components/utils/PageLoader";
import WinddownForm from "components/winddown";
import { GET, STORE } from "graphql/queries/store";
import { SliderValue } from "models/SliderValue";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import { winddownState } from "recoil/atoms/winddown";
import { parseStoreData, valueGetter } from "utils/storeParser";

const WinddownScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [winddown, setWinddown] = useRecoilState(winddownState);
  const [duration, setDuration] = useState<number>(winddown);
  const { push } = useHistory();

  const onDurationChange = (value: SliderValue) => {
    setDuration(value.x);
  };

  const { error: serverError, loading, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: ["winddown"],
    },
    onCompleted: (data) => {
      const serverResult = parseStoreData(data);
      const winddownState = valueGetter(serverResult, "winddown") as string;
      setWinddown(Number(winddownState));
    },
    fetchPolicy: "network-only",
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setWinddown(duration);
      push("/platform/goal-schedule");
    },
  });

  const onSave = () => {
    storeData({
      variables: {
        infant_id: id,
        data: [{ key: "winddown", value: duration.toString() }],
      },
    });
  };

  return (
    <PageLoader loading={loading} error={serverError} onRefetch={refetch}>
      <WinddownForm
        name={name}
        duration={duration}
        loading={storeLoading}
        onDurationChange={onDurationChange}
        onSave={onSave}
      />
    </PageLoader>
  );
};

export default WinddownScreen;
