import Content from "./Content";
import Header from "./Header";

export interface IntroProps {}

const Intro: React.FC<IntroProps> = () => {
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
};

export default Intro;
