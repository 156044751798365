import { appStrings } from "./strings";
import instagramPNG from "images/social/instagram.png";

export interface ILink {
  title: string;
  link: string;
  icon?: string;
}

export const footerLinks = (lang: string): ILink[] => [
  {
    title: appStrings.links.solution[lang],
    link: "/#solution",
  },
  {
    title: appStrings.links.features[lang],
    link: "/#features",
  },
  {
    title: appStrings.links.progress[lang],
    link: "/#music",
  },
  {
    title: appStrings.links.why[lang],
    link: "/#why",
  },
  {
    title: appStrings.links.comments[lang],
    link: "/#comments",
  },
];

export const socialLinks = (lang: string): ILink[] => [
  {
    title: appStrings.social.instagram[lang],
    link: "https://www.instagram.com/ghondagh.baby",
    icon: instagramPNG,
  },
];
