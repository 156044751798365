import { platformStrings } from "data/platform/platformStrings";

export const getMethodName = (method: string) => {
  switch (method) {
    case "touch":
      return platformStrings.touchMethod;
    case "visit":
      return platformStrings.visitMethod;
    case "stay":
      return platformStrings.stayMethod;
    default:
      return "روش انتخابی";
  }
};
