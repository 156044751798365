import { IOption } from "models/Option";
import { toPersian } from "utils/raqam";
import FormItem from "./FormItem";

export interface ListInputProps {
  label: string;
  selectedLabel: string;
  hint?: string;
  tooltip?: string;
  selected: IOption[];
  options: IOption[];
  onSelect: (value: IOption) => void;
  onRemove: (value: IOption) => void;
}

const ListInput: React.FC<ListInputProps> = ({
  label,
  hint,
  tooltip,
  selectedLabel,
  selected,
  options,
  onSelect,
  onRemove,
}) => {
  return (
    <FormItem label={label} hint={hint} tooltip={tooltip}>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          {options.map((option, index) => {
            const isExist =
              selected.filter((item) => item.value === option.value).length > 0;
            if (isExist) return "";
            return (
              <p
                key={index}
                onClick={() => onSelect(option)}
                className="text-sm cursor-pointer rounded-lg bg-gray-800 py-1 px-1 transition duration-200 hover:text-jungle hover:bg-gray-700"
              >
                {option.label}
              </p>
            );
          })}
        </div>
        <div className="bg-white bg-opacity-5 rounded-lg">
          <p className="bg-jasmine text-navy font-semibold text-center rounded-t-lg py-2">
            {selectedLabel}
          </p>
          <div className="flex flex-col space-y-3 mt-2">
            {selected.map((option, index) => (
              <p
                key={index}
                onClick={() => onRemove(option)}
                className="text-sm cursor-pointer rounded-lg bg-gray-800 py-1 px-1 transition duration-200 hover:text-jungle hover:bg-gray-700"
              >
                <span className="font-semibold text-base ml-2">
                  {toPersian(index + 1)}
                </span>
                {option.label}
              </p>
            ))}
          </div>
        </div>
      </div>
    </FormItem>
  );
};

export default ListInput;
