import FirstDayStart from "./Descriptions/FirstDayStart";
import FourthDayStart from "./Descriptions/FourthDayStart";
import NthDayStart from "./Descriptions/NthDayStart";
import SecondDayStart from "./Descriptions/SecondDayStart";
import ThirdDayStart from "./Descriptions/ThirdDayStart";

export interface StartProps {
  name?: string;
  dayNumber: number;
}

const Start: React.FC<StartProps> = ({ name, dayNumber }) => {
  switch (dayNumber) {
    case 1:
      return <FirstDayStart name={name} />;
    case 2:
      return <SecondDayStart />;
    case 3:
      return <ThirdDayStart />;
    case 4:
      return <FourthDayStart />;
    default:
      return <NthDayStart dayNumber={dayNumber} />;
  }
};

export default Start;
