import WMListItem from "components/sleep-plan/whats-missing/ListItem";
import { WhatsMissingItem } from "models/WMItem";
import { useState } from "react";

export interface WMListItemScreenProps extends WhatsMissingItem {
  onCheck: () => void;
}

const WMListItemScreen: React.FC<WMListItemScreenProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isMoreExpand, setIsMoreExpand] = useState<boolean>(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const toggleMoreExpand = () => {
    setIsMoreExpand(!isMoreExpand);
  };

  return (
    <WMListItem
      {...props}
      isExapnded={expanded}
      isMoreExpand={isMoreExpand}
      toggleMoreExpand={toggleMoreExpand}
      toggleExpand={toggleExpand}
      onCheck={props.onCheck}
    />
  );
};

export default WMListItemScreen;
