import TimeField from "react-simple-timefield";
import { ChangeEvent } from "react";
import { platformStrings } from "data/platform/platformStrings";
import DailyFormItem from "./DailyFormItem";

export interface DailyTimeInputProps {
  label: string;
  hint?: string;
  tooltip?: string;
  value?: string;
  min?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

const DailyTimeInput: React.FC<DailyTimeInputProps> = ({
  label,
  hint,
  tooltip,
  min,
  value,
  onChange,
}) => {
  let hasError = false;
  if (value && min) {
    const getTime = (time: any) =>
      new Date(2019, 9, 2, time.substring(0, 2), time.substring(3, 5), 0, 0);

    hasError = getTime(value) < getTime(min);
  }

  return (
    <DailyFormItem label={label} tooltip={tooltip} hint={hint}>
      {hasError && <p className="text-orange">{platformStrings.timeError}</p>}
      <div dir="ltr" className="text-center">
        <TimeField
          onChange={onChange}
          value={value || "HH:mm"}
          input={
            <input
              type="tel"
              onFocus={(event) => event.currentTarget.select()}
              className="bg-navy border-b-2 border-jasmine py-2 font-semibold placeholder-gray-700 transition duration-200 outline-none text-center text-jasmine iransans-fanum"
            />
          }
        />
        <p className="text-gray-400 text-sm mt-2">
          {platformStrings.timeFormat}
        </p>
      </div>
    </DailyFormItem>
  );
};

export default DailyTimeInput;
