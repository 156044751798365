import React from "react";
import { appStrings } from "data/landing/strings";
import Divider from "components/utils/Divider";

export interface HeaderProps {
  lang?: string;
}

const Header: React.FC<HeaderProps> = ({ lang = "fa" }) => {
  return (
    <div className="flex flex-col space-y-6 px-6 text-center text-white">
      <p className="text-2xl font-bold lg:text-4xl">
        {appStrings.whyTitle[lang]}
      </p>
      <p className="text-sm lg:text-base">{appStrings.whyDesc[lang]}</p>
      <div>
        <Divider color="white" />
      </div>
    </div>
  );
};

export default Header;
