export const whenToBeginStrings = {
  title: "چه زمانی شروع کنیم؟",
  toothLabel: (name?: string) => `${name} الآن در حال دندون در آوردنه؟`,
  toothTooltip:
    "آیا روی لثه‌های کودکتون تورم یا قرمزی می بینید و کودکتون نا‌آرومه؟",
  actsLabel: (name?: string) =>
    `${name} کدوم یکی از این کارها رو در هفته گذشته انجام داده یا احتمالا در هفته آینده انجام میده؟`,
  rolling: "غلت زدن",
  crawling: "سینه خیز رفتن",
  standing: "ایستادن",
  walking: "راه رفتن",
  sitterJobLabel: (name?: string) =>
    `اگه ${name} پرستار داره، آیا تازگی به سرکارش برگشته و یا در دو هفته آتی قصد بازگشت به کار رو داره؟`,
  sitteJobHint: "(اگه خودتون مراقب کودکتون هستید گزینه خیر رو انتخاب کنید)",
  familyTripLabel:
    "آیا خانواده تون قصد سفر داره و یا در دو هفته آتی مهمونی به منزلتون میاد؟",
  illnessLabel: (name?: string) =>
    `در حال حاضر ${name} بیماره؟ مثلا به سرما خوردگی یا آنفولانزا مبتلا شده؟`,
  reflaxLabel: (name?: string) =>
    `آیا ${name} به طور مداوم مشکل رفلاکس معده، گاز اضافی، یبوست، اگزما یا مشکلات پزشکی دیگه ای داره؟`,
  medicalLabel: (name?: string) =>
    `آیا ${name} مشکل پزشکی ای داره که با مراقبت، درمان، درد یا ناراحتی همراه باشه؟`,
  medicalTooltip:
    "برای مثال: لب‌ها و کام کودکتون قاچ قاچ می‌شه، فلج مغزی شده، به سندروم x یا سندروم داون مبتلاست، فتق نخاع داره، مبتلا به فیبرو‌کیستیه و یا بیماری قلبی داره؟",
  feedingLabel: (name?: string) => `روش تغذیه ${name} کدوم گزینه است؟`,
  breastFeeding: "شیر مادر",
  bottleFeeding: "شیر خشک",
  bothFeeding: "شیر مادر و شیر خشک",
  breastFeedWorryLabel: "آیا نگران کمبود شیر مادر هستین؟",
  breastFeedWorryTooltip:
    "آیا احساس می‌کنید شیرتون برای تغذیه و رشد کودکتون کافی نیست؟",
  hardSituationLabel:
    "آیا در حال حاضر خانواده شما شرایط سختی رو پشت سر میذاره؟",
  hardSituationTooltip:
    "با مسائلی مثل از دست دادن یکی از اعضای خانواده، یک بیماری خطرناک در خانواده، تغییر شغل یا جابجایی روبه‌رو هستید؟",
  okDesc: (name?: string) =>
    `تبریک میگم بهتون ، الآن بهترین زمان برای شروع آموزش خواب ${name} کوچولوئه!`,
  failedDesc: (name?: string) =>
    `شاید الان زمان خوبی برای شروع یادگیری خواب توسط ${name} نباشه. زمان شروع یادگیری برای موفقیت سریع و بدون بدخلقی و گریه ${name} خیلی مهمه. پس لطفا قبل ازشروع  برنامه خواب، مطمئن بشید که:`,
  allItems:
    " برای دسترسی به فهرست کاملی از موانع و مشکلات خواب که قبل از شروع یادگیری باید برطرف بشن",
  clickHere: "اینجا کلیک کنین",
  sleepStealersDesc:
    'عوامل زیادی مانع خواب صحیح میشن که همه اونا در پنج گروه جا میگیرن. اسم این گروه ها رو میذاریم "خواب دزد ها". این عوامل در ویدئوی زیر شرح داده شدن. بعد از تماشای ویدئو، به سوالات پرسشنامه ای که در مشاوره های خصوصی ما استفاده میشه، پاسخ میدین. این کار به ما کمک میکنه تا مشکلات و موانع خوابِ راحت رو ریشه یابی کنیم!',
  allFactors: "لیست کامل موانع شروع یادگیری",
  finalError:
    "با توجه به نتیجه ارزیابی، توصیه اکید ما اینه که تا وقتی به آمادگی کامل دست پیدا نکردید همین جا متوقف بشید و جلوتر نرید تا مشکلی در روند یادگیری خواب برای کوچولوتون پیش نیاد. می تونید الان از سامانه خارج شید و هر وقت که آمادگی لازم رو کسب کردید دوباره برگردید و مراحل رو ادامه بدید. با این حال امکان رفتن به مرحله بعد برای شما وجود داره",
  sleepStealers: "خواب دزدها",
};
