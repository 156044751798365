import PlatformContent from "components/layout/PlatfromContent";
import { finishStrings } from "data/platform/finishStrings";
import Box from "./Box";

export interface AfterDreamLabProps {
  name?: string;
  age: number;
}

const AfterDreamLab: React.FC<AfterDreamLabProps> = ({ name, age }) => {
  return (
    <PlatformContent>
      <Box title={finishStrings.afterTitle}>
        <p>{finishStrings.afterDescription(age, name)}</p>
      </Box>
      <Box title={finishStrings.disimprovementTitle}>
        <p>{finishStrings.disimprovementDescription(name)}</p>
      </Box>
      <Box title={finishStrings.manageSleep}>
        <p>{finishStrings.manageSleepDesc1}</p>
        <ul className="list-inside list-disc">
          {finishStrings.manageSleepitems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>{finishStrings.manageSleepDesc2}</p>
      </Box>
      <Box title={finishStrings.updatePlan}>
        <p>{finishStrings.updatePlanDesc1(name)}</p>
        <ul className="list-inside list-disc">
          {finishStrings.updatePlanItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <p>{finishStrings.updatePlanDesc2(name)}</p>
      </Box>
      {age < 12 && (
        <Box title={finishStrings.reduceNaps}>
          <p>{finishStrings.reduceNapsDesc1(name)}</p>
          <ul className="list-inside list-disc">
            {finishStrings.reduceNapsitems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>{finishStrings.reduceNapsDesc2(name)}</p>
        </Box>
      )}
    </PlatformContent>
  );
};

export default AfterDreamLab;
