import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface PlatformContentProps {
  children: ReactNode;
  isLarge?: boolean;
}

const PlatformContent: React.FC<PlatformContentProps> = ({
  children,
  isLarge = false,
}) => {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("bedtime") ||
      location.pathname.includes("winddown")
    )
      return;
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      className={`w-full ${
        isLarge ? "lg:w-2/3" : "lg:w-1/3"
      } mx-auto pt-24 pb-12 px-10 lg:px-0`}
    >
      {children}
    </div>
  );
};

export default PlatformContent;
