import { platformStrings } from "data/platform/platformStrings";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { toPersian } from "utils/raqam";

export interface SleepPlanHeaderProps {
  name?: string;
  title: string;
  icon: string;
  day?: number;
  nextLink?: string;
  backLink?: string;
}

const SleepPlanHeader: React.FC<SleepPlanHeaderProps> = ({
  name,
  title,
  icon,
  day = 0,
  nextLink,
  backLink,
}) => {
  return (
    <div className="w-full bg-gradient-to-tr from-navy via-navy-400 to-jungle py-24 flex flex-row items-center">
      <div className="w-1/6 lg:w-1/3">
        {nextLink && (
          <NavLink
            to={nextLink}
            className="pl-4 py-4 rounded-l-full bg-gray-600 bg-opacity-50 inline-block"
          >
            <IoIosArrowForward className="text-jasmine text-3xl" />
          </NavLink>
        )}
      </div>
      <div className="w-4/6 lg:w-1/3 text-center">
        <div className="relative">
          <img src={icon} alt={title} className="w-28 mx-auto mb-8" />
          {day > 0 && (
            <span className="text-navy text-3xl font-semibold absolute top-11 left-1/2 transform -translate-x-1/2 select-none">
              {toPersian(day)}
            </span>
          )}
        </div>
        <p className="font-semibold mb-3">{platformStrings.sleepPlan(name)}</p>
        <p className="text-jungle-400 text-2xl font-semibold">{title}</p>
      </div>
      <div className="w-1/6 lg:w-1/3 flex justify-end">
        {backLink && (
          <NavLink
            to={backLink}
            className="pr-4 py-4 rounded-r-full bg-gray-600 bg-opacity-50 inline-block"
          >
            <IoIosArrowBack className="text-jasmine text-3xl" />
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default SleepPlanHeader;
