import PlatformContent from "components/layout/PlatfromContent";
import NextLink from "components/utils/NextLink";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import VideoPlayer from "components/utils/VideoPlayer";
import { whereBeginStrings } from "data/platform/whereBeginStrings";

export interface WhereToBeginProps {
  name?: string;
}

const WhereToBegin: React.FC<WhereToBeginProps> = ({ name = "" }) => {
  return (
    <PlatformContent isLarge>
      <div className="flex flex-col space-y-6">
        <p className="text-jungle font-semibold text-2xl">
          {whereBeginStrings.title}
        </p>
        <p>{whereBeginStrings.description1(name)}</p>
        <VideoPlayer code="2" />
        <NextStep
          onNext={() => window.scrollTo(0, document.body.scrollHeight)}
        />
      </div>
      
      <PlatformSection>
      <PlatformDivider />
        <div className="flex flex-col space-y-6 text-justify">
          <p>{whereBeginStrings.description2(name)}</p>
          <p>{whereBeginStrings.description3}</p>
          <NextLink to="/platform/when-to-begin" />
        </div>
      </PlatformSection>
    </PlatformContent>
  );
};

export default WhereToBegin;
