import React from "react";
import musicCirclePNG from "images/music/music-circle.png";
import musicMobilePNG from "images/music/music-mobile.png";

const Demo: React.FC = () => {
  return (
    <div className="relative lg:w-1/2">
      <img
        src={musicCirclePNG}
        alt="music-circle"
        className="w-10/12 mx-auto"
      />
      <img
        src={musicMobilePNG}
        alt="music-mobile"
        className="absolute music-mobile"
      />
    </div>
  );
};

export default Demo;
