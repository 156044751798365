import { ChangeEvent } from "react";
import FormItem from "./FormItem";

export interface TextAreaInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  value?: string;
  className?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({
  name,
  label,
  placeholder,
  value,
  hint,
  className = "",
  autoFocus = false,
  tabIndex,
  onChange,
}) => {
  return (
    <FormItem label={label} hint={hint}>
      <textarea
        name={name}
        id={name}
        placeholder={placeholder}
        rows={4}
        value={value || ""}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        onChange={onChange}
        autoComplete="off"
        className={`bg-navy border-2 border-gray-600 py-3 text-lg font-semibold placeholder-gray-700 transition duration-200 outline-none focus:border-jasmine ${className}`}
      />
    </FormItem>
  );
};

export default TextAreaInput;
