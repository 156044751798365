import { useLazyQuery } from "@apollo/client";
import Tickets from "components/tickets";
import PageLoader from "components/utils/PageLoader";
import { GET_TICKETS } from "graphql/queries/question";
import { Ticket } from "models/Ticket";
import { useEffect, useState } from "react";
import { ticketArrayParser } from "utils/ticketParser";

const TicketListScreen: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [getTickets, { loading, error }] = useLazyQuery(GET_TICKETS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const parsedTickets = ticketArrayParser(data.SUPPORT_my_tickets.data);
      setTickets([...tickets, ...parsedTickets]);
      setTotalPages(Number(data.SUPPORT_my_tickets?.paginator_meta?.last_page));
    },
  });

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const refetch = () => {
    getTickets({
      variables: {
        page: currentPage,
      },
    });
  };

  useEffect(() => {
    getTickets({
      variables: {
        page: currentPage,
      },
    });
  }, [currentPage, getTickets]);

  return (
    <PageLoader error={error} loading={loading} onRefetch={refetch}>
      <Tickets
        tickets={tickets}
        showLoadMore={currentPage < totalPages}
        loadMore={loadMore}
      />
    </PageLoader>
  );
};

export default TicketListScreen;
