import { appStrings } from "./strings";

export interface IMenuItem {
  title: string;
  url: string;
  isLink: boolean;
}

export const menuItems = (lang: string): IMenuItem[] => {
  switch (lang) {
    case "fa":
      return persianMenuItems;
    case "en":
      return englishMenuItems;
    default:
      return persianMenuItems;
  }
};

const persianMenuItems: IMenuItem[] = [
  {
    title: "خانه",
    url: "/",
    isLink: false,
  },
  {
    title: "برنامه روزانه",
    url: "/#solution",
    isLink: false,
  },
  {
    title: "ویژگی‌ها",
    url: "/#features",
    isLink: false,
  },
  {
    title: "ارزیابی پیشرفت",
    url: "/#music",
    isLink: false,
  },
  {
    title: `چرا ${appStrings.brand.fa}`,
    url: "/#why",
    isLink: false,
  },
  {
    title: "نظرات شما",
    url: "/#comments",
    isLink: false,
  },
  {
    title: `دانشنامه`,
    url: "https://forum.ghondagh.com/index.php?/forum/1-%D8%AF%D8%A7%D9%86%D8%B4%D9%86%D8%A7%D9%85%D9%87-%D9%85%D8%AF%D8%B1%D8%B3%D9%87-%D8%AE%D9%88%D8%A7%D8%A8/",
    isLink: false,
  },
  {
    title: `قنداق`,
    url: "https://ghondagh.com",
    isLink: false,
  },
];

const englishMenuItems: IMenuItem[] = [
  {
    title: "Home",
    url: "/en",
    isLink: false,
  },
  {
    title: "Solution",
    url: "/en#solution",
    isLink: false,
  },
  {
    title: "Features",
    url: "/en#features",
    isLink: false,
  },
  {
    title: `Why ${appStrings.brand.en}`,
    url: "/en#why",
    isLink: false,
  },
  {
    title: "Our Clients",
    url: "/en#clients",
    isLink: false,
  },
  {
    title: "Download",
    url: "/apps?lang=en",
    isLink: true,
  },
  {
    title: "About",
    url: "/about-us?lang=en",
    isLink: true,
  },
  {
    title: "Blog",
    url: "/en#blog",
    isLink: false,
  },
];
