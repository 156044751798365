import { introStrings } from "data/platform/introStrings";
import { NavLink } from "react-router-dom";
import { TiArrowSortedDown } from "react-icons/ti";

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="bg-gradient-to-bl from-gray-800 via-gray-600 to-orange pt-40 text-center min-h-screen flex flex-col justify-evenly">
      <div>
        <p className="text-jungle-400 text-5xl font-semibold mb-10">
          {introStrings.title}
        </p>
        <p>
          <NavLink
            to="/platform/meet-instructors"
            className="text-lg text-navy bg-jungle-400 py-3 px-4 rounded shadow-lg"
          >
            {introStrings.takeAssessment}
          </NavLink>
        </p>
      </div>
      <div className="mt-24 text-navy">
        <a href="#steps">{introStrings.howItWorks}</a>
        <a href="#steps">
          <TiArrowSortedDown className="text-2xl mx-auto mt-4" />
        </a>
      </div>
    </div>
  );
};

export default Header;
