import React from "react";
import Content from "./Content";
import Demo from "./Demo";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section
      className="container mx-auto flex flex-col space-y-4 lg:flex-row lg:pb-16"
      dir={dir}
    >
      <Content lang={lang} dir={dir} />
      <Demo dir={dir} lang={lang} />
    </section>
  );
};

export default Container;
