import React from "react";

export interface TitleChipProps {
  title: string;
  color?: string;
}

const TitleChip: React.FC<TitleChipProps> = ({ title, color = "blue" }) => {
  const blueTheme = {
    textColor: "text-sky-500",
    bgColor: "bg-sky-100",
  };

  const greenTheme = {
    textColor: "text-leaf-500",
    bgColor: "bg-leaf-100",
  };

  const chipTheme = color === "blue" ? blueTheme : greenTheme;
  return (
    <span
      className={`p-1 text-sm ${chipTheme.textColor} ${chipTheme.bgColor} rounded-xl`}
    >
      {title}
    </span>
  );
};

export default TitleChip;
