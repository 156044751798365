import { toPersian } from "utils/raqam";

export interface NumBoxProps {
  num?: number;
  description: string;
  items?: string[];
}

const NumBox: React.FC<NumBoxProps> = ({ num, description, items = [] }) => {
  return (
    <div className="flex flex-col space-y-4">
      <p>
        {num && (
          <span className="text-navy bg-jasmine h-6 w-6 rounded-full inline-block text-center ml-2">
            {toPersian(num)}
          </span>
        )}
        {description}
      </p>
      <ul className="list-disc list-inside flex flex-col space-y-2">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default NumBox;
