import FormItem from "./FormItem";
import TimeField from "react-simple-timefield";
import { ChangeEvent } from "react";
import { platformStrings } from "data/platform/platformStrings";

export interface TimeInputProps {
  label: string;
  hint?: string;
  tooltip?: string;
  value?: string;
  min?: string;
  nightFeedingMin?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({
  label,
  hint,
  tooltip,
  min,
  value = "00:00",
  nightFeedingMin = false,
  onChange,
}) => {
  let hasError = false;
  if (value && min) {
    const getTime = (time: any) =>
      new Date(2019, 9, 2, time.substring(0, 2), time.substring(3, 5), 0, 0);

    if (nightFeedingMin) {
      hasError =
        getTime(value) < getTime(min) && getTime(value) > getTime("08:00");
    } else {
      hasError = getTime(value) < getTime(min);
    }
  }

  return (
    <FormItem label={label} tooltip={tooltip} hint={hint}>
      {hasError && <p className="text-orange">{platformStrings.timeError}</p>}
      <div dir="ltr" className="text-center">
        <TimeField
          onChange={onChange}
          value={value}
          input={
            <input
              type="tel"
              onFocus={(event) => event.currentTarget.select()}
              className="bg-navy border-b-2 border-jasmine py-3 text-lg font-semibold placeholder-gray-700 transition duration-200 outline-none text-center iransans-fanum"
            />
          }
        />
        <p className="text-gray-400 text-sm mt-2">{platformStrings.timeFormat}</p>
      </div>
    </FormItem>
  );
};

export default TimeInput;
