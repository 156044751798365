import { SliderValue } from "models/SliderValue";
import Slider from "react-input-slider";
import { toPersian } from "utils/raqam";
import DailyFormItem from "./DailyFormItem";

export interface DailySliderInputProps {
  label: string;
  hint?: string;
  value: number;
  min: number;
  max: number;
  minHint?: string;
  maxHint?: string;
  onChange: (value: SliderValue) => void;
}

const DailySliderInput: React.FC<DailySliderInputProps> = ({
  label,
  hint,
  value,
  min,
  max,
  minHint,
  maxHint,
  onChange,
}) => {
  return (
    <DailyFormItem label={label} hint={hint}>
      <div dir="ltr" className="px-6">
        <div className="flex justify-between mb-2 text-sm text-jasmine">
          <span className="pt-6">{toPersian(min)}</span>
          <span className="font-semibold text-base">{toPersian(value)}</span>
          <span className="pt-6">{toPersian(max)}</span>
        </div>
        <Slider
          axis="x"
          xmin={min}
          xmax={max}
          x={value}
          onChange={onChange}
          styles={{
            track: {
              width: "100%",
              background:
                "linear-gradient(90deg, rgba(87,87,132,1) 0%, rgba(255,255,255,1) 100%)",
              height: "10px",
              borderRadius: "15px",
            },
            active: {
              background: "transparent",
              height: "10px",
              borderRadius: "15px",
            },
            thumb: {
              width: "20px",
              height: "20px",
              background: "#d4d4f9",
              borderColor: "#122d41",
            },
          }}
        />
        {(minHint || maxHint) && (
          <div className="flex justify-between my-4 text-sm font-thin">
            <span>{minHint}</span>
            <span>{maxHint}</span>
          </div>
        )}
      </div>
    </DailyFormItem>
  );
};

export default DailySliderInput;
