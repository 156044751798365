import { platformStrings } from "data/platform/platformStrings";

export interface LockedAlertProps {}

const LockedAlert: React.FC<LockedAlertProps> = () => {
  return (
    <p className="text-sm text-orange font-semibold italic my-4">
      {platformStrings.readonly}
    </p>
  );
};

export default LockedAlert;
