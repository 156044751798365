import { Ticket } from "models/Ticket";

export const ticketParser = (serverTicket: any) => {
  const answers: string[] = serverTicket.responses
    ? serverTicket.responses.map((item: any) => item.body)
    : [];
  const parsedTicket: Ticket = {
    title: serverTicket.title,
    description: serverTicket.body,
    answers,
    isAnswered: answers.length > 0,
  };
  return parsedTicket;
};

export const ticketArrayParser = (serverTickets: any[]) => {
  const parsedArray: Ticket[] = serverTickets.map((item) => ticketParser(item));
  return parsedArray;
};
