export const ticketStrings = {
  ticketList: "لیست پرسش ها",
  pending: "در انتظار پاسخ",
  showAnswer: "مشاهده پاسخ",
  hideAnswer: "پنهان کردن",
  answers: "پاسخ",
  noTicket:
    "هنوز سوالی از ما نپرسیدید. اگه سوالی دارید می تونید روی لینک زیر کلیک کنید تا کارشناسای ما خیلی زود جوابتونو بدن.",
  askQuestion: "اولین سوالتونو بپرسید!",
};
