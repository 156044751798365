import {
  enStep1Strings,
  enStep2Strings,
  enStep3Strings,
  enStep4Strings,
} from "data/platform/daily/enStrings";
import ContentWrapper from "./ContentWrapper";

export interface EmergencyNapContentProps {
  name?: string;
  step: number;
}

const EmergencyNapContent: React.FC<EmergencyNapContentProps> = ({
  name,
  step,
}) => {
  switch (step) {
    case 1:
      return (
        <ContentWrapper
          title={enStep1Strings.title}
          description={enStep1Strings.description(name)}
        />
      );
    case 2:
      return (
        <ContentWrapper
          title={enStep2Strings.title}
          description={enStep2Strings.description1(name)}
        >
          <p>{enStep2Strings.description2(name)}</p>
        </ContentWrapper>
      );
    case 3:
      return (
        <ContentWrapper
          title={enStep3Strings.title}
          description={enStep3Strings.description(name)}
        >
          <p>{enStep3Strings.importnantNote}</p>
          <ul className="list-inside list-disc">
            {enStep3Strings.notes(name).map((note, index) => (
              <li className="my-2" key={index}>
                {note}
              </li>
            ))}
          </ul>
        </ContentWrapper>
      );
    default:
      return (
        <ContentWrapper
          title={enStep4Strings.title}
          description={enStep4Strings.description(name)}
        />
      );
  }
};

export default EmergencyNapContent;
