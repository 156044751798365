import { appStrings } from "./strings";

export interface IReason {
  title: string;
  description: string;
}

export const reasonsList = (lang: string): IReason[] => [
  {
    title: appStrings.estimation.title[lang],
    description: appStrings.estimation.desc[lang],
  },
  {
    title: appStrings.obstacles.title[lang],
    description: appStrings.obstacles.desc[lang],
  },
  {
    title: appStrings.sleepWay.title[lang],
    description: appStrings.sleepWay.desc[lang],
  },
  {
    title: appStrings.dailySchedule.title[lang],
    description: appStrings.dailySchedule.desc[lang],
  },
];
