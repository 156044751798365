import { gql } from "@apollo/client";

export const ME = gql`
  query me {
    me {
      first_name
      last_name
      email
      is_impersonating
      dreamlab_licenses {
        code
        infant{
          id
        }
      }
      infants {
        id
        name
        adjusted_age
        birthday
        gender
        premature
      }
    }
  }
`;

export const STOP_IMPERSONATING = gql`
  mutation AUTH_impersonate_stop {
    AUTH_impersonate_stop
  }
`;
