import { ticketStrings } from "data/platform/ticketStrings";

export interface TicketBadgeProps {
  open: boolean;
  isAnswered: boolean;
}

const TicketBadge: React.FC<TicketBadgeProps> = ({ open, isAnswered }) => {
  if (isAnswered) {
    return (
      <span className="bg-jungle text-navy text-xs px-1 py-1 rounded-lg mx-auto">
        {open ? ticketStrings.hideAnswer : ticketStrings.showAnswer}
      </span>
    );
  }
  return (
    <span className="bg-orange text-navy text-xs px-1 py-1 rounded-lg mx-auto">
      {ticketStrings.pending}
    </span>
  );
};

export default TicketBadge;
