import WhereToBegin from "components/where-to-begin";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";

const WhereToBeginScreen: React.FC = () => {
  const { name } = useRecoilValue(babyState);

  return <WhereToBegin name={name} />;
};

export default WhereToBeginScreen;
