import { IOption } from "models/Option";
import { platformStrings } from "./platformStrings";

export const genders: IOption[] = [
  {
    label: platformStrings.girl,
    value: "girl",
  },
  {
    label: platformStrings.boy,
    value: "boy",
  },
];
