import { ReactNode } from "react";

export interface BoxProps {
  title: string;
  children?: ReactNode;
}

const Box: React.FC<BoxProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col space-y-6 text-justify leading-relaxed mb-12 pb-12 border-b border-gray-400">
      <p className="text-2xl text-jungle-400 font-semibold">{title}</p>
      {children}
    </div>
  );
};

export default Box;
