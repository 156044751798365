import PlatformContent from "components/layout/PlatfromContent";
import VideoPlayer from "components/utils/VideoPlayer";
import { faBrand, platformStrings } from "data/platform/platformStrings";
import { FaChevronLeft } from "react-icons/fa";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import NumBox from "../NumBox";

export interface NapsVideoProps {}

const NapsVideo: React.FC<NapsVideoProps> = () => {
  const { name } = useRecoilValue(babyState);
  const { method } = useParams<any>();

  return (
    <PlatformContent>
      <NavLink
        to="/platform/method-in-action"
        className="text-lg font-semibold text-left mb-6 block"
      >
        <span>{platformStrings.back}</span>
        <FaChevronLeft className="inline-block mr-2" />
      </NavLink>
      <VideoPlayer code="15" />
      <p className="text-jungle-400 font-semibold text-2xl mt-12 mb-4">چرت ها</p>
      <p>در آموزش خواب، خواب روزانه چه تفاوتی با خواب شبانه داره. </p>
      <div className="flex flex-col space-y-12 mt-12 leading-relxed">
        <NumBox
          num={1}
          description="فعالیت های قبل از خواب رو شروع کنین."
          items={[
            `فعالیت ها رو ۱۰ تا ۱۵ دقیقه قبل از زمان چرت ${name} شروع کنین. `,
            `مطمئن بشین فعالیت ها رو در اتاق خواب ${name} انجام بدین.`,
            `در طول زمان کل فعالیت ها، ${name} رو بیدار نگه دارین.`,
            `مطمئن بشین که اتاق تاریک و نویز سفید فعاله.`,
          ]}
        />
        <NumBox num={2} description={`${name} رو بیدار توی تختش بذارید.`} />
        <NumBox
          num={3}
          description={`زمانی قرار دادن ${name} توی تختش رو در ${faBrand} وارد کنین. `}
        />
        {method === "visit" && (
          <NumBox
            num={4}
            description="بعد از ۵ دقیقه بهش سر بزنید(روش ملاقات)"
            items={[
              `برید نزدیک تخت ${name}. اگر به اندازه ای بزرگ شده که میتونه توی تختش بایسته، کمی عقب تر بایستین`,
              `${name} باید بتونه شما رو ببینه و صداتونو بشنوه`,
              `با کلمات آرامش بخش، عاشقانه و مثبت با ${name} حرف بزنید`,
              `${name} رو لمس نکنید. لمس کردن ${name} ممکنه باعث بشه اون بیشتر گریه کنه`,
              `وقتی بهش سر میزنید بیشتر از ۳۰ ثانیه پیشش نمونید`,
              `اتاق رو ترک کنید`,
            ]}
          />
        )}
        {method === "stay" && (
          <NumBox
            num={4}
            description="بعد از ۵ دقیقه بهش سر بزنید(روش ماندن)"
            items={[
              `بعد از از اینکه ${name} رو توی تختش گذاشتید، خودتون روی زمین یا روی یه صندلی کنارش بشینید.`,
              `وقتی که زمان سر زدن برسه، ${name} باید شما رو ببینه و صداتونو بشنوه.`,
              `با کلمات آرامش بخش، عاشقانه و مثبت با ${name} حرف بزنید`,
              `${name} رو لمس نکنید. لمس کردن ${name} ممکنه باعث بشه اون بیشتر گریه کنه`,
              `وقتی بهش سر میزنید بیشتر از ۳۰ ثانیه پیشش نمونید`,
              `روی زمین یا روی یه صندلی کنارش بشینید.`,
            ]}
          />
        )}
        {method === "touch" && (
          <NumBox
            num={4}
            description="بعد از ۵ دقیقه بهش سر بزنید(روش لمسی)"
            items={[
              `بعد از از اینکه ${name} رو توی تختش گذاشتید، خودتون روی زمین یا روی یه صندلی کنارش بشینید.`,
              `اگه گریه ${name} کمه: همون طوری در حالت نشسته یا ایستاده برید نزدیک تا ${name} شما رو ببینه و صداتونو بشنوه (اگر به اندازه ای بزرگ شده که میتونه توی تختش بایسته، کمی عقب تر بایستین)`,
              `با کلمات آرامش بخش، عاشقانه و مثبت بدون اینکه ${name} رو لمس کنید باهاش حرف بزنید(حداکثر ۳۰ ثانیه)`,
              `اگر ${name} کمی بیشتر گریه کرد، برید پیشش و در حالی که از جملات آرامش بخش استفاده می‌کنید، اونو نوازش کنید و  به سکوت دعوتش کنید(حداکثر ۳۰ ثانیه).`,
              `اگر گریه‌ش شدت گرفت، اونو بلند کنید و با گفتن جملات آرام بخش آرومش کنید(حداکثر ۳۰ ثانیه).`,
              `بعد هم دوباره روی زمین یا صندلی کنارش بشینید.`,
            ]}
          />
        )}
        <NumBox
          num={5}
          description={`بعد از ۱۰ دقیقه و بعدش هر ۱۵ دقیقه یکبار به ${name} سر بزنین. `}
        />
        <NumBox
          num={6}
          description={`زمانی که ${name} به خواب فرو میره رو در ${faBrand} ثبت کنین. `}
        />
        <NumBox
          num={7}
          description={`وقتی ${name} به خواب فرو رفت، حداکثر ساعت مجاز خواب برای اون چرت رو در بخش راهنمای روزانه نگاه کنید. اگه هنوز بیدار نشده بود، خودتون بیدارش کنین تا برنامه بهم نریزه و زمان خواب شبانه تغییر نکنه.`}
        />
        <NumBox
          num={8}
          description={`هر زمانی که ${name} از خواب بیدار میشه، فراموش نکنین این زمان رو وارد راهنمای روزانه کنین تا در صورت لزوم راهنمایی های لازم رو بهتون ارائه بدیم.`}
        />
        <NumBox
          description="پیشنهاد های مفید:"
          items={[
            `حواستون به ساعت باشه و خودتونو با برنامه هماهنگ کنید`,
            `اگر ${name} بیش از حد خسته بشه، ممکنه به سختی به خواب بره، در طول زمان چرت ها مدام بیدار شه و یا حتی خیلی زود تر از زمان برنامه ریزی شده از چرت بیدار بشه.`,
          ]}
        />
      </div>
    </PlatformContent>
  );
};

export default NapsVideo;
