import JungleButton from "components/form-elements/JungleButton";
import ShortTextInput from "components/form-elements/ShortTextInput";
import SingleChoice from "components/form-elements/SingleChoice";
import TextAreaInput from "components/form-elements/TextAreaInput";
import PlatformContent from "components/layout/PlatfromContent";
import { categoryOptions } from "data/platform/askQuestionOptions";
import { askQuestionStrings } from "data/platform/askQuestionStrings";
import { QuestionData } from "models/Question";
import { ChangeEvent, FormEvent } from "react";

export interface AskQuestionProps extends QuestionData {
  loading: boolean;
  handleInputChange: (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => void;
  handleDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleCategoryChange: (value: string | number | boolean) => void;
  onSubmit: (event: FormEvent) => void;
}

const FORUM_LINK =
  "https://forum.ghondagh.com/index.php?/forum/1-%D8%AF%D8%A7%D9%86%D8%B4%D9%86%D8%A7%D9%85%D9%87-%D9%85%D8%AF%D8%B1%D8%B3%D9%87-%D8%AE%D9%88%D8%A7%D8%A8/";

const AskQuestion: React.FC<AskQuestionProps> = ({
  title,
  description,
  category,
  loading,
  handleInputChange,
  handleDescriptionChange,
  handleCategoryChange,
  onSubmit,
}) => {
  return (
    <PlatformContent>
      <p className="text-2xl font-semibold text-jungle-400 mb-6">
        {askQuestionStrings.title}
      </p>
      <p className="text-justify">{askQuestionStrings.description}</p>
      <p className="text-justify mt-3 text-orange">
        {askQuestionStrings.forumHint}
      </p>
      <a
        href={FORUM_LINK}
        target="_blank"
        rel="noreferrer"
        className="bg-orange text-navy py-2 px-3 block w-full mt-3 rounded-lg text-center"
      >
        {askQuestionStrings.forumLink}
      </a>
      <form onSubmit={onSubmit} className="flex flex-col space-y-4">
        <SingleChoice
          label={askQuestionStrings.typeLabel}
          options={categoryOptions}
          selected={category}
          onSelect={handleCategoryChange}
        />
        <ShortTextInput
          name="title"
          label={askQuestionStrings.questionLabel}
          value={title}
          onChange={(value) => handleInputChange(value, "title")}
        />
        <TextAreaInput
          name="description"
          label={askQuestionStrings.descriptionLabel}
          value={description}
          onChange={handleDescriptionChange}
        />
        <JungleButton loading={loading} onClick={onSubmit}>
          {askQuestionStrings.askQuestion}
        </JungleButton>
      </form>
    </PlatformContent>
  );
};

export default AskQuestion;
