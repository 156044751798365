import PlatformContent from "components/layout/PlatfromContent";
import VideoPlayer from "components/utils/VideoPlayer";
import { faBrand, platformStrings } from "data/platform/platformStrings";
import { FaChevronLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";
import NumBox from "../NumBox";

export interface NightWakesProps {}

const NightWakes: React.FC<NightWakesProps> = () => {
  const { name } = useRecoilValue(babyState);

  return (
    <PlatformContent>
      <NavLink
        to="/platform/method-in-action"
        className="text-lg font-semibold text-left mb-6 block"
      >
        <span>{platformStrings.back}</span>
        <FaChevronLeft className="inline-block mr-2" />
      </NavLink>
      <VideoPlayer code="12" />
      <div className="flex flex-col space-y-12 mt-12 leading-relxed">
        <p className="text-jungle-400 font-semibold text-2xl">
          بیداری های شبانه
        </p>
        <NumBox
          num={1}
          description={`ساعت بیداری ${name} رو در ${faBrand} وارد کنین.`}
        />
        <NumBox
          num={2}
          description={`اگه بعد از ۵ دقیقه ${name} به گریه کردن ادامه داد، بهش سر بزنین. `}
        />
        <NumBox num={3} description={`ساعت سر زدن رو ثبت کنین. `} />
        <NumBox
          num={4}
          description={`اگه ${name} هنوز گریه میکنه، ابتدا هر ۱۰ دقیقه و بعد هر ۱۵ دقیقه یکبار بهش سر بزنین تا به خواب بره.`}
        />
        <NumBox
          num={5}
          description={`ساعتی که ${name} دوباره به خواب میره رو در ${faBrand} وارد کنین.`}
        />
      </div>
    </PlatformContent>
  );
};

export default NightWakes;
