import { ApolloError } from "@apollo/client";
import { Fragment, ReactNode } from "react";
import loadingSVG from "images/platform/loading.svg";
import JungleButton from "components/form-elements/JungleButton";
import { platformStrings } from "data/platform/platformStrings";

export interface PageLoaderProps {
  children: ReactNode;
  loading: boolean;
  error?: ApolloError | string;
  showError?: boolean;
  isVideoLoader?: boolean;
  onRefetch?: () => void;
}

const PageLoader: React.FC<PageLoaderProps> = ({
  children,
  loading,
  error,
  showError = true,
  isVideoLoader = false,
  onRefetch = () => {
    window.location.reload();
  },
}) => {
  if (loading) {
    return (
      <div
        className={`flex flex-col items-center justify-center ${
          isVideoLoader ? "" : "h-screen"
        }`}
      >
        <img src={loadingSVG} alt="..." className="w-64" />
      </div>
    );
  }

  if (error && showError) {
    return (
      <div className="bg-navy pt-32 flex flex-col space-y-12">
        <p className="text-center text-orange text-2xl font-semibold">
          {error}
        </p>
        {onRefetch && (
          <JungleButton onClick={onRefetch}>
            {platformStrings.tryAgain}
          </JungleButton>
        )}
      </div>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default PageLoader;
