import React from "react";
import heroBabyPNG from "images/hero/hero-baby.png";

export interface DemoProps {
  lang?: string;
  dir?: string;
}

const Demo: React.FC<DemoProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <div className="relative lg:w-4/6">
      <img src={heroBabyPNG} alt="herobaby" className="w-11/12 mx-auto" />
    </div>
  );
};

export default Demo;
