import React from "react";
import { featuresList } from "data/landing/features";
import Header from "./Header";
import Item from "./Item";

export interface ContainerProps {
  dir?: string;
  lang?: string;
}

const Container: React.FC<ContainerProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <section id="features" className="container mx-auto py-8 pt-24" dir={dir}>
      <Header lang={lang} />
      <div className="my-7 flex flex-col space-y-5 px-4 lg:grid lg:grid-cols-4 lg:space-y-0 lg:gap-5">
        {featuresList(lang).map((item, index) => (
          <Item key={index} {...item} dir={dir} />
        ))}
      </div>
    </section>
  );
};

export default Container;
