import DailySliderInput from "components/form-elements/DailySliderInput";
import DailyTimeInput from "components/form-elements/DailyTimeInput";
import { nightStrings } from "data/platform/daily/nightStrings";
import { platformStrings } from "data/platform/platformStrings";
import { IFeedingItem } from "models/FeedingItem";
import { INightWaking } from "models/NightWaking";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent, Fragment } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaCheckSquare, FaPlusCircle, FaRegSquare } from "react-icons/fa";
import { FiMoon } from "react-icons/fi";
import { toPersian } from "utils/raqam";
import { timeValidation } from "utils/timeUtils";
import GlassBox from "./GlassBox";
import NapSavedInfo from "./NapSavedInfo";
import NightWakingModal from "./NightWakingModal";
import ReactTooltip from "react-tooltip";

export interface NightProps {
  name?: string;
  winddown: string;
  bedtime: string;
  feedings: IFeedingItem[];
}

export interface NightFormProps extends NightProps {
  winddownCheck: boolean;
  sleep?: string;
  duration: number;
  isEditing: boolean;
  feedingChecks: boolean[];
  nightWakings: INightWaking[];
  nwModalOpen: boolean;
  nightWakingTime?: string;
  nightWakingSleepDuration: number;
  onRemoveNightWaking: (id: string) => void;
  onCheckWinddown: () => void;
  onTimeChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onNWTimeChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onClickFeeding: (index: number) => void;
  onSliderChange: (value: SliderValue) => void;
  onNWSliderChange: (value: SliderValue) => void;
  onAddNightWaking: () => void;
  onCloseNWModal: () => void;
  onOpenNWModal: () => void;
  onSave: () => void;
  onEdit: () => void;
}

const NightForm: React.FC<NightFormProps> = ({
  winddown,
  name,
  bedtime,
  feedings,
  sleep,
  duration,
  isEditing,
  feedingChecks,
  winddownCheck,
  nwModalOpen,
  nightWakingTime,
  nightWakingSleepDuration,
  nightWakings,
  onRemoveNightWaking,
  onOpenNWModal,
  onAddNightWaking,
  onCloseNWModal,
  onNWSliderChange,
  onNWTimeChange,
  onCheckWinddown,
  onClickFeeding,
  onTimeChange,
  onEdit,
  onSave,
  onSliderChange,
}) => {
  const sleepValidation = sleep ? timeValidation(sleep) : false;

  return (
    <GlassBox
      title={
        <p className="text-lg font-semibold">
          <FiMoon className="inline-block ml-2" />
          {nightStrings.nighttime}
        </p>
      }
      isEditing={isEditing}
      onSave={onSave}
      onEdit={onEdit}
    >
      <ReactTooltip className="w-64 text-justify" />
      <div className="flex flex-col space-y-3">
        <div className="flex justify-between">
          <span
            className="cursor-pointer select-none"
            onClick={onCheckWinddown}
          >
            {winddownCheck ? (
              <FaCheckSquare className="inline-block ml-2" />
            ) : (
              <FaRegSquare className="inline-block ml-2" />
            )}
            {nightStrings.winddown}
          </span>
          <span>{toPersian(winddown)}</span>
        </div>
        <div className="flex justify-between">
          <span>
            {nightStrings.bedtime}
            <span data-tip={nightStrings.bedtimeTooltip}>
              <AiOutlineQuestionCircle
                className="inline-block mr-2 cursor-pointer"
                transform="scale(-1,1)"
              />
            </span>
          </span>
          <span>{toPersian(bedtime)}</span>
        </div>
      </div>
      {isEditing && winddownCheck && (
        <Fragment>
          <DailyTimeInput
            label={nightStrings.sleepLabel(name)}
            value={sleep}
            onChange={onTimeChange}
          />
          {sleepValidation && (
            <DailySliderInput
              label={nightStrings.durationLabel(name)}
              value={duration}
              min={5}
              max={60}
              onChange={onSliderChange}
            />
          )}
        </Fragment>
      )}
      {!isEditing && sleep && (
        <NapSavedInfo sleep={sleep} duration={duration} />
      )}
      <div className="flex flex-col space-y-3 mt-6 pt-6 border-t border-navy">
        {feedings.length < 4 &&
          feedings.map((item, index) => (
            <div key={index} className="flex justify-between">
              <span
                className="cursor-pointer select-none"
                onClick={() => onClickFeeding(index)}
              >
                {feedingChecks[index] ? (
                  <FaCheckSquare className="inline-block ml-2" />
                ) : (
                  <FaRegSquare className="inline-block ml-2" />
                )}
                {nightStrings.feed(index + 1)}
              </span>
              <span>
                {toPersian(item.time)} (
                {toPersian(
                  item.duration === "Unlimited"
                    ? platformStrings.unlimit
                    : item.duration
                )}
                )
              </span>
            </div>
          ))}
        <div className="pt-6">
          {nightWakings.map((item, index) => (
            <div
              key={index}
              className="text-jungle-400 flex flex-col space-y-4 mb-6 pb-6 border-b border-navy"
            >
              <div className="flex justify-between font-semibold">
                <span>{nightStrings.nightWakingTitle(index + 1)}</span>
                {isEditing && (
                  <span
                    className="text-orange cursor-pointer"
                    onClick={() => onRemoveNightWaking(item.id)}
                  >
                    {platformStrings.remove}
                  </span>
                )}
              </div>
              <div className="flex justify-between text-sm">
                <span>{nightStrings.wokeUpTime}</span>
                <span>{toPersian(item.time)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>{nightStrings.fallSleepDuration}</span>
                <span>
                  {toPersian(item.duration)} {platformStrings.minutes}
                </span>
              </div>
            </div>
          ))}
        </div>
        {isEditing && sleep && (
          <div>
            <button
              onClick={onOpenNWModal}
              className="flex items-center justify-center w-full text-orange focus:outline-none"
            >
              <FaPlusCircle className="inline-block ml-2" />
              <span>{nightStrings.addNightWaking}</span>
            </button>
          </div>
        )}
        {nwModalOpen && (
          <NightWakingModal
            open={nwModalOpen}
            onClose={onCloseNWModal}
            formProps={{
              name,
              nightWakingTime,
              nightWakingSleepDuration,
              onAddNightWaking,
              onSliderChange: onNWSliderChange,
              onTimeChange: onNWTimeChange,
            }}
          />
        )}
      </div>
    </GlassBox>
  );
};

export default NightForm;
