import { platformStrings } from "data/platform/platformStrings";
import Modal from "react-modal";
import NightWakingForm, { NightWakingFormProps } from "./NightWakingForm";

export interface NightWakingModalProps {
  open: boolean;
  formProps: NightWakingFormProps;
  onClose: () => void;
}

const NightWakingModal: React.FC<NightWakingModalProps> = ({
  open,
  formProps,
  onClose,
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="w-full h-screen mx-auto bg-navy text-jasmine outline-none"
      overlayClassName="bg-transparent fixed inset-0 z-50"
    >
      <div className="w-full lg:w-1/3 mx-auto py-12 px-10 lg:px-0">
        <NightWakingForm {...formProps} />
        <button
          onClick={onClose}
          className="bg-gray-700 text-white py-1 px-4 mt-6 rounded shadow-md w-full"
        >
          {platformStrings.close}
        </button>
      </div>
    </Modal>
  );
};

export default NightWakingModal;
