import { toPersian } from "utils/raqam";
import { faBrand } from "../platformStrings";

export const nightFeedingStrings = {
  title: "خواب دزد شماره ۵: تغذیه شبانه",
  duration: "(زمان تقریبی جواب دادن: ۲ تا ۵ دقیقه)",
  hasFeedingLabel: (name?: string) =>
    `شب ها بعد از خوابوندن ${name} بهش شیر میدین؟`,
  weightLabel: (name?: string) => `الان وزن ${name} چقدره؟`,
  weightHint: (name?: string) =>
    `(اگه در ماه اخیر ${name} رو وزن نکردین، به مطب پزشک کودکان مراجعه و وزن دقیق رو وارد کنین)`,
  feedingCountLabel: (name?: string) =>
    `به جز شیر دادن وقت خواب، شب ها معمولا چند بار به ${name} شیر میدین؟`,
  feedingCountHint: (name?: string) =>
    `(بیشترین دفعاتی که در طول سه شب گذشته به ${name} شیر دادین رو انتخاب کنین.)`,
  firstFeedingLabel: (name?: string) =>
    `اولین شیردهی شبانه به  ${name} رو حدودا چه ساعتی انجام میدین؟`,
  firstFeedingDurationLabel: (name?: string) =>
    `اولین باری که ${name} در طول شب از خواب بیدار میشه، چه مقدار بهش شیر میدین؟`,
  FeedingDurationHint: (name?: string) =>
    ` (از زمانی محاسبه کنین که ${name} شیر خوردن رو شروع میکنه. اگه این عدد هر بار متفاوته، بیشترین مقدار رو در طول سه شب گذشته انتخاب کنین.)`,
  secFeedingLabel: (name?: string) =>
    `دومین شیردهی شبانه به  ${name} رو حدودا چه ساعتی انجام میدین؟`,
  secFeedingDurationLabel: (name?: string) =>
    `دومین باری که ${name} در طول شب از خواب بیدار میشه، چه مقدار بهش شیر میدین؟ `,
  thirdFeedingLabel: (name?: string) =>
    `سومین شیردهی شبانه به  ${name} رو حدودا چه ساعتی انجام میدین؟`,
  thirdFeedingDurationLabel: (name?: string) =>
    `سومین باری که ${name} در طول شب از خواب بیدار میشه، چه مقدار بهش شیر میدین؟ `,
  completedStarDesc: (name?: string) =>
    `همه چی مرتبه!  شما همه ۵ ستاره مربوط به تغذیه شبانه رو دریافت کردین!`,
  lowRateDesc: (rate: number, name?: string) =>
    `شما ${toPersian(
      rate
    )} ستاره از ۵ ستاره بخش تغذیه شبانه ${name} رو به دست آوردین. میخواین بدونین چطور ۵ ستاره رو به دست بیارین؟ در بخش "چه چیزی کمه" بهتون به طور دقیق نشون میدیم که چطور تغذیه شبانه رو درست کنین.`,
  letsMove: "حالا بریم روش های آموزش خواب رو یاد بگیریم.",
  frequentFeederTitle: "هشدار تغذیه زیاد:",
  frequentFeeder: `برنامه قطع تغذیه شبانه برای کودک شما کمی متفاوته! منتظر دریافت توضیحات مرتبط با این هشدار در ادامه ${faBrand} باشید.`,
  changeAlert:
    'برنامه شیردهی شبانه شما اصلاح شد. حتما توضیحات جدید مرتبط با این بخش رو در قسمت های "چه چیزی کمه" و "یادگیری عملی روش" ببینید',
  feedingTimeHint: "(زمان وارد شده بین ۱۸:۰۰ عصر تا ۸:۰۰ صبح باشه)",
};
