import PlatformContent from "components/layout/PlatfromContent";
import NextStep from "components/utils/NextStep";
import { selectBabyStrings } from "data/platform/selectBabyStrings";
import { IBaby } from "models/Baby";
import { useRecoilValue } from "recoil";
import { babyListState } from "recoil/atoms/babyList";

export interface SelectBabyProps {
  selected?: IBaby;
  onSelect: (baby: IBaby) => void;
  onNext: () => void;
}

const SelectBaby: React.FC<SelectBabyProps> = ({
  selected,
  onNext,
  onSelect,
}) => {
  const babyList = useRecoilValue(babyListState);
  return (
    <PlatformContent>
      <div className="flex flex-col space-y-6">
        <p className="text-jungle-400 text-2xl font-semibold">
          {selectBabyStrings.title}
        </p>
        <p className="text-justify">{selectBabyStrings.description}</p>
        <p className="text-justify text-orange">{selectBabyStrings.ageAlert}</p>
        <div className="flex flex-col space-y-4">
          {babyList.map((item) => (
            <p
              className={`border-2 rounded py-2 text-center  border-jasmine transition duration-200 cursor-pointer select-none ${
                selected?.id === item.id
                  ? "bg-jasmine text-navy"
                  : "text-jasmine hover:text-jungle-400 hover:border-jungle-400"
              }`}
              key={item.id}
              onClick={() => onSelect(item)}
            >
              {item.name}
            </p>
          ))}
        </div>
        <NextStep onNext={onNext} />
      </div>
    </PlatformContent>
  );
};

export default SelectBaby;
