import { useMutation, useQuery } from "@apollo/client";
import SleepStealersActivity from "components/sleep-stealers/activities";
import PageLoader from "components/utils/PageLoader";
import { activitiesErrors } from "data/platform/errors/activitiesErrors";
import { GET, STORE } from "graphql/queries/store";
import { IOption } from "models/Option";
import { IServerData } from "models/ServerData";
import { SleepStealersActivityData } from "models/SPA";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { boolToJson } from "utils/booleanParser";
import { parseSentData, parseStoreData, valueGetter } from "utils/storeParser";

const SleepStealersActivityScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [app, setApp] = useRecoilState(appState);
  const [spaData, setSpaData] = useState<SleepStealersActivityData>({
    activityList: [],
  });
  const [doneList, setDoneList] = useState<boolean>(false);
  const [formCompleted, setformCompleted] = useState<boolean>(false);
  const [rate, setRate] = useState<number | undefined>(undefined);
  const [locked, setLocked] = useState<boolean>(false);

  const onSingleChange = (value: number | string | boolean, key: string) => {
    if (locked) return;
    setSpaData({ ...spaData, [key]: value });
  };

  const onListAdd = (value: IOption) => {
    if (locked) return;
    const currList = spaData.activityList;
    const newList = [...currList, value];
    setSpaData({ ...spaData, activityList: newList });
  };

  const onListRemove = (value: IOption) => {
    if (locked) return;
    const currList = spaData.activityList;
    const newList = currList.filter((item) => item.value !== value.value);
    setSpaData({ ...spaData, activityList: newList });
  };

  const onListDone = () => {
    setDoneList(true);
  };

  const generateResult = () => {
    const errors = activitiesErrors(name);
    let missingItems = [];
    !spaData.hasActivity && missingItems.push(errors.hasActivity);
    !spaData.beforeNapActivity && missingItems.push(errors.beforeNapActivity);
    let finalRate = Number((((2 - missingItems.length) / 2) * 5).toFixed(0));
    if (spaData.hasActivity) {
      !spaData.inRoom && missingItems.push(errors.inRoom);
      spaData.activityList.filter(
        (item) => item.value === 10 || item.value === 13
      ).length !== 0 && missingItems.push(errors.activityList);
      !(spaData.duration === 3 || spaData.duration === 4) &&
        missingItems.push(errors.duration);
      finalRate = Number((((5 - missingItems.length) / 5) * 5).toFixed(0));
    }
    return {
      items: missingItems,
      rate: finalRate,
    };
  };

  const spaKeys: string[] = [
    "301",
    "302",
    "303",
    "304",
    "305",
    "spaLocked",
    "spaResult",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: spaKeys,
    },
    onCompleted: (data) => {
      const spaServerResult = parseStoreData(data);
      const spaState: SleepStealersActivityData = {
        hasActivity: valueGetter(spaServerResult, "301", true) as boolean,
        inRoom: valueGetter(spaServerResult, "302", true) as boolean,
        activityList: valueGetter(spaServerResult, "303")
          ? JSON.parse(valueGetter(spaServerResult, "303") as string)
          : [],
        duration: valueGetter(spaServerResult, "304")
          ? Number(valueGetter(spaServerResult, "304"))
          : undefined,
        beforeNapActivity: valueGetter(spaServerResult, "305", true) as boolean,
      };
      const lockedState = valueGetter(spaServerResult, "spaLocked") as boolean;
      const rateState = valueGetter(spaServerResult, "spaResult")
        ? JSON.parse(valueGetter(spaServerResult, "spaResult") as string).rate
        : undefined;
      rateState && setRate(Number(rateState));
      lockedState && setformCompleted(true);
      setLocked(lockedState);
      setSpaData(spaState);
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const rateState = JSON.parse(
        valueGetter(parseSentData(data), "spaResult") as string
      ).rate;
      if (rateState !== undefined) {
        setRate(rateState);
      }
      setformCompleted(true);
      setLocked(true);
      setApp({
        ...app,
        state: "/platform/sleep-stealers/habits",
      });
    },
  });

  const onFormCompleted = () => {
    const result = generateResult();
    const spaServerData: IServerData[] = [
      {
        key: "301",
        value: boolToJson(spaData.hasActivity),
      },
      {
        key: "302",
        value: boolToJson(spaData.inRoom),
      },
      {
        key: "303",
        value: JSON.stringify(spaData.activityList),
      },
      {
        key: "304",
        value: spaData.duration?.toString() || "",
      },
      {
        key: "305",
        value: boolToJson(spaData.beforeNapActivity),
      },
      {
        key: "spaLocked",
        value: boolToJson(true),
      },
      {
        key: "appState",
        value: JSON.stringify({
          ...app,
          state: "/platform/sleep-stealers/habits",
        }),
      },
      {
        key: "spaResult",
        value: JSON.stringify(result),
      },
    ];
    if (rate === undefined) {
      storeData({
        variables: {
          infant_id: id,
          data: spaServerData,
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [spaData, formCompleted, doneList]);

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <SleepStealersActivity
        formProps={{
          ...spaData,
          name,
          doneList,
          loading: storeLoading,
          locked,
          onFormCompleted,
          onListAdd,
          onListDone,
          onListRemove,
          onSingleChange,
        }}
        descProps={{ rate: rate || 0, name }}
        formCompleted={formCompleted}
        rate={rate}
      />
    </PageLoader>
  );
};

export default SleepStealersActivityScreen;
