import { useMutation, useQuery } from "@apollo/client";
import SleepStealersHabit from "components/sleep-stealers/habit";
import PageLoader from "components/utils/PageLoader";
import { habitErrors } from "data/platform/errors/habitErrors";
import { GET, STORE } from "graphql/queries/store";
import { IServerData } from "models/ServerData";
import { SliderValue } from "models/SliderValue";
import { SleepStealersHabitData } from "models/SPH";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { babyState } from "recoil/atoms/baby";
import { boolToJson } from "utils/booleanParser";
import { parseSentData, parseStoreData, valueGetter } from "utils/storeParser";

const SleepStealersHabitScreen: React.FC = () => {
  const { name, id } = useRecoilValue(babyState);
  const [app, setApp] = useRecoilState(appState);
  const [sphData, setSphData] = useState<SleepStealersHabitData>({
    againSleepActs: [],
    beforeNapActs: [],
    beforeSleepActs: [],
    awakeScale: 1,
  });
  const [doneFallingSleep, setDoneFallingSleep] = useState<boolean>(false);
  const [doneAgainSleep, setDoneAgainSleep] = useState<boolean>(false);
  const [doneFallingNap, setDoneFallingNap] = useState<boolean>(false);
  const [formCompleted, setFormCompleted] = useState<boolean>(false);
  const [rate, setRate] = useState<number | undefined>(undefined);
  const [locked, setLocked] = useState<boolean>(false);

  const actsErrors = (arr: number[]) => {
    const errors = habitErrors(name);
    let actErrors = [];
    arr.find((item) => item === 1) && actErrors.push(errors.beforeSleepActs1);
    arr.find((item) => item === 2) && actErrors.push(errors.beforeSleepActs2);
    arr.find((item) => item === 3) && actErrors.push(errors.beforeSleepActs3);
    arr.find((item) => item === 4) && actErrors.push(errors.beforeSleepActs4);
    arr.find((item) => item === 5) && actErrors.push(errors.beforeSleepActs5);
    arr.find((item) => item === 6) && actErrors.push(errors.beforeSleepActs6);
    arr.find((item) => item === 7) && actErrors.push(errors.beforeSleepActs7);
    return actErrors;
  };

  const generateResult = () => {
    const errors = habitErrors(name);
    let missingItems = [];
    const beforeSleepErrors = actsErrors(sphData.beforeSleepActs);
    sphData.fallingSleepDuration !== 1 &&
      missingItems.push(errors.fallingSleepDuration);
    const againSleepErrors = actsErrors(sphData.againSleepActs);
    const beforeNapErrors = actsErrors(sphData.beforeNapActs);
    sphData.fallingNapDuration !== 1 &&
      missingItems.push(errors.fallingSleepDuration);
    sphData.awakeScale > 4 && missingItems.push(errors.awakeScale);
    let allErrors = [
      ...missingItems,
      ...beforeSleepErrors,
      ...beforeNapErrors,
      ...againSleepErrors,
    ];
    const finalItems = allErrors.filter(
      (error, index, self) =>
        index === self.findIndex((t) => t.title === error.title)
    );
    let rawRate = 6;
    beforeSleepErrors.length > 0 && rawRate--;
    beforeNapErrors.length > 0 && rawRate--;
    againSleepErrors.length > 0 && rawRate--;
    sphData.fallingSleepDuration !== 1 && rawRate--;
    sphData.fallingNapDuration !== 1 && rawRate--;
    sphData.awakeScale > 4 && rawRate--;
    const finalRate = Number(((rawRate / 6) * 5).toFixed(0));
    return {
      items: finalItems,
      rate: finalRate,
    };
  };

  const sphKeys: string[] = [
    "401",
    "402",
    "403",
    "404",
    "405",
    "406",
    "sphLocked",
    "sphResult",
  ];

  const { loading, error, refetch } = useQuery(GET, {
    variables: {
      infant_id: id,
      keys: sphKeys,
    },
    onCompleted: (data) => {
      const sphServerResult = parseStoreData(data);
      const sphState: SleepStealersHabitData = {
        beforeSleepActs: valueGetter(sphServerResult, "401")
          ? JSON.parse(valueGetter(sphServerResult, "401") as string)
          : [],
        fallingSleepDuration: valueGetter(sphServerResult, "402")
          ? Number(valueGetter(sphServerResult, "402"))
          : undefined,
        againSleepActs: valueGetter(sphServerResult, "403")
          ? JSON.parse(valueGetter(sphServerResult, "403") as string)
          : [],
        beforeNapActs: valueGetter(sphServerResult, "404")
          ? JSON.parse(valueGetter(sphServerResult, "404") as string)
          : [],
        fallingNapDuration: valueGetter(sphServerResult, "405")
          ? Number(valueGetter(sphServerResult, "405"))
          : undefined,
        awakeScale: valueGetter(sphServerResult, "406")
          ? Number(valueGetter(sphServerResult, "406"))
          : 1,
      };
      const lockedState = valueGetter(sphServerResult, "sphLocked") as boolean;
      const rateState = valueGetter(sphServerResult, "sphResult")
        ? JSON.parse(valueGetter(sphServerResult, "sphResult") as string).rate
        : undefined;
      rateState && setRate(Number(rateState));
      lockedState && setFormCompleted(true);
      setLocked(lockedState);
      setSphData(sphState);
      if (rateState !== undefined) {
        setDoneAgainSleep(true);
        setDoneFallingNap(true);
        setDoneFallingSleep(true);
      }
    },
  });

  const [storeData, { loading: storeLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const rateState = JSON.parse(
        valueGetter(parseSentData(data), "sphResult") as string
      ).rate;
      if (rateState !== undefined) {
        setRate(rateState);
      }
      setFormCompleted(true);
      setLocked(true);
      setApp({
        ...app,
        state: "/platform/sleep-stealers/schedule",
      });
    },
  });

  const onFormCompleted = () => {
    const result = generateResult();
    const sphServerData: IServerData[] = [
      {
        key: "401",
        value: JSON.stringify(sphData.beforeSleepActs),
      },
      {
        key: "402",
        value: sphData.fallingSleepDuration?.toString() || "",
      },
      {
        key: "403",
        value: JSON.stringify(sphData.againSleepActs),
      },
      {
        key: "404",
        value: JSON.stringify(sphData.beforeNapActs),
      },
      {
        key: "405",
        value: sphData.fallingNapDuration?.toString() || "",
      },
      {
        key: "406",
        value: sphData.awakeScale.toString() || "",
      },
      {
        key: "sphLocked",
        value: boolToJson(true),
      },
      {
        key: "sphResult",
        value: JSON.stringify(result),
      },
      {
        key: "appState",
        value: JSON.stringify({
          ...app,
          state: "/platform/sleep-stealers/schedule",
        }),
      },
    ];
    if (rate === undefined) {
      storeData({
        variables: {
          infant_id: id,
          data: sphServerData,
        },
      });
    }
  };

  const onDoneAgainSleep = () => {
    setDoneAgainSleep(true);
  };

  const onDoneFallingNap = () => {
    setDoneFallingNap(true);
  };

  const onDoneFallingSleep = () => {
    setDoneFallingSleep(true);
  };

  const onSingleChange = (value: string | number | boolean, key: string) => {
    if (locked) return;
    setSphData({ ...sphData, [key]: value });
  };

  const onMultipleChange = (
    value: string | boolean | number,
    key: keyof SleepStealersHabitData
  ) => {
    if (locked) return;
    const currentList = sphData[key] as number[];
    const isExist = currentList.findIndex((item) => item === value);
    if (isExist > -1) {
      const newList = currentList.filter((item) => item !== value);
      setSphData({ ...sphData, [key]: newList });
      return;
    }
    const selectedNone = currentList.filter((item) => item === 8).length > 0;
    if (selectedNone && value !== 8) return;
    if (!selectedNone && currentList.length > 0 && value === 8) return;
    const newList = [...currentList, Number(value)];
    setSphData({ ...sphData, [key]: newList });
    return;
  };

  const onSlideChange = (value: SliderValue) => {
    if (locked) return;
    setSphData({ ...sphData, awakeScale: value.x });
  };

  useEffect(() => {
    if (sphData.beforeSleepActs.length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [
    sphData,
    doneAgainSleep,
    doneFallingNap,
    doneFallingSleep,
    formCompleted,
  ]);

  return (
    <PageLoader loading={loading} error={error} onRefetch={refetch}>
      <SleepStealersHabit
        formCompleted={formCompleted}
        formProps={{
          ...sphData,
          name,
          doneAgainSleep,
          doneFallingNap,
          doneFallingSleep,
          loading: storeLoading,
          locked,
          onDoneAgainSleep,
          onDoneFallingNap,
          onDoneFallingSleep,
          onFormCompleted,
          onMultipleChange,
          onSingleChange,
          onSlideChange,
        }}
        descProps={{
          name,
          rate: rate || 0,
        }}
        rate={rate}
      />
    </PageLoader>
  );
};

export default SleepStealersHabitScreen;
