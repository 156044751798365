import { faBrand } from "./platformStrings";

export const finishStrings = {
  afterTitle: `بعد از ${faBrand}`,
  afterDescription: (age: number, name?: string) =>
    `حالا که ${faBrand} رو تموم کردین، قصد داریم به شما اطلاعات بیشتری برای ادامه راه ${name} بدیم! نگاهی به اطلاعات زیر در مورد پسرفت خواب، به روز رسانی برنامه خواب ${
      age < 12 ? "و کاهش چرت ها به ۱ چرت" : ""
    } بندازین.`,
  disimprovementTitle: "پسرفت خواب",
  disimprovementDescription: (name?: string) =>
    `وقتی که برای مدتی ${name} خوب میخوابه، اما دوباره به مشکلات خواب برمیخوره، ممکنه دچار پسرفت خواب شده باشه. پسرفت خواب ممکنه علل مختلفی داشته باشه. توضیحات زیر رو در مورد علل اصلی این اتفاق مطالعه کنین.`,
  manageSleep: "چطوری پسرفت خواب رو مدیریت کنیم؟",
  manageSleepDesc1:
    "بچه ها معمولا از مسیر خواب مناسب خارج نمیشن، مگر اینکه یکی از چهار مورد زیر اتفاق بیفته: ",
  manageSleepitems: [
    "دارن دندون درمیارن.",
    "بیمارن.",
    "وارد مرحله جدیدی از رشد شدن",
    "در سفر هستن یا مهمون اومده (یا اتفاقی غیرمعمول مثل مواجه شدن با رعد و برق یا دل درد رو تجربه میکنن.) ",
  ],
  manageSleepDesc2:
    "قبل ازاینکه دست به کاری بزنین، مطمئن بشین که مشکل مربوط به دندون در آوردن یا بیماری نیست. اگه فرزندتون داره دندون درمیاره، مطمئن بشین که قبل از خواب یا در طول شب، از مسکن هایی که دکتر تجویز کرده استفاده کنین. اگه مطمئن شدید فرزند  تون وارد مرحله جدیدی از رشد نشده، و از غذای جامد استفاده میکنه، غذاهایی که در روزهایی که کودک مشکل خواب داشته مصرف کرده رو بررسی کنید و اگه اون روز غذای تازه ای خورده، تا ۴-۵ روز از اون غذا به کودک ندین. اگه بازم مشکل خواب تکرار شد، ممکنه دچار گاز معده یا واکنش خفیف به غذا شده باشه.",
  updatePlan: "به روز رسانی برنامه",
  updatePlanDesc1: (name?: string) =>
    `${faBrand} برنامه ای بر اساس سن برای ${name} ارائه میده. این برنامه در سنین زیر کمی متفاوته. `,
  updatePlanItems: ["۴-۵ ماه", "۶-۷ ماه", "۸-۹ ماه", "۱۰-۱۲ ماه", "۱۲-۱۸ ماه"],
  updatePlanDesc2: (name?: string) =>
    `هر بار که ${name} به یکی از سنین بالا میرسه، برنامه خواب باید به روز رسانی بشه. به ${faBrand} و بخش برنامه هدف یا راهنمای روزانه برین تا به برنامه جدید دسترسی پیدا کنین.`,
  reduceNaps: "کاهش چرت ها به ۱ چرت",
  reduceNapsDesc1: (name?: string) =>
    `وقتی ${name} به ۱۲ ماهگی برسه، وقتشه تا روزی یکبار چرت بزنه. ما شما رو راهنمایی میکنیم که چطور این کار رو انجام بدین. معمولا این تغییر بین ۱۲-۱۶ماهگی اتفاق می افته. برای اینکه مطمئن بشین ${name} آمادگی این تغییر رو داره، علائم زیر رو در نظر بگیرین:`,
  reduceNapsitems: [
    "بیشتر اوقات از چرت دوم امتناع میکنه",
    "ساعت چرت دوم اونقدر دیر شده که وقت خواب رو هم به شدت به تعویق انداخته.",
  ],
  reduceNapsDesc2: (name?: string) =>
    `وقتی علائم بالا رو دیدین، باید هر چند روز یکبار ۱۵-۲۰ دقیقه زمان چرت رو به تعویق بندازین تا شروع چرت زدن به حدود ساعت ۱۱ تا ۱۲ برسه. اگه بدون تنظیم زمان شروع چرت اول، چرت دوم رو حذف کنین، چرت زدن ${name} دچار مشکل میشه و در ساعت ۱۶ اون روز فاجعه رخ میده! تغییر ساعت چرت زدن باید به زمان یکسان بین بیداری صبحگاهی و شروع چرت زدن، با پایان چرت زدن و ساعت خواب شبانه ختم بشه. ساعت چرت اول رو به آرومی به سمت ۱۱:۳۰ حرکت بدین تا بدن ${name} رو شوکه نکنین و منجر به خستگی مفرط و ترشح هورمون کورتیزول که با خواب مبارزه میکنه نشین. اگه سریع پیش برین، فرزندتون خیلی کوتاه چرت میزنه. هنوز هم میتونین هر چند روز یکبار به سراغ دومین چرت برین (حرکت در ماشین و کالسکه هم مشکلی نداره) تا ${name} با این تغییر سازگار بشه. اما اگه چرت دوم جلوی خواب شبانه رو میگیره، کلا ازش صرف نظر کنین. وقتی چرت دوم رو حذف میکنین، ساعت خواب شبانه رو ۳۰-۶۰ دقیقه جلو بکشین تا کمبود خواب روز جبران بشه. در طول یک چرت، ${name} باید حدودا همونقدری بخوابه که در طول دو چرت میخوابید. پس اگه فرزندتون ۱ ساعت در طول روز و ۹۰ دقیقه در طول عصر چرت میزد، الان باید یه چرت به طول دو ساعت و نیم داشته باشه. این زمان میتونه با توجه به زمانای چرت زدن ${name} بین ۱:۳۰ تا ۲:۳۰ باشه.`,
};
