import { useState } from "react";
import ReactPlayer from "react-player";

const Player = (props) => {
  const [load, setLoad] = useState(false);

  return (
    <ReactPlayer
      url={props.link}
      controls={true}
      light={`/videos/${props.code}.jpg`}
      playing={load}
      onClickPreview={() => setLoad(true)}
      width="100%"
    />
  );
};

export default Player;
