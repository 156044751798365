import PlatformContent from "components/layout/PlatfromContent";
import { envStrings } from "data/platform/sleep-stealers/envStrings";
import StealerContainer from "../Container";
import EnvDescription, { EnvDescriptionProps } from "./Description";
import EnvForm, { EnvFormProps } from "./Form";

export interface SleepStealerEnvProps {
  formProps: EnvFormProps;
  descProps: EnvDescriptionProps;
  formCompleted: boolean;
  rate?: number;
}

const SleepStealerEnv: React.FC<SleepStealerEnvProps> = ({
  formProps,
  descProps,
  formCompleted,
  rate,
}) => {
  return (
    <PlatformContent>
      <StealerContainer title={envStrings.title} duration={envStrings.duration}>
        <EnvForm {...formProps} />
        {formCompleted && rate !== undefined && (
          <EnvDescription {...descProps} />
        )}
      </StealerContainer>
    </PlatformContent>
  );
};

export default SleepStealerEnv;
