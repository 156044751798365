export const enStep1Strings = {
  title: "چرت اضطراری:",
  description: (name?: string) =>
    `ظاهرا ${name} چرت نزده. برای اینکه فرزند دلبندتون زیادی خسته نشه، الان وقت "چرت اضطراریه". چرت اضطراری کمی متفاوته، چرا که برای جلوگیری از خستگی مفرط و گیج شدن ${name}، مجبور به تقلب هستیم. مراحل از این قرار هستن:`,
};

export const enStep2Strings = {
  title: "گام اول:",
  description1: (name?: string) => `اگه وقت شیر دادنه، به ${name} شیر بدین.`,
  description2: (name?: string) =>
    `نکته مهم: با شیر دادن ${name} رو نخوابونین. شیر خوردن یکی از عادات مهم خواب برای بچه هاست و میتونه آموزش خواب رو با مشکل مواجه کنه. `,
};

export const enStep3Strings = {
  title: "گام دوم:",
  description: (name?: string) =>
    `از حرکت برای خوابوندن ${name} استفاده کنین. شما میتونین از کالسکه یا کریر برای خوابوندن ${name} استفاده کنین (${name} رو صاف بخوابونین). اجازه بدین ${name} ۳۰ دقیقه بخوابه. `,
  importnantNote: "نکته مهم:",
  notes: (name?: string) => [
    `ما یکم اجازه تقلب دادیم، اما زیاده روی نکنین. از عادات خوابی که قصد تغییر اونا رو دارین استفاده نکنین. حرکت، با اینکه جز عادات خوابه، اما کم ضررترین روش خوابوندن ${name}ه. `,
    `اجازه ندین ${name} در مکان همیشگی بخوابه (مثلا اگه برنامه خواب رو در تختخواب و اتاق انجام میدین، چرت اضطراری رو در کالسکه در سالن انجام بدین.`,
    `بعد از ۳۰ دقیقه چرت اضطراری، اگه آخرین چرت روزه، اجازه بدین ${name} تا وقت خواب بیدار باشه. اما اگه روز سختی داشته، اشکالی نداره که 15-30 دقیقه زودتر بخوابه.`,
  ],
};

export const enStep4Strings = {
  title: "گام سوم:",
  description: (name?: string) =>
    `در قسمت " آزمایشگاه رویا" زمان چرت اضطراری رو ثبت کنین. آموزش چرت زدن ممکنه بیشتر از آموزش خواب طول بکشه. اما صرفا به این علت که ${name} گاهی به چرت اضطراری نیاز داره، از یادگیری خواب صرف نظر نکنین. بدون تمرین، نمیتونین انتظار داشته باشین که ${name} در طول روز مهارت آروم کردن خودش رو کسب کنه. حتی اگه به چرت اضطراری نیاز داشتین، لطفا چرت بعدی رو از یاد نبرین و برنامه خواب رو ادامه بدین.`,
  addNap: "افزودن چرت اضطراری",
};
