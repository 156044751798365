import { CgArrowDownO } from "react-icons/cg";
import loadingSVG from "images/platform/loading.svg";

export interface NextStepProps {
  loading?: boolean;
  onNext: () => void;
}

const NextStep: React.FC<NextStepProps> = ({ loading = false, onNext }) => {
  if (loading) {
    return <img src={loadingSVG} className="w-9 h-9 mx-auto my-8" alt="..." />;
  }

  return (
    <CgArrowDownO
      onClick={onNext}
      className="text-4xl mx-auto my-8 cursor-pointer"
    />
  );
};

export default NextStep;
