import { toPersian } from "utils/raqam";

export const scheduleStrings = {
  title: "خواب دزد شماره ۴: برنامه خواب",
  duration: "(زمان تقریبی جواب دادن: ۸ تا ۱۲ دقیقه)",
  sleepTimeLabel: (name?: string) => `شب ها، چه ساعتی ${name} رو میخوابونین؟`,
  sleepTimeHint: "(زمانی که اون رو توی تختخواب میذارین تا به خواب فرو بره.)",
  awakeTimeLabel: (name?: string) =>
    `صبح ها ${name} معمولا چه ساعتی از خواب بیدار میشه؟`,
  awakeTimeHint:
    "(اگه این ساعت مدام در حال تغییره، میانگین سه روز گذشته رو وارد کنین.)",
  napCountLabel: (name?: string) =>
    `در یک روز عادی، ${name} چند بار چرت میزنه؟`,
  napCountHint: "در صورتی که بیشتر از ۴ بار است، ۴ را انتخاب نمایید.",
  hasSitterLabel: (name?: string) =>
    `آیا ${name} روزها رو با پرستار یا مراقب میگذرونه و شما کنترلی روی چرت زدنش ندارین؟`,
  firstNapLabel: (name?: string) =>
    `معمولا اولین چرت صبحگاهی ${name} چه ساعتیه؟`,
  firstNapHint: "(بر اساس ساعتی که صبح ها از خواب بیدار میشه.)",
  firstNapAwakeLabel: (name?: string) =>
    `معمولا ${name} چه زمانی از اولین چرت صبحگاهی بیدار میشه؟`,
  secNapLabel: (name?: string) => `معمولا دومین چرت صبحگاهی ${name} چه ساعتیه؟`,
  secNapAwakeLabel: (name?: string) =>
    ` معمولا ${name} چه زمانی از دومین چرت صبحگاهی بیدار میشه؟`,
  thirdNapLabel: (name?: string) =>
    `معمولا سومین چرت صبحگاهی ${name} چه ساعتیه؟`,
  thirdNapAwakeLabel: (name?: string) =>
    ` معمولا ${name} چه زمانی از سومین چرت صبحگاهی بیدار میشه؟`,
  betweenNapLabel: (name?: string) =>
    `آیا معمولا ${name} بین ساعت چرت ها به خواب فرو میره؟ مثلا در ماشین، کالسکه، ننو یا آغوش شما؟`,
  completedRateDesc: (name?: string) =>
    `همه چی مرتبه!  شما همه ۵ ستاره مربوط به برنامه خواب رو دریافت کردین!`,
  lowRateDesc: (rate: number, name?: string) =>
    `شما ${toPersian(
      rate
    )} ستاره از ۵ ستاره برنامه خواب رو دریافت کردین. تنظیم برنامه خواب، واقعا میتونه به خواب بهتر ${name} کمک کنه! در بخش "چه چیزی کمه" بهتون به طور دقیق نشون میدیم که چه مواردی رو اصلاح کنید و چطور این کارو انجام بدین.`,
  letsMove: 'بریم سراغ خواب دزد آخر: "تغذیه شبانه"',
};
