import PlatformContent from "components/layout/PlatfromContent";
import { spMenu } from "data/menu";
import { platformStrings } from "data/platform/platformStrings";
import { NavLink } from "react-router-dom";

export interface SleepStealersProps {}

const SleepStealers: React.FC<SleepStealersProps> = () => {
  return (
    <PlatformContent>
      <div className="flex flex-col space-y-8">
        <p className="text-jungle-400 font-semibold text-2xl">
          {platformStrings.sleepStealers}
        </p>
        <div className="flex flex-col space-y-6">
          {spMenu.map((item, index) => (
            <NavLink
              key={index}
              to={item.link}
              className="text-center py-3 border-2 border-jasmine rounded-lg"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </PlatformContent>
  );
};

export default SleepStealers;
