import NightForm, { NightProps } from "components/daily/NightForm";
import { platformStrings } from "data/platform/platformStrings";
import { DailyServer, DailyServerNight } from "models/DailyServer";
import { INightWaking } from "models/NightWaking";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { addTime, getHour, minuteDiffer } from "utils/timeUtils";
import { v4 as uuidv4 } from "uuid";

export interface NightFormScreenProps extends NightProps {
  dayNumber: number;
  serverData: DailyServer;
  onSaveData: (data: DailyServer) => void;
}

const NightFormScreen: React.FC<NightFormScreenProps> = (props) => {
  const { serverData, onSaveData } = props;
  const [sleep, setSleep] = useState<string | undefined>(undefined);
  const [duration, setDuration] = useState<number>(5);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [winddownCheck, setWinddownCheck] = useState<boolean>(false);
  const [feedingChecks, setFeedingChecks] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [nwModalOpen, setNwModalOpen] = useState<boolean>(false);
  const [nightWakingTime, setNightWakingTime] = useState<string | undefined>(
    undefined
  );
  const [
    nightWakingSleepDuration,
    setNightWakingSleepDuration,
  ] = useState<number>(5);
  const [nightWakings, setNightWakings] = useState<INightWaking[]>([]);

  const onOpenNWModal = () => setNwModalOpen(true);

  const onCloseNWModal = () => setNwModalOpen(false);

  const onCheckWinddown = () => {
    setWinddownCheck(!winddownCheck);
  };

  const onClickFeeding = (index: number) => {
    const currVal = feedingChecks[index];
    const newFeedingChecks = Object.assign([], feedingChecks, {
      [index]: !currVal,
    });
    setFeedingChecks(newFeedingChecks);
  };

  const onTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSleep(value);
  };

  const onSliderChange = (value: SliderValue) => {
    setDuration(value.x);
  };

  const onNWTimeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setNightWakingTime(value);
  };

  const onNWSliderChange = (value: SliderValue) => {
    setNightWakingSleepDuration(value.x);
  };

  const onModifyServerData = () => {
    const nightServerData: DailyServerNight = {
      nightWakings,
      bedtime: sleep,
      duration: duration.toString(),
      feedings: feedingChecks,
      winddown: winddownCheck,
    };
    onSaveData({ ...serverData, night: nightServerData });
  };

  const onSave = () => {
    onModifyServerData();
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onAddNightWaking = () => {
    if (nightWakingTime && sleep) {
      let sleptTime = addTime(sleep, duration);
      if (nightWakings.length > 0) {
        sleptTime = addTime(
          nightWakings[nightWakings.length - 1].time,
          nightWakings[nightWakings.length - 1].duration
        );
      }
      let nwTime = addTime(nightWakingTime, nightWakingSleepDuration);
      let nw = getHour(nwTime);
      let st = getHour(sleptTime);
      if (
        nw < 8 ||
        nw > st ||
        (nw === st && minuteDiffer(sleptTime, nightWakingTime) > 0)
      ) {
        if (st < 8) {
          st = st + 24;
        }
        if (nw < 8) {
          nw = nw + 24;
        }
        if (
          nw > st ||
          (nw === st && minuteDiffer(sleptTime, nightWakingTime) > 0)
        ) {
          const nightServerData: DailyServerNight = {
            nightWakings: [
              ...nightWakings,
              {
                id: uuidv4(),
                time: nightWakingTime,
                duration: nightWakingSleepDuration,
              },
            ],
            bedtime: sleep,
            duration: duration.toString(),
            feedings: feedingChecks,
            winddown: winddownCheck,
          };
          onSaveData({ ...serverData, night: nightServerData });
          setNightWakings([
            ...nightWakings,
            {
              id: uuidv4(),
              time: nightWakingTime,
              duration: nightWakingSleepDuration,
            },
          ]);
          setNightWakingTime(undefined);
          setNightWakingSleepDuration(5);
          onCloseNWModal();
        } else {
          toast.error(platformStrings.timeError);
        }
      } else {
        toast.error(platformStrings.timeError);
      }
    }
  };

  const onRemoveNightWaking = (id: string) => {
    const newNWArr = nightWakings.filter((item) => item.id !== id);
    const nightServerData: DailyServerNight = {
      nightWakings: newNWArr,
      bedtime: sleep,
      duration: duration.toString(),
      feedings: feedingChecks,
      winddown: winddownCheck,
    };
    onSaveData({ ...serverData, night: nightServerData });
    setNightWakings(newNWArr);
    setNightWakings(newNWArr);
  };

  useEffect(() => {
    if (serverData && serverData.night) {
      setFeedingChecks(serverData.night.feedings || [false, false, false]);
      setDuration(Number(serverData.night.duration));
      setWinddownCheck(serverData.night.winddown || false);
      setSleep(serverData.night.bedtime);
      setNightWakings(serverData.night.nightWakings || []);
      setIsEditing(false);
    } else {
      setFeedingChecks([false, false, false]);
      setDuration(5);
      setWinddownCheck(false);
      setSleep(undefined);
      setNightWakings([]);
      setIsEditing(true);
    }
  }, [serverData, props.dayNumber]);

  return (
    <NightForm
      {...props}
      isEditing={isEditing}
      sleep={sleep}
      duration={duration}
      winddownCheck={winddownCheck}
      feedingChecks={feedingChecks}
      nightWakings={nightWakings}
      nightWakingTime={nightWakingTime}
      nightWakingSleepDuration={nightWakingSleepDuration}
      nwModalOpen={nwModalOpen}
      onAddNightWaking={onAddNightWaking}
      onCloseNWModal={onCloseNWModal}
      onNWSliderChange={onNWSliderChange}
      onNWTimeChange={onNWTimeChange}
      onOpenNWModal={onOpenNWModal}
      onRemoveNightWaking={onRemoveNightWaking}
      onClickFeeding={onClickFeeding}
      onCheckWinddown={onCheckWinddown}
      onSliderChange={onSliderChange}
      onTimeChange={onTimeChange}
      onSave={onSave}
      onEdit={onEdit}
    />
  );
};

export default NightFormScreen;
