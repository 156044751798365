import React from "react";
import { IFeatureItem } from "data/landing/features";

export interface ItemProps extends IFeatureItem {
  dir?: string;
}

const Item: React.FC<ItemProps> = ({
  title,
  icon,
  description,
  dir = "rtl",
}) => {
  return (
    <div className="border-2 border-gray-200 rounded-xl p-8 flex flex-row lg:flex-col transition duration-300 hover:border-opacity-0 hover:shadow-2xl select-none">
      <div className="w-1/3 self-center lg:w-full lg:h-28">
        <img src={icon} alt={title} className="mx-auto lg:w-24" />
      </div>
      <div
        className={`w-2/3 text-navy ${
          dir === "rtl" ? "text-right" : "text-left pl-2"
        } flex flex-col space-y-5 lg:w-full lg:text-center`}
      >
        <p className="text-xl font-bold">{title}</p>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
};

export default Item;
