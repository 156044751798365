export const whatsMissingStrings = {
  title: "چه چیزی کمه",
  needToChange: "اینجا مواردی رو ذکر کردیم که نیاز به تغییر دارن",
  description: (name?: string) =>
    `با توجه به ارزیابی ای که انجام دادین، در این قسمت به مواردی اشاره میشه که برای خواب مناسب ${name} نیازمند تغییر هستن. وقتی تغییرات رو اعمال کردین، موارد رو علامت بزنین تا در بخش "خواب دزد ها" ۵ ستاره کامل رو بگیرین.`,
  sleepEnv: "محیط خواب",
  routines: "فعالیت های قبل از خواب",
  sleepHabits: "عادت های قبل از خواب",
  sleepSchedule: "برنامه خواب",
  nightNoshing: "تغذیه شبانه",
};
