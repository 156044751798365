import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import VideoPlayer from "components/utils/VideoPlayer";
import { whenToBeginStrings } from "data/platform/whenToBeginStrings";
import { Fragment } from "react";
import ItemsModal from "./ItemsModal";

export interface WhenToBeginDescriptionProps {
  name?: string;
  allItems: string[];
  userItems: string[];
  openItemsFlag: boolean;
  resultSeen: boolean;
  isOK: boolean;
  loading: boolean;
  handleResultSeen: () => void;
  openItems: () => void;
  closeItems: () => void;
  onFinish: () => void;
}

const WhenToBeginDescription: React.FC<WhenToBeginDescriptionProps> = ({
  name,
  openItemsFlag,
  allItems,
  userItems,
  resultSeen,
  isOK,
  loading,
  openItems,
  closeItems,
  handleResultSeen,
  onFinish,
}) => {
  return (
    <Fragment>
      <div className="flex flex-col space-y-6">
        <PlatformSection>
          <PlatformDivider />
          {isOK && <p>{whenToBeginStrings.okDesc(name)}</p>}
          {!isOK && <p>{whenToBeginStrings.failedDesc(name)}</p>}
          {!isOK && (
            <ul className="list-disc list-inside pr-4">
              {userItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
          {!isOK && (
            <p className="text-justify">
              {whenToBeginStrings.allItems}{" "}
              <span
                onClick={openItems}
                className="font-semibold cursor-pointer underline text-jungle"
              >
                {whenToBeginStrings.clickHere}
              </span>
            </p>
          )}
          {!resultSeen && userItems.length === 0 && (
            <NextStep onNext={handleResultSeen} />
          )}
          {!resultSeen && userItems.length > 0 && (
            <div className="mt-6 flex flex-col space-y-6 px-3 py-4 text-justify bg-orange text-navy rounded-lg">
              <div>
                <p>{whenToBeginStrings.finalError}</p>
                <NextStep onNext={handleResultSeen} />
              </div>
            </div>
          )}
        </PlatformSection>
        {resultSeen && (
          <PlatformSection>
            <PlatformDivider />
            <p className="text-2xl font-semibold text-jungle-400 mb-6">
              {whenToBeginStrings.sleepStealers}
            </p>
            <p className="text-justify mb-4">
              {whenToBeginStrings.sleepStealersDesc}
            </p>
            <VideoPlayer code="3" />
            <NextStep onNext={onFinish} loading={loading} />
          </PlatformSection>
        )}
        {openItemsFlag && (
          <ItemsModal
            open={openItemsFlag}
            items={allItems}
            onClose={closeItems}
          />
        )}
      </div>
    </Fragment>
  );
};

export default WhenToBeginDescription;
