import { MorningBoxProps } from "components/sleep-plan/goal-schedule/MorningBox";
import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import { addTime } from "./timeUtils";

export const morningBoxDataGenerator = (
  age: number,
  bedtime_delay: number,
  name?: string
) => {
  const morning: MorningBoxProps = {
    name,
    title: goalScheduleStrings.morningTitle,
    startTime: addTime("06:00", bedtime_delay),
    label: goalScheduleStrings.wakeTimeLabel,
    hint: goalScheduleStrings.wakeTimeHint(name),
    wakeTime: addTime("07:00", bedtime_delay),
  };

  let nap1: MorningBoxProps = {
    name,
    title: goalScheduleStrings.napLabel(1),
    startTime: "10:45",
    endTime: "11:30",
    label: goalScheduleStrings.minCribTime,
    hint: goalScheduleStrings.minCribHint(name),
    wakeTime: "08:45",
  };

  let nap2: MorningBoxProps = {
    name,
    title: goalScheduleStrings.napLabel(2),
    startTime: "10:45",
    endTime: "11:30",
    label: goalScheduleStrings.minCribTime,
    hint: goalScheduleStrings.minCribHint(name),
    wakeTime: "08:45",
  };

  let nap3: MorningBoxProps = {
    name,
    title: goalScheduleStrings.flexNap,
    startTime: "10:45",
    endTime: "11:30",
    label: goalScheduleStrings.flexNap,
    hint: goalScheduleStrings.flexNapHint(name),
    wakeTime: "08:45",
  };

  if (age <= 5) {
    nap1 = {
      ...nap1,
      startTime: addTime("08:00", bedtime_delay),
      wakeTime: addTime("10:00", bedtime_delay),
      endTime: addTime("09:00", bedtime_delay),
    };
    nap2 = {
      ...nap2,
      startTime: addTime("11:30", bedtime_delay),
      wakeTime: addTime("13:30", bedtime_delay),
      endTime: addTime("12:30", bedtime_delay),
    };
    nap3 = {
      ...nap3,
      startTime: addTime("15:00", bedtime_delay),
      wakeTime: addTime("17:00", Math.min(bedtime_delay, 30)),
      endTime: addTime("16:00", bedtime_delay),
    };
    return [morning, nap1, nap2, nap3];
  }
  if (age <= 7 && age >= 6) {
    nap1 = {
      ...nap1,
      startTime: addTime("08:00", bedtime_delay),
      wakeTime: addTime("10:00", bedtime_delay),
      endTime: addTime("09:00", bedtime_delay),
    };
    nap2 = {
      ...nap2,
      startTime: addTime("11:30", bedtime_delay),
      wakeTime: addTime("13:30", bedtime_delay),
      endTime: addTime("12:30", bedtime_delay),
    };
    nap3 = {
      ...nap3,
      startTime: addTime("15:00", bedtime_delay),
      wakeTime: addTime("16:00", bedtime_delay),
      endTime: addTime("16:00", bedtime_delay),
    };
    return [morning, nap1, nap2, nap3];
  }
  if (age <= 9 && age >= 8) {
    nap1 = {
      ...nap1,
      startTime: addTime("08:30", bedtime_delay),
      wakeTime: addTime("10:30", bedtime_delay),
      endTime: addTime("09:30", bedtime_delay),
    };
    nap2 = {
      ...nap2,
      startTime: addTime("12:30", bedtime_delay),
      wakeTime: addTime("14:30", bedtime_delay),
      endTime: addTime("13:30", bedtime_delay),
    };
    return [morning, nap1, nap2];
  }
  if (age <= 12 && age >= 10) {
    nap1 = {
      ...nap1,
      startTime: addTime("09:00", bedtime_delay),
      wakeTime: addTime("11:00", bedtime_delay),
      endTime: addTime("10:00", bedtime_delay),
    };
    nap2 = {
      ...nap2,
      startTime: addTime("13:30", bedtime_delay),
      wakeTime: addTime("15:30", bedtime_delay),
      endTime: addTime("14:30", bedtime_delay),
    };
    return [morning, nap1, nap2];
  }

  nap1 = {
    ...nap1,
    startTime: addTime("11:00", bedtime_delay),
    wakeTime: addTime("13:30", bedtime_delay),
    endTime: addTime("12:30", bedtime_delay),
  };
  return [morning, nap1];
};
