import React from "react";
import { IMenuItem } from "data/landing/menu";

export interface NavItemProps extends IMenuItem {
  toggleMenu: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ title, url, toggleMenu }) => {
  return (
    <a
      href={url}
      className="px-3 py-2 rounded-md text-sm duration-150 text-navy hover:text-blue-800"
      onClick={toggleMenu}
    >
      {title}
    </a>
  );
};

export default NavItem;
