import { appStrings } from "./strings";
import sbPNG from "images/facilities/sb.svg";
import uxPNG from "images/facilities/ux.svg";
import returnPolicyPNG from "images/facilities/returnPolicy.svg";
import supportPNG from "images/facilities/support.svg";

export interface IFacility {
  icon: string;
  title: string;
  subtitle: string;
}

export const facilityList = (lang: string): IFacility[] => [
  {
    icon: sbPNG,
    title: appStrings.sb[lang],
    subtitle: appStrings.sbDesc[lang],
  },
  {
    icon: uxPNG,
    title: appStrings.ux[lang],
    subtitle: appStrings.uxDesc[lang],
  },
  {
    icon: returnPolicyPNG,
    title: appStrings.returnPolicy[lang],
    subtitle: appStrings.returnPolicyDesc[lang],
  },
  {
    icon: supportPNG,
    title: appStrings.support[lang],
    subtitle: appStrings.supportDesc[lang],
  },
];
