import { IOption } from "models/Option";
import { platformStrings } from "./platformStrings";
import { whenToBeginStrings } from "./whenToBeginStrings";

export const acts: IOption[] = [
  {
    label: whenToBeginStrings.rolling,
    value: "rolling",
  },
  {
    label: whenToBeginStrings.crawling,
    value: "crawling",
  },
  {
    label: whenToBeginStrings.standing,
    value: "standing",
  },
  {
    label: whenToBeginStrings.walking,
    value: "walking",
  },
  {
    label: platformStrings.none,
    value: "none",
  },
];
