import { toPersian } from "utils/raqam";

export const scheduleErrors = (
  napCount: number,
  age: number,
  a: number,
  b: number,
  firstNapTime: number,
  name?: string
) => ({
  sleepTimeMore: {
    title: `${name} رو بین ساعت ۷ تا ۸ شب در تختخواب قرار بدین`,
    description: `${name} خیلی دیر میخوابه، اگه ${name} زیادی خسته بشه، به سه روش روی خواب اثر منفی میذاره. ۱) ممکنه خوابش نبره ۲) ممکن بیشتر از خواب بپره ۳) ممکنه صبح خیلی زود از خواب بیدار بشه. برای اینکه ${name} بیش از اندازه خسته نشه، وقت خواب رو بین ساعت ۷ تا ۸ شب تنظیم کنین.`,
    more: `وقتی بچه ها زیادی خسته بشن، هورمونی به اسم کورتیزول ترشح میکنن. کورتیزول در بدن اثر تحریک کننده ای داره، برای همین به خواب فرو رفتن یا خواب راحت رو با مشکل مواجه میکنه. اگه معمولا بیشتر از ۱۵ دقیقه طول میکشه تا ${name} به خواب بره، در طول شب از خواب میپره و صبح ها زود از خواب بیدار میشه و در واقع کمتر از ۱۱ ساعت میخوابه، احتمال به خستگی مفرط و ترشح هورمون کورتیزول دچار شده. اما خبر خوب اینکه خواب، خواب میاره! یعنی وقتی ${name} یاد بگیره خوب بخوابه، بیشتر و بهتر میخوابه!`,
  },
  sleepTimeLess: {
    title: `${name} رو بین ساعت ۷ تا ۸ شب در تختخواب قرار بدین`,
    description: `${name} خیلی زود میخوابه، اگه ${name} خیلی کم خسته بشه، به دو روش روی خواب اثر منفی میذاره.  ۱) ممکنه خوابش نبره ۲) ممکنه صبح خیلی دیر از خواب بیدار بشه. برای اینکه ${name} به اندازه خسته بشه، وقت خواب رو بین ساعت ۷ تا ۸ شب تنظیم کنین.`,
    more: ``,
  },
  awakeTimeLess: {
    title: `از وقتی ${name} رو در تختخواب قرار میدین، باید حداقل ۱۱ ساعت بخوابه`,
    description: `در طول شب ${name} باید ۱۱ تا ۱۲ ساعت بخوابه تا خواب کافی یا به اصطلاح "خواب مغذی" داشته باشه.`,
    more: `"خواب مغذی" به سه دلیل حائز اهمیته: خواب کافی برای رشد شناختی، اجتماعی، جسمی و عاطفی ${name} ضروریه. وقتی ${name} خوب بخوابه، شما هم خواب راحتی خواهید داشت! در نتیجه میتونین پدر و مادر بهتری باشن و اگه بیرون از منزل کار میکنین، در شغل تون عملکرد بهتری داشته باشین. وقتی ${name} خوب بخوابه، برنامه قابل پیشبینی خواهد داشت و شما هم فرصت بیشتری برای گذران وقت با همسرتون خواهید داشت. استراحت و بهبود روابط زوجین، کلید پدر و مادری موفق بودن و داشتن خانوده ای شاد هست.`,
  },
  awakeTimeMore: {
    title: `از وقتی ${name} رو در تختخواب قرار میدین، نباید بیشتر از  ۱۲ ساعت بخوابه`,
    description: `در طول شب ${name} باید ۱۱ تا ۱۲ ساعت بخوابه تا خواب کافی یا به اصطلاح "خواب مغذی" داشته باشه. بیشتر خوابیدن ${name} باعث میشه که چرت زدن های روزانه ش بهم بریزه `,
    more: `"خواب مغذی" به سه دلیل حائز اهمیته: خواب کافی برای رشد شناختی، اجتماعی، جسمی و عاطفی ${name} ضروریه. وقتی ${name} خوب بخوابه، شما هم خواب راحتی خواهید داشت! در نتیجه میتونین پدر و مادر بهتری باشین و اگه بیرون از منزل کار میکنین، در شغل تون عملکرد بهتری داشته باشین. وقتی ${name} خوب بخوابه، برنامه قابل پیشبینی خواهد داشت و شما هم فرصت بیشتری برای گذران وقت با همسرتون خواهید داشت. استراحت و بهبود روابط زوجین، کلید پدر و مادری موفق بودن و داشتن خانوده ای شاد هست`,
  },
  napCountLess: {
    title: `در طول روز، ${name} باید ${toPersian(napCount)} بار چرت بزنه`,
    description: `${name} به اندازه کافی نمیخوابه. در ${toPersian(
      age
    )} ماهگی او باید ${toPersian(napCount)} بار به مدت ${toPersian(
      a
    )} تا ${toPersian(b)} ساعت چرت بزنه. چرت ${
      age < 8 ? "اول و دوم" : "ها"
    } باید هر کدوم یک ساعت طول بکشن تا ${name} خواب مغذی کافی رو دریافت کنه و زیادی خسته نشه. ${
      age < 8
        ? `اگه ${name} در چرت اول و دوم${
            age < 6 ? "۳" : "۲:۳۰"
          } ساعت خوابیده باشه، چرت سوم میتونه تنها ۳۰ دقیقه طول بکشه.`
        : ""
    } اگه به علت چرت ناکافی، شب ${name} زیادی خسته باشه، ممکنه نتونه به راحتی برای خواب شبانه و چرت روزانه آماده بشه ، مدام از خواب بپره و صبح زودتر از خواب بیدار بشه. وقتی شروع به آموزش روند خواب میکنین، ما در مورد زمانبندی ایده آل و طول مدت هر چرت به طور روزانه شما رو راهنمایی می کنیم.`,
    more: `وقتی بچه ها زیادی خسته بشن، هورمونی به اسم کورتیزول ترشح میکنن. کورتیزول در بدن اثر تحریک کننده ای داره، برای همین به خواب فرو رفتن یا خواب راحت رو با مشکل مواجه میکنه. اگه معمولا بیشتر از ۱۵ دقیقه طول میکشه تا ${name} به خواب بره، در طول شب از خواب میپره و صبح ها زود از خواب بیدار میشه و در واقع کمتر از ۱۱ ساعت میخوابه، احتمال به خستگی مفرط و ترشح هورمون کورتیزول دچار شده. اما خبر خوب اینکه خواب، خواب میاره! یعنی وقتی ${name} یاد بگیره خوب بخوابه، بیشتر و بهتر میخوابه!`,
  },
  napCountMore: {
    title: `در طول روز، ${name} باید ${toPersian(napCount)} بار چرت بزنه`,
    description: `${name} بیش از اندازه میخوابه. در ${toPersian(
      age
    )} ماهگی او باید ${toPersian(napCount)} بار به مدت ${toPersian(
      a
    )} تا ${toPersian(b)} ساعت چرت بزنه. چرت ${
      age < 8 ? "اول و دوم" : "ها"
    } باید هر کدوم یک ساعت طول بکشن تا ${name} خواب مغذی کافی رو دریافت کنه و زیادی خسته نشه. ${
      age < 8
        ? `اگه ${name} در چرت اول و دوم${
            age < 6 ? "۳" : "۲:۳۰"
          } ساعت خوابیده باشه، چرت سوم میتونه تنها ۳۰ دقیقه طول بکشه.`
        : ""
    } اگه به علت چرت بیش از حد، شب ${name} به مقدار کافی خسته نباشه، ممکنه نتونه به راحتی برای خواب شبانه و چرت روزانه آماده بشه و صبح دیرتر از خواب بیدار بشه. وقتی شروع به آموزش روند خواب میکنین، ما در مورد زمانبندی ایده آل و طول مدت هر چرت به طور روزانه شما رو راهنمایی می کنیم.`,
    more: ``,
  },
  hasSitter: { title: ``, description: ``, more: `` },
  firstNapLess: {
    title: `دقیقا ${toPersian(
      firstNapTime
    )} ساعت بعد از بیدار شدن ${name} در صبح، اولین چرت رو شروع کنین`,
    description: `اولین چرت ${name} باید دقیقا ${toPersian(
      firstNapTime
    )} ساعت بعد از بیدار شدن در صبح شروع بشه. اگه زودتر اون رو بخوابونین، ممکنه خسته نباشه و به سختی به خواب بره. در ضمن این کار باعث میشه وارد چرخه خواب زودهنگام بشه و در پایان روز و هنگام شب نتونه بخوابه.`,
    more: ``,
  },
  firstNapMore: {
    title: `دقیقا ${toPersian(
      firstNapTime
    )} ساعت بعد از بیدار شدن ${name} در صبح، اولین چرت رو شروع کنین`,
    description: `اولین چرت ${name} باید دقیقا ${toPersian(
      firstNapTime
    )} ساعت بعد از بیدار شدن در صبح شروع بشه. اگه دیرتر اون رو بخوابونین، ممکنه  خیلی خسته باشه و دیر از خواب بیدار بشه. در ضمن این کار باعث میشه وارد چرخه خواب دیرهنگام بشه و در پایان روز و هنگام شب نتونه به موقع بخوابه.`,
    more: ``,
  },
  firstNapAwake: { title: ``, description: ``, more: `` },
  secNap: { title: ``, description: ``, more: `` },
  secNapAwake: { title: ``, description: ``, more: `` },
  thirdNap: { title: ``, description: ``, more: `` },
  thirdNapAwake: { title: ``, description: ``, more: `` },
  betweenNaps: {
    title: ` هنگام استفاده از آموزش خواب، باید از خواب های خارج از برنامه پرهیز کنین`,
    description: `اگه ${name} بین ساعات چرت زدن هم بخوابه، دیگه وقت چرت زدن خواب آلود نیست. این کار حتی اگر ۵ دقیقه هم طول بکشه، خواب بعدی رو خراب میکنه! در طول آموزش خواب، خیلی مهمه که مطمئن بشین ${name} خارج از برنامه نمیخوابه. پس ۳۰ دقیقه قبل از زمان مورد نظر، ${name} رو در ننو، تاب، ماشین یا کالسکه قرار ندین. بله، موقتا کمی دست و بالتون بسته میشه، اما وقتی ${name} حرفه ای شد، میتونین با خیال راحت برین بیرون. `,
    more: ``,
  },
});
