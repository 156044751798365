import Intro from "components/intro";
import { Redirect } from "react-router";
import { useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";

const IntroScreen: React.FC = () => {
  const app = useRecoilValue(appState);

  if (app.isEvaluated) {
    return <Redirect to="/platform/master-sleep-plan" />;
  }

  return <Intro />;
};

export default IntroScreen;
