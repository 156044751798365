import PlatformContent from "components/layout/PlatfromContent";
import NextStep from "components/utils/NextStep";
import { planIntroStrings } from "data/platform/planIntroStrings";
import whatsMissingIcon from "images/platform/whats-missing.png";
import dailyGuideIcon from "images/platform/daily-guide.png";
import methodIcon from "images/platform/method.png";
import goalScheduleIcon from "images/platform/goal-schedule.png";
import PartItem from "./PartItem";
import PlatformDivider from "components/utils/PlatformDivider";
import NextLink from "components/utils/NextLink";
import PlatformSection from "components/utils/PlatformSection";

export interface PlanIntroProps {
  name?: string;
  method?: string;
  step: number;
  onNextStep: (currStep: number) => void;
}

const PlanIntro: React.FC<PlanIntroProps> = ({
  step,
  name,
  method,
  onNextStep,
}) => {
  return (
    <PlatformContent>
      <div className="leading-loose">
        <p className="text-jungle font-semibold text-2xl">
          {planIntroStrings.title}
        </p>
        <p className="my-8">{planIntroStrings.introDescription(name)}</p>
        {step < 2 && <NextStep onNext={() => onNextStep(1)} />}
        {step > 1 && (
          <PlatformSection isPassed={step > 2}>
            <PlatformDivider />
            <PartItem
              title={planIntroStrings.whatsMissing}
              description={planIntroStrings.whatsMissingDescription}
              icon={whatsMissingIcon}
              onNext={() => onNextStep(2)}
              isPassed={step < 3}
            />
          </PlatformSection>
        )}
        {step > 2 && (
          <PlatformSection isPassed={step > 3}>
            <PlatformDivider />
            <PartItem
              title={planIntroStrings.methodInAction(method)}
              description={planIntroStrings.methodInActionDescription(method)}
              icon={methodIcon}
              isPassed={step < 4}
              onNext={() => onNextStep(3)}
            />
          </PlatformSection>
        )}
        {step > 3 && (
          <PlatformSection isPassed={step > 4}>
            <PlatformDivider />
            <PartItem
              title={planIntroStrings.goalSchedule}
              description={planIntroStrings.goalScheduleDescription(name)}
              icon={goalScheduleIcon}
              isPassed={step < 5}
              onNext={() => onNextStep(4)}
            />
          </PlatformSection>
        )}
        {step > 4 && (
          <PlatformSection isPassed={step > 5}>
            <PlatformDivider />
            <PartItem
              title={planIntroStrings.dailyGuide}
              description={planIntroStrings.dailyGuideDescription}
              icon={dailyGuideIcon}
              isPassed={step < 6}
              onNext={() => onNextStep(5)}
            />
          </PlatformSection>
        )}
        {step > 5 && (
          <PlatformSection isPassed={step > 6}>
            <PlatformDivider />
            <p className="my-16">{planIntroStrings.timeTogetPlan}</p>
            {step < 7 && <NextStep onNext={() => onNextStep(6)} />}
          </PlatformSection>
        )}
        {step > 6 && (
          <PlatformSection>
            <PlatformDivider />
            <p className="my-16">{planIntroStrings.drumroll}</p>
            <NextLink to="/platform/whats-missing" />
          </PlatformSection>
        )}
      </div>
    </PlatformContent>
  );
};

export default PlanIntro;
