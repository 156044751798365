import { dailyStrings } from "data/platform/daily/dailyStrings";
import ContentWrapper from "../EmergencyNapDescriptions/ContentWrapper";
import AlertModal, { AlertModalProps } from "./AlertModal";

export interface WokeEarlyProps extends AlertModalProps {
  name?: string;
}

const WokeEarly: React.FC<WokeEarlyProps> = (props) => {
  const { name } = props;
  return (
    <AlertModal {...props}>
      <ContentWrapper
        title={dailyStrings.wokeUpEarlyTitle(name)}
        description={dailyStrings.wokeUpEarly(name)}
      >
        <p>{dailyStrings.wokeUpEarlyNote(name)}</p>
      </ContentWrapper>
    </AlertModal>
  );
};

export default WokeEarly;
