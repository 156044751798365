export const methodInActionStrings = {
  title: (method?: string) => `یادگیری عملی ${method}`,
  videoStepByStep: "راهنمای ویدئویی گام به گام",
  videoDescription:
    "اینجا میتونین تمام ویدئوهای راهنمای مخصوص خودتون رو پیدا کنین",
  method: (method?: string) => `${method} - گام به گام`,
  methodDescription: "روش منتخب شما به صورت گام به گام بهتون توضیح داده میشه",
  nightAwaking: "بیداری های شبانه",
  nightAwakingDescription: "چطور روند آموزش خواب رو در طول شب ادامه بدین.",
  nightFeeding: "شیر دادن در شب",
  nightFeedingDescription: (name?: string) =>
    `چطور با ملایمت و به آرومی تغذیه شبانه ${name} رو متوقف کنیم`,
  goodMorning: "صبح بخیر! ",
  goodMorningDescription: "شروع روز چطور روی خواب تاثیر میذاره",
  naps: "چرت ها",
  napsDescription: "در آموزش خواب، خواب روزانه چه تفاوتی با خواب شبانه داره.",
  bonusDescription: "ویدئوی هدیه: اضطراب جدایی",
  finalDescription:
    "این ویدیو ها در بخش راهنمای روزانه همچنان در دسترس خواهند بود",
  ffAlertTitle: `هشدار تغذیه زیاد`,
  ffAlertDescription: `برنامه قطع تغذیه شبانه برای کودک شما کمی متفاوته! برای توضیحات بیشتر روی خلاصه ویدئو کلیک کنید.`,
};
