export const wtbErrors = (name?: string) => ({
  tooth: `${name} در حال دندون درآوردن نباشه.`,
  act: "هیجان و اضطراب ناشی از هر اتفاقی رو پشت سر گذاشته باشین.",
  sitterJob:
    "پرستار کودکتون اخیراً سر کارش نیومده یا اینکه نمیخواد به کارش ادامه بده، طوری که برای این تغییر نیاز به زمان دارید.",
  familyTrip:
    "تغییرات بزرگی مثل جابجایی، سفر یا از دست دادن اعضای خانواده براتون رخ نداده و یا نخواهد داد.",
  illness: `هم شما و هم کودکتون در سلامت کامل به سر می برین (لطفا اگه مشکلی وجود داره، با پزشک مربوطه مشورت کنین.)`,
  reflax: `هم شما و هم کودکتون در سلامت کامل به سر می برین (لطفا اگه مشکلی وجود داره، با پزشک مربوطه مشورت کنین.)`,
  medical: `هم شما و هم کودکتون در سلامت کامل به سر می برین (لطفا اگه مشکلی وجود داره، با پزشک مربوطه مشورت کنین.)`,
  breastWorry: `اگر کودکتون شیر مادر می‌خوره و نگران میزان شیردهی تون هستید، بهتره برای افزایش شیرتون در طول روز، قبل از شروع برنامه خواب و از شیر گرفتن ${name} در طول شب، با پزشک اطفال یا متخصص شیردهی مشورت کنین.`,
  hardSituation: `می‌تونید به مدت یک یا دو هفته کارای دیگه تونو محدود یا متوقف کنید- هم کارای روزانه و هم شبانه- تا بتونید روی فرآیند یادگیری کودکتون تمرکز بیشتری داشته باشید`,
});
