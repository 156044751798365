import NextLink from "components/utils/NextLink";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { envStrings } from "data/platform/sleep-stealers/envStrings";
import { Fragment } from "react";
import StealersRate from "../StealersRate";

export interface EnvDescriptionProps {
  rate: number;
  name?: string;
}

const EnvDescription: React.FC<EnvDescriptionProps> = ({ rate, name }) => {
  return (
    <PlatformSection>
      <PlatformDivider />
      <StealersRate rate={rate} />
      {rate === 5 ? (
        <p>{envStrings.completeStarDesc(name)}</p>
      ) : (
        <Fragment>
          <p className="text-justify">{envStrings.lowStar(rate, name)}</p>
          <p className="text-justify mt-2">{envStrings.lowStarDesc(name)}</p>
        </Fragment>
      )}
      <p className="mt-2">{envStrings.letsMove}</p>
      <NextLink to="/platform/sleep-stealers/activities" />
    </PlatformSection>
  );
};

export default EnvDescription;
