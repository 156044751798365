export const faBrand = "مدرسه خواب";
export const enBrand = "Dreamlab";

export const appStrings: any = {
  brand: {
    fa: faBrand,
    en: enBrand,
  },
  login: {
    fa: "ورود / ثبت نام",
    en: "",
  },
  enterDreamlab: {
    fa: `ورود به ${faBrand}`,
    en: "",
  },
  menu: {
    fa: "منو",
    en: "Menu",
  },
  register: {
    fa: "ثبت نام",
    en: "Sign Up",
  },
  visitWay: {
    fa: "روش بازدید",
    en: "Body temperature",
  },
  touchWay: {
    fa: "روش لمس کردن",
    en: "Body temperature",
  },
  stayWay: {
    fa: "روش ماندن",
    en: "Body temperature",
  },
  purchase: {
    fa: "خرید کنید",
    en: "",
  },
  surePurchase: {
    fa: "آیا از خرید خود مطمئن هستید؟",
    en: "",
  },
  purchaseNote1: {
    fa: `${faBrand} برای کودکان ۴ تا ۱۸ ماهه طراحی شده`,
    en: "",
  },
  purchaseNote2: {
    fa: `در صورت عدم رضایت، تا ۱۴ روز می تونید درخواست بازگشت وجه رو در سامانه ثبت کنید.`,
    en: "",
  },
  price: { fa: "مبلغ", en: "" },
  toman: { fa: "تومان", en: "" },
  pay: { fa: "پرداخت", en: "" },
  close: {
    fa: "بستن",
    en: "Close",
  },
  heroSubtitle: {
    fa: "راه حل شخصی سازی شده برای نوزاد شما",
    en: "A gadget for your baby's health",
  },
  heroTitle: {
    fa: `خواب بهتر از امشب شروع میشه`,
    en: `${enBrand}, The voice of your baby`,
  },
  heroDesc: {
    fa: `کوچولوی شما تو خوابیدن مشکل داره؟ ${faBrand} میتونه به شما کمک کنه! ${faBrand} برای کودکان ۴ تا ۱۸ ماهه طراحی شده و بهتون روش های مطمئن و آسونی ارائه میده تا کودکتون خواب سالم و کافی داشته باشه و شادی و آرامش رو برای خانواده شما به ارمغان میاره. تو ${faBrand} به کمک بهترین متخصصان خواب کودک، طی ۷ تا ۱۵ روز به صورت تضمینی خواب بهتر رو تجربه خواهید کرد!`,
    en:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis",
  },
  introSubtitle: {
    fa: "راهنمایی‌های مخصوص به خودتون رو بگیرید",
    en: "A smart solution for your baby's health",
  },
  introTitle: {
    fa: `برنامه مخصوص کوچولوی شما`,
    en: `Smart and small ${enBrand} gadget can be implemented on infant belly easily and withour disturbing`,
  },
  introDesc: {
    fa: `به کمک ارزیابی های دقیق و راهنمایی های روزانه مخصوص به خودتون، ${faBrand} برای شما کاری رو انجام میده که کتاب ها، دوره های مختلف و توصیه های متناقضی که از جاهای مختلف میشنوید هیچ وقت نمیتونن: متناسب با ویژگی های مختص کودک خودتون بهش یاد بدید خودش خودشو بخوابونه!`,
    en:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis",
  },
  before: {
    fa: `قبل از ${faBrand}`,
    en: "",
  },
  after: {
    fa: `بعد از ${faBrand}`,
    en: "",
  },
  featuresTitle: {
    fa: "ویژگی ها",
    en: "Features",
  },
  featuresSubtitle: {
    fa: `ویژگی‌های اصلی و بارز ${faBrand}`,
    en: "Lorem ipsum dolor sit amet",
  },
  featureContact: {
    title: {
      fa: "ارتباط با کارشناسان پشتیبان",
      en: "",
    },
    description: {
      fa:
        "تو هر مرحله ای می تونید سوالات و مشکلاتتون رو با پشتیبان ها مطرح کنید و در اسرع وقت جواب رو دریافت کنید",
      en: "",
    },
  },
  featureTutorial: {
    title: {
      fa: "آموزش ویدیومحور",
      en: "",
    },
    description: {
      fa:
        "برای درک بهتر و دقیق، هر کدوم از آموزش ها توسط کارشناسان ما در قالب محتوای ویدیویی در اختیار شما قرار میگیره",
      en: "",
    },
  },
  featurePersonalize: {
    title: {
      fa: "شخصی سازی برنامه خواب نوزاد",
      en: "",
    },
    description: {
      fa:
        "برنامه خواب هر نوزاد بر اساس اطلاعات وارد شده توی پروفایل اختصاصی خودش آماده و بروز میشه",
      en: "",
    },
  },
  featureExpert: {
    title: {
      fa: "نتیجه سال ها تجربه متخصصان",
      en: "",
    },
    description: {
      fa:
        "ارائه روش هایی که بیش از ۵۰۰۰۰۰ خانواده در سراسر دنیا ازشون استفاده کردن و نتیجه گرفتن",
      en: "",
    },
  },
  musicTitle: {
    fa: `روند پیشرفتتون ارزیابی میشه`,
    en: "Songs for your baby's sweet sleep",
  },
  musicSubtitle: {
    fa: "موقع یادگیری تنهاتون نمیذاریم",
    en: "A smart solution for your baby's health",
  },
  musicDesc: {
    fa: `تو فرایند یادگیری خواب، گاهی اوقات بعضی چیزا طبق برنامه پیش نمیره؛ اما راهنمایی های روزانه، ویدیوهای آموزشی همیشه در دسترس و حضور دلگرم کننده کارشناسان ${faBrand} به شما کمک می کنن که تو هر نقطه از مسیر یادگیری روند رو ارزیابی کنید و این طوری موفقیتتون تضمین میشه`,
    en:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis",
  },
  whyTitle: {
    fa: `تو سامانه ${faBrand} چه چیزی در انتظار شماست؟`,
    en: `Why ${enBrand}?`,
  },
  whyDesc: {
    fa: "چرا به محصول ما نیاز دارید",
    en: "Lorem ipsum dolor sit amet",
  },
  estimation: {
    title: {
      fa: "ارزیابی کامل از شرایط  فعلی",
      en: "",
    },
    desc: {
      fa:
        "قبل از هر چیزی لازمه بدونیم الان زمان مناسبی برای یادگیری برنامه خواب هست یا نه. پس اول  شرایط شما و کوچولوتون از لحاظ آمادگی برای شروع یادگیری بررسی میشه و بعدش با توجه به آمادگی نوزاد شما روند کار شروع میشه",
      en: "",
    },
  },
  obstacles: {
    title: {
      fa: "برطرف کردن موانع خواب",
      en: "",
    },
    desc: {
      fa:
        "بعد از ارزیابی شرایط نوبت خواب دزد هاست؛ یه سری عوامل محیطی و رفتاری که ممکنه مانع راحت خوابیدن کوچولوی شما بشن. ما این عوامل رو شناسایی می کنیم و بهتون یاد میدیم چطوری برطرفشون کنید تا کوچولوی شما بتونه راحت تر بخوابه",
      en: "",
    },
  },
  sleepWay: {
    title: {
      fa: "انتخاب و یادگیری روش خواب",
      en: "",
    },
    desc: {
      fa:
        "حالا وقتشه که روش های اصلی یادگیری خواب رو به شما معرفی کنیم و با توجه به شرایط خودتون مناسب ترین روش بهتون پیشنهاد میشه. بعدش گام به گام با روشی که انتخاب کردید آشنا میشید و به صورت عملیاتی یاد میگیرید چطور اون رو اجرا کنید",
      en: "",
    },
  },
  dailySchedule: {
    title: {
      fa: "دنبال کردن برنامه روزانه",
      en: "",
    },
    desc: {
      fa:
        "وقتی به طور کامل روشی که انتخاب کردید رو یاد گرفتید، متناسب با سن کوچولوی شما و بقیه شرایط تون، برنامه روزانه رو دریافت می کنید و به کمک سامانه ما هر روز این برنامه رو دنبال می کنید. تو این مرحله مربی ها و مشاورین ما همراهتون هستن تا بتونید به بهترین نحو برنامه تون رو اجرا کنید و جواب هر سوالی که براتون پیش میاد رو بگیرین",
      en: "",
    },
  },
  commentsTitle: {
    fa: "نظرات مشتریان",
    en: "Our Clients",
  },
  commentsSubtitle: {
    fa: `مشتریان در مورد ${faBrand} چه می‌گویند`,
    en: "Lorem ipsum dolor sit amet",
  },
  sb: {
    fa: "موفقیت سریع",
    en: "",
  },
  sbDesc: {
    fa: "خواب بهتر در سریع ترین زمان",
    en: "",
  },
  ux: {
    fa: "کاربری آسان",
    en: "",
  },
  uxDesc: {
    fa: "استفاده راحت و بی دردسر",
    en: "",
  },
  returnPolicy: {
    fa: "بازگشت وجه",
    en: "",
  },
  returnPolicyDesc: {
    fa: "در صورت عدم رضایت",
    en: "",
  },
  support: {
    fa: "پشتیبانی",
    en: "",
  },
  supportDesc: {
    fa: "پاسخ گویی همه روزه",
    en: "",
  },
  about: {
    fa: `${faBrand} پاسخ مشکلات کلافه کننده ای ست که والدین در مورد خواب کودک با آن ها مواجه می شوند. محتوای آموزشی ${faBrand} حاصل تجربه چندین ساله متخصصین و مشاورین خواب کودک است که به شیوه ای تعاملی به والدین کمک می کند تا به کودک خود بهتر خوابیدن را بیاموزند.`,
    en:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare pulvinar convallis",
  },
  links: {
    title: { fa: "لینک ها", en: "Links" },
    solution: { fa: "برنامه روزانه", en: "Home" },
    features: { fa: "ویژگی‌ها", en: "Free demo" },
    progress: { fa: "ارزیابی پیشرفت", en: "Free demo" },
    why: { fa: `چرا ${faBrand}`, en: "Last news" },
    comments: { fa: "نظرات شما", en: "Blog" },
  },
  contact: {
    title: { fa: "تماس با ما", en: "Contact Us" },
    address1: {
      fa: "آدرس دفتر فروش: تهران، خیابان مطهری",
      en: "Sales Office:8 Number. 8th deadend. Kooh-e Noor St.",
    },
    address2: {
      fa: "خیابان کوه نور، بن بست هشتم، پلاک ۸",
      en: "Motahari St. Tehran. Iran",
    },
    phone: { fa: "۰۲۱ ۸۸۵۴۱۱۶۰", en: "+98 21 88541160" },
    email: { fa: "info@ghondagh.com", en: "info@ninixco.com" },
  },
  social: {
    title: { fa: "شبکه های اجتماعی", en: "Social Media" },
    subtite: {
      fa: "ما را در شبکه های اجتماعی دنبال کنید.",
      en: "Follow us on our social media",
    },
    instagram: { fa: "اینستاگرام", en: "Instagram" },
  },
  copyright: {
    fa: `${enBrand} Inc., ©2018-2019`,
    en: `${enBrand} Inc., ©2018-2019`,
  },
};
