import React from "react";
import { footerLinks, socialLinks } from "data/landing/links";
import { appStrings } from "data/landing/strings";
import logoPNG from "images/logo.png";

export interface FooterProps {
  lang?: string;
  dir?: string;
}

const Footer: React.FC<FooterProps> = ({ lang = "fa", dir = "rtl" }) => {
  return (
    <footer id="contact" className="bg-gray-100 text-navy" dir={dir}>
      <div className="container mx-auto py-8 px-2 lg:px-6">
        <div className="flex flex-col space-y-6 lg:grid lg:grid-cols-3 lg:gap-4 lg:space-y-0">
          <div>
            <img src={logoPNG} className="mb-4 w-32" alt="logo" />
            <p className="pr-1 pl-12 text-sm text-justify">
              {appStrings.about[lang]}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mb-6">
              {appStrings.links.title[lang]}
            </p>
            <div className="grid grid-cols-2 gap-2 pl-8">
              {footerLinks(lang).map((item, index) => (
                <a href={item.link} key={index}>
                  {item.title}
                </a>
              ))}
            </div>
          </div>
          <div>
            <p className="text-lg font-semibold mb-6">
              {appStrings.contact.title[lang]}
            </p>
            <div>
              <p>
                <a
                  href="https://www.google.com/maps/@35.7261982,51.422819,17.71z"
                  target="_blank"
                  rel="noreferrer"
                >
                  {appStrings.contact.address1[lang]}
                </a>
              </p>
              <p className="mb-3">
                <a
                  href="https://www.google.com/maps/@35.7261982,51.422819,17.71z"
                  target="_blank"
                  rel="noreferrer"
                >
                  {appStrings.contact.address2[lang]}
                </a>
              </p>
              <p
                className={`mb-3 ${dir === "rtl" ? "text-right" : "text-left"}`}
                style={{ direction: "ltr" }}
              >
                <a href="tel:02188541160">{appStrings.contact.phone[lang]}</a>
              </p>
              <p>
                <a href="mailto:info@ghondagh.com">
                  {appStrings.contact.email[lang]}
                </a>
              </p>
              <div className="flex flex-row mt-6">
                {socialLinks(lang).map((item, index) => (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    className="ml-8"
                  >
                    <img src={item.icon} alt={item.title} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="w-11/12 h-1 bg-gray-300 mx-auto my-10"></div>
        <p className="text-navy text-center">{appStrings.copyright[lang]}</p>
      </div>
    </footer>
  );
};

export default Footer;
