import { toPersian } from "utils/raqam";

export const faBrand = "مدرسه خواب";

export const platformStrings = {
  stayMethod: "روش ماندن",
  touchMethod: "روش لمسی",
  visitMethod: "روش ملاقات",
  boy: "پسر",
  girl: "دختر",
  kilo: "کیلو",
  geram: "گرم",
  yes: "بله",
  no: "خیر",
  none: "هیچکدوم",
  close: "بستن",
  timeError: "ساعت وارد شده نامعتبر است",
  dateError: "تاریخ وارد شده نامعتبر است",
  oldBabyError: "آموزش های خواب فقط مختص کودکان زیر ۱۸ ماه می باشند",
  youngError:
    "سن ۰ تا ۴ ماه برای شروع آموزش های خواب زوده، بهتره بزارید کودکتون حداقل ۴ ماهه باشه تا آموزش ها تاثیر خودشو بزاره",
  weightError: "وزن وارد شده نامعتبر است",
  requiredError: "لطفا تمامی فیلدها را کامل نمایید",
  sleepPlan: (name?: string) => `برنامه خواب ${name}`,
  save: "ذخیره",
  edit: "ویرایش",
  minutes: "دقیقه",
  unlimit: "نامحدود",
  nextStep: "مرحله بعد",
  remove: "پاک کردن",
  understood: "متوجه شدم",
  sanctomError: "خطای ارتباط با سرور",
  tryAgain: "تلاش مجدد",
  more: "مشاهده توضیح بیشتر",
  moreAboutGoalSchedule: "توضیحات بیشتر درباره برنامه هدف",
  hideMore: "پنهان کردن",
  day: (day: number) => `روز ${toPersian(day)}`,
  assessment: "ارزیابی",
  masterSleepPlan: "برنامه جامع خواب",
  sleepStealers: "خواب دزدها",
  dailyGuide: "راهنمای روزانه",
  readonly: "اطلاعات این فرم غیرقابل تغییر است",
  ml: "میلی لیتر",
  minute: "دقیقه",
  accessDenied: "دسترسی غیرمجاز",
  accessDeniedHint:
    "جهت دسترسی به این صفحه باید وارد حساب کاربری خود شده باشید.",
  login: "ورود به حساب کاربری",
  support: "پشتیبانی",
  back: "بازگشت",
  videosSummary: "خلاصه ویدئو",
  stopImpersonating: "بازگشت به پنل پشتیبانی",
  methodSelectionError: "لطفا یکی از ورش ها رو انتخاب کنید",
  loadMore: "مشاهده موارد بیشتر",
  timeFormat: "فرمت ساعت ۲۴ ساعته است",
};
