import FormItem from "components/form-elements/FormItem";
import ShortTextInput from "components/form-elements/ShortTextInput";
import SingleChoice from "components/form-elements/SingleChoice";
import SliderInput from "components/form-elements/SliderInput";
import NextStep from "components/utils/NextStep";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { babyInfoStrings } from "data/platform/babyInfoStrings";
import { genders } from "data/platform/genders";
import { platformStrings } from "data/platform/platformStrings";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent } from "react";

export interface BabyInformationFormProps {
  id?: number;
  gender?: string;
  name?: string;
  premature: number;
  weightKilo?: string;
  weightGeram?: string;
  day?: string;
  month?: string;
  year?: string;
  showName: boolean;
  showBirthday: boolean;
  showPremature: boolean;
  birthdayError?: string;
  birthdayAlert?: string;
  weightError?: string;
  assignedLicense: boolean;
  createLoading: boolean;
  weightLoading: boolean;
  handleGenderChange: (gender: string | number | boolean) => void;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePrematureChange: (value: SliderValue) => void;
  handleWeightKiloChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleWeightGeramChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDayChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleMonthChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleYearChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  handleCreateBaby: () => void;
  handleStoreWeight: () => void;
  handleSavePremature: () => void;
}

const BabyInformationForm: React.FC<BabyInformationFormProps> = ({
  id,
  gender,
  name,
  premature,
  day,
  month,
  year,
  weightGeram,
  weightKilo,
  showBirthday,
  showName,
  showPremature,
  birthdayError,
  birthdayAlert,
  assignedLicense,
  weightLoading,
  createLoading,
  weightError,
  handleNameChange,
  handleDayChange,
  handleMonthChange,
  handlePrematureChange,
  handleYearChange,
  handleGenderChange,
  handleWeightGeramChange,
  handleWeightKiloChange,
  handleSave,
  handleStoreWeight,
  handleCreateBaby,
  handleSavePremature,
}) => {
  return (
    <div className="mt-12">
      <SingleChoice
        label={babyInfoStrings.genderLabel}
        options={genders}
        selected={gender}
        onSelect={handleGenderChange}
      />
      {showName && (
        <PlatformSection isPassed={showBirthday}>
          <PlatformDivider />
          <ShortTextInput
            name="name"
            value={name}
            label={babyInfoStrings.nameLabel}
            placeholder={babyInfoStrings.namePlaceholder}
            onChange={handleNameChange}
            autoFocus
          />
          {!showPremature && <NextStep onNext={handleSave} />}
        </PlatformSection>
      )}
      {showPremature && (
        <PlatformSection isPassed={showBirthday}>
          <PlatformDivider />
          <SliderInput
            label={babyInfoStrings.preMatureLabel(name)}
            hint={babyInfoStrings.preMatureHint}
            value={premature}
            min={0}
            max={6}
            onChange={handlePrematureChange}
          />
          {!showBirthday && (
            <NextStep
              onNext={() => {
                handleSave();
                handleSavePremature();
              }}
            />
          )}
        </PlatformSection>
      )}
      {showBirthday && (
        <PlatformSection isPassed={id !== undefined && assignedLicense}>
          <PlatformDivider />
          <FormItem
            label={babyInfoStrings.birthdayLabel}
            hint={babyInfoStrings.birthdayHint}
          >
            {birthdayError && (
              <p className="font-semibold text-sm text-orange">
                {birthdayError}
              </p>
            )}
            {birthdayAlert && (
              <p className="font-semibold text-sm text-orange">
                {birthdayAlert}
              </p>
            )}
            <div className="grid grid-cols-3 gap-4">
              <ShortTextInput
                name="day"
                type="tel"
                placeholder={babyInfoStrings.dayPlaceholder}
                value={day}
                className="text-center"
                onChange={handleDayChange}
                autoFocus
              />
              <ShortTextInput
                name="month"
                type="tel"
                placeholder={babyInfoStrings.monthPlaceholder}
                value={month}
                className="text-center"
                onChange={handleMonthChange}
              />
              <ShortTextInput
                name="year"
                type="tel"
                placeholder={babyInfoStrings.yearPlaceholder}
                value={year}
                className="text-center"
                onChange={handleYearChange}
              />
            </div>
          </FormItem>
          {!birthdayError && (id === undefined || !assignedLicense) && (
            <NextStep loading={createLoading} onNext={handleCreateBaby} />
          )}
        </PlatformSection>
      )}

      {id && assignedLicense && (
        <PlatformSection>
          <PlatformDivider />
          <FormItem
            label={babyInfoStrings.weightLabel(name)}
            hint={babyInfoStrings.weightHint(name)}
          >
            {weightError && (
              <p className="font-semibold text-sm text-orange">{weightError}</p>
            )}
            <div className="grid grid-cols-2 gap-4">
              <ShortTextInput
                name="geram"
                type="tel"
                placeholder={platformStrings.geram}
                value={weightGeram}
                className="text-center"
                onChange={handleWeightGeramChange}
                tabIndex={2}
              />
              <ShortTextInput
                name="kilo"
                type="tel"
                placeholder={platformStrings.kilo}
                value={weightKilo}
                className="text-center"
                onChange={handleWeightKiloChange}
                autoFocus
                tabIndex={1}
              />
            </div>
          </FormItem>
          <NextStep loading={weightLoading} onNext={handleStoreWeight} />
        </PlatformSection>
      )}
    </div>
  );
};

export default BabyInformationForm;
