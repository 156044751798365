import { assessmentMenu, continueMenu, planMenu, supportMenu } from "data/menu";
import { faBrand, platformStrings } from "data/platform/platformStrings";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import MenuBox from "./MenuBox";

export interface MenuProps {
  open: boolean;
  isFinished?: boolean;
  isEvaluated?: boolean;
  state?: string;
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({
  open,
  isEvaluated = false,
  isFinished = false,
  state,
  onClose,
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="w-full lg:w-1/3 h-screen overflow-y-auto mr-0 bg-navy-400 text-jasmine outline-none relative"
      overlayClassName="bg-transparent fixed inset-0 z-50"
    >
      <AiOutlineClose
        className="absolute left-6 top-6 text-lg cursor-pointer"
        onClick={onClose}
      />
      <div className="flex flex-col py-12">
        {isEvaluated ? (
          <>
            <MenuBox
              toggleMenu={onClose}
              title={platformStrings.assessment}
              items={assessmentMenu}
            />
            <MenuBox
              toggleMenu={onClose}
              title={platformStrings.masterSleepPlan}
              items={planMenu(isFinished)}
            />
          </>
        ) : (
          <MenuBox
            toggleMenu={onClose}
            title={faBrand}
            items={continueMenu(state)}
          />
        )}
        <MenuBox
          toggleMenu={onClose}
          title={platformStrings.support}
          items={supportMenu}
        />
      </div>
    </Modal>
  );
};

export default Menu;
