import DailySliderInput from "components/form-elements/DailySliderInput";
import DailyTimeInput from "components/form-elements/DailyTimeInput";
import JungleButton from "components/form-elements/JungleButton";
import { nightStrings } from "data/platform/daily/nightStrings";
import { platformStrings } from "data/platform/platformStrings";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent } from "react";

export interface NightWakingFormProps {
  name?: string;
  nightWakingTime?: string;
  nightWakingSleepDuration: number;
  onTimeChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onSliderChange: (value: SliderValue) => void;
  onAddNightWaking: () => void;
}

const NightWakingForm: React.FC<NightWakingFormProps> = ({
  name,
  nightWakingTime,
  nightWakingSleepDuration,
  onAddNightWaking,
  onSliderChange,
  onTimeChange,
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <DailyTimeInput
        label={nightStrings.nightWakingTimeLabel(name)}
        value={nightWakingTime}
        onChange={onTimeChange}
      />
      <DailySliderInput
        label={nightStrings.nightWakingDurationLabel(name)}
        value={nightWakingSleepDuration}
        min={5}
        max={60}
        onChange={onSliderChange}
      />
      <JungleButton onClick={onAddNightWaking}>
        {platformStrings.save}
      </JungleButton>
    </div>
  );
};

export default NightWakingForm;
