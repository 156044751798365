import NextLink from "components/utils/NextLink";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { habitStrings } from "data/platform/sleep-stealers/habitStrings";
import StealersRate from "../StealersRate";

export interface HabitDescriptionProps {
  name?: string;
  rate: number;
}

const HabitDescription: React.FC<HabitDescriptionProps> = ({ rate, name }) => {
  return (
    <PlatformSection>
      <PlatformDivider />
      <StealersRate rate={rate} />
      <div className="flex flex-col space-y-4">
        {rate === 5 ? (
          <p className="text-justify">{habitStrings.completedRateDesc(name)}</p>
        ) : (
          <p className="text-justify">{habitStrings.lowRateDesc(rate, name)}</p>
        )}
        <p>{habitStrings.letsMove}</p>
        <NextLink to="/platform/sleep-stealers/schedule" />
      </div>
    </PlatformSection>
  );
};

export default HabitDescription;
