import DailySliderInput from "components/form-elements/DailySliderInput";
import DailyTimeInput from "components/form-elements/DailyTimeInput";
import { napStrings } from "data/platform/daily/napStrings";
import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent, Fragment } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaRegSquare } from "react-icons/fa";
import { FiSun } from "react-icons/fi";
import { toPersian } from "utils/raqam";
import { timeValidation } from "utils/timeUtils";
import GlassBox from "./GlassBox";
import NapSavedInfo from "./NapSavedInfo";

export interface NapProps {
  name?: string;
  title: string;
  label: string;
  hint?: string;
  startTime: string;
  endTime?: string;
  wakeTime: string;
}

export interface NapFormProps extends NapProps {
  index: number;
  sleep?: string;
  duration: number;
  wake?: string;
  isEditing: boolean;
  hasEmergencyNap: boolean;
  hasSlept?: boolean;
  minWake?: string;
  onTimeChange: (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
    keu: string
  ) => void;
  onSliderChange: (value: SliderValue) => void;
  onSave: () => void;
  onEdit: () => void;
  onOpenENModal: () => void;
}

const NapForm: React.FC<NapFormProps> = ({
  index,
  name,
  title,
  label,
  hint,
  startTime,
  endTime,
  wakeTime,
  wake,
  duration,
  sleep,
  isEditing,
  hasEmergencyNap,
  hasSlept,
  minWake,
  onSave,
  onEdit,
  onSliderChange,
  onTimeChange,
  onOpenENModal,
}) => {
  const sleepValidation = sleep ? timeValidation(sleep) : false;

  if (hasSlept === false) {
    return (
      <GlassBox
        title={
          <p className="text-lg font-semibold">
            <FiSun className="inline-block ml-2" />
            {title}
          </p>
        }
        isEditing={false}
        onSave={() => {}}
        onEdit={() => {}}
      >
        <div className="flex justify-between">
          <span>
            {label}
            <span data-tip={hint}>
              <AiOutlineQuestionCircle
                className="inline-block mr-2 cursor-pointer"
                transform="scale(-1,1)"
              />
            </span>
          </span>
          <div>
            <p>
              {endTime && `${toPersian(endTime)} - `}
              {toPersian(startTime)}
            </p>
            <p className="text-xs mt-1">
              {goalScheduleStrings.wakeBy(wakeTime, name)}
            </p>
          </div>
        </div>
        <p className="mt-6 pt-6 border-t border-navy">
          {napStrings.didntSleep(name)}
        </p>
      </GlassBox>
    );
  }
  return (
    <GlassBox
      title={
        <p className="text-lg font-semibold">
          <FiSun className="inline-block ml-2" />
          {title}
        </p>
      }
      isEditing={isEditing}
      onSave={onSave}
      onEdit={onEdit}
    >
      <div className="flex justify-between">
        <span>
          {label}
          <span data-tip={hint}>
            <AiOutlineQuestionCircle
              className="inline-block mr-2 cursor-pointer"
              transform="scale(-1,1)"
            />
          </span>
        </span>
        <div>
          <p>
            {endTime && `${toPersian(endTime)} - `}
            {toPersian(startTime)}
          </p>
          <p className="text-xs mt-1">
            {goalScheduleStrings.wakeBy(wakeTime, name)}
          </p>
        </div>
      </div>
      {isEditing ? (
        <Fragment>
          <DailyTimeInput
            label={napStrings.sleepLabel(name, index + 1)}
            value={sleep}
            onChange={(event, value) => onTimeChange(event, value, "sleep")}
          />
          {sleepValidation && (
            <DailySliderInput
              label={napStrings.durationLabel(name)}
              value={duration}
              min={5}
              max={60}
              onChange={onSliderChange}
            />
          )}
          {sleepValidation && (
            <DailyTimeInput
              label={napStrings.wakeLabel(name)}
              value={wake}
              min={minWake}
              onChange={(event, value) => onTimeChange(event, value, "wake")}
            />
          )}
          {!hasEmergencyNap && hasSlept !== true && (
            <p className="mt-8">
              <span
                className="text-orange font-semibold text-sm cursor-pointer select-none"
                onClick={onOpenENModal}
              >
                <FaRegSquare className="inline-block ml-2" />
                {napStrings.didntSleep(name)}
              </span>
            </p>
          )}
        </Fragment>
      ) : (
        <NapSavedInfo sleep={sleep} wake={wake} duration={duration} />
      )}
    </GlassBox>
  );
};

export default NapForm;
