export const activitiesErrors = (name?: string) => ({
  hasActivity: {
    title: `قبل از خواب ${name} باید براش یک سری فعالیت آرامش بخش به صورت روتین انجام بدید`,
    description: `قبل از خواب ${name} باید براش یک سری فعالیت آرامش بخش به صورت روتین انجام بدید فعالیت هایی مثل حموم کردن، ماساژ دادن، کتاب خوندن، تعویض لباس، تغذیه قبل از خواب، لالایی خوندن و تعویض پوشک می تونن جزو فعالیت های قبل خواب باشن. با توجه به سن کودک میتونید فعالیت های مختلفی انجام بدید فقط نکته مهم اینه که این فعالیت ها در همون اتاقی انجام بشن که ${name} توش میخوابه و در هنگام انجام این فعالیت ها ${name} به خواب نره. قبل از اینکه ${name} به خواب بره باید روی تخت خودش قرار گرفته باشه.`,
  },
  inRoom: {
    title: ` فعالیتهای قبل از خواب رو در اتاق خواب ${name} انجام بدین`,
    description: `کارهای قبل از خواب و چرت زدن رو در اتاق خواب ${name} انجام بدین تا با محیط اتاق سازگار بشه. انجام کارهای قبل از خواب در سایر اتاق ها ممکنه آمادگی برای خواب رو با مشکل مواجه کنه که در نهایت آروم شدن ${name} رو سخت میکنه.`,
  },
  activityList: {
    title: `انجام فعالیت های قبل از خواب هیچ مشکلی ندارن مگر در دو حالت`,
    description: `یک حالت اینکه باعث بشه کودک در حین این فعالیت و قبل از قرار گرفتن در تختش به خواب بره. دوم هم اینکه فعالیت به گونه ای باشه که ${name} حس کنه همیشه کنارش هستید؛ دراز کشیدن کنار نوزاد از این دسته فعالیت هاست. پس قبل از خواب کنار نوزادتون دراز نکشید و اونو راه نبرید تا به خواب بره.`,
  },
  duration: {
    title: `بهترین مدت زمان برای کارهای قبل از خواب ۴۵ دقیقه هست، اما مقداری کمتر یا بیشتر هم مشکلی نداره`,
    description: ``,
  },
  beforeNapActivity: {
    title: `برای چرت زدن آماده بشین`,
    description: `مهمه که ${name} به طور ناگهانی از بازی و تحرک سراغ خواب روزانه نره. لزومی نداره که آمادگی قبل از چرت زدن به اندازه آمادگی قبل از خواب شبانه طول بکشه. این آمادگی های کوتاه میتونه بین ۱۰ تا ۲۰ دقیقه زمان ببره. میتونین چند تا از کارهایی که شب برای آروم کردن ${name} انجام میدین رو به کار بگیرین. مثلا اگر وقتشه بهش شیر بدین، یا برای کودکتون کتاب یا آواز بخونین. فعالیت ها باید با ترتیبی مشابه با فعالیت های قبل از خواب شب و در اتاق خواب ${name} انجام بشن.`,
  },
});
