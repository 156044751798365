import React from "react";

const QouteIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.42"
      height="44.51"
      viewBox="0 0 52.42 44.51"
      className="fill-current text-navy lg:text-gray-100 transition duration-300 group-hover:text-navy"
    >
      <path
        d="M27.358,44.067A62.29,62.29,0,0,1,24.145,25.25q0-11.932,5.966-19.161t17.9-7.228V7.81q-5.737,0-8.376,3.1T37,20.661v2.065H48.7V44.067Zm-28.684,0A62.29,62.29,0,0,1-4.539,25.25q0-11.932,5.966-19.161t17.9-7.228V7.81q-5.737,0-8.376,3.1T8.311,20.661v2.065h11.7V44.067Z"
        transform="translate(4.539 1.139)"
      />
    </svg>
  );
};

export default QouteIcon;
