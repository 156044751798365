import { gql } from "@apollo/client";

export const CREATE_INFANT = gql`mutation create_infant($input:CreateInfantInput!){
    create_infant(input:$input){
      id
      name
      adjusted_age
      birthday
      gender
      premature
    }
  }`;
