import {
  dailyStrings,
  fourthDayString,
} from "data/platform/daily/dailyStrings";
import StartWrapper from "./StartWrapper";

export interface FourthDayStartProps {}

const FourthDayStart: React.FC<FourthDayStartProps> = () => {
  return (
    <StartWrapper
      title={dailyStrings.goodMorning}
      description={fourthDayString.description}
      video="20"
    />
  );
};

export default FourthDayStart;
