import { FiSun } from "react-icons/fi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { toPersian } from "utils/raqam";
import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";

export interface MorningBoxProps {
  name?: string;
  title: string;
  label: string;
  hint?: string;
  startTime: string;
  endTime?: string;
  wakeTime: string;
}

const MorningBox: React.FC<MorningBoxProps> = ({
  name,
  title,
  label,
  hint,
  startTime,
  endTime,
  wakeTime,
}) => {
  return (
    <div className="flex flex-col space-y-3">
      <ReactTooltip event="click" place="top" className="w-48" />
      <p className="text-jungle-400 font-semibold text-xl">
        <FiSun className="inline-block ml-2" />
        {title}
      </p>
      <div className="bg-white py-4 px-3 rounded-xl bg-opacity-10 flex justify-between text-jungle-400">
        <span>
          {label}
          <span data-tip={hint}>
            <AiOutlineQuestionCircle
              className="inline-block mr-2 cursor-pointer"
              transform="scale(-1,1)"
            />
          </span>
        </span>
        <div>
          <p>
            {endTime && `${toPersian(endTime)} - `}
            {toPersian(startTime)}
          </p>
          <p className="text-xs mt-1">
            {goalScheduleStrings.wakeBy(wakeTime, name)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MorningBox;
