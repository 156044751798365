import { IMenu } from "models/Menu";
import { NavLink } from "react-router-dom";

export interface MenuBoxProps {
  title: string;
  items: IMenu[];
  toggleMenu: () => void;
}

const MenuBox: React.FC<MenuBoxProps> = ({ title, items, toggleMenu }) => {
  return (
    <div className="flex flex-col space-y-6 px-8 border-b-2 border-gray-500 pb-8 mb-8">
      <p className="text-jungle-400 font-semibold">{title}</p>
      <div className="flex flex-col space-y-6">
        {items.map((item, index) => (
          <NavLink
            key={index}
            to={item.link}
            className="text-lg font-semibold"
            onClick={toggleMenu}
          >
            {item.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default MenuBox;
