import React, { FormEvent, ReactNode } from "react";
import loadingSVG from "images/platform/loading.svg";

export interface BlueButtonProps {
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (() => void) | ((event: FormEvent) => void);
}

const BlueButton: React.FC<BlueButtonProps> = ({
  children,
  disabled = false,
  loading = false,
  onClick,
}) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className="bg-gradient-to-l from-sky-600 to-sky-200 text-white py-3 px-8 text-xs font-medium rounded-xl mx-4 shadow-skyXl"
    >
      {children}
      {loading && (
        <img src={loadingSVG} alt="..." className="w-6 h-6 mr-2 inline-block" />
      )}
    </button>
  );
};

export default BlueButton;
