import { useMutation } from "@apollo/client";
import BabyInfo from "components/baby-info";
import { platformStrings } from "data/platform/platformStrings";
import { CREATE_INFANT } from "graphql/queries/baby";
import { ASSIGN_LICENSE } from "graphql/queries/license";
import { STORE } from "graphql/queries/store";
import { useMask } from "hooks/useMask";
import { IAppState } from "models/AppState";
import { SliderValue } from "models/SliderValue";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { appState } from "recoil/atoms/appState";
import { userState } from "recoil/atoms/auth";
import { babyState } from "recoil/atoms/baby";
import { licenseCodeState, licenseState } from "recoil/atoms/license";
import { babyObjectParser } from "utils/babyParser";
import raqam from "utils/raqam";
import {
  isFuture,
  jalaliDateValidation,
  monthDiff,
  parseJalaliObjectToStringDate,
} from "utils/timeUtils";

const BabyInformationScreen: React.FC = () => {
  const [baby, setBaby] = useRecoilState(babyState);
  const [app, setApp] = useRecoilState(appState);
  const [gender, setGender] = useState<string | undefined>(baby.gender);
  const [name, setName] = useState<string | undefined>(baby.name);
  const [preMature, setPreMature] = useState<number>(baby.premature || 0);
  const [step, setStep] = useState<number>(0);
  const [prematureSaved, setPrematureSaved] = useState<boolean>(false);
  const [assignedLicense, setAssignedLicense] = useRecoilState(licenseState);
  const user = useRecoilValue(userState);
  const licenseCode = useRecoilValue(licenseCodeState);
  const { push } = useHistory();

  const code =
    user?.licenses && user.licenses.length > 0
      ? user.licenses[0].code
      : licenseCode;

  const handleSavePremature = () => {
    setPrematureSaved(true);
  };

  const [weightKilo, maskedWeightKilo, setWeightKilo] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: baby.weightKilo?.toString(),
  });

  const [weightGeram, maskedWeightGeram, setWeightGeram] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: baby.weightGeram?.toString(),
  });

  const [day, dayMask, setDay] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: baby.day,
  });

  const [month, monthMask, setMonth] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: baby.month,
  });

  const [year, yearMask, setYear] = useMask({
    mask: (value: string) => {
      return raqam(value).value;
    },
    unmask: (value: string) => {
      return raqam(value).toEnglish().toString();
    },
    validator: (value: string) => {
      return !isNaN(Number(value));
    },
    defaultValue: baby.year,
  });

  const handleGenderChange = (value: string | number | boolean) => {
    if (baby.id !== undefined) return;
    setGender(value.toString());
    setBaby((currBaby) => ({ ...currBaby, gender: value.toString() }));
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (baby.id !== undefined) return;
    setName(event.currentTarget.value);
  };

  const handleWeightKiloChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWeightKilo(event.currentTarget.value);
  };

  const handleWeightGeramChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWeightGeram(event.currentTarget.value);
  };

  const handlePrematureChange = (value: SliderValue) => {
    if (baby.id !== undefined) return;
    setPreMature(value.x);
  };

  const handleDayChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (baby.id !== undefined) return;
    setDay(event.currentTarget.value);
  };

  const handleMonthChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (baby.id !== undefined) return;
    setMonth(event.currentTarget.value);
  };

  const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (baby.id !== undefined) return;
    setYear(event.currentTarget.value);
  };

  const handleSave = () => {
    setBaby({
      name,
      gender,
      day,
      year,
      month,
      premature: preMature,
      weightKilo: Number(weightKilo),
      weightGeram: Number(weightGeram),
    });
  };

  const [storeWeight, { loading: weightLoading }] = useMutation(STORE, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      setBaby((currBaby) => ({
        ...currBaby,
        weightGeram: Number(weightGeram),
        weightKilo: Number(weightKilo),
      }));
      handleNextStep(0);
      setApp({
        ...app,
        infantID: baby.id,
        dayCount: 15,
        state: "/platform/ready-to-begin",
      });
    },
  });

  const [assignLicense, { loading: licenseLoading }] = useMutation(
    ASSIGN_LICENSE,
    {
      onError: (error) => toast.error(error.message),
      onCompleted: (data) => {
        setAssignedLicense(true);
      },
    }
  );

  const [createBaby, { loading: babyLoading }] = useMutation(CREATE_INFANT, {
    onError: (error) => toast.error(error.message),
    onCompleted: (data) => {
      const parsedBaby = babyObjectParser(data.create_infant);
      setBaby(parsedBaby);
      assignLicense({
        variables: {
          code,
          infant_id: Number(parsedBaby.id),
        },
      });
    },
  });

  const handleCreateBaby = () => {
    const jalaliString = `${year}-${month}-${day}`;
    if (!jalaliDateValidation(jalaliString)) {
      toast.error(platformStrings.dateError);
      return;
    }

    const birthday = parseJalaliObjectToStringDate(jalaliString);

    if (name && gender && birthday)
      if (baby.id && !assignedLicense) {
        assignLicense({
          variables: {
            code,
            infant_id: Number(baby.id),
          },
        });
        return;
      }
    createBaby({
      variables: {
        input: {
          name,
          gender,
          birthday,
          premature: preMature,
        },
      },
    });
  };

  const handleStoreWeight = () => {
    const appState: IAppState = {
      ...app,
      infantID: baby.id,
      dayCount: 15,
      state: "/platform/ready-to-begin",
    };
    storeWeight({
      variables: {
        infant_id: baby.id,
        data: [
          { key: "weight", value: JSON.stringify({ weightKilo, weightGeram }) },
          { key: "appState", value: JSON.stringify(appState) },
        ],
      },
    });
  };

  const handleNextStep = (currStep: number) => {
    if (currStep === 6) {
      push("/platform/ready-to-begin");
    }
    setStep(currStep + 1);
  };

  useEffect(() => {
    if (step > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [step]);

  useEffect(() => {
    setName(baby.name);
    setGender(baby.gender);
    setDay(baby.day || "");
    setMonth(baby.month || "");
    setYear(baby.year || "");
    setPreMature(baby.premature || 0);
    if (baby.day && baby.month && baby.year) setPrematureSaved(true);
    window.scrollTo(0, document.body.scrollHeight);
    //eslint-disable-next-line
  }, [baby, assignedLicense]);

  const getMorningSleeps = () => {
    const age = baby.age || 0;
    if (age < 6) {
      return ["3", "4"];
    }
    if (age < 8) {
      return ["3", "3:30"];
    }
    if (age < 12) {
      return ["2", "3"];
    }
    if (age < 19) {
      return ["1:30", "2:30"];
    }
    return ["0", "0"];
  };

  let birthdayError = "";
  let birthdayAlert = "";
  if (day && month && year) {
    const dateString = `${year}-${month}-${day}`;
    if (!jalaliDateValidation(dateString) || isFuture(dateString)) {
      birthdayError = platformStrings.dateError;
    } else if (monthDiff(dateString) - preMature > 18) {
      birthdayError = platformStrings.oldBabyError;
    } else if (monthDiff(dateString) - preMature < 4) {
      birthdayAlert = platformStrings.youngError;
    }
  }

  let weightError = "";
  const weight = Number(weightKilo) + Number(weightGeram) / 1000;
  if (weightKilo && weightGeram && (weight < 1 || weight > 15)) {
    weightError = platformStrings.weightError;
  }

  return (
    <BabyInfo
      formProps={{
        id: baby.id,
        name,
        premature: preMature,
        day: dayMask,
        month: monthMask,
        year: yearMask,
        gender,
        weightGeram: maskedWeightGeram,
        weightKilo: maskedWeightKilo,
        showName: !!baby.gender,
        showBirthday: prematureSaved,
        showPremature: !!baby.name,
        birthdayError,
        birthdayAlert,
        assignedLicense,
        weightLoading,
        weightError,
        createLoading: babyLoading || licenseLoading,
        handleSavePremature,
        handleStoreWeight,
        handleCreateBaby,
        handleDayChange,
        handleMonthChange,
        handlePrematureChange,
        handleYearChange,
        handleGenderChange,
        handleNameChange,
        handleSave,
        handleWeightGeramChange,
        handleWeightKiloChange,
      }}
      descripitonProps={{
        step,
        age: baby.age,
        minSleep: getMorningSleeps()[0],
        maxSleep: getMorningSleeps()[1],
        name,
        onNext: handleNextStep,
      }}
    />
  );
};

export default BabyInformationScreen;
