import { Fragment, ReactNode } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";

export interface FormItemProps {
  label?: string;
  hint?: string;
  tooltip?: string;
  children?: ReactNode;
}

const FormItem: React.FC<FormItemProps> = ({
  label,
  hint,
  tooltip,
  children,
}) => {
  if (label || hint) {
    return (
      <div className="flex flex-col space-y-10">
        <ReactTooltip event="click" place="top" className="w-36 text-justify" />
        <p className="text-xl">
          {label}
          {tooltip && (
            <span data-tip={tooltip}>
              <AiOutlineQuestionCircle
                className="inline-block mr-2 cursor-pointer"
                transform="scale(-1,1)"
              />
            </span>
          )}
        </p>
        {hint && <p className="text-xs italic">{hint}</p>}
        {children}
      </div>
    );
  }
  return <Fragment>{children}</Fragment>;
};

export default FormItem;
