import { useMutation } from "@apollo/client";
import UpdateSchedule from "data/platform/sleep-plan/updateSchedule";
import { STORE } from "graphql/queries/store";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { babyState } from "recoil/atoms/baby";

export interface UpdateScheduleScreenProps {
  handleScheduleAge: () => void;
}

const UpdateScheduleScreen: React.FC<UpdateScheduleScreenProps> = ({
  handleScheduleAge,
}) => {
  const { name, age, id } = useRecoilValue(babyState);

  const [storeData, { loading }] = useMutation(STORE, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => toast.error(error.message),
    onCompleted: () => {
      handleScheduleAge();
    },
  });

  const handleSaveAge = () => {
    storeData({
      variables: {
        infant_id: id,
        data: [{ key: "scheduleAge", value: age?.toString() }],
      },
    });
  };

  return (
    <UpdateSchedule
      name={name}
      age={age || 0}
      loading={loading}
      handleScheduleAge={handleSaveAge}
    />
  );
};

export default UpdateScheduleScreen;
