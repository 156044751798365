export interface PlatformDividerProps {
  lowMargin?: boolean;
}

const PlatformDivider: React.FC<PlatformDividerProps> = ({
  lowMargin = false,
}) => {
  return (
    <div
      className={`w-full h-1 bg-jasmine rounded bg-opacity-60 ${
        lowMargin ? "my-4" : "my-16"
      } `}
    ></div>
  );
};

export default PlatformDivider;
