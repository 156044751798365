import PlatformContent from "components/layout/PlatfromContent";
import { platformStrings } from "data/platform/platformStrings";
import { goalScheduleStrings } from "data/platform/sleep-plan/goalScheduleStrings";
import goalScheduleIcon from "images/platform/goal-schedule.png";
import { IFeedingItem } from "models/FeedingItem";
import { Fragment } from "react";
import { FaChevronLeft, FaChevronRight, FaGripLines } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import UpdateScheduleScreen from "screens/sleep-plan/goal-schedule/updateSchedule";
import { scheduleNeedsUpdate } from "utils/checkScheduleUpdate";
import SleepPlanHeader from "../SleepPlanHeader";
import FeedingItem from "./FeedingItem";
import MorningBox, { MorningBoxProps } from "./MorningBox";
import NightTimeBox, { NightTimeBoxProps } from "./NightTime";

export interface GoalScheduleProps {
  name?: string;
  age: number;
  scheduleAge: number;
  nightBoxProps: NightTimeBoxProps;
  morningBoxData: MorningBoxProps[];
  weaningSchedule: Array<IFeedingItem[]>;
  isFF: boolean;
  isBoth: boolean;
  handleScheduleAge: () => void;
}

const GoalSchedule: React.FC<GoalScheduleProps> = ({
  name,
  age,
  nightBoxProps,
  morningBoxData,
  weaningSchedule,
  isFF,
  isBoth,
  scheduleAge,
  handleScheduleAge,
}) => {
  return (
    <Fragment>
      <SleepPlanHeader
        title={goalScheduleStrings.title}
        name={name}
        icon={goalScheduleIcon}
        nextLink="/platform/daily/1"
        backLink="/platform/method-in-action"
      />
      <PlatformContent>
        <div className="flex flex-col space-y-8">
          {scheduleNeedsUpdate(age, scheduleAge) && (
            <UpdateScheduleScreen handleScheduleAge={handleScheduleAge} />
          )}
          <p className="text-2xl text-jungle-400 font-semibold">
            {goalScheduleStrings.goalSchedule(name)}
          </p>
          <p className="text-justify">
            {goalScheduleStrings.description(name)}
          </p>
          <NightTimeBox {...nightBoxProps} name={name} />
          {morningBoxData.map((item, index) => (
            <MorningBox key={index} {...item} />
          ))}
          <NavLink
            to="/platform/goal-schedule/guide"
            className="text-lg italic"
          >
            <FaGripLines className="inline-block ml-2" />
            {platformStrings.moreAboutGoalSchedule}
          </NavLink>
          <p className="text-justify">
            {goalScheduleStrings.morningDescription1(name)}
          </p>
          <p className="text-justify">
            {goalScheduleStrings.morningDescription2(name)}
          </p>
          {!isFF && (
            <Fragment>
              <p className="text-justify">
                {goalScheduleStrings.morningDescription3(name)}
              </p>
              <NavLink
                to="/platform/sleep-stealers/night-feeding"
                className="text-orange"
              >
                {goalScheduleStrings.changeFeedingTImes}
                <FaChevronLeft className="inline-block mr-2" />
              </NavLink>
            </Fragment>
          )}
          <p className="text-justify">
            {goalScheduleStrings.morningDescription4(name)}
          </p>
          {weaningSchedule.length > 0 && (
            <Fragment>
              <p className="text-xl font-semibold text-jungle-400">
                {goalScheduleStrings.weaningSchedule(name)}
              </p>
              <div className="bg-white bg-opacity-10 py-4 px-3 rounded-xl flex flex-col space-y-4 w-2/3 mx-auto">
                {weaningSchedule.map((item, index) => (
                  <FeedingItem key={index} index={index + 1} items={item} />
                ))}
              </div>
              {isFF ? (
                <Fragment>
                  <p className="text-sm text-justify">
                    {goalScheduleStrings.weaningScheduleDescription2(
                      name,
                      isBoth
                    )}
                  </p>
                  <NavLink
                    to="/platform/sleep-stealers/night-feeding"
                    className="text-orange"
                  >
                    {goalScheduleStrings.unlimitedFeed(name)}
                    <FaChevronLeft className="inline-block mr-2" />
                  </NavLink>
                </Fragment>
              ) : (
                <Fragment>
                  <p className="text-sm text-justify">
                    {goalScheduleStrings.weaningScheduleDescription1(
                      name,
                      isBoth
                    )}
                  </p>
                  <NavLink
                    to="/platform/sleep-stealers/night-feeding"
                    className="text-orange"
                  >
                    {goalScheduleStrings.changeFeedingTImes}
                    <FaChevronLeft className="inline-block mr-2" />
                  </NavLink>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
        <p className="text-right mt-6">
          <NavLink
            to={`/platform/daily`}
            className="text-xl text-orange font-semibold"
          >
            <FaChevronRight className="inline-block ml-2" />
            {goalScheduleStrings.daily}
          </NavLink>
        </p>
      </PlatformContent>
    </Fragment>
  );
};

export default GoalSchedule;
