import { ReactNode } from "react";

export interface PlatformSectionProps {
  isPassed?: boolean;
  children: ReactNode;
}

const PlatformSection: React.FC<PlatformSectionProps> = ({
  children,
  isPassed = false,
}) => {
  return (
    <div className={`${isPassed ? "" : "min-h-screen"} pt-16`}>{children}</div>
  );
};

export default PlatformSection;
