import NextLink from "components/utils/NextLink";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { activityStrings } from "data/platform/sleep-stealers/activityStrings";
import StealersRate from "../StealersRate";

export interface ActivityDescriptionProps {
  name?: string;
  rate: number;
}

const ActivityDescription: React.FC<ActivityDescriptionProps> = ({
  name,
  rate,
}) => {
  return (
    <PlatformSection>
      <PlatformDivider />
      <StealersRate rate={rate} />
      <div className="flex flex-col space-y-4">
        {rate === 5 ? (
          <p className="text-justify">
            {activityStrings.completeRateDesc(name)}
          </p>
        ) : (
          <p className="text-justify">
            {activityStrings.lowRateDesc(rate, name)}
          </p>
        )}
        <p>{activityStrings.letsMove}</p>
        <NextLink to="/platform/sleep-stealers/habits" />
      </div>
    </PlatformSection>
  );
};

export default ActivityDescription;
