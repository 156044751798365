import { IOption } from "models/Option";
import { whenToBeginStrings } from "./whenToBeginStrings";

export const feedingMethods: IOption[] = [
  {
    label: whenToBeginStrings.breastFeeding,
    value: "mother",
  },
  {
    label: whenToBeginStrings.bottleFeeding,
    value: "bottle",
  },
  {
    label: whenToBeginStrings.bothFeeding,
    value: "both",
  },
];
