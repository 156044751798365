import { IBaby } from "models/Baby";
import { parseDateToJalaliArray } from "./timeUtils";

export const babyObjectParser = (item: any) => {
  const [year, month, day] = parseDateToJalaliArray(item.birthday);
  return {
    name: item.name,
    age: Number(item.adjusted_age),
    day,
    month,
    year,
    gender: item.gender,
    id: Number(item.id),
    premature: item.premature,
  };
};

export const babyArrayParser = (babyArray: any[]) => {
  const babies: IBaby[] = babyArray.map((item) => {
    const baby = babyObjectParser(item);
    return baby;
  });
  return babies;
};
