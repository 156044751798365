import { FormEvent, ReactNode } from "react";
import loadingSVG from "images/platform/blackLoading.svg";

export interface JungleButtonProps {
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick: (() => void) | ((event: FormEvent) => void);
}

const JungleButton: React.FC<JungleButtonProps> = ({
  children,
  disabled = false,
  loading = false,
  onClick,
}) => {
  return (
    <button
      className="bg-jungle-400 py-2 text-navy rounded-lg"
      disabled={disabled || loading}
      onClick={onClick}
    >
      {children}
      {loading && (
        <img src={loadingSVG} alt="..." className="w-6 h-6 mr-2 inline-block" />
      )}
    </button>
  );
};

export default JungleButton;
