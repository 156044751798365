import React, { useState } from "react";
import { menuItems } from "data/landing/menu";
import NavItem from "./NavItem";
import { appStrings } from "data/landing/strings";
import BlueButton from "components/utils/BlueButton";
import logoPNG from "images/logo.png";
import { useRecoilValue } from "recoil";
import { authState } from "recoil/atoms/auth";
import avatarPNG from "images/platform/avatar.png";
import RedButton from "components/utils/RedButton";

export interface NavbarProps {
  lang?: string;
  dir?: string;
}

const Navbar: React.FC<NavbarProps> = ({ lang = "fa", dir = "rtl" }) => {
  const auth = useRecoilValue(authState);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <nav className="bg-white">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div
            className={`absolute inset-y-0 ${
              dir === "rtl" ? "left-0" : "right-0"
            } flex items-center lg:hidden`}
          >
            {/* <!-- Mobile menu button--> */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-navy focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded={openMenu}
              onClick={toggleMenu}
            >
              <span className="sr-only">{appStrings.menu[lang]}</span>
              {/* <!-- Icon when menu is closed. -->
          <!--
            Heroicon name: menu

            Menu open: "hidden", Menu closed: "block"
          --> */}
              <svg
                className={`${openMenu ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* <!-- Icon when menu is open. -->
          <!--
            Heroicon name: x

            Menu open: "block", Menu closed: "hidden"
          --> */}
              <svg
                className={`${!openMenu ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-between lg:items-stretch lg:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="block lg:hidden h-8 w-auto"
                src={logoPNG}
                alt="Logo"
              />
              <img
                className={`hidden lg:block h-8 w-auto ${
                  dir === "rtl"
                    ? "pl-4 ml-4 border-l-2"
                    : "pr-4 mr-4 border-r-2"
                } border-gray-200`}
                src={logoPNG}
                alt="Logo"
              />
            </div>
            <div className="hidden lg:block lg:ml-6">
              <div className="flex space-x-4">
                {menuItems(lang).map((menu, index) => (
                  <NavItem key={index} {...menu} toggleMenu={toggleMenu} />
                ))}
              </div>
            </div>
          </div>
          <div className="hidden absolute inset-y-0 right-0 lg:flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
            {!auth ? (
              <a
                href={`${process.env.REACT_APP_ACCOUNT_URL}/login/?from=${window.location.href}`}
                target="_self"
              >
                <BlueButton>{appStrings.login[lang]}</BlueButton>
              </a>
            ) : (
              <div className="flex flex-row">
                <a href="/platform" target="_self" className="ml-4">
                  <RedButton>{appStrings.enterDreamlab[lang]}</RedButton>
                </a>
                <a href={process.env.REACT_APP_ACCOUNT_URL}>
                  <img
                    src={avatarPNG}
                    className="w-10 h-10 rounded-full mx-auto"
                    alt="user-avatar"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <!--
    Mobile menu, toggle classes based on menu state.

    Menu open: "block", Menu closed: "hidden"
  --> */}
      <div className={`${openMenu ? "block" : "hidden"} lg:hidden`}>
        <div className="flex flex-col px-2 pt-2 pb-3 space-y-1">
          {menuItems(lang).map((menu, index) => (
            <NavItem key={index} {...menu} toggleMenu={toggleMenu} />
          ))}
        </div>
        <div className="flex items-center static linset-auto justify-center lg:hidden">
          {!auth ? (
            <a
              href={`${process.env.REACT_APP_ACCOUNT_URL}/login/?from=${window.location.href}`}
              target="_self"
            >
              <BlueButton>{appStrings.login[lang]}</BlueButton>
            </a>
          ) : (
            <div className="flex flex-row mb-6">
              <a href="/platform" target="_self" className="ml-4">
                <RedButton>{appStrings.enterDreamlab[lang]}</RedButton>
              </a>
              <a href={process.env.REACT_APP_ACCOUNT_URL}>
                <img
                  src={avatarPNG}
                  className="w-10 h-10 rounded-full mx-auto"
                  alt="user-avatar"
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
