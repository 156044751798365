import StarRate from "components/utils/StarRate";

export interface StealersRateProps {
  rate: number;
}

const StealersRate: React.FC<StealersRateProps> = ({ rate }) => {
  return (
    <div className="text-2xl my-8">
      <StarRate
        rate={rate}
        className={`text-2xl ${rate === 5 ? "text-jungle-400" : "text-orange"}`}
      />
    </div>
  );
};

export default StealersRate;
