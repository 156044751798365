import NextLink from "components/utils/NextLink";
import PlatformDivider from "components/utils/PlatformDivider";
import PlatformSection from "components/utils/PlatformSection";
import { nightFeedingStrings } from "data/platform/sleep-stealers/nightFeedingStrings";
import StealersRate from "../StealersRate";

export interface NightFeedingDescriptionProps {
  rate: number;
  lastRate?: number;
  name?: string;
}

const NightFeedingDescription: React.SFC<NightFeedingDescriptionProps> = ({
  name,
  rate,
  lastRate,
}) => {
  return (
    <PlatformSection>
      <PlatformDivider />
      <StealersRate rate={rate} />
      <div className="flex flex-col space-y-4">
        {rate === 5 ? (
          <p className="text-justify">
            {nightFeedingStrings.completedStarDesc(name)}
          </p>
        ) : (
          <>
            <p className="text-justify">
              {nightFeedingStrings.lowRateDesc(rate, name)}
            </p>
            {lastRate !== undefined && (
              <p className="text-justify bg-orange text-navy py-2 px-3 rounded-lg">
                {nightFeedingStrings.changeAlert}
              </p>
            )}
          </>
        )}
        <p>{nightFeedingStrings.letsMove}</p>
        <NextLink
          to={
            lastRate !== undefined
              ? "/platform/whats-missing"
              : "/platform/method-selection"
          }
        />
      </div>
    </PlatformSection>
  );
};

export default NightFeedingDescription;
